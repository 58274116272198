import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { Component } from "react";
import ServerlessService from "../../../Service/ServerlessService";
import ProfileDetails from "./profileDetails";
import { BasicLazyParams } from "../../../Utils/constants";
import { logOut } from "../../../Utils";


class Profiles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profiles: null,
            isShowDetails: false,
            isShowTable: true,
            globalSearch: "",
            lazyParams: {
                ...BasicLazyParams,
                sortField: "createdAt",
                sortOrder: 1,
            },
        };
        this.serverlessService = new ServerlessService();
    }
    getProfiles = () => {
        let { lazyParams, globalSearch } = this.state;

        this.setState({
            isLoading: true,
        });

        const url = `/mprofiles/filter?limit=${lazyParams.rows}&page=${lazyParams.page + 1
            }&search=${globalSearch}${lazyParams.sortField
                ? `&sort=${lazyParams.sortOrder === 1 ? "" : "-"}${lazyParams.sortField
                }`
                : ""
            }`;

        this.serverlessService
            .post(url, {}, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    console.log(res.res.data, "get Profiles data");


                    this.setState({
                        isLoading: false,
                        profiles: res.res.data,
                        totalProfiles: res.res.totalRecords
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    onSuccessfullyApproved = () => {
        this.setState(
            {
                isShowDetails: false,
            },

            this.getProfiles
        );
    };

    onActions = (profileId) => {
        this.setState({
            isShowDetails: true,
            profileId,
        });
    };

    onHide = () => {
        this.setState({
            isShowDetails: false,
            profileId: null,
        });
    };

    onPage = (event) => {
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event,
                },
            };
        }, this.getProfiles);
    };

    onSort = (event) => {
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event,
                },
            };
        }, this.getProfiles);
    };

    onFilter = (event) => {
        event["first"] = 0;
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    filters: event.filters,
                },
            };
        }, this.getProfiles);
    };

    componentDidMount() {
        this.getProfiles();
    }

    render() {
        const { profiles, isShowDetails, isShowTable } = this.state;
        console.log(this.state.profiles, "profiles");
        return (
            <div className="h-screen overflow-auto p-4">
                <span className="text-1000 font-medium text-4xl  mb-3">
                    Pending Profiles
                </span>
                <br />
                <div>
                    <DataTable
                        lazy
                        onSort={this.onSort}
                        onPage={this.onPage}
                        onFilter={this.onFilter}
                        first={this.state.lazyParams.first}
                        last={this.state.totalProfiles}
                        rows={this.state.lazyParams.rows}
                        totalRecords={this.state.totalProfiles}
                        paginator
                        rowsPerPageOptions={[5, 10, 25]}
                        id="_id"
                        selection={this.state.selectedCustomer}
                        onSelectionChange={(e) =>
                            this.setState({ selectedCustomer: e.value })
                        }
                        selectionMode="single"
                        stripedRows
                        value={profiles}
                    >
                        <Column field="firstName" header="Name"></Column>
                        <Column field="address" header="Address"></Column>
                        <Column
                            field="phoneNumber"
                            header="Phone"
                        ></Column>
                        <Column
                            body={(rowData) => {
                                return (
                                    <>
                                        <Button
                                            label="Actions"
                                            rounded
                                            outlined
                                            onClick={() =>
                                                this.onActions(rowData._id)
                                            }
                                        />
                                    </>
                                );
                            }}
                            header="Actions"
                        ></Column>
                    </DataTable>
                </div>

                {isShowDetails && (
                    <ProfileDetails
                        profileId={this.state.profileId}
                        onHide={this.onHide}
                        onSuccessfullyApproved={this.onSuccessfullyApproved}
                    ></ProfileDetails>
                )}
            </div>
        );
    }
}

export default Profiles;
