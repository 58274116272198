import React, { Component } from "react";
import { Toast } from "primereact/toast";
import ServerlessService from "../../../Service/ServerlessService";
import { Button } from "primereact/button";
import { BasicLazyParams, CONSULTANT_STATUS } from "../../../Utils/constants";
import { Tooltip } from "primereact/tooltip";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";

import { Dropdown } from "primereact/dropdown";
import "./styles.scss";
import {
    getLearningResources,
    resetLazyLearningResources,
} from "../../../store/actions";
import BaseComponent from "../../common/BaseComponent";

import Authorize, { PERMISSIONS } from "../../../session/authorize";

class ResourceSelection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tableHistorysData: null,
            isShowRenewal: false,
            globalSearch: "",
            lazyParams: {
                ...BasicLazyParams,
                sortField: "createdAt",
                sortOrder: 1,
            },
            isShowRenewal: false,
            globalSearch: "",
            selectedReason: "",
            resources: {},
            allresouces: [],
            selectedResource: null,
            showAddResource: false,
            filterdResources: [],
            selectedRows: [],
            updatedResource: {},
        };
        this.serverlessService = new ServerlessService();
    }

    onGlobalSearch = (e) => {
        this.props.getLearningResources({ globalSearch: e.target.value });
    };

    onPage = (event) => {
        this.props.getLearningResources({ lazyParams: event });
    };

    onSort = (event) => {
        this.props.getLearningResources({ lazyParams: event });
    };

    onFilter = (event) => {
        event["first"] = 0;
        this.props.getLearningResources({ lazyParams: event });
    };

    componentDidMount() {
        this.props.getLearningResources();

        const { resources } = this.props;
        this.setState({ selectedRows: resources });
    }

    toggleAddResource = () => {
        this.setState((prevState) => ({
            showAddResource: !prevState.showAddResource,
        }));
    };

    createRoleDialogFooter = () => {
        return (
            <div className="mt-4">
                <Button
                    label="Cancel"
                    className="p-button-outlined"
                    onClick={this.toggleAddResource}
                />
                <Button
                    label={"Add"}
                    className="ml-2 mr-2"
                    onClick={() => {
                        this.props.onSelectedResourcesChange(
                            this.state.updatedResource
                        );
                        this.toggleAddResource();
                    }}
                />
            </div>
        );
    };

    onSelectionChange = (e) => {
        this.setState({ selectedRows: e.value }, () => {

            const { resources, day } = this.props;

            const currentResources = resources[day] || [];

            const previousResources = Object.values(resources).reduce(
                (prev, curr) => prev.concat(curr),
                []
            );

            const updatedResourcesForDay = [...e.value];

            const updatedResources = {
                [day]: updatedResourcesForDay,
            };

            this.setState({
                updatedResource: updatedResources,
            });
            
        });
    };

    handleRemoveResource = (resourceToRemove) => {
        
        const { resources, day, onDeletedResourcesChange } = this.props;
      
        const currentResources = resources;

        const indexToRemove = currentResources?.findIndex(
            (resource) => resource._id === resourceToRemove._id
        );

        if (indexToRemove !== -1) {
            const updatedResources = [
                ...currentResources?.slice(0, indexToRemove),
                ...currentResources?.slice(indexToRemove + 1),
            ];
            const updatedResourcesWithDay = {
                [day]: updatedResources,
            };

            onDeletedResourcesChange(updatedResourcesWithDay);
            this.setState({ selectedRows: updatedResources }, () => {
            });
        }
    };

    render() {
        const {
            showAddResource,
            selectedResource,
            filterdResources,
            selectedRows,
        } = this.state;
        const { day, resources } = this.props;
        const resourcesArray = Array.isArray(resources)
            ? resources
            : [resources];
      

        const header = (
            <div className="">
                <div class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 top-100 z-1 shadow-2 lg:shadow-none border-1 lg:border-none border-gray-800">
                    <ul class="list-none flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li>
                            <p className="mx-0 my-1 ss-header">Resources</p>
                        </li>
                    </ul>
                    <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li class="flex-order-2 lg:flex-order-0">
                            <ul className="right-0">
                                <ul className="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                                    <li className="flex-order-2 lg:flex-order-0 ml-4 ">
                                        {this.state.showSearch && (
                                            <InputText
                                                placeholder="Search"
                                                className=""
                                                value={this.props.globalSearch}
                                                onChange={this.onGlobalSearch}
                                            />
                                        )}
                                    </li>

                                    <Authorize
                                        permId={
                                            PERMISSIONS.CONSULTANTS_GENERAL_SEARCH
                                        }
                                    >
                                        <li className="flex-order-2 lg:flex-order-0 ml-4 cursor-pointer">
                                            <i
                                                data-pr-tooltip="Search"
                                                data-pr-position="bottom"
                                                className="pi pi-search text-base lg:text-2xl mr-2 ss-tbl-srch"
                                                onClick={(e) => {
                                                    this.setState({
                                                        showSearch:
                                                            !this.state
                                                                .showSearch,
                                                    });
                                                }}
                                            ></i>
                                        </li>
                                        <Tooltip
                                            className="table-li-tooltip"
                                            autoHide={false}
                                            target=".ss-tbl-srch"
                                        />
                                    </Authorize>
                                </ul>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="flex justify-content-between flex-wrap">
                    <div class="flex font-bold border-round w-4"></div>
                    <div class="flex absolute right-0 align-items-right  font-bold border-round w-8 "></div>
                </div>
            </div>
        );
        return (
            <div>
                <div className="font-medium text-2xl text-900 mb-3">
                    Day {day}
                    <span
                        style={{ marginLeft: "1rem" }} // Adjust margin for the plus icon
                    >
                        <Button
                            icon="pi pi-plus-circle"
                            className="p-button-rounded "
                            onClick={this.toggleAddResource}
                        />
                    </span>
                    {showAddResource && (
                        <>
                            <Dialog
                                style={{ width: "80%" }}
                                // header={header}
                                modal
                                footer={this.createRoleDialogFooter}
                                draggable={false}
                                blockScroll={true}
                                closeOnEscape={false}
                                dismissableMask={false}
                                visible={true}
                                closable={true}
                                onHide={this.toggleAddResource}
                            >
                                <DataTable
                                    value={this.props.learningResources}
                                    lazy
                                    header={header}
                                    scrollHeight="400px"
                                    onSort={this.onSort}
                                    onPage={this.onPage}
                                    onFilter={this.onFilter}
                                    first={this.props.lazyParams.first}
                                    last={this.props.totalRecords}
                                    rows={this.props.lazyParams.rows}
                                    totalRecords={this.props.totalRecords}
                                    paginator
                                    paginatorLeft
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    rowsPerPageOptions={[
                                        5, 10, 25, 50, 100, 200, 300, 500,
                                    ]}
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                    scrollable
                                    responsiveLayout="scroll"
                                    rowHover
                                    dataKey="_id"
                                    selection={selectedRows}
                                    onSelectionChange={this.onSelectionChange}
                                >
                                    <Column
                                        selectionMode="multiple"
                                        headerStyle={{ width: "3em" }}
                                    />
                                    <Column field="title" header="Title" />
                                    <Column
                                        field="resource"
                                        header="Resource"
                                    />
                                    <Column
                                        field="description"
                                        header="Description"
                                    />
                                </DataTable>
                            </Dialog>
                        </>
                    )}
                    <div>
                        <div className="text-500 font-medium mb-2">
                            Resources
                        </div>
                        <Dropdown
                            value={this.state.selectedResource}
                            options={resourcesArray}
                            onChange={(e) =>
                                this.setState({ selectedResource: e.value })
                            }
                            optionLabel="title"
                            filter
                            filterBy="title"
                            placeholder="Check added Resources"
                            className="p-mb-2"
                            style={{ width: "10%" }}
                            itemTemplate={(option) => (
                                <div className="p-clearfix">
                                    <div style={{ float: "left" }}>
                                        {option.title}
                                    </div>
                                    <Button
                                        icon="pi pi-times"
                                        className="p-button-rounded p-button-text"
                                        style={{
                                            float: "right",
                                            color: "#6366F1",
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            this.handleRemoveResource(option);
                                        }}
                                    />
                                </div>
                            )}
                        />
                        <hr />
                    </div>
                    <Toast
                        ref={(el) => (this.toast = el)}
                        position="bottom-right"
                    />
                    {this.props.isLoading ||
                        (this.state.isLoading && <LoadingComponent />)}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    learningResources: state?.learningResources?.data,
    totalRecords: state?.learningResources?.totalRecords,
    lazyParams: state?.learningResources?.lazyParams,
    isLoading: state?.learningResources?.isLoading,
});

export default BaseComponent(ResourceSelection, mapStateToProps, {
    getLearningResources,
    resetLazyLearningResources,
});
