import React, { Component } from "react";
import { Toast } from "primereact/toast";
import Service from "../../../Service";
import ServerlessService from "../../../Service/ServerlessService";
import Authentication from "./../../../session/index";
import LoadingComponent from "../../../LoadingComponent";
import { BasicLazyParams } from "../../../Utils/constants";
import NavFooter from "../../navFooter";
import { Accordion, AccordionTab } from "primereact/accordion";
import { connect } from "react-redux";
import { getConsultantFaqs, clearConsltantsFaqs } from "../../../store/actions";
import { DataView } from "primereact/dataview";
import './styles.scss'
import { Card } from "primereact/card";

class SingleFAQ extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: null,
        };

    }

    toggleAccordion = (index) => {
        this.setState({
            activeIndex: this.state.activeIndex === index ? null : index,
        });
    };


    render() {
        const { activeIndex } = this.state;
        const questionPanels = [this.props.faq].map((ques, index) => (
            <AccordionTab
                key={index}
                header={ques.question}
                contentClassName="p-p-3"
                active={activeIndex === index}

                onClick={() => this.toggleAccordion(index)}
            >
                <p>{ques.answer}</p>
            </AccordionTab>
        ));

        return <>
            <Accordion>{questionPanels}</Accordion>
        </>

    }

}



class ConsultantFAQs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lazyParams: {
                ...BasicLazyParams,
                sortField: "createdAt",
                sortOrder: 1,
            },
            questions: [],
            activeIndex: null,
        };

        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }



    componentDidMount() {
        this.props.getConsultantFaqs();
    }

    onPage = (event) => {

        this.props.getConsultantFaqs({ lazyParams: event });
    };



    itemTemplate(data) {
        return (<div className="grid">
            <div className="col-12">
                <SingleFAQ faq={data} />
            </div>
        </div>
        );
    }


    render() {
        const { questions, activeIndex } = this.state;
        const { faqs } = this.props;


        return (
            <>
                <NavFooter>
                    <div className='bg-white'>
                        <div className="mx-8 py-8">

                            <div className="text-3xl font-medium text-900 mb-4">FAQs</div>
                            <br />

                            <DataView value={this.props.faqs} layout={'list'}
                                itemTemplate={this.itemTemplate} paginator
                                gutter={false}
                                lazy
                                first={this.props.lazyParams.first}
                                last={this.props.totalRecords}
                                rows={this.props.lazyParams.rows}
                                onPage={this.onPage}
                                totalRecords={this.props.totalRecords}
                                className="consultant-faqs"

                            />

                            <Card title="Not Found answers?" subTitle="Please let us know what is your concern/question?" className=" my-4">

                            </Card>
                        </div>


                    </div>
                    <Toast
                        ref={(el) => (this.toast = el)}
                        position="bottom-right"
                    />
                    {(this.state.isLoading || this.props.isLoading) && (
                        <LoadingComponent />
                    )}
                </NavFooter >
            </>
        );
    }
}



const mapStateToProps = (state) => ({
    faqs: state?.consultantFAQs?.faqs,
    totalRecords: state?.consultantFAQs?.totalRecords,
    lazyParams: state?.consultantFAQs?.lazyParams,
    isLoading: state?.consultantFAQs?.isLoading,
});


export default connect(mapStateToProps, {
    getConsultantFaqs, clearConsltantsFaqs

})(Authentication(ConsultantFAQs));
