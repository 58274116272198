// import React from 'react';
// import ReactDOM from 'react-dom';
// // import App from './App';
// import reportWebVitals from './reportWebVitals';
// import { HashRouter, BrowserRouter, Router } from 'react-router-dom';

// import App from './MainApp';

// import { Provider } from 'react-redux';
// import store from './store'

// ReactDOM.render(
//     <HashRouter>
//         <Provider store={store}>

//             <App />

//         </Provider>
//     </HashRouter>
//     , document.getElementById('root')
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();


import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { HashRouter } from 'react-router-dom';
import store, { persistor } from './store';
import App from './MainApp';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <Provider store={store}>

    <HashRouter>
      <App />
    </HashRouter>

  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
