import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

export default function (ComposedComponent, mapStateToProps = (() => { }), mapAtionsToProps = {}, isAuthNotRequired = false) {
    class BaseComponent extends Component {
     
       
        render() {
            const params = new URLSearchParams(this.props?.location?.search | '');
            const queryFields = {};
            for (const [key, value] of params.entries()) {
                queryFields[key] = value;
            }
            const { match } = this.props;
            return (
                <>
                    <ComposedComponent {...this.props}
                        query={queryFields}
                        params={match?.params}
                       
                    />
                </>
            );
        }
    }

    return connect(mapStateToProps, {
        ...mapAtionsToProps
    })(withRouter(BaseComponent));
}

