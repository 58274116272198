import * as React from "react";
//import { S3_UPLOAD_CONFIG, StaticConstants } from './../../constants'
import AWS from "aws-sdk";

// import { baseUrlAdmin } from "../../store/apiConstants";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Authentication from "../../session/index";

import { v4 as uuidv4 } from "uuid";
import OtherService from "../../Service/OtherService";
import { logOut } from "../../Utils";

// const s3Bucket = new AWS.S3({
//     accessKeyId: S3_UPLOAD_CONFIG.accessKeyId,
//     secretAccessKey: S3_UPLOAD_CONFIG.secretAccessKey,
//     region: S3_UPLOAD_CONFIG.region,

// });

class FileUploadLambda2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dirName: this.props.dirName,
            uploadedFileInfo: [],
            uploadedFilesInfo: [],
            accept: this.props.accept,
            maxFileSize: this.props.maxFileSize
                ? this.props.maxFileSize
                : 1073741824,
        };

        this.service = new OtherService();
    }

    convertDataURIToBinary(dataURI) {
        var BASE64_MARKER = ";base64,";
        var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
        var base64 = dataURI.substring(base64Index);
        var raw = window.atob(base64);
        var rawLength = raw.length;
        var array = new Uint8Array(new ArrayBuffer(rawLength));

        for (let i = 0; i < rawLength; i++) {
            array[i] = raw.charCodeAt(i);
        }
        return array;
    }

    // fileUrploadChangeToBinary1 = (f) => {

    //     if (f) {
    //         // if (/(jpe?g|png|gif)$/i.test(f.type)) {
    //         var r = new FileReader();
    //         let thisObj = this;
    //         r.onload = (e) => {
    //             var base64Img = e.target.result;

    //             var binaryImg = this.convertDataURIToBinary(base64Img);
    //             // this.setState({
    //             //     attachFileTestBinary: binaryImg
    //             // });

    //             let url = 'https://v2ao5dt0v3.execute-api.ap-south-1.amazonaws.com/prod/v1/data/presign/url';
    //             let cloudFrontEndpoint = 'https://d1wqfechwjsewm.cloudfront.net/';

    //             this.service.post(url, {
    //                 "type": "insert",
    //                 "key": thisObj.props.user.user.orgId + '/' + uuidv4() + f.name.replace(/\s/g, "")
    //             }).then((data) => {
    //                 console.log('presignedURL', data.res);

    //                 if (data && data.res && data.res.presignedURL)
    //                     this.service.put(data.res.presignedURL, binaryImg, false).then((response) => {
    //                         //    console.log(response, 'response')
    //                         if (response && response.status) {
    //                             // this.service.post(`${ baseUrlAdmin } / se3fetch`, { preUrl: data.res.imageStorageKey }, false).then((res2) => {
    //                             //     if (res2 && res2.status && res2?.res) {
    //                             //         data.res.storageUrl = res2?.res
    //                             this.props.onSucess(f.name, data.res)
    //                             //  }
    //                             // if (response && response.status) {

    //                             //     this.props.onSucess(f.name, data.res)
    //                             // }

    //                             //  })
    //                             //  this.props.onSucess(f.name, data.res)
    //                         }

    //                     }).catch(e => {
    // if(e?.response?.status > 400 && e?.response?.status < 500) {
    // logOut()
    // }
    //                         console.log(e, 'bb')
    //                         alert(e.message)
    //                     })

    //             }).catch(e => {
    // if (e?.response?.status > 400 && e?.response?.status < 500) {
    //     logOut()
    // }
    //                 console.log(e)
    //                 alert(e.message)
    //             })
    //         };
    //         r.readAsDataURL(f);
    //         //  } else {
    //         //      alert('Failed file type');
    //         //  }
    //     } else {
    //         alert('Failed to load file');
    //     }
    // };

    fileUploadChangeToBinary = (f) => {
        // debugger
        const thisObj = this;
        if (f) {
            // if (/(jpe?g|png|gif)$/i.test(f.type)) {
            var r = new FileReader();

            r.onload = (e) => {
                var base64Img = e.target.result;

                // var binaryImg = this.convertDataURIToBinary(base64Img);
                // this.setState({
                //     attachFileTestBinary: binaryImg
                // });
                let url =
                    "https://v2ao5dt0v3.execute-api.ap-south-1.amazonaws.com/prod/v1/data/presign/url";
                let cloudFrontEndpoint =
                    "https://d1wqfechwjsewm.cloudfront.net/";

                this.service
                    .post(url, {
                        type: "insert",
                        key:
                            thisObj.props.user.user.orgId +
                            "/" +
                            uuidv4() +
                            f.name.replace(/\s/g, ""),
                    })
                    .then((data) => {
                        console.log("presignedURL", data.res);
                        // debugger;
                        if (
                            data &&
                            data.res &&
                            data.res.data &&
                            data.res.data.presignedURL
                        )
                            this.service
                                .put(data.res.data.presignedURL, f, false)
                                .then((response) => {
                                    //   console.log(response, 'response')
                                    if (response && response.status) {
                                        // this.service.post(`${ baseUrlAdmin } / se3fetch`, { preUrl: data.res.imageStorageKey }, false).then((res2) => {
                                        //     if (res2 && res2.status && res2?.res) {
                                        //         data.res.storageUrl = res2?.res

                                        // send from here . that object
                                        let newFileName = new Date().getTime() + f.name;
                                        let fileUpload = {
                                            id: "",
                                            name: f.name,
                                            newFileName: newFileName,
                                            size: f.size,
                                            type: f.type,
                                            timeReference: "Unknown",
                                            progressStatus: 0,
                                            displayName: f.name,
                                            status: "Uploading..",
                                        };

                                        //create object like thsi and send from here.  and use it.
                                        // so use can do actions bsed on the type in client view for preview or download if it is an exe to download.
                                        this.props.onSucess(
                                            f.name,
                                            data.res.data,
                                            fileUpload
                                        );
                                        //  }
                                        // if (response && response.status) {

                                        //     this.props.onSucess(f.name, data.res)
                                        // }

                                        //  })
                                        //  this.props.onSucess(f.name, data.res)
                                    }
                                })
                                .catch((e) => {
                                    if (e?.response?.status > 400 && e?.response?.status < 500) {
                                        logOut()
                                    }
                                    console.log(e, "bb");
                                    alert(e.message);
                                });
                    })
                    .catch((e) => {
                        if (e?.response?.status > 400 && e?.response?.status < 500) {
                            logOut()
                        }
                        console.log(e);
                        alert(e.message);
                    });
            };
            r.readAsDataURL(f);
            //  } else {
            //      alert('Failed file type');
            //  }
        } else {
            alert("Failed to load file");
        }
    };

    //     {
    //     "imageStorageKey": "bab41190-1491-11ed-adac-8dc7709ad7c7-बॉर्डर (4K) - Border Full Movie - Sunny Deol - Suniel Shetty.mp4",
    //         "imageStorageBucketName": "meluhaimage",
    //             "imageType": "binary/octet-stream",
    //                 "presignedURL": "https://meluhaimage.s3.ap-south-1.amazonaws.com/bab41190-1491-11ed-adac-8dc7709ad7c7-%E0%A4%AC%E0%A5%89%E0%A4%B0%E0%A5%8D%E0%A4%A1%E0%A4%B0%20%284K%29%20-%20Border%20Full%20Movie%20-%20Sunny%20Deol%20-%20Suniel%20Shetty.mp4?Content-Type=binary%2Foctet-stream&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAJWGD7NJSR76CODMQ%2F20220805%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220805T073925Z&X-Amz-Expires=300&X-Amz-Signature=eb31dbb8ea80b0e6ad07a95feb7389671feef79073c85856fde03a4822c78905&X-Amz-SignedHeaders=host"
    // }
    // uploadfile = (fileName, file, folderName, onSucess) => {
    //     const params = {
    //         Bucket: S3_UPLOAD_CONFIG.bucketName,
    //         Key: folderName + fileName,
    //         Body: file,
    //         ContentType: file.type
    //     };
    //     return s3Bucket.upload(params, function (err, data) {

    //         if (err) {
    //             //   console.log('There was an error uploading your file: ', err);
    //             return false;
    //         }
    //         onSucess(file.name, data);
    //         return true;
    //     });
    // }

    uploadfile2 = (fileName, file, folderName, onSucess) => {
        this.fileUploadChangeToBinary(file);
        return;
        // const params = {
        //     Bucket: S3_UPLOAD_CONFIG.bucketName,
        //     Key: folderName + fileName,
        //     Body: file,
        //     ContentType: file.type
        // };
        // return s3Bucket.upload(params, function (err, data) {

        //     if (err) {
        //         console.log('There was an error uploading your file: ', err);
        //         return false;
        //     }
        //     onSucess(file.name, data);
        //     return true;
        // });
    };

    onSucess = (fileName, data) => {
        this.props.onSucess(fileName, data);
    };

    onProgress = (some) => {
        this.props.onProgress(some);
    };

    onChangeHandler = (event, onSucess, onProgress) => {
        let canUpload = true;
        let errMsg = "";
        if (
            event.target.files !== null &&
            event.target.files !== undefined &&
            event.target.files.length > 0
        ) {
            for (let i = 0; i < event.target.files.length; i++) {
                /// 300mb == 314572800
                /// 200mb == 209715200
                if (event.target.files[i].size > this.state.maxFileSize) {
                    canUpload = false;
                    errMsg = `Only ${this.state.maxFileSize / 1048576
                        }MB files are allowed.`;
                }
                if (
                    !this.getAcceptString(this.props.accept).includes(
                        event.target.files[i].type
                    )
                ) {
                    canUpload = false;
                    errMsg = `Only ${this.props.accept} are allowed`;
                }
            }
            // if (event.target.files !== null && event.target.files !== undefined && event.target.files.length > 0) {
            if (canUpload) {
                this.props.afterFilesUploaded(event.target.files);
                for (let i = 0; i < event.target.files.length; i++) {
                    let needToUpload = true;
                    if (this.state.uploadedFileInfo.length > 0) {
                        for (
                            let j = 0;
                            j < this.state.uploadedFileInfo.length;
                            j++
                        ) {
                            if (
                                event.target.files[i].name ===
                                this.state.uploadedFileInfo[j].data.originalName
                            ) {
                                needToUpload = false;
                            }
                        }
                    }
                    if (needToUpload) {
                        let file = event.target.files[i];
                        let newFileName = new Date().getTime() + file.name;

                        let fileUpload = {
                            id: "",
                            name: file.name,
                            newFileName: newFileName,
                            size: file.size,
                            type: file.type,
                            timeReference: "Unknown",
                            progressStatus: 0,
                            displayName: file.name,
                            status: "Uploading..",
                        };

                        this.uploadfile2(
                            newFileName,
                            event.target.files[0],
                            this.props.dirName != undefined &&
                                this.props.dirName == ""
                                ? this.props.dirName
                                : "consultant",
                            onSucess
                        );

                        // this.uploadfile(newFileName, file, this.props.dirName != undefined && this.props.dirName == '' ? this.props.dirName : S3_UPLOAD_CONFIG.dirName, onSucess)
                        //     .on('httpUploadProgress', function (progress) {
                        //         let progressPercentage = Math.round(progress.loaded / progress.total * 100);
                        //         if (progressPercentage < 100) {
                        //             fileUpload.progressStatus = progressPercentage;

                        //         } else if (progressPercentage == 100) {
                        //             fileUpload.progressStatus = progressPercentage;

                        //             fileUpload.status = "Uploaded";

                        //         }
                        //         onProgress(fileUpload);
                        //     })
                    }
                }
            } else {
                this.props.onError(errMsg);
            }
        }
        event.target.value = null;
    };

    componentWillReceiveProps(newProps) {
        if (newProps.Reset === true) {
            this.setState({
                uploadedFileInfo: [],
                uploadedFilesInfo: [],
            });
        }
    }

    getAcceptString(accept) {
        // if (accept) {
        //     if (accept == "images") {
        //        // return 'image/*'
        //        return ['image/jpeg', 'image/png', 'image/jpg'];
        //     } else if (accept == "audios") {
        //         return ['audio/mpeg'];
        //     } else if (accept == "videos") {
        //         return ['video/mp4', 'video/quicktime'];
        //     } else if (accept == "pdfs") {
        //         return ['application/pdf']
        //     } else {

        //         return 'audio/*,video/*,image/*,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,application/vnd.ms-powerpoint';
        //     }
        // } else {
        //     return 'audio/*,video/*,image/*,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,application/vnd.ms-powerpoint';
        // }
        if (accept) {
            if (accept == "images") {
                //return 'image';
                return ["image/jpeg", "image/png", "image/jpg"];
            }
            if (accept == "png") {
                //return 'image';
                return ["image/png"];
            } else if (accept == "audios") {
                return ["audio/mpeg"];
            } else if (accept == "videos") {
                return ["video/mp4", "video/quicktime"];
            } else if (accept == "pdfs") {
                return ["application/pdf"];
            } else if (accept == "documents") {
                return [
                    // Images
                    "image/jpeg",
                    "image/png",
                    "image/gif",
                    "image/bmp",
                    "image/webp",

                    // Documents
                    "application/msword",
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    "application/vnd.ms-excel",
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    "application/vnd.ms-powerpoint",
                    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                    "application/vnd.oasis.opendocument.text",

                    // PDF
                    "application/pdf",

                    // Other Document-related types
                    "text/plain",
                    "application/rtf",
                    "text/csv",
                ];
            } else if (accept == "imagevideo") {
                return [
                    "video/mp4",
                    "video/quicktime",
                    "image/jpg",
                    "image/jpeg",
                    "image/png",
                ];
            } else {
                return [];
            }
        } else {
            return [];
        }
    }

    render() {
        return (
            <>
                <label
                    htmlFor={this.props.id}
                    title={this.props.title ? this.props.title : "add"}
                >
                    {this.props.children}
                </label>
                <input
                    type="file"
                    disabled={this.props.disabled}
                    hidden={true}
                    accept={this.getAcceptString(this.props.accept)}
                    name="files"
                    id={this.props.id}
                    multiple={this.props.multiple}
                    className="ma-file"
                    onChange={(event) =>
                        this.onChangeHandler(
                            event,
                            this.onSucess,
                            this.onProgress
                        )
                    }
                />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    userType: state?.user?.userType,
    phoneNumber: state?.user?.user?.phoneNumber,
    email: state?.user?.user?.email,
    authenticated: state?.user?.authenticated,
    orgCode: state?.orgData?.orgCode,
    isLoading: state?.orgData?.isLoading,
});

export default connect(mapStateToProps, {})(withRouter(FileUploadLambda2));
