import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import React, { Component } from "react";
import ServerlessService from "../../../Service/ServerlessService";
import LoadingComponent from "../../../LoadingComponent";
import { Toast } from "primereact/toast";
import NoteEntryComponent from "../documentPreperator/noteEntryComponent";
import { logOut } from "../../../Utils";

export default class AssignContentWriter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            contentWriters: [],
            contentWritingAssignedTo: null,
            contentWritingAssignedToErr: false,
            notesToContentWriter: "",
        };
        this.serverlessService = new ServerlessService();
    }

    getUserPrivileges = () => {
        this.setState({
            isLoading: true,
            contentWriters: [],
        });

        const url = `/admin/get-privilege-users`;
        this.serverlessService
            .post(url, { privilegeType: "isContentWriter" }, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                        contentWriters: res.res.data,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    componentDidMount() {
        this.getUserPrivileges();
    }

    assignToContentWriter = () => {
        if (!this.state.contentWritingAssignedTo) {
            this.setState({
                contentWritingAssignedToErr: true,
            });
            return;
        }

        this.setState({
            isLoading: true,
        });

        const url = `/consultants/consultant/assign-to-content-writer`;
        this.serverlessService
            .post(
                url,
                {
                    _id: this.props.selectedCustomer._id,
                    contentWritingAssignedTo:
                        this.state.contentWritingAssignedTo,
                    notesToContentWriter: this.state.notesToContentWriter,
                },
                true
            )
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState(
                        {
                            isLoading: false,
                        },
                        this.props.onHide
                    );
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    footerContent = () => (
        <div>
            <Button
                label="Cancel"
                className=" p-button-text hover:bg-primary mr-3 "
                onClick={this.props.onCancel}
            />
            <Button
                label={"Assign"}
                onClick={this.assignToContentWriter}
                autoFocus
                className="p-button-primary"
            />
        </div>
    );

    render() {
        return (
            <Dialog
                header="Assign To Content Writer"
                visible={true}
                style={{ width: "65vw" }}
                draggable={false}
                onHide={() => this.props.onCancel()}
                footer={this.footerContent}
            >
                <div>
                    <div className="p-grid row pl-6 pr-6">
                        <div className="col-12 md:col-8 lg:col-8">
                            <label className="font-bold block mb-2">
                                Content Writer
                            </label>
                            <Dropdown
                                emptyMessage="No Consultant Found"
                                onChange={(e) => {
                                    this.setState({
                                        contentWritingAssignedTo: e.value,
                                        contentWritingAssignedToErr: false,
                                    });
                                }}
                                value={this.state.contentWritingAssignedTo}
                                options={this.state.contentWriters}
                                optionValue="_id"
                                optionLabel="name"
                                placeholder="Select a user"
                                className="w-full md:w-14rem"
                            />
                            {this.state.contentWritingAssignedToErr && (
                                <p className="p-error">
                                    Please select the user
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="p-grid row pl-6 pr-6">
                        <div className="col-12 md:col-8 lg:col-8">
                            <label className="font-bold block mb-2">
                                Notes
                            </label>
                            <NoteEntryComponent
                                onChange={(e) => {
                                    this.setState({
                                        notesToContentWriter: e.target.value,
                                    });
                                }}
                                value={this.state.notesToContentWriter}
                            />
                        </div>
                    </div>
                </div>

                {(this.state.isLoading || this.props.isLoading) && (
                    <LoadingComponent />
                )}
                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
            </Dialog>
        );
    }
}

