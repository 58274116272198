import React, { Component } from "react";
import { InputText } from "primereact/inputtext";

import { Card } from "primereact/card";

import ServerlessService from "../../../Service/ServerlessService";
import BaseComponent from "../../common/BaseComponent";
import NavFooter from "../../navFooter";
import { Toast } from "primereact/toast";
import Authentication from "./../../../session/index";
import { logOut } from "../../../Utils";

class SearchBank extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: "",
            questions: [],
        };
        this.serverlessService = new ServerlessService();
    }

    searchQuestion = (search) => {
        this.setState({
            isLoading: true,
            search: search,
            questions: [],
        });

        const url = `/consultants/questionbank/search`;
        this.serverlessService
            .post(
                url,
                {
                    search,
                },
                true
            )
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState({
                        questions: res.res.data,
                        isLoading: false,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });

                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    render() {
        const { questions } = this.state;
        return (
            <NavFooter>
                <div className="m-4 ">
                    <h4>Questions & Answers</h4>
                    <InputText
                        onChange={(e) => {
                            this.searchQuestion(e.target.value);
                        }}
                        className="p-py-3 mt-2"
                        value={this.state.search}
                        placeholder="Search questions..."
                    />
                    <div>
                        {questions.length > 0 ? (
                            questions.map((question) => (
                                <Card className="my-4" key={question._id}>
                                    <div>
                                        <h3 className="p-mb-0">
                                            {question.question}
                                        </h3>
                                        <p className="p-mb-0">
                                            {question.answer}
                                        </p>
                                    </div>
                                </Card>
                            ))
                        ) : (
                            <div className="p-col m-2">
                                <div
                                    className="p-text-center p-text-muted"
                                    style={{ fontSize: "1.125rem" }}
                                >
                                    No results found
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
            </NavFooter>
        );
    }
}

export default BaseComponent(Authentication(SearchBank));
