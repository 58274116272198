import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import LoadingComponent from "../../../LoadingComponent";
import { MultiSelect } from "primereact/multiselect";
import { Dialog } from "primereact/dialog";
import { Tooltip } from "primereact/tooltip";
import ConsultantNotes from "../documentPreperator/consultantNotes";
import ConsultantMonitor from "./consultantMonitor";
import { Card } from "primereact/card";
import {
    getLearningPlans,
    getDocumentPreperator,
    resetLazyDocumentPreperator,
} from "../../../store/actions";
import BaseComponent from "../../common/BaseComponent";
import { BasicLazyParams } from "../../../Utils/constants";
import ServerlessService from "../../../Service/ServerlessService";
import Authorize, { PERMISSIONS } from "../../../session/authorize";
import { BreadCrumb } from "primereact/breadcrumb";
import AssignContentWriter from "../documentPreperator/assignContentWriter";
import DocumentEditingDialog from "../documentPreperator/documentEditingRequest/documentEditingDialog";
import { logOut } from "../../../Utils";

class DocumentPreparator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowView: false,
            viewTable: [],
            visible: false,
            isShowConfirmationDialog: false,
            consultants: [],
            customers: [],
            isShowAssignTable: false,
            isShowUnAssignTable: false,
            editData: null,
            id: null,
            renewalData: null,
            tableHistorysData: null,
            rowDataOfTable: null,
            isShowRenewal: false,
            globalSearch: "",
            lazyParams: {
                ...BasicLazyParams,
                sortField: "createdAt",
                sortOrder: 1,
            },
            selectedReason: "",
            learningPlans: [],
            studyPlan: false,
            selectedPlan: [],
            consultandId: "",
            showUserDialog: false,
            user: {
                password: "",
                passwordConfirm: "",
                email: "",
            },
            consultant: {},
            orginfo: {},
            rowData: {},
        };
        this.serverlessService = new ServerlessService();
    }

    assignLearningPlan = () => {
        const { consultandId, selectedPlan } = this.state;
        const learningPlanIds = selectedPlan.map((plan) => plan._id);
        const requestBody = {
            _id: consultandId,
            learningplan: learningPlanIds,
        };

        const url = `/consultants/consultant`;

        this.serverlessService
            .put(url, requestBody, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState(
                        {
                            isLoading: false,
                            studyPlan: false,
                        },
                        () => {
                            this.props.getDocumentPreperator();
                        }
                    );
                } else {
                    this.setState({
                        isLoading: false,
                    });

                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };



    getLearningPlans = () => {
        let { lazyParams, globalSearch } = this.state;

        this.setState({
            isLoading: true,
            consultants: [],
        });

        const url = `/consultants/learning-plans?limit=${lazyParams.rows
            }&page=${lazyParams.page + 1}&search=${globalSearch}${lazyParams.sortField
                ? `&sort=${lazyParams.sortOrder === 1 ? "" : "-"}${lazyParams.sortField
                }`
                : ""
            }`;
        this.serverlessService
            .post(url, { status: CONSULTANT_STATUS.CREATED }, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                        learningPlans: res.res.data,
                        totalRecords: res.res.totalRecords,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    componentDidMount() {
        if (!this.props.documentPreperatorConsultants?.length) {
            this.props.getLearningPlans();
            this.props.getDocumentPreperator();
        }
    }

    onGlobalSearch = (e) => {
        this.props.getDocumentPreperator({ globalSearch: e.target.value });
    };

    onPage = (event) => {
        this.props.getDocumentPreperator({ lazyParams: event });
    };

    onSort = (event) => {
        this.props.getDocumentPreperator({ lazyParams: event });
    };

    onFilter = (event) => {
        event["first"] = 0;
        this.props.getDocumentPreperator({ lazyParams: event });
    };

    footerContent = () => {
        return (
            <div>
                <Button
                    label="Cancel"
                    className="p-button-text hover:bg-primary"
                    onClick={this.onHide}
                />
                <Button
                    label="Renewal"
                    autoFocus
                    onClick={this.onRenewalClick}
                />
            </div>
        );
    };

    assignPlan = (rowData) => {
        if (Array.isArray(rowData.learningplan)) {
            const assignPlan = this.props.data.filter((plan) =>
                rowData.learningplan.includes(plan._id)
            );
            this.setState({ selectedPlan: assignPlan });
        } else {
            console.error("rowData.learningplan is not an array");
        }
    };

    actionsBody = (rowData) => {
        return (
            <div className="p-grid row">
                <div className="col-2">
                    <Authorize
                        permId={
                            PERMISSIONS.DOCUMENT_PREPERATOR_ASSIGN_TO_VERIFICATION_TEAM
                        }
                    >
                        <span
                            onClick={() =>
                                this.setState({
                                    isShowAssignToContentWriter: true,
                                    selectedCustomer: rowData,
                                })
                            }
                            data-pr-tooltip="Assign To Verification Team"
                            data-pr-position="top"
                            className={`p-text assign${rowData._id.substr(4)}`}
                        >
                            <i className="pi pi-directions" />
                        </span>
                        <Tooltip target={`.assign${rowData._id.substr(4)}`} />
                    </Authorize>
                </div>
                <div className="col-2">
                    <Authorize
                        permId={PERMISSIONS.DOCUMENT_PREPERATOR_EDIT_DOC_WINDOW}
                    >
                        <span
                            onClick={() =>
                                this.setState({
                                    isShowDocumentWindow: true,
                                    editData: rowData,
                                })
                            }
                            data-pr-tooltip="Document Editing"
                            data-pr-position="top"
                            className={`p-text server${rowData._id.substr(4)}`}
                        >
                            <i className="pi pi-server" />
                        </span>
                        <Tooltip target={`.server${rowData._id.substr(4)}`} />
                    </Authorize>
                </div>
                <div className="col-2">
                    <Authorize
                        permId={PERMISSIONS.DOCUMENT_PREPERATOR_SHOW_CONSULTANT}
                    >
                        <span
                            onClick={() => {
                                this.props.history.push(
                                    `/admin/cosultant/view/${rowData._id}`
                                );
                            }}
                            data-pr-tooltip="Show Consultant"
                            data-pr-position="top"
                            className={`p-text abc${rowData._id.substr(4)}`}
                        >
                            <i className="pi pi-eye" />
                        </span>
                        <Tooltip target={`.abc${rowData._id.substr(4)}`} />
                    </Authorize>
                </div>
                <div className="col-2">
                    <Authorize permId={PERMISSIONS.DOCUMENT_PREPERATOR_MONITOR}>
                        <span
                            onClick={() => {
                                this.setState({
                                    isShowConsultantMonitor: true,
                                    editData: rowData,
                                });
                            }}
                            data-pr-tooltip="Consultant Monitor"
                            data-pr-position="top"
                            className={`p-text Monitor${rowData._id.substr(4)}`}
                        >
                            <i className="pi pi-comments" />
                        </span>
                        <Tooltip target={`.Monitor${rowData._id.substr(4)}`} />
                    </Authorize>
                </div>
                <div className="col-2">
                    <Authorize permId={PERMISSIONS.DOCUMENT_PREPERATOR_TRACKER}>
                        <span
                            onClick={() => {
                                this.props.history.push(
                                    `/admin/cosultant/tracker/${rowData._id}`
                                );
                            }}
                            data-pr-tooltip="Consultant Tracker"
                            data-pr-position="top"
                            className={`p-text Tracker${rowData._id.substr(4)}`}
                        >
                            <i className="pi pi-building" />
                        </span>
                        <Tooltip target={`.Tracker${rowData._id.substr(4)}`} />
                    </Authorize>
                </div>
                <div className="col-2">
                    <Authorize permId={PERMISSIONS.DOCUMENT_PREPERATOR_NOTES}>
                        <span
                            onClick={() => {
                                this.setState({
                                    isShowConsultantNotes: true,
                                    editData: rowData,
                                });
                            }}
                            data-pr-tooltip="Notes (Only visible to you.)"
                            data-pr-position="top"
                            className={`p-text Notes${rowData._id.substr(4)}`}
                        >
                            <i className="pi pi-book" />
                        </span>
                        <Tooltip target={`.Notes${rowData._id.substr(4)}`} />
                    </Authorize>
                </div>
                {rowData.isProfileValid && rowData.isUserCreated && (
                    <div className="col-2">
                        <Authorize
                            permId={PERMISSIONS.DOCUMENT_PREPERATOR_ASSIGN_PLAN}
                        >
                            <span
                                onClick={() => {
                                    this.setState(
                                        {
                                            studyPlan: true,
                                            consultandId: rowData._id,
                                        },
                                        () => {
                                            this.assignPlan(rowData);
                                        }
                                    );
                                }}
                                data-pr-tooltip="Assign Study Plan"
                                data-pr-position="top"
                                className={`p-text assign${rowData._id.substr(
                                    4
                                )}`}
                            >
                                <i className="pi pi-envelope" />
                            </span>
                            <Tooltip
                                target={`.assign${rowData._id.substr(4)}`}
                            />
                        </Authorize>
                    </div>
                )}
                {rowData.isProfileValid && !rowData.isUserCreated && (
                    <div className="col-2">
                        <Authorize
                            permId={PERMISSIONS.DOCUMENT_PREPERATOR_USER_CREATE}
                        >
                            <span
                                onClick={() => {
                                    this.setState({
                                        rowData: rowData,
                                        showUserDialog: true,
                                    });
                                }}
                                data-pr-tooltip="Create Login For User Consultant"
                                data-pr-position="top"
                                className={`p-text User${rowData._id.substr(
                                    4
                                )}`}
                            >
                                <i className="pi pi-user-plus" />
                            </span>
                        </Authorize>
                        <Tooltip target={`.User${rowData._id.substr(4)}`} />
                    </div>
                )}
            </div>
        );
    };

    registerUser = () => {
        const { _id } = this.state.rowData;
        const url = `/consultants/user/create/${_id}`;

        this.setState({
            isLoading: true,
        });

        this.serverlessService
            .get(url, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState(
                        {
                            isLoading: false,
                            showUserDialog: false,
                        },
                        () => {
                            this.props.getDocumentPreperator();
                        }
                    );
                    this.toast.show({
                        severity: "success",
                        summary: "Success",
                        detail: "User Created successfully",
                        life: 3000,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Error",
                        detail: res.message,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };



    onclose = () => {
        this.setState({
            showUserDialog: false,
        });
    };

    footerContentforDelete = () => (
        <div>
            <Button
                label="Cancel"
                className=" p-button-text hover:bg-primary "
                onClick={() => {
                    this.setState({
                        isShowConfirmationDialog: false,
                    });
                }}
            />
            <Button
                label="Ok"
                onClick={this.deleteTable}
                autoFocus
                className=" p-button-primary "
            />
        </div>
    );

    footerContentforUser = () => (
        <div>
            <Button
                label="Cancel"
                className=" p-button-text hover:bg-primary "
                onClick={() => {
                    this.setState({
                        showUserDialog: false,
                    });
                }}
            />
            <Button
                label="Ok"
                onClick={() => {
                    this.registerUser();
                }}
                autoFocus
                className=" p-button-primary "
            />
        </div>
    );
    onPlanChange = (e) => {
        this.setState({ selectedPlan: e.value });
    };

    onDocumentAccepted = (files) => {
        this.setState((prevState) => {
            return {
                consultants: prevState.consultants.map((a) => {
                    if (prevState.editData._id == a._id) {
                        a.files = files;
                    }
                    return a;
                }),
                editData: {
                    ...prevState.editData,
                    files: files,
                },
            };
        });
    };
    exportCSV = () => {
        this.dt.exportCSV();
    };
    render() {
        const planOptions = this.props.data.map((plan) => ({
            label: plan.planName,
            value: plan,
        }));
        const header = (
            <div className="">
                <div class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 top-100 z-1 shadow-2 lg:shadow-none border-1 lg:border-none border-gray-800">
                    <ul class="list-none flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li>
                            <p className="mx-0 my-1 ss-header">Consultants</p>
                        </li>
                    </ul>
                    <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li class="flex-order-2 lg:flex-order-0">
                            <ul className="right-0">
                                <ul className="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                                    <Authorize
                                        permId={
                                            PERMISSIONS.DOCUMENT_PREPERATOR_GENERAL_SEARCH
                                        }
                                    >
                                        <li className="flex-order-2 lg:flex-order-0 ml-4 ">
                                            {this.state.showSearch && (
                                                <InputText
                                                    placeholder="Search"
                                                    className=""
                                                    value={
                                                        this.props.globalSearch
                                                    }
                                                    onChange={
                                                        this.onGlobalSearch
                                                    }
                                                />
                                            )}
                                        </li>
                                    </Authorize>
                                    <Authorize
                                        permId={
                                            PERMISSIONS.DOCUMENT_PREPERATOR_GENERAL_SEARCH
                                        }
                                    >
                                        <li className="flex-order-2 lg:flex-order-0 ml-4 cursor-pointer">
                                            <i
                                                data-pr-tooltip="Search"
                                                data-pr-position="bottom"
                                                className="pi pi-search text-base lg:text-2xl mr-2 ss-tbl-srch"
                                                onClick={(e) => {
                                                    this.setState({
                                                        showSearch:
                                                            !this.state
                                                                .showSearch,
                                                    });
                                                }}
                                            ></i>
                                        </li>
                                        <Tooltip
                                            className="table-li-tooltip"
                                            autoHide={false}
                                            target=".ss-tbl-srch"
                                        />
                                    </Authorize>
                                    <Authorize
                                        permId={
                                            PERMISSIONS.DOCUMENT_PREPERATOR_GENERAL_DOWNLOAD
                                        }
                                    >
                                        <li className="border-top-1 border-gray-800 lg:border-top-none ml-4 mr-4 cursor-pointer">
                                            <i
                                                data-pr-tooltip="Download"
                                                data-pr-position="bottom"
                                                className="pi pi-download  text-base lg:text-2xl mr-2 ss-tbl-dwnld"
                                                onClick={this.exportCSV}
                                            ></i>
                                        </li>
                                        <Tooltip
                                            className="table-li-tooltip"
                                            target=".ss-tbl-dwnld"
                                        />
                                    </Authorize>
                                </ul>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        );
        const dialogFooter = (
            <div className="flex justify-content-end">
                <Button
                    label="Close"
                    onClick={() => this.setState({ studyPlan: false })}
                    className="p-button-text hover:bg-primary mr-3"
                />
                <Button
                    label="Assign"
                    onClick={this.assignLearningPlan}
                    className="p-button-primary "
                    autoFocus
                />
            </div>
        );

        const items = [{ label: "Admin" }, { label: "Document Prepare" }];

        const home = {
            icon: "pi pi-home",
            url: "http://localhost:3000/#/admin",
        };
        return (
            <>
                <div className="h-screen overflow-auto ss-data-table">
                    <Authorize permId={PERMISSIONS.DOCUMENT_PREPERATOR_LIST}>
                        <Card>
                            <BreadCrumb model={items} home={home} />
                            <DataTable
                                ref={(el) => (this.dt = el)}
                                value={this.props.documentPreperatorConsultants}
                                lazy
                                scrollHeight="400px"
                                onSort={this.onSort}
                                onPage={this.onPage}
                                onFilter={this.onFilter}
                                header={header}
                                first={this.props.lazyParams?.first}
                                last={this.props.totalRecords}
                                rows={this.props.lazyParams?.rows}
                                totalRecords={this.props.totalRecords}
                                paginator
                                paginatorLeft
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                rowsPerPageOptions={[
                                    5, 10, 25, 50, 100, 200, 300, 500,
                                ]}
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                scrollable
                                responsiveLayout="scroll"
                                rowHover
                                dataKey="_id"
                                selection={this.state.selectedCustomer}
                                onSelectionChange={(e) =>
                                    this.setState({
                                        selectedCustomer: e.value,
                                    })
                                }
                                selectionMode="single"
                            >
                                <Column
                                    field="name"
                                    header="Full Name"
                                ></Column>
                                <Column field="mobile" header="Phone"></Column>
                                <Column field="email" header="Email"></Column>
                                <Column
                                    field="rolePrefered"
                                    header="Role"
                                ></Column>
                                <Column
                                    header="Actions"
                                    body={(rowData) =>
                                        this.actionsBody(rowData)
                                    }
                                ></Column>
                            </DataTable>
                        </Card>
                    </Authorize>
                </div>

                {this.state.isShowAssignToContentWriter && (
                    <AssignContentWriter
                        selectedCustomer={this.state.selectedCustomer}
                        onCancel={() => {
                            this.setState({
                                isShowAssignToContentWriter: false,
                                selectedCustomer: null,
                            });
                        }}
                        onHide={() => {
                            let thisObj = this;

                            this.setState(
                                {
                                    isShowAssignToContentWriter: false,
                                    selectedCustomer: null,
                                },
                                () => {
                                    thisObj.toast.show({
                                        severity: "success",
                                        summary:
                                            "User Assign to Verification team.",
                                        detail: "User assigned successfully",
                                        life: 3000,
                                    });
                                    thisObj.props.getDocumentPreperator();
                                }
                            );
                        }}
                    />
                )}

                <Dialog
                    visible={this.state.studyPlan}
                    style={{ width: "450px" }}
                    header="Select a Study Plan"
                    modal
                    draggable={false}
                    footer={dialogFooter}
                    onHide={() => this.setState({ studyPlan: false })}
                    className="p-dialog-custom"
                >
                    <div className="p-fluid">
                        {" "}
                        <div className="p-field">
                            <label htmlFor="selectedPlan">Select a Plan</label>

                            <MultiSelect
                                inputId="selectedPlan"
                                value={this.state.selectedPlan}
                                options={planOptions}
                                onChange={this.onPlanChange}
                                placeholder="Select Plans"
                                className="p-inputtext p-component"
                                display="chip"
                            />
                        </div>
                    </div>
                </Dialog>

                {this.state.isShowDocumentWindow && (
                    <DocumentEditingDialog
                        consultantId={this.state.editData._id}
                        onDocumentAccepted={this.onDocumentAccepted}
                        consultant={this.state.editData}
                        isDocumentPrepartor={true}
                        onUploaded={() => {
                            this.setState({
                                isShowDocumentWindow: false,
                            });
                        }}
                        onHide={() => {
                            this.setState({
                                isShowDocumentWindow: false,
                            });
                        }}
                    />
                )}

                {this.state.isShowNotes && (
                    <Dialog
                        header={"Notes"}
                        visible={true}
                        style={{ width: "49vw" }}
                        draggable={false}
                        onHide={() => {
                            this.setState({
                                isShowNotes: false,
                                editData: null,
                            });
                        }}
                    >
                        <p>{this.state.editData.notesToDocumentPreparator}</p>
                    </Dialog>
                )}



                {this.state.isShowConsultantMonitor && (
                    <ConsultantMonitor
                        consultant={this.state.editData}
                        onHide={() => {
                            this.setState({
                                isShowConsultantMonitor: false,
                                editData: null,
                            });
                        }}
                    />
                )}

                {this.state.isShowConsultantNotes && (
                    <ConsultantNotes
                        consultant={this.state.editData}
                        onHide={() => {
                            this.setState({
                                isShowConsultantNotes: false,
                                editData: null,
                            });
                        }}
                    />
                )}

                {this.state.showUserDialog && (
                    <Dialog
                        header="Are you Sure ?"
                        draggable={false}
                        visible={this.state.showUserDialog}
                        style={{ width: "15vw" }}
                        onHide={this.onclose}
                        footer={this.footerContentforUser}
                    >
                        <p>User will be created</p>
                    </Dialog>
                )}

                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
                {this.state.isLoading && <LoadingComponent />}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    documentPreperatorConsultants:
        state?.documentPreperators?.documentPreperatorConsultants,
    totalRecords: state?.documentPreperators?.totalRecords,
    lazyParams: state?.documentPreperators?.lazyParams,
    isLoading: state?.documentPreperators?.isLoading,
    data: state?.learningPlans?.data,
});

export default BaseComponent(DocumentPreparator, mapStateToProps, {
    getDocumentPreperator,
    resetLazyDocumentPreperator,
    getLearningPlans,
});
