import React, { Component } from "react";
import { Chart } from "primereact/chart";
import ServerlessService from "../../../Service/ServerlessService";
import { CONSULTANT_STATUS } from "../../../Utils/constants";
import { BasicLazyParams } from "../../../Utils/constants";
import { logOut } from "../../../Utils";

class ProfilesToRecruiter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chartData: null,
            isLoading: false,
        };
        this.serverlessService = new ServerlessService();
    }

    componentDidMount() {
        this.getRecruiters();
    }

    getRecruiters = () => {
        const urlEnd = "/admin/get-privilege-users";
        const requestBody = {
            privilegeType: "isBenchSales"
        };

        this.serverlessService
            .post(urlEnd, requestBody, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    const recruiterList = res.res.data;
                    const promises = recruiterList.map(recruiter => {
                        const getConsultantApi = `/consultants/consultant/getconsultant-by-recruiter`;
                        const requestBody = {
                            id: recruiter._id,
                        };
                        return this.serverlessService.post(getConsultantApi, requestBody, true);
                    });

                    Promise.all(promises)
                        .then(results => {
                            console.log(results)
                            const chartData = {};
                            results.forEach((res, index) => {
                                const recruiterName = recruiterList[index].name;
                                const consultantData = res.res.data;
                                chartData[recruiterName] = consultantData.length;
                            });
                            console.log(chartData)
                            this.setState({ chartData });
                        })
                        .catch((error) => {
                            console.error("Error fetching consultant data:", error);
                        });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                console.error("Error fetching recruiters:", e);
            });
    };

    render() {
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <h2>Profiles Assigned To Recruiters</h2>
                    {this.state.chartData && (
                        <Chart type="bar" data={{
                            labels: Object.keys(this.state.chartData),
                            datasets: [
                                {
                                    label: "Profiles Count",
                                    backgroundColor: "#42A5F5",
                                    data: Object.values(this.state.chartData)
                                }
                            ]
                        }} />
                    )}
                </div>
            </div>
        );
    }
}

export default ProfilesToRecruiter;
