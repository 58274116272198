import React, { Component } from "react";
import { Chart } from "primereact/chart";
import ServerlessService from "../../../Service/ServerlessService";
import { CONSULTANT_STATUS } from "../../../Utils/constants";
import { BasicLazyParams } from "../../../Utils/constants";
import { logOut } from "../../../Utils";

class TotalProfilesCharts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            consultants: [],
            chartData: null,
            isLoading: false,
        };
        this.serverlessService = new ServerlessService();
    }

    componentDidMount() {
        this.getConsultants();
    }

    getConsultants = () => {
        let urlEnd = "/consultants/consultant/all";

        this.setState({
            isLoading: true,
        });

        this.serverlessService
            .get(urlEnd, {}, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                        consultants: res.res.data,
                    });
                    this.prepareChartData(res.res.data);
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    // Handle error
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                // Handle error
            });
    };

    prepareChartData(consultants) {
        const statusCounts = {};

        // Count profiles for each status category
        consultants.forEach((consultant) => {
            const status = consultant.status;
            if (statusCounts[status]) {
                statusCounts[status]++;
            } else {
                statusCounts[status] = 1;
            }
        });

        // Prepare chart data
        const chartData = {
            labels: Object.keys(CONSULTANT_STATUS).map((status) => CONSULTANT_STATUS[status]),
            datasets: [
                {
                    label: "Total Profiles",
                    backgroundColor: "#42A5F5",
                    data: Object.values(statusCounts),
                },
            ],
        };

        this.setState({ chartData });
    }

    render() {
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <h2>Total Profiles by Status Category</h2>
                    {this.state.chartData && (
                        <Chart type="bar" data={this.state.chartData} />
                    )}
                </div>
            </div>
        );
    }
}

export default TotalProfilesCharts;
