import React, { Component } from "react";
import { Dialog } from "primereact/dialog";
import { InputSwitch } from "primereact/inputswitch";
import { DataTable } from "primereact/datatable";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { connect } from "react-redux";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Tooltip } from "primereact/tooltip";
import CreateRole from "./createRole";

import "./styles.scss";
import Authorize, {
    PERMISSIONS,
    isAuthorized,
} from "./../../../../session/authorize";
import LoadingComponent from "./../../../../LoadingComponent";
import { logOut, warningDailogInit } from "./../../../../Utils";
import Service from "./../../../../Service";
import ServerlessService from "./../../../../Service/ServerlessService";
import { BreadCrumb } from "primereact/breadcrumb";
const BasicLazyParams = {
    first: 0,
    rows: 10,
    page: 0,
    search: "",
    sortField: null,
    sortOrder: null,
    filters: {
        // 'name': { value: '', matchMode: 'contains' },
        // 'country.name': { value: '', matchMode: 'contains' },
        // 'company': { value: '', matchMode: 'contains' },
        // 'representative.name': { value: '', matchMode: 'contains' },
    },
};

class Roles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            globalFilter: "",
            globalSearch: "",
            columns: [{ columnName: "Role Name" }],

            roles: [],
            editRoleData: null,
            showSearch: false,
            isShowCreate: false,
            isAdd: false,
            dialogData: {},

            warningDialog: warningDailogInit,
            totalRecords: 0,
            globalSearch: "",
            lazyParams: BasicLazyParams,
        };
        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }

    getRoles = () => {
        this.setState({
            isLoading: true,
        });
        let lazyParams = this.state.lazyParams;
        let url = `/authorize/getRoles?limit=${lazyParams.rows}&page=${lazyParams.page + 1
            }&search=${this.state.globalSearch}`;
        this.serverlessService
            .get(url, true)
            .then((res) => {
                if (res && res.res && res.res.data) {
                    this.setState({
                        roles: res.res.data.map((r, index) => {
                            r.slNo = index + 1;
                            return r;
                        }),
                        totalRecords: res.res.totalRecords,
                        isLoading: false,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    componentDidMount() {
        this.getRoles();
    }

    changeStatus = (e, rowData) => {
        let data = { ...rowData };
        if (e.value == true) {
            data.status = "Active";
        } else {
            data.status = "InActive";
        }

        this.setState({
            warningDialog: {
                visible: true,
                headerMsg: "Are you Sure?",
                message: `You are changing this Role status ${e.value ? "InActive" : "Active"
                    } to ${e.value ? "Active" : "InActive"}`,
            },
            editRoleData: data,
        });
    };

    onConfirmChange = () => {
        this.setState({
            warningDialog: warningDailogInit,
            isLoading: true,
        });
        const url = `/authorize/updateRole`;
        this.serverlessService
            .put(url, this.state.editRoleData, true)
            .then((res) => {
                if (res && res.status) {
                    this.setState(
                        {
                            isLoading: false,
                        },
                        () => {
                            this.onRoleCreate(false);
                        }
                    );
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
                this.setState({
                    isLoading: false,
                });
            });
    };
    exportCSV = () => {
        this.dt.exportCSV();
    };

    editTemplate = (rowData) => {
        return (
            <>
                <div className="p-d-flex  user-edit-t">
                    <Authorize permId={PERMISSIONS.ROLE_EDIT}>
                        <span
                            data-pr-tooltip="Edit Role"
                            data-pr-position="bottom"
                            className={`cursor-pointer edit${rowData._id.substr(
                                4
                            )}`}
                            onClick={() => this.showEditRoleDialog(rowData)}
                        >
                            <i className="pi pi-pencil" />
                        </span>
                        <Tooltip
                            className="table-li-tooltip2"
                            autoHide={false}
                            target={`.edit${rowData._id.substr(4)}`}
                        />
                    </Authorize>
                </div>
            </>
        );
    };

    onPage = (event) => {
        this.setState({ lazyParams: event }, this.getRoles);
    };

    showEditRoleDialog = (editRoleData) => {
        this.setState({
            editRoleData,
            isShowCreate: true,
            isAdd: false,
        });
    };

    showRoleCreateDialog = () => {
        this.setState({
            isShowCreate: true,
            editRoleData: null,
            isAdd: true,
        });
    };

    hideRoleCreateDialog = () => {
        this.setState({
            isShowCreate: false,
            editRoleData: null,
            isAdd: false,
        });
    };

    onRoleCreate = (isCreate, data) => {
        this.toast.show({
            severity: "success",
            summary: isCreate ? "Role Created" : "Role Updated",
            detail: `Role Successfully ${isCreate ? "Created" : "Updated"}`,
            life: 3000,
        });
        this.setState(
            {
                isShowCreate: false,
                editRoleData: null,
                lazyParams: BasicLazyParams,
            },
            () => {
                this.getRoles();
            }
        );
    };

    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {
                globalSearch: e.target.value,
                lazyParams: BasicLazyParams,
            };
        }, this.getRoles);
    };

    render() {
        const header = (
            <div className="">
                <div class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 top-100 z-1 shadow-2 lg:shadow-none border-1 lg:border-none border-gray-800">
                    <ul class="list-none flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li>
                            <p className="mx-0 my-1 ss-header">Admin Roles</p>
                        </li>
                    </ul>
                    <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li class="flex-order-2 lg:flex-order-0">
                            <ul className="right-0">
                                <ul className="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                                    <Authorize
                                        permId={PERMISSIONS.ROLE_GENERAL_SEARCH}
                                    >
                                        <li className="flex-order-2 lg:flex-order-0 ml-4 ">
                                            {this.state.showSearch && (
                                                <InputText
                                                    placeholder="Search"
                                                    className=""
                                                    value={
                                                        this.state.globalSearch
                                                    }
                                                    onChange={
                                                        this.onGlobalSearch
                                                    }
                                                />
                                            )}
                                        </li>
                                    </Authorize>
                                    <Authorize
                                        permId={PERMISSIONS.ROLE_GENERAL_SEARCH}
                                    >
                                        <li className="flex-order-2 lg:flex-order-0 ml-4 cursor-pointer">
                                            <i
                                                data-pr-tooltip="Search"
                                                data-pr-position="bottom"
                                                className="pi pi-search text-base lg:text-2xl mr-2 ss-tbl-srch"
                                                onClick={(e) => {
                                                    this.setState({
                                                        showSearch:
                                                            !this.state
                                                                .showSearch,
                                                    });
                                                }}
                                            ></i>
                                        </li>
                                        <Tooltip
                                            className="table-li-tooltip"
                                            autoHide={false}
                                            target=".ss-tbl-srch"
                                        />
                                    </Authorize>
                                    <Authorize
                                        permId={
                                            PERMISSIONS.ROLE_GENERAL_DOWNLOAD
                                        }
                                    >
                                        <li className="border-top-1 border-gray-800 lg:border-top-none ml-4 mr-4 cursor-pointer">
                                            <i
                                                data-pr-tooltip="Download"
                                                data-pr-position="bottom"
                                                className="pi pi-download  text-base lg:text-2xl mr-2 ss-tbl-dwnld"
                                                onClick={this.exportCSV}
                                            ></i>
                                        </li>
                                        <Tooltip
                                            className="table-li-tooltip"
                                            target=".ss-tbl-dwnld"
                                        />
                                    </Authorize>
                                </ul>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="flex justify-content-between flex-wrap">
                    <div class="flex font-bold border-round w-4"></div>
                    <div class="flex absolute right-0 align-items-right  font-bold border-round w-8 "></div>
                </div>

                {/* <Menu
                    style={{ width: "100px" }}
                    model={[
                        {
                            template: (<div className='search-filter'>
                                <h4 className="sort-title">Sort & Filter</h4>
                                <ul>
                                    {this.state.columns.map((column) => {
                                        return <li>{column.columnName}</li>
                                    })}
                                </ul>
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} /> */}
            </div>
        );

        const items = [
            { label: 'Admin' },
            { label: 'Admin' },
            { label: 'Admin Roles' },

        ];

        const home = { icon: 'pi pi-home', url: 'http://localhost:3000/#/admin' }


        return (
            <>
                <div className="h-screen overflow-auto ss-data-table">
                    <Card>
                        <BreadCrumb model={items} home={home} />
                        <Authorize permId={PERMISSIONS.ROLE_ADD}>
                            <Button
                                label="New"
                                icon="pi pi-plus"
                                className="m-3"
                                outlined
                                aria-label="Create"
                                onClick={this.showRoleCreateDialog}
                            />
                            {/* <Button label={`Add Role`} className='p-button-primary' /> */}
                        </Authorize>
                        <Authorize permId={PERMISSIONS.ROLE_LIST}>
                            <DataTable
                                ref={(el) => (this.dt = el)}
                                value={this.state.roles}
                                dataKey="_id"
                                paginator
                                lazy
                                onPage={this.onPage}
                                first={this.state.lazyParams.first}
                                last={this.state.totalRecords}
                                rows={this.state.lazyParams.rows}
                                totalRecords={this.state.totalRecords}
                                paginatorLeft
                                header={header}
                                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                responsiveLayout="scroll"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                globalFilter={this.state.globalFilter}
                            >
                                {/* <Column field="slNo" header="Sr. No" body={(rowData) => {
                                    return <span>
                                        {(this.state.lazyParams.page * this.state.lazyParams.rows) + rowData.slNo}
                                    </span>
                                }} /> */}
                                <Column field="roleName" header="Role" />
                                <Column
                                    body={(rowData) => {
                                        return (
                                            <div className="">
                                                <Authorize
                                                    permId={
                                                        PERMISSIONS.ROLE_EDIT
                                                    }
                                                >
                                                    <InputSwitch
                                                        onChange={(e) => {
                                                            this.changeStatus(
                                                                e,
                                                                rowData
                                                            );
                                                        }}
                                                        checked={
                                                            rowData.status ==
                                                            "Active"
                                                        }
                                                    />
                                                </Authorize>
                                            </div>
                                        );
                                    }}
                                    field="status"
                                    header="Status"
                                />
                                <Column
                                    body={this.editTemplate}
                                    header="Actions"
                                ></Column>
                            </DataTable>
                        </Authorize>
                    </Card>

                    <Dialog
                        header={<>{this.state.warningDialog.headerMsg}</>}
                        draggable={false}
                        closeOnEscape={true}
                        blockScroll={true}
                        dismissableMask={false}
                        closable={false}
                        visible={this.state.warningDialog.visible}
                        style={{ maxWidth: "22vw", minWidth: "300px" }}
                        footer={() => {
                            return (
                                <div>
                                    <Button
                                        label="No"
                                        className="p-button-outlined"
                                        onClick={() => {
                                            this.setState({
                                                warningDialog:
                                                    warningDailogInit,
                                                editRoleData: null,
                                            });
                                        }}
                                    />
                                    <Button
                                        label="Yes"
                                        onClick={this.onConfirmChange}
                                    />
                                </div>
                            );
                        }}
                        onHide={() =>
                            this.setState({
                                warningDialog: warningDailogInit,
                                editRoleData: null,
                            })
                        }
                    >
                        <div>
                            <span className="warning-icon"></span>
                        </div>
                        {this.state.warningDialog.message}
                    </Dialog>

                    <Toast
                        ref={(el) => (this.toast = el)}
                        position="bottom-right"
                    />

                    {this.state.isShowCreate && (
                        <CreateRole
                            onRoleCreate={this.onRoleCreate}
                            isAdd={this.state.isAdd}
                            hideRoleCreateDialog={this.hideRoleCreateDialog}
                            editRoleData={this.state.editRoleData}
                        />
                    )}
                    {this.state.isLoading && (
                        <>
                            <LoadingComponent />
                        </>
                    )}
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Roles);
