import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import AddPolicy from "./addPolicyType1";
import { BasicLazyParams } from "../../../Utils/constants";
import { Toast } from "primereact/toast";
import LoadingComponent from "../../../LoadingComponent";
import { Dialog } from "primereact/dialog";
import PolicyHistory from "./policyHistory";

import {
    getFormFields,
    isFormValid,
    onDropDownChange,
    onNumberChange,
    onTextChange,
} from "../../../Utils/formHelper";
import ServerlessService from "../../../Service/ServerlessService";
import { Tooltip } from "primereact/tooltip";
import AddMembers from "./addMembers";
import { InputText } from "primereact/inputtext";
import PolicyType from "./policyType";
import { logOut } from "../../../Utils";

class Policy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            policy: null,
            policyId: null,
            isShowPolicy: true,
            isShowPolicyHistory: false,
            editData: null,
            isShowConfirmationDialog: false,
            globalSearch: "",
            lazyParams: {
                ...BasicLazyParams,
                sortField: "createdAt",
                sortOrder: 1,
            },
        };

        this.serverlessService = new ServerlessService();
    }

    deletePolicy = (_id) => {
        this.setState({
            isLoading: true,
        });
        var _id = this.state.selectedId;

        const url = `/chitfunds/policy/${_id}`;
        this.serverlessService
            .delete(url, true)
            .then((res) => {
                if (res && res?.status && res?.res?.status) {
                    this.setState(
                        {
                            isLoading: false,
                        },
                        () => {
                            this.getPolicies();
                        }
                    );
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    onPolicyAddOrUpdate = () => {

        this.setState(
            {
                visible: false,
                editData: null,
            }
            // this.getTables
        );
    };

    onHide = () => {
        this.setState({
            visible: false,
            // isShowRenewal: false,
            // editData: null,
        });
    };

    getPolicies = () => {
        let { lazyParams, globalSearch } = this.state;
        this.setState({
            isLoading: true,
            policy: [],
        });

        const url = `/chitfunds/policy?limit=${lazyParams.rows}&page=${lazyParams.page + 1
            }&search=${globalSearch}${lazyParams.sortField
                ? `&sort=${lazyParams.sortOrder === 1 ? "" : "-"}${lazyParams.sortField
                }`
                : ""
            }`;
        this.serverlessService
            .get(url, true)
            .then((res) => {

                if (res && res?.status && res?.res?.status) {
                    console.log(res?.res?.data, "policies dara data");
                    this.setState({
                        isLoading: false,
                        policy: res?.res?.data,
                        totalRecords: res.res.totalRecords,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {
                globalSearch: e.target.value,
                lazyParams: BasicLazyParams,
            };
        }, this.getPolicies);
    };

    onPage = (event) => {
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event,
                },
            };
        }, this.getPolicies);
    };

    onSort = (event) => {
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event,
                },
            };
        }, this.getPolicies);
    };

    onFilter = (event) => {
        event["first"] = 0;
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    filters: event.filters,
                },
            };
        }, this.getPolicies);
    };

    footerContent = () => {
        return (
            <div>
                <Button
                    label="Cancel"
                    className=" p-button-text hover:bg-primary "
                    onClick={this.onHide}
                />
                <Button
                    label="Ok"
                    onClick={() => this.deletePolicy()}
                    autoFocus
                    className=" p-button-primary "
                />
            </div>
        );
    };

    showDeletePolicyDialog = (id) => {
        this.setState({
            isShowConfirmationDialog: true,
            selectedId: id,
        });
    };

    onCloseConfirmation = () => {
        this.setState({
            isShowConfirmationDialog: false,
        });
    };

    actionsBody = (rowData) => {
        return (
            <div className="p-grid row">
                <div className="col-4 ml-2 mr-2">
                    {/* <Authorize permId={PERMISSIONS.BRANCHES_DELETE}> */}

                    <span
                        onClick={() => this.showDeletePolicyDialog(rowData._id)}
                        data-pr-tooltip="Delete Policy"
                        data-pr-position="top"
                        className="p-text"
                    >
                        <i className="pi pi-trash" />
                    </span>
                    <Tooltip target=".p-text" />

                    {/* </Authorize> */}
                </div>

                <div className="col-4 ml-2 mr-2">
                    {/* <Authorize permId={PERMISSIONS.BRANCHES_EDIT}> */}

                    <span
                        onClick={() =>
                            this.setState({
                                editPolicy: rowData,
                                visible: true,
                                isEdit: true,
                            })
                        }
                        data-pr-tooltip="Edit Policy"
                        data-pr-position="top"
                        className="p-0"
                    >
                        <i className="pi pi-pencil" />
                    </span>
                    <Tooltip target=".p-0" />
                    {/* </Authorize> */}
                </div>
            </div>
        );
    };

    onAddMembersClick = (policyId) => {
        this.setState({
            isShowAddMembers: true,
            isShowPolicy: false,
            policyId: policyId,
        });
    };

    onPolicyHistoryClick = (policyId) => {
        this.setState({
            isShowAddMembers: false,
            isShowPolicy: false,
            policyId: policyId,
            isShowPolicyHistory: true,
        });
    };

    onAddMembersCancel = () => {
        this.setState({
            isShowAddMembers: false

        })
    };

    componentDidMount() {
        this.getPolicies();
    }
    render() {
        const { policy } = this.state;
        console.log(policy, "sfdsssssssssssssss");
        console.log(this.state.selectedId, "selectedId");
        return (
            <>
                {this.state.isShowPolicy && (
                    <div className="p-4">
                        <span className="text-1000 font-medium text-3xl  mb-3">
                            Policies
                        </span>
                        <br />
                        <Button
                            className="p-button-primary "
                            label="Add Policy"
                            severity="warning"
                            onClick={() => this.setState({ visible: true })}
                        />
                        <br />
                        <br />
                        {this.state.visible && (
                            <PolicyType
                                onHide={this.onHide}
                                editPolicy={this.state.editPolicy}
                                onpolicyAddorEdit={this.onPolicyAddOrUpdate}
                            ></PolicyType>
                        )}
                        <div>
                            <DataTable
                                value={policy}
                                lazy
                                scrollable
                                responsiveLayout="scroll"
                                rowHover
                                onSort={this.onSort}
                                onPage={this.onPage}
                                onFilter={this.onFilter}
                                first={this.state.lazyParams.first}
                                last={this.state.totalRecords}
                                rows={this.state.lazyParams.rows}
                                totalRecords={this.state.totalRecords}
                                paginator
                                scrollHeight="400px"
                                paginatorLeft
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                rowsPerPageOptions={[
                                    5, 10, 25, 50, 100, 200, 300, 500,
                                ]}
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                dataKey="_id"
                                selection={this.state.selectedCustomer}
                                globalFilter={this.state.globalFilter}
                                onSelectionChange={(e) =>
                                    this.setState({ selectedCustomer: e.value })
                                }
                                selectionMode="single"
                            >
                                <Column
                                    field="policyType"
                                    header="Policy Type"
                                ></Column>
                                <Column
                                    field="totalAmount"
                                    header="Total Amount"
                                ></Column>
                                {/* <Column field="totalMembers" header="Total Members"></Column> */}
                                {/* <Column field="totalMonths" header="Total Months"></Column> */}
                                <Column
                                    body={(rowData) => (
                                        <Button
                                            label="Add Members"
                                            onClick={() =>
                                                this.onAddMembersClick(
                                                    rowData._id
                                                )
                                            }
                                        ></Button>
                                    )}
                                    header="Actions"
                                ></Column>

                                <Column
                                    body={(rowData) => (
                                        <Button
                                            label="Policy History"
                                            onClick={() =>
                                                this.onPolicyHistoryClick(
                                                    rowData._id
                                                )
                                            }
                                        ></Button>
                                    )}
                                    header="Actions"
                                ></Column>

                                <Column
                                    body={(rowData) =>
                                        this.actionsBody(rowData)
                                    }
                                    header="Actions"
                                ></Column>
                            </DataTable>


                            {(this.state.isLoading || this.props.isLoading) && (
                                <LoadingComponent />
                            )}
                        </div>
                    </div>
                )}

                {this.state.isShowAddMembers && (
                    <AddMembers
                        onAddMembersCancel={this.onAddMembersCancel}
                        policyId={this.state.policyId}
                    ></AddMembers>
                )}
                {this.state.isShowConfirmationDialog && (
                    <Dialog
                        header="Are you Sure ?"
                        visible={this.state.isShowConfirmationDialog}
                        style={{ width: "25vw" }}
                        onHide={this.onCloseConfirmation}
                        footer={this.footerContent()}
                        draggable={false}
                    >

                        <p>Deleted Policy can't be restore.</p>
                    </Dialog>
                )}

                {this.state.isShowPolicyHistory && (
                    <PolicyHistory
                        policyId={this.state.policyId}
                        monthlyPay={policy[0]?.monthlyPay}
                    ></PolicyHistory>
                )}

                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
            </>
        );
    }
}

export default Policy;
