import React from "react";
import { withRouter } from "react-router-dom";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";

import { connect } from "react-redux";
import { InputText } from "primereact/inputtext";

import './styles.scss'





class AddQuestion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            question: (props.data && props.data.question) || "",


        };
    }


    componentDidUpdate(prevProps) {
        if (
            prevProps.question !== this.props.question
        ) {
            this.setState({
                question: this.props.question || "",

                isAssigned: !!this.props.resource,
            });
        }
    }

    checkIsAssigned = (subjectId) => {
        let isAssigned = false;
        this.props.resources.forEach((g) => {
            if (g.data && g.data.subjectId == subjectId) {
                isAssigned = true;
            }
        });
        return isAssigned;
    };

    assignCurriculum = () => {
        const { question, } = this.state;

        if (question != "") {
            this.setState(
                {
                    isAssigned: true,
                },
                () => {
                    this.props.onAssignClick({
                        id: this.props.id,
                        data: {
                            question,

                        },
                        assigned: true,
                    });
                }
            );
        } else {
            this.setState({
                errorMsg: "Enter Question",
            });

            setTimeout(() => {
                this.setState({
                    errorMsg: "",
                });
            }, 3000);
        }
    };

    onEditClick = () => {
        if (this.props.resources.every((s) => s.assigned === true)) {
            this.setState({
                isAssigned: false,
            }, () => {
                this.props.onAssignClick({
                    id: this.props.id,
                    assigned: false,
                });
            });
        } else {
            this.toast.show({
                severity: "warn",
                summary: "Warning",
                detail: "Please add or remove which is in edit state.",
                life: 3000,
            });
        }
    };



    render() {

        return (
            <div className="p-grid ">
                <div>
                    <div className="p-grid row">
                        <div className="col-8 ">
                            <InputText
                                className="w-full	"
                                placeholder="Enter question here"
                                disabled={this.state.isAssigned}
                                value={this.state.question}
                                onChange={(e) => {
                                    this.setState({
                                        question: e.target.value,
                                    });
                                }}
                            />
                        </div>
                        <div className="col-4">
                            {this.state.isAssigned ? (
                                <Button
                                    label={`Edit`}
                                    onClick={this.onEditClick}
                                />
                            ) : (
                                <Button
                                    label={`Save`}
                                    onClick={this.assignCurriculum}
                                />
                            )}


                            <Button
                                icon="pi pi-times"
                                className="p-button-text  button.close p-button-secondary "
                                onClick={() => {
                                    this.props.removeTempSubject(this.props.id);
                                }}

                            />
                        </div>
                    </div>

                    <Toast
                        ref={(el) => (this.toast = el)}
                        position="bottom-right"
                    />
                    <p className="sp-required">
                        {this.state.errorMsg}
                    </p>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

export default connect(mapStateToProps, {})(withRouter(AddQuestion));
