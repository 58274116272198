import { classNames } from "primereact/utils";

import React, { Component } from "react";
import ServerlessService from "../../../Service/ServerlessService";
import NavFooter from "../../navFooter";
import OrderDetails from "./orderDetails";
import { BasicLazyParams } from "../../../Utils/constants";
import { Toast } from "primereact/toast";
import { logOut } from "../../../Utils";


class UserOrders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lazyParams: {
                ...BasicLazyParams,
                rows: 27,
                sortField: "updatedAt",
                sortOrder: 1,
                disableNext: false
            },
            globalSearch: "",
            isShowWarning: false,
            warningMessage: "",
            orders: null,
            isShowDialog: false,
        };
        this.serverlessService = new ServerlessService();
    }


    onNextOrPrevious = (next) => {

        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    page:
                        prevState.lazyParams.page +
                        (next ? 1 : prevState.lazyParams.page ? -1 : 0),
                },
            };
        }, this.getOrders);
    };

    getOrders = () => {
        let { lazyParams, globalSearch } = this.state;

        this.setState({
            isLoading: true,
            orders: null,
        });

        const url = `/orders?limit=${lazyParams.rows}&page=${lazyParams.page + 1
            }&search=${globalSearch}${lazyParams.sortField
                ? `&sort=${lazyParams.sortOrder == 1 ? "" : "-"}${lazyParams.sortField
                }`
                : ""
            }`;

        this.serverlessService
            .get(url, true)
            .then((res) => {
                if (res && res.status && res.res.status) {


                    this.setState({
                        isLoading: false,
                        orders: res.res.data,
                        totalRecords: res.res.totalRecords,
                        disableNext: res.res.data.length == 0 ? true : false

                    });
                    if (!res.res?.data?.length) {
                        this.toast.show({
                            severity: "warn",
                            summary: "No more profiles",
                            detail: "",
                            life: 3000,
                        });
                    }
                } else {
                    if (res.res.statusCode == 34 || res.res.statusCode == 32) {
                        this.setState({
                            isLoading: false,
                            isShowWarning: true,
                            warningMessage: res.res.message,
                        });
                    }
                    else {
                        this.setState({
                            isLoading: false,
                        });
                        this.toast.show({
                            severity: "error",
                            summary: "Some error occurred",
                            detail: res.errMessage,
                            life: 3000,
                        });
                    }
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    componentDidMount() {
        this.getOrders();
    }

    onClickOrder = (id) => {
        this.setState({
            isShowDialog: true,
            selectedOrder: id,
        });
    };

    onHide = () => {
        this.setState({
            isShowDialog: false,
        });
    };

    render() {
        const { disableNext, lazyParams, warningMessage, isShowWarning } = this.state;

        return (
            <NavFooter>
                {isShowWarning ? (
                    <div className="card shadow-2  surface-0 border-round p-3">
                        <p>{warningMessage}</p>
                    </div>
                ) :
                    <div className="p-8">

                        <div className="text-900 text-xl mb-3 text-left font-medium">
                            Orders List
                        </div>


                        <div className="">
                            {this.state.orders?.map((item) => (
                                <div
                                    className={classNames(
                                        "shadow-2  border-1 p-4 mb-3 border-round flex flex-column md:flex-row align-items-start md:align-items-center md:justify-content-between cursor-pointer surface-border"
                                    )}
                                    onClick={() => this.onClickOrder(item?._id)}
                                >
                                    <div
                                        className="col-9 flex align-items-start mr-0 md:mr-8"

                                    >
                                        <div>
                                            <div
                                                className={classNames(
                                                    "text-3xl text-1500"
                                                )}
                                            >
                                                Package Name- {item?.selectedPackage?.packageName}
                                            </div><br />

                                            <span
                                                className={classNames(
                                                    "p-1 border-round surface-100 text-600"
                                                )}
                                            >
                                                <b> Credits -{" "} </b>
                                                {item?.selectedPackage?.credits}
                                            </span><br />
                                            <span
                                                className={classNames(
                                                    "p-1 border-round surface-100 text-600"
                                                )}
                                            >
                                                <b> Name as per the billing -{" "}</b>
                                                {item?.billingDetails?.name}
                                            </span><br />
                                            <span
                                                className={classNames(
                                                    "p-1 border-round surface-100 text-600"
                                                )}
                                            >
                                                <b>Package / Subscription -{" "}</b>
                                                {item?.selectedPackage?.type}
                                            </span><br />
                                            <span
                                                className={classNames(
                                                    "p-1 border-round surface-100 text-600"
                                                )}
                                            >
                                                Payment Status -{" "}
                                                <b>{item?.paymentStatus == true ? <label className="text-success">Success</label> : <label className="text-danger">Failed</label>}</b>
                                            </span>
                                        </div>
                                    </div>
                                    <div
                                        className={classNames(
                                            " ml-0 md:ml-8 mt-2 md:mt-0 border-1 p-1 px-2 border-round flex align-items-cente text-600 surface-border"
                                        )}
                                    >
                                        <span>
                                            Rs.<b>{item?.selectedPackage?.price}</b>
                                        </span>
                                    </div>
                                </div>
                            ))}
                        </div>

                        {this.state.isShowDialog && (
                            <OrderDetails
                                id={this.state.selectedOrder}
                                onHide={this.onHide}
                            ></OrderDetails>
                        )}
                    </div>
                }
                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
            </NavFooter>
        );
    }
}

export default UserOrders;
