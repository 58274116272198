import { act } from "react-dom/test-utils";
import { BasicLazyParams } from "../../Utils/constants";
import { ACTIONS } from "../actionTypes";
export default (
    state = {
        tags: [],
        adminScreeningCalls: [],
        isLoading: false,
        error: false,
        errorMessage: "",
        lazyParams: {
            ...BasicLazyParams,
            sortField: "-createdAt",
            sortOrder: 1,
        },
        globalSearch: "",
        
        selectedStatus: '',
        totalRecords: 0,
        
    },
    
    action
) =>

{
    
    switch (action.type) {
        case ACTIONS.REQUEST_ADMIN_SCREENINGCALLS:
            return {
                ...state,
                lazyParams: {
                    ...state.lazyParams,
                    ...action?.data?.lazyParams,
                },
                globalSearch: action?.data?.globalSearch || state?.globalSearch,
                selectedStatus: action?.data?.status,
                isLoading: true,
                error: false,
            };
        case ACTIONS.RECEIVED_ADMIN_SCREENINGCALLS:
            return {
                ...state,
                isLoading: false,
                adminScreeningCalls: action?.data?.data,
                totalRecords: action?.data?.totalRecords,
            };
        case ACTIONS.ERROR_ADMIN_SCREENINGCALLS:
            return {
                ...state,
                isLoading: false,
                error: true,
                errorMessage: action?.err?.message,
            };
        case ACTIONS.CLEAR_ADMIN_SCREENINGCALLS:
            return {
                isLoading: false,
                error: false,
                errorMessage: "",
                adminScreeningCalls: [],
            };

        
        default:
            return state;
    }
};
