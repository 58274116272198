import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './reducers';
import thunk from 'redux-thunk';

const logger = (store) => {
  return (next) => {
    return (action) => {
      const result = next(action);

      return result;
    };
  };
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(logger, thunk)));

export default store;


// import { createStore, applyMiddleware, compose } from 'redux';
// import rootReducer from './reducers';
// import thunk from 'redux-thunk';
// import { persistStore, persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

// // Custom logger middleware
// const logger = (store) => (next) => (action) => {
//   console.log('dispatching', action);
//   let result = next(action);
//   console.log('next state', store.getState());
//   return result;
// };

// const persistConfig = {
//   key: 'root',
//   storage,
//   // Optionally, you can add more configuration options here.
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const store = createStore(
//   persistedReducer, // use persistedReducer instead of rootReducer
//   composeEnhancers(applyMiddleware(logger, thunk))
// );

// export const persistor = persistStore(store);

// export default store;
