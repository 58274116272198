import React, { Component } from "react";
import NavFooter from "../navFooter";
import Organization from "./organization";
import Authentication from "./../../session/index";
import { Route, Switch, Link } from "react-router-dom";
import { StyleClass } from "primereact/styleclass";
import { Ripple } from "primereact/ripple";
import AdminUser from "./adminManagement/adminUser";
import Roles from "./adminManagement/roles";
import PortalManage from "./organization/portalManage";
import { connect } from "react-redux";

import RegistrationFee from "./feeManagement/registration_fee";
import PackagesFee from "./feeManagement/packagesFee";
import Profiles from "./profiles/profiles";
import Subscription from "./subscriptions";
import ApprovedProfiles from "./profiles/approvedProfiles";
import Users from "./endUsers";

import Customers from "../co-space/customers";
import FeeDetailsList from "../co-space/feeDetails";
import TablesList from "../co-space/tables";
import BranchDetails from "../co-space/branches";
import Transactions from "../co-space/transactions";
import LandingPage from "../landingPage";
// import LandingPageNew from "./landingPage";
import Dashboard from "../co-space/dashboard/dashboard";
import Policy from "../chitfunds/policy";
import ChitsCustomers from "../chitfunds/customers";
import { APP_TARGETS, CONSULTANT_MENU_TAB } from "../../Utils/constants";
import Consultants from "../consultant/consultants";
import AdminUserPrivileges from "../consultant/adminUserPrivileges";
import QuestionBank from "../consultant/questionBank";
import SearchBank from "../consultant/questionBank/searchBank";
import ContentWriter from "../consultant/contentWriter";
import DocumentPreparator from "../consultant/documentPreperator";
import DocumentEditing from "../consultant/documentEditing";
import DocumentVerifier from "../consultant/documentVerifier";
import TechnicalPreperator from "../consultant/technicalPreperator";
import PreMarketReview from "../consultant/preMarketReview";
import SalesTeamHead from "../consultant/salesTeamHead";

import Resources from "../consultant/resources";
import ScreeningCalls from "../consultant/userFunctionaliy/screeningCalls";
import Authorize, {
    PERMISSIONS,
    isAuthorized,
    isAuthorizedWithOnePermissions,
} from "../../session/authorize";

import SalesTeam from "../consultant/salesTeam";

import AddConsultant from "../consultant/consultants/addConsultant";
import LearningResources from "../consultant/learningResources";
import AddResource from "../consultant/learningResources/addConsultant";

import LearningPlans from "../consultant/learningPlans";
import AddPlan from "../consultant/learningPlans/addConsultant";
import AddPlanDetails from "../consultant/learningPlans/addPlanDetails";
import EditConsultant from "../consultant/contentWriter/addConsultant";
import EditDocument from "../consultant/documentEditing/addConsultant";
import ViewComponent from "../consultant/documentPreperator/viewConsultant";
import TackerComponent from "../consultant/documentPreperator/consultantTracker";
import MonitorComponent from "../consultant/documentPreperator/consultantMonitor";
import NotesComponent from "../consultant/documentPreperator/consultantNotes";
import Interview from "../consultant/salesTeam/interviews";
import Submissions from "../consultant/salesTeam/submissions";
import Copyhelper from "../consultant/copy-helper";
import AddCopyHelper from "../consultant/copy-helper/addConsultant";
import ViewInterview from "../consultant/salesTeam/interviews/viewInterview";
import AddInterview from "../consultant/salesTeam/interviews/editingRequest";
import AddSubmission from "../consultant/salesTeam/submissions/editingRequest";
import ViewSubmission from "../consultant/salesTeam/submissions/viewSubmission";
import AddQuestion from "../consultant/questionBank/addQuestion";
import AddFAQ from "../consultant/faq/addQuestion";
import AddUserLogin from "../consultant/documentPreperator/AddUserLogin";
import RecruiterList from "../consultant/recruitersList";
import SubmissionChart from "../consultant/wibgets/submissionWibget";
import InterviewChart from "../consultant/wibgets/interviewsWibget";
import ScreeningCallsChart from "../consultant/wibgets/screeningcallsWibget";
import InterSubChart from "../consultant/wibgets/sub&interWibget";
import ClosureChart from "../consultant/wibgets/closureWibget";
import ClosureByRecruiterChart from "../consultant/wibgets/closureByRecruiterWibget";
import TotalProfilesChart from "../consultant/wibgets/totalProfilesWibget";
import ProfilesToRecruiter from "../consultant/wibgets/ProfilesAssignedToRecruiter";
import WidgetDashboard from "../consultant/wibgets";
import Faq from "../consultant/faq";
import Editfaq from "../consultant/faq/addQuestion";
import ApprovedQuestion from "../consultant/questionBank/approveQuestions";
import CreatedByProfiles from "../consultant/wibgets/createdByListofProfilesWibget";
import CreatedByProfilesCount from "../consultant/wibgets/createdByConsultantCount";
import AllConsultants from "../consultant/allConsultants";
import ScreeningCallbyConsultant from "../consultant/userFunctionaliy/screeningCalls";
import AddScreeningCall from "../consultant/userFunctionaliy/screeningCalls/addScreeningCalls";
import AdminViewCall from "../consultant/userFunctionaliy/screeningCalls/adminViewCalls";
import UserQuestion from "../consultant/questionBank/user-questions";
import AdminViewScreeningCall from "../consultant/userFunctionaliy/screeningCalls/adminViewScreeningCall";
import HistoryRecruiter from "../consultant/salesTeamHead/historyRecruiter";
import InterviewsView from "../consultant/allConsultants/interviewsView";
import SubmissionsView from "../consultant/allConsultants/submissionsView";
import InterviewStatus from "../consultant/salesTeam/interviews/interviewStatus";
import SubmissionStatus from "../consultant/salesTeam/submissions/submissionStatus";
import InterviewsDropdown from "../consultant/salesTeam/interviews/interviewsDropdown";
import AddUserQuestion from "../consultant/questionBank/addUserQuestion";
import AddContact from "../consultant/contacts/addContact";
import Contacts from "../consultant/contacts";

const adminModules = {
    consultant: [{}],
};

class Admin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            title: "",
        };

        this.btnCospace = React.createRef();
        this.btnAdminManagement = React.createRef();
        this.btnQuestionBank = React.createRef();
        this.btnQuestionBank1 = React.createRef();
        this.btnChits = React.createRef();
        this.btnRef1 = React.createRef();
        this.btnRef2 = React.createRef();
        this.btnRef3 = React.createRef();
        this.btnRef4 = React.createRef();
    }

    render() {
        let { path, url } = this.props?.match;
        console.log(this.props?.match, "ddd");

        const { title } = this.state;

        const { user } = this.props;
        console.log(this.props, "dddd-a");
        return (
            <NavFooter>
                <div className="min-h-screen flex relative lg:static">
                    <div
                        id="app-sidebar-1"
                        className="bg-bluegray-800 h-screen hidden lg:block flex-shrink-0 absolute lg:static left-0 top-0 z-1 select-none"
                        style={{ width: "280px" }}
                    >
                        <div className="flex flex-column h-full">
                            <div className="overflow-y-auto mt-3">
                                <ul className="list-none p-3 m-0">
                                    {this.props.isSuper && (
                                        <Authorize
                                            permId={
                                                "fa41ed34-3f8b-4dd2-ab70-039f9aee713b"
                                            }
                                        >
                                            <li>
                                                <Link
                                                    className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50
                transition-duration-150 transition-colors w-full"
                                                    to={`${url}/resellers`}
                                                >
                                                    {" "}
                                                    <i className="pi pi-home mr-2"></i>
                                                    <span className="font-medium hover:bg-bluegray-00 hover:text-bluegray-500">
                                                        Resellers
                                                    </span>
                                                    <Ripple />
                                                </Link>
                                            </li>
                                        </Authorize>
                                    )}

                                    {user?.user?.isAdmin && (
                                        <>
                                            {process.env.REACT_APP_TARGET ==
                                                APP_TARGETS.CO_SPACE && (
                                                    <>
                                                        <li>
                                                            <Link
                                                                className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
            transition-duration-150 transition-colors w-full"
                                                                to={`${url}/branches`}
                                                            >
                                                                {" "}
                                                                <i className="pi pi-sitemap mr-2"></i>
                                                                <span className="font-medium hover:text-white">
                                                                    Branches
                                                                </span>
                                                                <Ripple />
                                                            </Link>
                                                        </li>
                                                    </>
                                                )}
                                            <Authorize
                                                multiple={false}
                                                isGroup={true}
                                                permIds={[
                                                    PERMISSIONS.ADMIN_USER,
                                                    PERMISSIONS.ROLE,
                                                    PERMISSIONS.USER_PRIVILEGE,
                                                ]}
                                            >
                                                <li>
                                                    <StyleClass
                                                        nodeRef={
                                                            this
                                                                .btnAdminManagement
                                                        }
                                                        selector="@next"
                                                        enterClassName="hidden"
                                                        enterActiveClassName="slidedown"
                                                        leaveToClassName="hidden"
                                                        leaveActiveClassName="slideup"
                                                    >
                                                        <a
                                                            ref={
                                                                this
                                                                    .btnAdminManagement
                                                            }
                                                            className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
                    transition-duration-150 transition-colors w-full"
                                                        >
                                                            <i className="pi pi-chart-line mr-2"></i>
                                                            <span className="font-medium">
                                                                Admin
                                                            </span>
                                                            <i className="pi pi-chevron-down ml-auto mr-1"></i>
                                                            <Ripple />
                                                        </a>
                                                    </StyleClass>

                                                    <ul className="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                                                        <Authorize
                                                            permId={
                                                                "c54bee0c-bf05-4739-b88c-eff7a1850008"
                                                            }
                                                        >
                                                            <li>
                                                                <Link
                                                                    className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50
                        transition-duration-150 transition-colors w-full"
                                                                    to={`${url}/admin-roles`}
                                                                >
                                                                    {" "}
                                                                    <i className="pi pi-home mr-2"></i>
                                                                    <span className="font-medium hover:text-white">
                                                                        Admin
                                                                        Roles
                                                                    </span>
                                                                    <Ripple />
                                                                </Link>
                                                            </li>
                                                        </Authorize>

                                                        <Authorize
                                                            permId={
                                                                "0761fb3e-2f31-45e5-a431-c3d2a745d1a3"
                                                            }
                                                        >
                                                            <li>
                                                                <Link
                                                                    className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
                        transition-duration-150 transition-colors w-full"
                                                                    to={`${url}/admin-users`}
                                                                >
                                                                    {" "}
                                                                    <i className="pi pi-home mr-2"></i>
                                                                    <span className="font-medium hover:text-white">
                                                                        Admin
                                                                        Users
                                                                    </span>
                                                                    <Ripple />
                                                                </Link>
                                                            </li>
                                                        </Authorize>
                                                        <Authorize
                                                            permId={
                                                                "1158bb31-8912-4246-8ada-0cfa01a5b6d3"
                                                            }
                                                        >
                                                            <li>
                                                                <Link
                                                                    className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
                            transition-duration-150 transition-colors w-full"
                                                                    to={`${url}/admin-user-privileges`}
                                                                >
                                                                    {" "}
                                                                    <i className="pi pi-home mr-2"></i>
                                                                    <span className="font-medium hover:text-white">
                                                                        Admin
                                                                        Users
                                                                        Privileges
                                                                    </span>
                                                                    <Ripple />
                                                                </Link>
                                                            </li>
                                                        </Authorize>
                                                    </ul>
                                                </li>
                                            </Authorize>
                                            {process.env.REACT_APP_TARGET ==
                                                APP_TARGETS.MATRIMONY && (
                                                    <>
                                                        <li>
                                                            <Link
                                                                className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50
                transition-duration-150 transition-colors w-full"
                                                                to={`${url}/end-users`}
                                                            >
                                                                {" "}
                                                                <i className="pi pi-home mr-2"></i>
                                                                <span className="font-medium hover:text-white">
                                                                    End Users
                                                                </span>
                                                                <Ripple />
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link
                                                                className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50
                transition-duration-150 transition-colors w-full"
                                                                to={`${url}/portal-manage`}
                                                            >
                                                                {" "}
                                                                <i className="pi pi-home mr-2"></i>
                                                                <span className="font-medium hover:text-white">
                                                                    Portal
                                                                    Management
                                                                </span>
                                                                <Ripple />
                                                            </Link>
                                                        </li>

                                                        <li>
                                                            <Link
                                                                className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50
                transition-duration-150 transition-colors w-full"
                                                                to={`${url}/profiles`}
                                                            >
                                                                {" "}
                                                                <i className="pi pi-home mr-2"></i>
                                                                <span className="font-medium hover:text-white">
                                                                    Pending profiles
                                                                </span>
                                                                <Ripple />
                                                            </Link>
                                                        </li>

                                                        <Authorize
                                                            permId={
                                                                "7c43cda2-632c-4d45-a1e5-31e9f07a90ad"
                                                            }
                                                        >
                                                            <li>
                                                                <Link
                                                                    className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50
                transition-duration-150 transition-colors w-full"
                                                                    to={`${url}/registration-fee`}
                                                                >
                                                                    {" "}
                                                                    <i className="pi pi-home mr-2"></i>
                                                                    <span className="font-medium hover:text-white">
                                                                        Registration
                                                                        Fee
                                                                    </span>
                                                                    <Ripple />
                                                                </Link>
                                                            </li>
                                                        </Authorize>
                                                        <Authorize
                                                            permId={
                                                                "7c43cda2-632c-4d45-a1e5-31e9f07a90adfd"
                                                            }
                                                        >
                                                            <li>
                                                                <Link
                                                                    className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
            transition-duration-150 transition-colors w-full"
                                                                    to={`${url}/resource`}
                                                                >
                                                                    {" "}
                                                                    <i className="pi pi-table mr-2"></i>
                                                                    <span className="font-medium hover:text-white">
                                                                        Resource
                                                                    </span>
                                                                    <Ripple />
                                                                </Link>
                                                            </li>
                                                        </Authorize>
                                                        <Authorize
                                                            permId={
                                                                "d458dc8a-649a-48a3-906f-0ac9d529ccee"
                                                            }
                                                        >
                                                            <li>
                                                                <Link
                                                                    className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50
                transition-duration-150 transition-colors w-full"
                                                                    to={`${url}/packages-fee`}
                                                                >
                                                                    {" "}
                                                                    <i className="pi pi-home mr-2"></i>
                                                                    <span className="font-medium hover:text-white">
                                                                        Packages Fee
                                                                    </span>
                                                                    <Ripple />
                                                                </Link>
                                                            </li>
                                                        </Authorize>
                                                        {/* <li>
                                                    <Authorize
                                                        multiple={false}
                                                        isGroup={true}
                                                        permIds={[
                                                            "eb22e11d-bcb3-49eb-9c2a-2ed9673826f2",
                                                        ]}
                                                    >
                                                        <li>
                                                            <Link
                                                                className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
            transition-duration-150 transition-colors w-full"
                                                                to={`${url}/recruiterquestions`}
                                                            >
                                                                {" "}
                                                                <i className="pi pi-users mr-2"></i>
                                                                <span className="font-medium hover:text-white">
                                                                    Recruiter-Q/A
                                                                </span>
                                                                <Ripple />
                                                            </Link>
                                                        </li>
                                                    </Authorize>
                                                    <Authorize
                                                        multiple={false}
                                                        isGroup={true}
                                                        permIds={[
                                                            "eb22e11d-bcb3-49eb-9c2a-2ed9673826f2",
                                                        ]}
                                                    >
                                                        <li>
                                                            <Link
                                                                className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
            transition-duration-150 transition-colors w-full"
                                                                to={`${url}/screeningcalls`}
                                                            >
                                                                {" "}
                                                                <i className="pi pi-users mr-2"></i>
                                                                <span className="font-medium hover:text-white">
                                                                    ScreeningCalls
                                                                </span>
                                                                <Ripple />
                                                            </Link>
                                                        </li>
                                                    </Authorize>
                                                    <Authorize
                                                        multiple={false}
                                                        isGroup={true}
                                                        permIds={[
                                                            "7c43cda2-632c-4d45-a1e5-31e9f07a90ad",
                                                        ]}
                                                    >
                                                        <li>
                                                            <Link
                                                                className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50
                transition-duration-150 transition-colors w-full"
                                                                to={`${url}/registration-fee`}
                                                            >
                                                                {" "}
                                                                <i className="pi pi-home mr-2"></i>
                                                                <span className="font-medium hover:text-white">
                                                                    Registration
                                                                    Fee
                                                                </span>
                                                                <Ripple />
                                                            </Link>
                                                        </li>
                                                    </Authorize>
                                                    <Authorize
                                                        multiple={false}
                                                        isGroup={true}
                                                        permIds={[
                                                            "7c43cda2-632c-4d45-a1e5-31e9f07a90adfd",
                                                        ]}
                                                    >
                                                        <li>
                                                            <Link
                                                                className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
            transition-duration-150 transition-colors w-full"
                                                                to={`${url}/resource`}
                                                            >
                                                                {" "}
                                                                <i className="pi pi-table mr-2"></i>
                                                                <span className="font-medium hover:text-white">
                                                                    Resource
                                                                </span>
                                                                <Ripple />
                                                            </Link>
                                                        </li>
                                                    </Authorize>
                                                    <Authorize
                                                        multiple={false}
                                                        isGroup={true}
                                                        permIds={[
                                                            "d458dc8a-649a-48a3-906f-0ac9d529ccee",
                                                        ]}
                                                    >
                                                        <li>
                                                            <Link
                                                                className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50
                transition-duration-150 transition-colors w-full"
                                                                to={`${url}/packages-fee`}
                                                            >
                                                                {" "}
                                                                <i className="pi pi-home mr-2"></i>
                                                                <span className="font-medium hover:text-white">
                                                                    Packages Fee
                                                                </span>
                                                                <Ripple />
                                                            </Link>
                                                        </li>
                                                    </Authorize>
                                                    {/* <li>
                                                <Link
                                                    className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50
            transition-duration-150 transition-colors w-full"
                                                    to={`${url}/policy`}
                                                >
                                                    {" "}
                                                    <i className="pi pi-home mr-2"></i>
                                                    <span className="font-medium hover:text-white">
                                                        Policy
                                                    </span>
                                                    <Ripple />
                                                </Link>
                                            </li> */}

                                                        <li>
                                                            <Link
                                                                className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white
                transition-duration-150 transition-colors w-full"
                                                                to={`${url}/subscription`}
                                                            >
                                                                {" "}
                                                                <i className="pi pi-home mr-2"></i>
                                                                <span className="font-medium hover:text-white">
                                                                    Subscription
                                                                </span>
                                                                <Ripple />
                                                            </Link>
                                                        </li>
                                                    </>
                                                )}
                                            {process.env.REACT_APP_TARGET ==
                                                APP_TARGETS.CO_SPACE && (
                                                    <>
                                                        <li>
                                                            <Link
                                                                className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
            transition-duration-150 transition-colors w-full"
                                                                to={`${url}/dashboard`}
                                                            >
                                                                {" "}
                                                                <i className="pi pi-users mr-2"></i>
                                                                <span className="font-medium hover:text-white">
                                                                    Dashboard
                                                                </span>
                                                                <Ripple />
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link
                                                                className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
            transition-duration-150 transition-colors w-full"
                                                                to={`${url}/customers`}
                                                            >
                                                                {" "}
                                                                <i className="pi pi-users mr-2"></i>
                                                                <span className="font-medium hover:text-white">
                                                                    Customers
                                                                </span>
                                                                <Ripple />
                                                            </Link>
                                                        </li>

                                                        <li>
                                                            <Link
                                                                className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
            transition-duration-150 transition-colors w-full"
                                                                to={`${url}/tables`}
                                                            >
                                                                {" "}
                                                                <i className="pi pi-table mr-2"></i>
                                                                <span className="font-medium hover:text-white">
                                                                    Tables
                                                                </span>
                                                                <Ripple />
                                                            </Link>
                                                        </li>

                                                        <li>
                                                            <Link
                                                                className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
            transition-duration-150 transition-colors w-full"
                                                                to={`${url}/fee-management`}
                                                            >
                                                                {" "}
                                                                <i className="pi pi-credit-card mr-2"></i>
                                                                <span className="font-medium hover:text-white">
                                                                    Fee Management
                                                                </span>
                                                                <Ripple />
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link
                                                                className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
            transition-duration-150 transition-colors w-full"
                                                                to={`${url}/transactions`}
                                                            >
                                                                {" "}
                                                                <i className="pi pi-sitemap mr-2"></i>
                                                                <span className="font-medium hover:text-white">
                                                                    Transactions
                                                                </span>
                                                                <Ripple />
                                                            </Link>
                                                        </li>
                                                    </>
                                                )}
                                            {process.env.REACT_APP_TARGET ==
                                                APP_TARGETS.CONSULTANT && (
                                                    <>
                                                        <Authorize
                                                            permId={PERMISSIONS.FAQ}
                                                        >
                                                            <li>
                                                                <Link
                                                                    className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
                            transition-duration-150 transition-colors w-full"
                                                                    to={`${url}/faq`}
                                                                >
                                                                    {" "}
                                                                    <i className="pi pi-home mr-2"></i>
                                                                    <span className="font-medium hover:text-white">
                                                                        FAQ
                                                                    </span>
                                                                    <Ripple />
                                                                </Link>
                                                            </li>
                                                        </Authorize>
                                                        <Authorize
                                                            permId={
                                                                PERMISSIONS.SCREENING_CALLS
                                                            }
                                                        >
                                                            <li>
                                                                <Link
                                                                    className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
                            transition-duration-150 transition-colors w-full"
                                                                    to={`${url}/screeningcalls`}
                                                                >
                                                                    {" "}
                                                                    <i className="pi pi-home mr-2"></i>
                                                                    <span className="font-medium hover:text-white">
                                                                        Screening
                                                                        Calls
                                                                    </span>
                                                                    <Ripple />
                                                                </Link>
                                                            </li>
                                                        </Authorize>
                                                        {/* <Authorize
                                                        permId={
                                                            PERMISSIONS.DASHBOARD
                                                        }
                                                    >
                                                        <li>
                                                            <Link
                                                                className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
            transition-duration-150 transition-colors w-full"
                                                                to={`${url}/c-dashboard`}
                                                            >
                                                                {" "}
                                                                <i className="pi pi-users mr-2"></i>
                                                                <span className="font-medium hover:text-white">
                                                                    Dashboard
                                                                </span>
                                                                <Ripple />
                                                            </Link>
                                                        </li>
                                                    </Authorize> */}
                                                        <Authorize
                                                            multiple={true}
                                                            isGroup={true}
                                                            permIds={[
                                                                PERMISSIONS.QUESTION_BANK,
                                                                PERMISSIONS.APPROVED_QUESTION_BANK,
                                                                PERMISSIONS.USER_QUESTION_BANK,
                                                            ]}
                                                        >
                                                            <li>
                                                                <ul>
                                                                    <li>
                                                                        <StyleClass
                                                                            nodeRef={
                                                                                this
                                                                                    .btnQuestionBank
                                                                            }
                                                                            selector="@next"
                                                                            enterClassName="hidden"
                                                                            enterActiveClassName="slidedown"
                                                                            leaveToClassName="hidden"
                                                                            leaveActiveClassName="slideup"
                                                                        >
                                                                            <a
                                                                                ref={
                                                                                    this
                                                                                        .btnQuestionBank
                                                                                }
                                                                                className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
                transition-duration-150 transition-colors w-full"
                                                                            >
                                                                                <i className="pi pi-chart-line mr-2"></i>
                                                                                <span className="font-medium">
                                                                                    Question
                                                                                    Bank
                                                                                </span>
                                                                                <i className="pi pi-chevron-down ml-auto mr-1"></i>
                                                                                <Ripple />
                                                                            </a>
                                                                        </StyleClass>

                                                                        <ul className="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                                                                            <Authorize
                                                                                permId={
                                                                                    PERMISSIONS.APPROVED_QUESTION_BANK
                                                                                }
                                                                            >
                                                                                <li>
                                                                                    <Link
                                                                                        className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50
                transition-duration-150 transition-colors w-full"
                                                                                        to={`${url}/approved-questions`}
                                                                                    >
                                                                                        {" "}
                                                                                        <i className="pi pi-home mr-2"></i>
                                                                                        <span className="font-medium hover:text-white">
                                                                                            Approved
                                                                                            Question
                                                                                        </span>
                                                                                        <Ripple />
                                                                                    </Link>
                                                                                </li>
                                                                            </Authorize>

                                                                            <Authorize
                                                                                permId={
                                                                                    PERMISSIONS.QUESTION_BANK
                                                                                }
                                                                            >
                                                                                <li>
                                                                                    <Link
                                                                                        className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
                transition-duration-150 transition-colors w-full"
                                                                                        to={`${url}/question-bank`}
                                                                                    >
                                                                                        {" "}
                                                                                        <i className="pi pi-home mr-2"></i>
                                                                                        <span className="font-medium hover:text-white">
                                                                                            Questions
                                                                                        </span>
                                                                                        <Ripple />
                                                                                    </Link>
                                                                                </li>
                                                                            </Authorize>
                                                                            <Authorize
                                                                                permId={
                                                                                    PERMISSIONS.USER_QUESTION_BANK
                                                                                }
                                                                            >
                                                                                <li>
                                                                                    <Link
                                                                                        className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
                transition-duration-150 transition-colors w-full"
                                                                                        to={`${url}/user-question-bank`}
                                                                                    >
                                                                                        {" "}
                                                                                        <i className="pi pi-home mr-2"></i>
                                                                                        <span className="font-medium hover:text-white">
                                                                                            User
                                                                                            Questions
                                                                                        </span>
                                                                                        <Ripple />
                                                                                    </Link>
                                                                                </li>
                                                                            </Authorize>
                                                                        </ul>
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                        </Authorize>
                                                        <Authorize
                                                            isGroup={true}
                                                            permIds={[
                                                                PERMISSIONS.LEARNING_RESOURCES,
                                                                PERMISSIONS.LEARNING_PLANS,
                                                            ]}
                                                        >
                                                            <li>
                                                                <ul>
                                                                    <li>
                                                                        <StyleClass
                                                                            nodeRef={
                                                                                this
                                                                                    .btnQuestionBank1
                                                                            }
                                                                            selector="@next"
                                                                            enterClassName="hidden"
                                                                            enterActiveClassName="slidedown"
                                                                            leaveToClassName="hidden"
                                                                            leaveActiveClassName="slideup"
                                                                        >
                                                                            <a
                                                                                ref={
                                                                                    this
                                                                                        .btnQuestionBank1
                                                                                }
                                                                                className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
                transition-duration-150 transition-colors w-full"
                                                                            >
                                                                                <i className="pi pi-chart-line mr-2"></i>
                                                                                <span className="font-medium">
                                                                                    Materials
                                                                                </span>
                                                                                <i className="pi pi-chevron-down ml-auto mr-1"></i>
                                                                                <Ripple />
                                                                            </a>
                                                                        </StyleClass>

                                                                        <ul className="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                                                                            <Authorize
                                                                                permId={
                                                                                    PERMISSIONS.LEARNING_RESOURCES
                                                                                }
                                                                            >
                                                                                <li>
                                                                                    <Link
                                                                                        className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50
                transition-duration-150 transition-colors w-full"
                                                                                        to={`${url}/learning-resources`}
                                                                                    >
                                                                                        {" "}
                                                                                        <i className="pi pi-home mr-2"></i>
                                                                                        <span className="font-medium hover:text-white">
                                                                                            Learning
                                                                                            Resources
                                                                                        </span>
                                                                                        <Ripple />
                                                                                    </Link>
                                                                                </li>
                                                                            </Authorize>
                                                                            <Authorize
                                                                                permId={
                                                                                    PERMISSIONS.LEARNING_PLANS
                                                                                }
                                                                            >
                                                                                <li>
                                                                                    <Link
                                                                                        className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
                transition-duration-150 transition-colors w-full"
                                                                                        to={`${url}/learning-plans`}
                                                                                    >
                                                                                        {" "}
                                                                                        <i className="pi pi-home mr-2"></i>
                                                                                        <span className="font-medium hover:text-white">
                                                                                            Learning
                                                                                            plans
                                                                                        </span>
                                                                                        <Ripple />
                                                                                    </Link>
                                                                                </li>
                                                                            </Authorize>
                                                                        </ul>
                                                                    </li>
                                                                </ul>
                                                                {/* <Link
                                                                className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
            transition-duration-150 transition-colors w-full"
                                                                to={`${url}/c-dashboard`}
                                                            >
                                                                {" "}
                                                                <i className="pi pi-users mr-2"></i>
                                                                <span className="font-medium hover:text-white">
                                                                    Dashboard
                                                                </span>
                                                                <Ripple />
                                                            </Link> */}
                                                            </li>
                                                        </Authorize>
                                                        <Authorize
                                                            permId={
                                                                PERMISSIONS.CONSULTANTS
                                                            }
                                                        >
                                                            <li>
                                                                <Link
                                                                    className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
            transition-duration-150 transition-colors w-full"
                                                                    to={`${url}/consultants`}
                                                                >
                                                                    {" "}
                                                                    <i className="pi pi-users mr-2"></i>
                                                                    <span className="font-medium hover:text-white">
                                                                        Consultants
                                                                    </span>
                                                                    <Ripple />
                                                                </Link>
                                                            </li>
                                                        </Authorize>
                                                        <Authorize
                                                            permId={
                                                                PERMISSIONS.ALL_CONSULTANTS
                                                            }
                                                        >
                                                            <li>
                                                                <Link
                                                                    className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
            transition-duration-150 transition-colors w-full"
                                                                    to={`${url}/all-consultants`}
                                                                >
                                                                    {" "}
                                                                    <i className="pi pi-users mr-2"></i>
                                                                    <span className="font-medium hover:text-white">
                                                                        All
                                                                        Consultants
                                                                    </span>
                                                                    <Ripple />
                                                                </Link>
                                                            </li>
                                                        </Authorize>

                                                        <Authorize
                                                            permId={
                                                                PERMISSIONS.CONTENT_WRITER
                                                            }
                                                        >
                                                            <li>
                                                                <Link
                                                                    className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
            transition-duration-150 transition-colors w-full"
                                                                    to={`${url}/content-writer`}
                                                                >
                                                                    {" "}
                                                                    <i className="pi pi-table mr-2"></i>
                                                                    <span className="font-medium hover:text-white">
                                                                        Content
                                                                        Writer
                                                                    </span>
                                                                    <Ripple />
                                                                </Link>
                                                            </li>
                                                        </Authorize>
                                                        <Authorize
                                                            permId={
                                                                PERMISSIONS.DOCUMENT_PREPERATOR
                                                            }
                                                        >
                                                            <li>
                                                                <Link
                                                                    className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
            transition-duration-150 transition-colors w-full"
                                                                    to={`${url}/document-prepare`}
                                                                >
                                                                    {" "}
                                                                    <i className="pi pi-credit-card mr-2"></i>
                                                                    <span className="font-medium hover:text-white">
                                                                        Document
                                                                        Prepare
                                                                    </span>
                                                                    <Ripple />
                                                                </Link>
                                                            </li>
                                                        </Authorize>



                                                        <Authorize

                                                            permId={
                                                                PERMISSIONS.DOCUMENT_EDITING
                                                            }
                                                        >
                                                            <li>
                                                                <Link
                                                                    className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
            transition-duration-150 transition-colors w-full"
                                                                    to={`${url}/document-editings`}
                                                                >
                                                                    {" "}
                                                                    <i className="pi pi-sitemap mr-2"></i>
                                                                    <span className="font-medium hover:text-white">
                                                                        Document
                                                                        Editings
                                                                    </span>
                                                                    <Ripple />
                                                                </Link>
                                                            </li>
                                                        </Authorize>
                                                        <Authorize

                                                            permId={
                                                                PERMISSIONS.DOCUMENT_VERIFICATION
                                                            }
                                                        >
                                                            <li>
                                                                <Link
                                                                    className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
            transition-duration-150 transition-colors w-full"
                                                                    to={`${url}/document-verification`}
                                                                >
                                                                    {" "}
                                                                    <i className="pi pi-sitemap mr-2"></i>
                                                                    <span className="font-medium hover:text-white">
                                                                        Document
                                                                        Verifications
                                                                    </span>
                                                                    <Ripple />
                                                                </Link>
                                                            </li>
                                                        </Authorize>
                                                        <Authorize

                                                            permId={
                                                                PERMISSIONS.TECH_PREPERATION
                                                            }
                                                        >
                                                            <li>
                                                                <Link
                                                                    className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
            transition-duration-150 transition-colors w-full"
                                                                    to={`${url}/preperations`}
                                                                >
                                                                    {" "}
                                                                    <i className="pi pi-sitemap mr-2"></i>
                                                                    <span className="font-medium hover:text-white">
                                                                        Tech
                                                                        Preparations
                                                                    </span>
                                                                    <Ripple />
                                                                </Link>
                                                            </li>
                                                        </Authorize>
                                                        <Authorize

                                                            permId={
                                                                PERMISSIONS.PREMARKET
                                                            }
                                                        >
                                                            <li>
                                                                <Link
                                                                    className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
            transition-duration-150 transition-colors w-full"
                                                                    to={`${url}/premarket-review`}
                                                                >
                                                                    {" "}
                                                                    <i className="pi pi-sitemap mr-2"></i>
                                                                    <span className="font-medium hover:text-white">
                                                                        Premarket
                                                                        review
                                                                    </span>
                                                                    <Ripple />
                                                                </Link>
                                                            </li>
                                                        </Authorize>
                                                        <Authorize
                                                            permId={
                                                                PERMISSIONS.READY_MARKET
                                                            }
                                                        >
                                                            <li>
                                                                <Link
                                                                    className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
            transition-duration-150 transition-colors w-full"
                                                                    to={`${url}/ready-to-market`}
                                                                >
                                                                    {" "}
                                                                    <i className="pi pi-sitemap mr-2"></i>
                                                                    <span className="font-medium hover:text-white">
                                                                        Sales head
                                                                    </span>
                                                                    <Ripple />
                                                                </Link>
                                                            </li>
                                                        </Authorize>
                                                        <Authorize
                                                            permId={
                                                                PERMISSIONS.MARKETING
                                                            }
                                                        >
                                                            <li>
                                                                <Link
                                                                    className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
            transition-duration-150 transition-colors w-full"
                                                                    to={`${url}/marketing`}
                                                                >
                                                                    {" "}
                                                                    <i className="pi pi-sitemap mr-2"></i>
                                                                    <span className="font-medium hover:text-white">
                                                                        Marketing
                                                                    </span>
                                                                    <Ripple />
                                                                </Link>
                                                            </li>
                                                        </Authorize>
                                                        {/*
                                                    <Authorize
                                                        permId={
                                                            PERMISSIONS.RECRUITER
                                                        }
                                                    >
                                                        <li>
                                                            <Link
                                                                className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
            transition-duration-150 transition-colors w-full"
                                                                to={`${url}/recruiters`}
                                                            >
                                                                {" "}
                                                                <i className="pi pi-sitemap mr-2"></i>
                                                                <span className="font-medium hover:text-white">
                                                                    Recruiters
                                                                </span>
                                                                <Ripple />
                                                            </Link>
                                                        </li>
                                                    </Authorize>
                                                    <Authorize
                                                        permId={
                                                            PERMISSIONS.RECRUITER
                                                        }
                                                    >
                                                        <li>
                                                            <Link
                                                                className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
            transition-duration-150 transition-colors w-full"
                                                                to={`${url}/interviews/dropdown`}
                                                            >
                                                                {" "}
                                                                <i className="pi pi-sitemap mr-2"></i>
                                                                <span className="font-medium hover:text-white">
                                                                    Interviews
                                                                </span>
                                                                <Ripple />
                                                            </Link>
                                                        </li>
                                                    </Authorize> 

                                                    {/* <Authorize multiple={false} isGroup={true}
                                                                to={`${url}/marketing`}
                                                            >
                                                                {" "}
                                                                <i className="pi pi-sitemap mr-2"></i>
                                                                <span className="font-medium hover:text-white">
                                                                    Marketing
                                                                </span>
                                                                <Ripple />
                                                            </Link>
                                                        </li>
                                                    </Authorize>
                                                    {/* <Authorize multiple={false} isGroup={true}
                                                                to={`${url}/marketing`}
                                                            >
                                                                {" "}
                                                                <i className="pi pi-sitemap mr-2"></i>
                                                                <span className="font-medium hover:text-white">
                                                                    Marketing
                                                                </span>
                                                                <Ripple />
                                                            </Link>
                                                        </li>
                                                    </Authorize>
                                                    <Authorize
                                                        multiple={false}
                                                        isGroup={true}
                                                        permIds={[
                                                            "c50ac626-7a6c-4333-971a-1656389d20dddc",
                                                        ]}
                                                    >
                                                        <li>
                                                            <Link
                                                                className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
            transition-duration-150 transition-colors w-full"
                                                                to={`${url}/copy-helper`}
                                                            >
                                                                {" "}
                                                                <i className="pi pi-sitemap mr-2"></i>
                                                                <span className="font-medium hover:text-white">
                                                                    Copy-helper
                                                                </span>
                                                                <Ripple />
                                                            </Link>
                                                        </li>
                                                    </Authorize>
                                                   
                                                    {/* <Authorize multiple={false} isGroup={true}
                                                    permIds={['cf92fbbf-2f62-4247-bda7-2631e4603bcc']}>
                                                    <li>
                                                        <Link
                                                            className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
            transition-duration-150 transition-colors w-full"
                                                            to={`${url}/interviews`}
                                                        >
                                                            {" "}
                                                            <i className="pi pi-sitemap mr-2"></i>
                                                            <span className="font-medium hover:text-white">
                                                                Interviews
                                                            </span>
                                                            <Ripple />
                                                        </Link>
                                                    </li>
                                                </Authorize>
                                                <Authorize multiple={false} isGroup={true}
                                                    permIds={['cf92fbbf-2f62-4247-bda7-2631e4603bcc']}>
                                                    <li>
                                                        <Link
                                                            className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
            transition-duration-150 transition-colors w-full"
                                                            to={`${url}/paper-work`}
                                                        >
                                                            {" "}
                                                            <i className="pi pi-sitemap mr-2"></i>
                                                            <span className="font-medium hover:text-white">
                                                                Paper work
                                                            </span>
                                                            <Ripple />
                                                        </Link>
                                                    </li>
                                                </Authorize> */}
                                                    </>
                                                )}
                                            {process.env.REACT_APP_TARGET ==
                                                APP_TARGETS.CHITFUNDS && (
                                                    <>
                                                        <li>
                                                            <Link
                                                                className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
            transition-duration-150 transition-colors w-full"
                                                                to={`${url}/policy`}
                                                            >
                                                                {" "}
                                                                <i className="pi pi-users mr-2"></i>
                                                                <span className="font-medium hover:text-white">
                                                                    Policy
                                                                </span>
                                                                <Ripple />
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link
                                                                className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
            transition-duration-150 transition-colors w-full"
                                                                to={`${url}/chits-customers`}
                                                            >
                                                                {" "}
                                                                <i className="pi pi-users mr-2"></i>
                                                                <span className="font-medium hover:text-white">
                                                                    Customers
                                                                </span>
                                                                <Ripple />
                                                            </Link>
                                                        </li>
                                                    </>
                                                )}
                                        </>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div
                        className="min-h-screen flex flex-column relative flex-auto  "
                        style={{ backgroundColor: "#FFFFFF" }}
                    >
                        <div
                            className="flex justify-content-between align-items-center px-5 surface-section shadow-2 relative lg:static border-bottom-1 surface-border lg:hidden"
                            style={{ height: "60px" }}
                        >
                            <div className="flex">
                                <StyleClass
                                    nodeRef={this.btnRef4}
                                    selector="#app-sidebar-1"
                                    enterClassName="hidden"
                                    enterActiveClassName="fadeinleft"
                                    leaveToClassName="hidden"
                                    leaveActiveClassName="fadeoutleft"
                                    hideOnOutsideClick
                                >
                                    <a
                                        ref={this.btnRef4}
                                        className="p-ripple cursor-pointer block lg:hidden text-700 mr-3"
                                    >
                                        <i className="pi pi-bars text-4xl"></i>
                                        <Ripple />
                                    </a>
                                </StyleClass>

                                <span className="p-input-icon-left font-bold">
                                    {title}
                                </span>
                            </div>
                        </div>

                        {/*S2P  DOWN ROUTE  */}
                        <div className="">
                            <div className="surface-border border-round surface-section flex-auto">
                                <Switch>
                                    <Route exact path={path}>
                                        <h3 className="m-4">
                                            Welcome to admin. Please select a
                                            menu.
                                        </h3>
                                    </Route>
                                    <Route path={`${path}/resellers`}>
                                        <Organization
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Organization",
                                                });
                                            }}
                                        />
                                    </Route>

                                    <Route path={`${path}/admin-users`}>
                                        <AdminUser
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Admin Users",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route
                                        path={`${path}/admin-user-privileges`}
                                    >
                                        <AdminUserPrivileges
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Admin User Privileges",
                                                });
                                            }}
                                        />
                                    </Route>

                                    <Route path={`${path}/end-users`}>
                                        <Users
                                            setTitle={() => {
                                                this.setState({
                                                    title: "End Users",
                                                });
                                            }}
                                        />
                                    </Route>

                                    <Route path={`${path}/admin-roles`}>
                                        <Roles
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Admin Roles",
                                                });
                                            }}
                                        />
                                    </Route>

                                    <Route path={`${path}/portal-manage`}>
                                        <PortalManage
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Portal Managemenet",
                                                });
                                            }}
                                        />
                                    </Route>

                                    <Route path={`${path}/registration-fee`}>
                                        <RegistrationFee
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Registration Fee",
                                                });
                                            }}
                                        />
                                    </Route>

                                    <Route path={`${path}/packages-fee`}>
                                        <PackagesFee
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Packages Fee",
                                                });
                                            }}
                                        />
                                    </Route>

                                    <Route path={`${path}/profiles`}>
                                        <Profiles
                                            setTitle={() => {
                                                this.setState({
                                                    title: "profiles",
                                                });
                                            }}
                                        />
                                    </Route>

                                    <Route path={`${path}/approved-profiles`}>
                                        <ApprovedProfiles
                                            setTitle={() => {
                                                this.setState({
                                                    title: "approved profiles",
                                                });
                                            }}
                                        />
                                    </Route>

                                    <Route path={`${path}/subscription`}>
                                        <Subscription
                                            setTitle={() => {
                                                this.setState({
                                                    title: "subscriptions",
                                                });
                                            }}
                                        />
                                    </Route>

                                    <Route path={`${path}/customers`}>
                                        <Customers
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Customers",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route path={`${path}/recruiters`}>
                                        <RecruiterList
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Customers",
                                                });
                                            }}
                                        />
                                    </Route>

                                    <Route path={`${path}/submissionwibget`}>
                                        <SubmissionChart
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Customers",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route path={`${path}/interviewgraph`}>
                                        <InterviewChart
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Customers",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route path={`${path}/screeningcallwibget`}>
                                        <ScreeningCallsChart
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Customers",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route path={`${path}/inter-sub-wibget`}>
                                        <InterSubChart
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Customers",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route path={`${path}/closure-wibget`}>
                                        <ClosureChart
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Customers",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route
                                        path={`${path}/closure-by-recruiter-wibget`}
                                    >
                                        <ClosureByRecruiterChart
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Customers",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route path={`${path}/createdby-profiles`}>
                                        <CreatedByProfiles
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Customers",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route path={`${path}/Profiles-count`}>
                                        <CreatedByProfilesCount
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Customers",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route path={`${path}/total-recruiters`}>
                                        <TotalProfilesChart
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Customers",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route path={`${path}/profile-assigned`}>
                                        <ProfilesToRecruiter
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Customers",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route path={`${path}/fee-management`}>
                                        <FeeDetailsList
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Fee Management",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route path={`${path}/tables`}>
                                        <TablesList
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Tables",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route path={`${path}/branches`}>
                                        <BranchDetails
                                            setTitle={() => {
                                                this.setState({
                                                    title: "branches",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route path={`${path}/transactions`}>
                                        <Transactions
                                            setTitle={() => {
                                                this.setState({
                                                    title: "transactions",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route path={`${path}/dashboard`}>
                                        <Dashboard
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Dashboard",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route path={`${path}/policy`}>
                                        <Policy
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Policy",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route path={`${path}/chits-customers`}>
                                        <ChitsCustomers
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Chits Customers",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route path={`${path}/consultants`}>
                                        <Consultants
                                            tab={CONSULTANT_MENU_TAB.CONSULTANT}
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Consultants",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route path={`${path}/all-consultants`}>
                                        <AllConsultants
                                            tab={CONSULTANT_MENU_TAB.CONSULTANT}
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Consultants",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route
                                        path={`${path}/screeningcall/admin/view/:id`}
                                    >
                                        <AdminViewScreeningCall
                                            tab={CONSULTANT_MENU_TAB.CONSULTANT}
                                            setTitle={() => {
                                                this.setState({
                                                    title: "ScreeningCalls",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route path={`/screeningcall/add`}>
                                        <AddScreeningCall
                                            tab={CONSULTANT_MENU_TAB.CONSULTANT}
                                            setTitle={() => {
                                                this.setState({
                                                    title: "ScreeningCalls",
                                                });
                                            }}
                                        />
                                    </Route>

                                    <Route path={`${path}/screeningcalls`}>
                                        <AdminViewCall
                                            tab={CONSULTANT_MENU_TAB.CONSULTANT}
                                            setTitle={() => {
                                                this.setState({
                                                    title: "ScreeningCalls",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route
                                        path={`${path}/consultant/screeningcalls/:id`}
                                    >
                                        <AdminViewCall
                                            tab={CONSULTANT_MENU_TAB.CONSULTANT}
                                            setTitle={() => {
                                                this.setState({
                                                    title: "ScreeningCalls",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route path={`${path}/content-writer`}>
                                        <ContentWriter
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Content Writer",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route
                                        path={`${path}/contentwriter/:operation/:id`}
                                    >
                                        <EditConsultant
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Add Consultant",
                                                });
                                            }}
                                        />
                                    </Route>

                                    <Route path={`${path}/document-prepare`}>
                                        <DocumentPreparator
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Document Preperator",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route path={`${path}/resource`}>
                                        {/* <Route path={`${path}/resource`}>
                                        <Resources
                                            setTitle={() => {
                                                this.setState({
                                                    title: "resource",
                                                });
                                            }}
                                        />
                                    </Route>*/}
                                    </Route>

                                    <Route path={`${path}/interview/:id`}>
                                        <Interview
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Document Preperator",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route path={`${path}/submission/:id`}>
                                        <Submissions
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Document Preperator",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route
                                        path={`${path}/allconsultant/interview/:id`}
                                    >
                                        <InterviewsView
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Document Preperator",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route
                                        path={`${path}/allconsultant/submission/:id`}
                                    >
                                        <SubmissionsView
                                            setTitle={() => {
                                                this.setState({
                                                    title: "submission",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route path={`${path}/search-bank`}>
                                        <SearchBank
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Search Bank",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route path={`${path}/question-bank`}>
                                        <QuestionBank
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Question Bank",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route path={`${path}/user-question-bank`}>
                                        <UserQuestion
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Question Bank",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route path={`${path}/document-editings`}>
                                        <DocumentEditing
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Docunent Editings",
                                                });
                                            }}
                                        />
                                    </Route>

                                    <Route
                                        path={`${path}/document-editing/:operation/:id`}
                                    >
                                        <EditDocument
                                            setTitle={() => {
                                                this.setState({
                                                    title: "edit Document",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route path={`${path}/cosultant/view/:id`}>
                                        <ViewComponent
                                            setTitle={() => {
                                                this.setState({
                                                    title: "view Document",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route path={`${path}/interviews/tracker`}>
                                        <InterviewStatus
                                            setTitle={() => {
                                                this.setState({
                                                    title: "view interview",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route path={`${path}/interviews/view/:id`}>
                                        <ViewInterview
                                            setTitle={() => {
                                                this.setState({
                                                    title: "view interview",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route path={`${path}/interviews/dropdown`}>
                                        <InterviewsDropdown
                                            setTitle={() => {
                                                this.setState({
                                                    title: "view interview",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route path={`${path}/interviews/add/:id`}>
                                        <AddInterview
                                            setTitle={() => {
                                                this.setState({
                                                    title: "view interview",
                                                });
                                            }}
                                        />
                                    </Route>

                                    <Route
                                        path={`${path}/submissions/view/:id`}
                                    >
                                        <ViewSubmission
                                            setTitle={() => {
                                                this.setState({
                                                    title: "view interview",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route path={`${path}/submissions/tracker`}>
                                        <SubmissionStatus
                                            setTitle={() => {
                                                this.setState({
                                                    title: "view interview",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route path={`${path}/submissions/add/:id`}>
                                        <AddSubmission
                                            setTitle={() => {
                                                this.setState({
                                                    title: "view submission",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route
                                        path={`${path}/cosultant/tracker/:id`}
                                    >
                                        <TackerComponent
                                            setTitle={() => {
                                                this.setState({
                                                    title: "track Document",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route
                                        path={`${path}/cosultant/monitor/:id`}
                                    >
                                        <MonitorComponent
                                            setTitle={() => {
                                                this.setState({
                                                    title: "monitor Document",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route path={`${path}/cosultant/notes/:id`}>
                                        <NotesComponent
                                            setTitle={() => {
                                                this.setState({
                                                    title: "monitor Document",
                                                });
                                            }}
                                        />
                                    </Route>

                                    <Route
                                        path={`${path}/document-verification`}
                                    >
                                        <DocumentVerifier
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Docunent Verifier",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route path={`${path}/c-dashboard`}>
                                        <WidgetDashboard
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Docunent Verifier",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route path={`${path}/preperations`}>
                                        <TechnicalPreperator
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Technical Preperator",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route path={`${path}/premarket-review`}>
                                        <PreMarketReview
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Pre Market Review",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route path={`${path}/ready-to-market`}>
                                        <SalesTeamHead
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Sales Team Head",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route path={`${path}/marketing`}>
                                        <SalesTeam
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Marketing",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route
                                        path={`${path}/cosultant/:operation/:id`}
                                    >
                                        <AddConsultant
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Add Consultant",
                                                });
                                            }}
                                        />
                                    </Route>

                                    <Route
                                        path={`${path}/cosultant/:operation`}
                                    >
                                        <AddConsultant
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Add Consultant",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route
                                        path={`${path}/marketinghistory/:id`}
                                    >
                                        <HistoryRecruiter
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Add Consultant",
                                                });
                                            }}
                                        />
                                    </Route>

                                    <Route
                                        path={`${path}/question-banks/:operation/:id`}
                                    >
                                        <AddQuestion
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Add Consultant",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route
                                        path={`${path}/question-banks/:operation`}
                                    >
                                        <AddQuestion
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Add Consultant",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route
                                        path={`${path}/user-questionbank/:operation/:id`}
                                    >
                                        <AddUserQuestion
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Add Consultant",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route
                                        path={`${path}/user-questionbank/:operation`}
                                    >
                                        <AddUserQuestion
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Add Consultant",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route path={`${path}/faq/:operation/:id`}>
                                        <Editfaq
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Add FAQ",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route path={`${path}/contact/:operation/:id`}>
                                        <AddContact
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Add Contact",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route path={`${path}/approved-questions`}>
                                        <ApprovedQuestion
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Add FAQ",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route path={`${path}/faq/add`}>
                                        <AddFAQ
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Add FAQ",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route path={`${path}/faq`}>
                                        <Faq
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Add FAQ",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route path={`${path}/contact/add`}>
                                        <AddContact
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Add Contact",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route path={`${path}/contact`}>
                                        <Contacts
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Contacts",
                                                });
                                            }}
                                        />
                                    </Route>

                                    <Route
                                        path={`${path}/copy-helper/:operation/:id`}
                                    >
                                        <AddCopyHelper
                                            setTitle={() => {
                                                this.setState({
                                                    title: "edit Consultant",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route path={`${path}/copy-helper/add/:id`}>
                                        <AddCopyHelper
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Add Consultant",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route path={`${path}/copy-helper/:id`}>
                                        <Copyhelper
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Learning Resources",
                                                });
                                            }}
                                        />
                                    </Route>

                                    <Route path={`${path}/learning-resources`}>
                                        <LearningResources
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Learning Resources",
                                                });
                                            }}
                                        />
                                    </Route>

                                    <Route
                                        path={`${path}/learning-r/:operation/:id`}
                                    >
                                        <AddResource
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Edit Consultant",
                                                });
                                            }}
                                        />
                                    </Route>

                                    <Route
                                        path={`${path}/learning-r/:operation`}
                                    >
                                        <AddResource
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Add Consultant",
                                                });
                                            }}
                                        />
                                    </Route>

                                    <Route path={`${path}/learning-plans`}>
                                        <LearningPlans
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Learning Resources",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route
                                        path={`${path}/learning-p/:operation/:id`}
                                    >
                                        <AddPlan
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Edit Consultant",
                                                });
                                            }}
                                        />
                                    </Route>

                                    <Route
                                        path={`${path}/learning-p/:operation`}
                                    >
                                        <AddPlan
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Add Consultant",
                                                });
                                            }}
                                        />
                                    </Route>

                                    <Route
                                        path={`${path}/learning-plan-details/:id`}
                                    >
                                        <AddPlanDetails
                                            setTitle={() => {
                                                this.setState({
                                                    title: "plan details",
                                                });
                                            }}
                                        />
                                    </Route>

                                    {/* <Route path={`${path}/registration-fee`}>
                                        <RegistrationFee
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Registration Fee",
                                                });
                                            }}
                                        />
                                    </Route> */}
                                    {/* <Route path={`${path}/users`}>
                                        <p>users</p>
                                    </Route> */}
                                </Switch>
                            </div>
                        </div>
                    </div>
                </div>
            </NavFooter>
        );
    }
}

const mapStateToProps = (state) => ({
    isSuper: state?.orgData?.isSuper,
    isLoading: state?.orgData?.isLoading,
    permissionIds: state?.user?.user?.permissionIds,
    isSuperAdmin: state?.user?.user?.isSuperAdmin,
});

export default connect(mapStateToProps, {})(Authentication(Admin));
