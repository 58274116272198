import React from "react";
import { withRouter } from "react-router-dom";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { connect } from "react-redux";
import { InputText } from "primereact/inputtext";
import CkEditorComponent from "../../common/ckEditor";
import { ResourceTypes, showPreviewDocMain } from "../../../Utils";
import "./styles.scss";
import FileUploadLambda2 from "../../fileUpload/fileUploadLambda2";
import { CLOUDFRONT_ENDPOINT } from "./../../../Service/config";

class AddResource extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            file_upload_id: Math.random().toString(36).substring(2, 10),
            fileupload: "Choose File",
            title: (props.data && props.data.title) || "",
            resource: (props.data && props.data.resource) || null,
            isAssigned: props.data ? true : false,
            resourceType: (props.data && props.data.resourceType) || null,

            resourceTypes: [
                { label: "Youtube", value: "youtube" },
                { label: "External Link", value: "externalLink" },
                { label: "Textbox", value: "texbox" },
                { label: "Upload", value: "upload" },
            ],
        };
    }

    componentDidUpdate(prevProps) {
        console.log(this.props.resources);
        if (
            prevProps.resource !== this.props.resource ||
            prevProps.resourceType !== this.props.resourceType ||
            prevProps.title !== this.props.title
        ) {
            this.setState({
                resourceType: this.props.resourceType || "",
                title: this.props.title || "",
                resource: this.props.resource || "",
                isAssigned: !!this.props.resource,
            });
        }
    }

    checkIsAssigned = (subjectId) => {
        let isAssigned = false;
        this.props.resources.forEach((g) => {
            if (g.data && g.data.subjectId == subjectId) {
                isAssigned = true;
            }
        });
        return isAssigned;
    };

    assignCurriculum = () => {
        const { resource, resourceType, title } = this.state;
        console.log(resource);

        if (
            (resource != "" || resource != null) &&
            resourceType != "" &&
            title != ""
        ) {
            if (resource)


                this.setState(
                    {
                        isAssigned: true,
                    },
                    () => {
                        this.props.onAssignClick({
                            id: this.props.id,
                            data: {
                                resource,
                                resourceType,
                                title,
                            },
                            assigned: true,
                        });
                    }
                );
        } else {
            this.setState({
                errorMsg: "select resource, resourceType, title",
            });

            setTimeout(() => {
                this.setState({
                    errorMsg: "",
                });
            }, 3000);
        }
    };

    onEditClick = () => {
        if (this.props.resources.every((s) => s.assigned === true)) {
            this.setState(
                {
                    isAssigned: false,
                },
                () => {
                    this.props.onAssignClick({
                        id: this.props.id,
                        assigned: false,
                    });
                }
            );
        } else {
            this.toast.show({
                severity: "warn",
                summary: "Warning",
                detail: "Please add or remove which is in edit state.",
                life: 3000,
            });
        }
    };
    _onresourceUploadSuccess = (fileName, data, fileupload) => {
        this.addAttachment("resourceKey", fileName, data, fileupload);
        console.log(fileupload);
        return;
    };
    _onFilsUploadError = (errMsg) => {
        this.toast.show({
            severity: "error",
            summary: "Some error",
            detail: errMsg,
            life: 3000,
        });
    };

    addAttachment = (fieldName, fileName, data, fileupload) => {
        console.log(fileName, data);
        let upload = {
            ...fileupload,
            [fieldName]:
                data.contentStorageKey || data.Key,
        };

        this.setState((prevState) => {
            return {
                ...this.state.resource,
                resource: upload,
            };
        });
    };

    render() {
        const { resourceType, title, resource } = this.state;
        console.log(this.state, 'sssssssswe');

        return (
            <div className="p-grid ">
                <div>
                    <div className="p-grid row">
                        <div className="p-col-12 p-md-6 p-md-2-5 p-mb-1 m-2 p-col">
                            <Dropdown
                                value={this.state.resourceType}
                                disabled={this.state.isAssigned}
                                options={this.state.resourceTypes}
                                onChange={(e) =>
                                    this.setState({
                                        resourceType: e.value,
                                    })
                                }
                                placeholder="Select Resource Type"
                                className="dropdown-width"
                            />
                        </div>

                        <div className="p-col-12 p-md-6 p-md-2-5 p-mb-1 m-2">
                            <InputText
                                placeholder="Resource Title"
                                disabled={this.state.isAssigned}
                                value={this.state.title}
                                onChange={(e) => {
                                    this.setState({
                                        title: e.target.value,
                                    });
                                }}
                            />
                        </div>
                        <div className="p-col-12 p-md-6 p-md-2-5 p-mb-1 m-2">
                            {(this.state.resourceType ==
                                ResourceTypes.Youtube ||
                                this.state.resourceType ==
                                ResourceTypes.ExternalLink) && (
                                    <>
                                        <InputText
                                            disabled={this.state.isAssigned}
                                            placeholder="Resource URL"
                                            value={this.state.resource}
                                            onChange={(e) => {
                                                this.setState({
                                                    resource: e.target.value,
                                                });
                                            }}
                                        />
                                    </>
                                )}
                            {this.state.resourceType ==
                                ResourceTypes.Textbox && (
                                    <>
                                        <CkEditorComponent
                                            disabled={this.state.isAssigned}
                                            value={this.state.resource || ""}
                                            onChange={(value) => {
                                                this.setState({
                                                    resource: value,
                                                });
                                            }}
                                        />
                                    </>
                                )}

                            {this.state.resourceType ==
                                ResourceTypes.Upload && (
                                    <>
                                        <span
                                            onClick={() => {
                                                showPreviewDocMain(resource);
                                            }}
                                        >
                                            {resource?.name}
                                        </span>

                                        <FileUploadLambda2
                                            disabled={this.state.isAssigned}
                                            id={"r" + this.state.file_upload_id}
                                            multiple={false}
                                            onProgress={this._onProgress}
                                            onSucess={this._onresourceUploadSuccess}
                                            Reset={this.state.Reset}
                                            maxFileSize={3000000000}
                                            onError={this._onFilsUploadError}
                                            accept={"imagevideo"}
                                            title={"resourceUpload"}
                                            afterFilesUploaded={() => {
                                                this.setState((prevState) => {
                                                    return {
                                                        fileupload: "Reupload File",
                                                    };
                                                });
                                            }}
                                        >
                                            <span className="outlined-s1 ma-right ma-pointer">
                                                {this.state.fileupload}
                                            </span>
                                        </FileUploadLambda2>
                                    </>
                                )}
                        </div>
                        <div className="p-col-12 p-md-6 p-md-2-5 p-mb-1 m-2">
                            {this.state.isAssigned ? (
                                <Button
                                    label={`Edit`}
                                    onClick={this.onEditClick}
                                />
                            ) : (
                                <Button
                                    label={`Save`}
                                    onClick={this.assignCurriculum}
                                />
                            )}

                            <Button
                                icon="pi pi-times"
                                className="p-button-text  button.close p-button-secondary "
                                onClick={() => {
                                    this.props.removeTempSubject(this.props.id);
                                }}
                            />
                        </div>
                    </div>

                    <Toast
                        ref={(el) => (this.toast = el)}
                        position="bottom-right"
                    />
                    <p className="sp-required">{this.state.errorMsg}</p>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

export default connect(mapStateToProps, {})(withRouter(AddResource));
