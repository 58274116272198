import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import Service from "../../../Service";
import ServerlessService from "../../../Service/ServerlessService";
import LoadingComponent from "../../../LoadingComponent";
import moment from "moment";
import AddCustomer from "./addCustomer";
import { BasicLazyParams } from "../../../Utils/constants";
import { Tooltip } from "primereact/tooltip";
import Authorize, { PERMISSIONS } from "../../../session/authorize";
import { logOut } from "../../../Utils";

class CustomersList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            editCustomer: null,
            isShowConfirmationDialog: false,
            globalSearch: "",
            lazyParams: {
                ...BasicLazyParams,
                sortField: "createdAt",
                sortOrder: 1,
            },
        };

        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }

    deleteCustomer = (_id) => {
        this.setState({
            isLoading: true,
            isShowConfirmationDialog: false,
        });
        var _id = this.state.selectedId;

        const url = `/co-space/customers/${_id}`;
        this.serverlessService
            .delete(url, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState(
                        {
                            isLoading: false,
                        },
                        () => {
                            this.getCustomers();
                        }
                    );
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    getCustomers = () => {
        let { lazyParams, globalSearch } = this.state;
        this.setState({
            isLoading: true,
            customer: [],
        });

        const url = `/co-space/customers?limit=${lazyParams.rows}&page=${lazyParams.page + 1
            }&search=${globalSearch}${lazyParams.sortField
                ? `&sort=${lazyParams.sortOrder === 1 ? "" : "-"}${lazyParams.sortField
                }`
                : ""
            }`;
        this.serverlessService
            .get(url, true)
            .then((res) => {
                if (res && res?.status && res?.res?.status) {
                    this.setState({
                        isLoading: false,
                        customer: res?.res?.data,
                        totalRecords: res.res.totalRecords,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {
                globalSearch: e.target.value,
                lazyParams: BasicLazyParams,
            };
        }, this.getCustomers);
    };

    onPage = (event) => {
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event,
                },
            };
        }, this.getCustomers);
    };

    onSort = (event) => {
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event,
                },
            };
        }, this.getCustomers);
    };

    onFilter = (event) => {
        event["first"] = 0;
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    filters: event.filters,
                },
            };
        }, this.getCustomers);
    };

    showTransactionsHandler = (_id) => {
        this.setState({
            isLoading: true,
            transactionsData: [],
        });

        const url = `/co-space/transactions/${_id}`;
        this.serverlessService
            .get(url, true)
            .then((res) => {
                console.log(res, "response");
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                        transactionsData: res.res.data,
                        transactionsTable: true,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    onHide = () => {
        this.setState({
            visible: false,
            editCustomer: null,
        });
    };

    componentDidMount() {
        this.getCustomers();
    }

    onCustomerAddOrUpdate = () => {
        this.setState(
            {
                visible: false,
                editCustomer: null,
            },
            this.getCustomers
        );
    };

    actionsBody = (rowData) => {
        return (
            <div className="p-grid row">
                <div className="col-2">
                    <Authorize permId={PERMISSIONS.CUSTOMERS_VIEW_TRANS}>
                        <span
                            onClick={() =>
                                this.showTransactionsHandler(rowData._id)
                            }
                            data-pr-tooltip="View Transactions"
                            data-pr-position="top"
                            className={`p-2 customerView${rowData._id}`}
                        >
                            <i className="pi pi-eye" />
                        </span>
                        <Tooltip target={`.customerView${rowData._id}`} />
                    </Authorize>
                </div>

                <div className="col-2 ml-2 mr-2 ">
                    <Authorize permId={PERMISSIONS.CUSTOMERS_EDIT}>
                        <span
                            onClick={() =>
                                this.setState({
                                    editCustomer: rowData,
                                    visible: true,
                                    isEdit: true,
                                })
                            }
                            data-pr-tooltip="Edit Customer"
                            data-pr-position="top"
                            className={`p-2 customerEdit${rowData._id}`}
                        >
                            <i className="pi pi-pencil" />
                        </span>
                        <Tooltip target={`.customerEdit${rowData._id}`} />
                    </Authorize>
                </div>

                <div className="col-2">
                    <Authorize permId={PERMISSIONS.CUSTOMERS_DELETE}>
                        <span
                            onClick={() =>
                                this.showDeleteCustomerDialog(rowData._id)
                            }
                            data-pr-tooltip="Delete Customer"
                            data-pr-position="top"
                            className={`p-2 customerDelete${rowData._id}`}
                        >
                            <i className="pi pi-trash" />
                        </span>
                        <Tooltip target={`.customerDelete${rowData._id}`} />
                    </Authorize>
                </div>
            </div>
        );
    };

    showDeleteCustomerDialog = (id) => {
        this.setState({
            isShowConfirmationDialog: true,
            selectedId: id,
        });
    };

    footerContent = () => (
        <div>
            <Button
                label="Cancel"
                className=" p-button-text hover:bg-primary "
                onClick={() => {
                    this.setState({
                        isShowConfirmationDialog: false,
                    });
                }}
            />
            <Button
                label="Ok"
                onClick={this.deleteCustomer}
                autoFocus
                className=" p-button-primary "
            />
        </div>
    );

    onCloseConfirmation = () => {
        this.setState({
            isShowConfirmationDialog: false,
        });
    };

    render() {
        console.log(this.state.editCustomer, "customersdata");
        return (
            <>
                <div className="p-4">
                    <span className="text-1000 font-medium text-2xl  mb-3">
                        Customers
                    </span>
                    <br />
                    <Authorize permId={PERMISSIONS.CUSTOMERS_ADD}>
                        <Button
                            className=" p-button-primary "
                            label="Add Customer"
                            severity="warning"
                            onClick={() => this.setState({ visible: true })}
                        />
                    </Authorize>
                    <br></br>
                    <br></br>

                    {this.state.visible && (
                        <AddCustomer
                            onHide={this.onHide}
                            editCustomer={this.state.editCustomer}
                            onCustomerAddOrUpdate={this.onCustomerAddOrUpdate}
                            getCustomers={this.getCustomers}
                        ></AddCustomer>
                    )}
                    <Authorize permId={PERMISSIONS.CUSTOMERS_DATA}>
                        <div>
                            <DataTable
                                value={this.state.customer}
                                lazy
                                scrollHeight="400px"
                                onSort={this.onSort}
                                onPage={this.onPage}
                                onFilter={this.onFilter}
                                first={this.state.lazyParams.first}
                                last={this.state.totalRecords}
                                rows={this.state.lazyParams.rows}
                                totalRecords={this.state.totalRecords}
                                paginator
                                paginatorLeft
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                rowsPerPageOptions={[
                                    5, 10, 25, 50, 100, 200, 300, 500,
                                ]}
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                scrollable
                                responsiveLayout="scroll"
                                rowHover
                                dataKey="_id"
                                selection={this.state.selectedCustomer}
                                onSelectionChange={(e) =>
                                    this.setState({ selectedCustomer: e.value })
                                }
                                selectionMode="single"
                            >
                                <Column field="name" header=" Name"></Column>

                                <Column
                                    body={(rowData) => {
                                        return (
                                            <>
                                                {rowData?.assignedTable?.name ||
                                                    "--NA--"}
                                            </>
                                        );
                                    }}
                                    header="Assigned Table"
                                ></Column>
                                <Column
                                    body={(rowData) => {
                                        return (
                                            <>
                                                {rowData?.transaction
                                                    ?.validTill ? (
                                                    moment(
                                                        rowData?.transaction
                                                            ?.validTill
                                                    ).format("l")
                                                ) : (
                                                    <>--NA--</>
                                                )}
                                            </>
                                        );
                                    }}
                                    header="Valid Till"
                                ></Column>

                                <Column
                                    header="Actions"
                                    body={(rowData) =>
                                        this.actionsBody(rowData)
                                    }
                                ></Column>
                            </DataTable>
                            <br />
                            <br />

                            {this.state.transactionsTable && (
                                <>
                                    <span className="text-1000 font-medium text-2xl  mb-3">
                                        Transactions
                                    </span>
                                    <br />
                                    <DataTable
                                        value={this.state.transactionsData}
                                        lazy
                                        onSort={this.onSort}
                                        onPage={this.onPage}
                                        onFilter={this.onFilter}
                                        first={this.state.lazyParams.first}
                                        last={this.state.totalRecords}
                                        rows={this.state.lazyParams.rows}
                                        totalRecords={this.state.totalRecords}
                                        paginator
                                        paginatorLeft
                                        rowsPerPageOptions={[5, 10, 25]}
                                        id="_id"
                                        selection={this.state.selectedCustomer}
                                        onSelectionChange={(e) =>
                                            this.setState({
                                                selectedCustomer: e.value,
                                            })
                                        }
                                        selectionMode="single"
                                    >
                                        <Column
                                            field="fee"
                                            header=" Fee"
                                        ></Column>
                                        <Column
                                            field="validTill"
                                            header="ValidTill"
                                            body={(transactionsData) => {
                                                return (
                                                    <>
                                                        {moment(
                                                            transactionsData?.validTill
                                                        ).format(
                                                            "MM/DD/YYYY"
                                                        ) || "--NA--"}
                                                    </>
                                                );
                                            }}
                                        ></Column>
                                        <Column
                                            field="assignTable.name"
                                            header="Assigned Table"
                                        ></Column>
                                        <Column
                                            field="days"
                                            header="days"
                                        ></Column>
                                        <Column
                                            field="paymentMode"
                                            header="Payment Mode"
                                        ></Column>
                                    </DataTable>
                                </>
                            )}

                            <Toast
                                ref={(el) => (this.toast = el)}
                                position="bottom-right"
                            />
                            {(this.state.isLoading || this.props.isLoading) && (
                                <LoadingComponent />
                            )}
                        </div>
                    </Authorize>
                </div>

                {this.state.isShowConfirmationDialog && (
                    <Dialog
                        header="Are you Sure ?"
                        visible={this.state.isShowConfirmationDialog}
                        style={{ width: "15vw" }}
                        onHide={this.onCloseConfirmation}
                        footer={this.footerContent}
                        draggable={false}
                    >
                        <p>Deleted Customer can't be restore.</p>
                    </Dialog>
                )}
            </>
        );
    }
}

export default CustomersList;
