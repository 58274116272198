import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import React, { Component } from "react";
import ServerlessService from "../../../Service/ServerlessService";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import LoadingComponent from "../../../LoadingComponent";
import { Column } from "primereact/column";
import { logOut } from "../../../Utils";

class AddMembers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            members: null,
            policyMembers: null
        };

        this.serverlessService = new ServerlessService();
    }

    getCustomers = () => {
        this.setState({
            isLoading: true,
            members: null,
        });

        const url = `/chitfunds/customers/all`;
        this.serverlessService
            .get(url, true)
            .then((res) => {

                if (res && res?.status && res?.res?.status) {
                    this.setState({
                        isLoading: false,
                        members: res?.res?.data,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    getMembersByPolicyId = () => {
        this.setState({
            isLoading: true,
            policyMembers: null,
        });
        let id = this.props.policyId;
        const url = `/chitfunds/policy/${id}`;
        this.serverlessService
            .get(url, true)
            .then((res) => {

                if (res && res?.status && res?.res?.status) {
                    this.setState({
                        isLoading: false,
                        policyMembers: res?.res?.data,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    addOrEdit = (a) => {

        let policyId = this.props?.policyId;
        let selectedMember = this.state.selectedMember;

        const url = `/chitfunds/policy/members`;
        this.serverlessService
            .post(url, { selectedMember, policyId }, true)
            .then((res) => {

                if (res && res?.status && res?.res?.status) {
                    this.setState(
                        {
                            selectedMember: null,
                            isLoading: false,

                        },
                        () => {
                            this.getMembersByPolicyId();
                        }
                    );
                } else {
                    this.setState({
                        isLoading: false,
                    });

                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
        // }
    };

    footerContent = () => {
        return (
            <div>
                <Button
                    label="Cancel"
                    className=" p-button-text hover:bg-primary "
                    onClick={this.props.onAddMembersCancel}
                />
                <Button
                    label="Ok"
                    onClick={this.props.onAddMembersCancel}

                    // onClick={() => this.addOrEdit()}
                    autoFocus
                    className=" p-button-primary "
                />
            </div>
        );
    };

    componentDidMount() {
        this.getCustomers();
        this.getMembersByPolicyId();
    }

    render() {
        console.log(this.state.policyMembers, "get Policy members");
        const { members, policyMembers } = this.state;
        console.log(this.state.selectedMember, "selected members");
        return (
            <>
                <div className="p-4">
                    <span className="text-1000 font-medium text-3xl  mb-3">
                        Policies
                    </span>
                    <br />
                    <Button
                        className="p-button-primary "
                        label="Add Policy"
                        severity="warning"
                        onClick={() => this.setState({ visible: true })}
                    />
                    <br />
                    <br />
                    <DataTable
                        value={policyMembers}
                        lazy
                        scrollable
                        responsiveLayout="scroll"
                        rowHover
                        // onSort={this.onSort}
                        // onPage={this.onPage}
                        // onFilter={this.onFilter}
                        // first={this.state.lazyParams.first}
                        // last={this.state.totalRecords}
                        // rows={this.state.lazyParams.rows}
                        // totalRecords={this.state.totalRecords}
                        // paginator
                        scrollHeight="400px"
                        // paginatorLeft
                        // paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        // rowsPerPageOptions={[
                        // 5, 10, 25, 50, 100, 200, 300, 500,
                        // ]}
                        // currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        dataKey="_id"
                        // selection={this.state.selectedCustomer}
                        // globalFilter={this.state.globalFilter}
                        // onSelectionChange={(e) =>
                        // this.setState({ selectedCustomer: e.value })
                        // }
                        selectionMode="single"
                    >
                        <Column
                            field="selectedMember.name"
                            header="Selected Member"
                        ></Column>
                        <Column
                            field="selectedMember.email"
                            header="Email"
                        ></Column>
                        <Column
                            field="selectedMember.mobile"
                            header="Mobile"
                        ></Column>
                        <Column
                            body={(rowData) => (
                                <span
                                    icon="pi pi-trash"
                                    onClick={() =>
                                        this.onAddMembersClick(
                                            rowData._id
                                        )
                                    }
                                ></span>
                            )}
                            header="Actions"
                        ></Column>

                    </DataTable>

                    <Toast
                        ref={(el) => (this.toast = el)}
                        position="bottom-right"
                    />
                    {(this.state.isLoading || this.props.isLoading) && (
                        <LoadingComponent />
                    )}
                </div>

            </>
        );
    }
}

export default AddMembers;
