import React, { Component } from "react";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import LoadingComponent from "../../../LoadingComponent";
import ServerlessService from "../../../Service/ServerlessService";
import BaseComponent from "../../common/BaseComponent";
import axios from "axios";
import {
    getFormFields,
    isFormValid,
    onTextChange,
    onNumberChange,
    onEmailChange,
    onChoiceChange,
    onChipsChange,
    isFieldValid,
    onFileChange,
} from "../../../Utils/formHelper";
import { logOut, trimObj } from "../../../Utils";
import ConsultantFields from "./emailConsultant.json";
import { InputTextarea } from "primereact/inputtextarea";
import { Calendar } from "primereact/calendar";
import { CLOUDFRONT_ENDPOINT } from "../../../Service/config";
import { Chips } from "primereact/chips";
import NavFooter from "../../navFooter";
import { Card } from "primereact/card";
import Required from "../../common/required";
import FileUpload from "../../common/fileUpload";
import { v4 as uuidv4 } from "uuid";

class EmailConsultantDetails extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(
            ConsultantFields,
            this.props.editData || {}
        );
        this.state = {
            consultant: this.formFields.data,
            formValidations: this.formFields.formValidations,
            isProfileValid: false,
        };
        this.serverlessService = new ServerlessService();
    }

    fileToS3 = async (file) => {
        let url =
            "https://v2ao5dt0v3.execute-api.ap-south-1.amazonaws.com/prod/v1/data/presign/url";
        let cloudFrontEndpoint = "https://d1wqfechwjsewm.cloudfront.net/";
        let thisObj = this;
        // debugger;
        if (!file.status && file.name) {
            let { data } = await axios.post(url, {
                type: "insert",
                key:
                    thisObj.props.orgId +
                    "/" +
                    uuidv4() +
                    file.name.replace(/\s/g, ""),
            });
            let uploadStatus = await axios.put(data.data.presignedURL, file, {
                headers: {
                    'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
                    "Content-Type": "binary/octet-stream",
                    "Access-Control-Allow-Origin": "*",
                },
            });
            return {
                path: cloudFrontEndpoint + data.data.contentStorageKey,
                key: data.data.contentStorageKey,
                name: file.name,
                type: file.type,
                size: file.size,
                status: uploadStatus.status == 200 ? true : false,
            };
        } else {
            return file;
        }
    };

    uploadFilesToS3 = async (consultant) => {
        let fileFields = [
            "drivingLicenseURL",
            "i94URL",
            "workAuthorizationURL",
            "passportURL",
            "passphotoURL",
            "stateIdURL",
        ];
        for (let index = 0; index < fileFields.length; index++) {
            const field = fileFields[index];
            if (consultant[field]) {
                consultant[field] = await this.fileToS3(consultant[field]);
            }
        }

        return consultant;
    };

    addOrUpdate = async () => {
        try {
            const formStatus = isFormValid(
                ConsultantFields,
                this.formFields.formValidations,
                this.state.consultant
            );

            let isCompleteFormValid = true;
            if (!formStatus.formValidations.isFormValid) {
                this.setState({
                    formValidations: formStatus.formValidations,
                });
                isCompleteFormValid = false;
            }

            if (isCompleteFormValid) {
                this.setState({
                    isLoading: true,
                });

                let consultant = await this.uploadFilesToS3(
                    this.state.consultant
                );
                consultant = trimObj(this.state.consultant);
                if (this.state.consultant._id) {
                    const url = `/consultants/consultant/mail`;
                    this.serverlessService
                        .put(url, {
                            ...this.state.consultant,
                            isProfileValid: true,
                            documentStatus: "Submitted",
                        })
                        .then((res) => {
                            if (res && res.status && res.res.status) {
                                this.setState(
                                    {
                                        consultant: {
                                            referenceId: "",
                                            name: "",
                                            location: "",
                                        },
                                        isProfileValid: true,
                                        isLoading: false,
                                    },
                                    () => {
                                        this.toast.show({
                                            severity: "success",
                                            summary: "Success",
                                            detail: "User form successfully submitted.",
                                            life: 3000,
                                        });
                                    }
                                );
                            } else {
                                this.setState({
                                    isLoading: false,
                                });

                                this.toast.show({
                                    severity: "error",
                                    summary: "Some error occurred",
                                    detail: res.errMessage,
                                    life: 3000,
                                });
                            }
                        })
                        .catch((e) => {
                            this.setState({
                                isLoading: false,
                            });
                            this.toast.show({
                                severity: "error",
                                summary: "Some error occurred",
                                detail: e.message,
                                life: 3000,
                            });
                        });
                }
            }
        } catch (e) {

            this.setState({
                isLoading: false
            })
            this.toast.show({
                severity: "error",
                summary: "Some Error occurred",
                detail: "Please contact administration.",
                life: 3000,
            });
        }
    };

    footerContent = () => (
        <div>
            <Button
                label="Cancel"
                className="p-button-text hover:bg-primary"
                onClick={this.props.onHide}
            />
            <Button
                label={"Update"}
                onClick={this.addOrUpdate}
                autoFocus
                className="p-button-primary "
            />
        </div>
    );

    _ondrivingLicenseURLSuccess = (fileName, data) => {
        this.addAttachment("drivingLicenseURL", fileName, data);
        return;
    };

    _oni94URLSuccess = (fileName, data) => {
        this.addAttachment("i94URL", fileName, data);
        return;
    };
    _onworkAuthorizationURLSuccess = (fileName, data) => {
        this.addAttachment("workAuthorizationURL", fileName, data);
        return;
    };
    _onpassportURLSuccess = (fileName, data) => {
        this.addAttachment("passportURL", fileName, data);
        return;
    };

    _onpassphotoURLSuccess = (fileName, data) => {
        this.addAttachment("passphotoURL", fileName, data);
        return;
    };

    _onFilsUploadError = (errMsg) => {
        this.toast.show({
            severity: "error",
            summary: "Some error",
            detail: errMsg,
            life: 3000,
        });
    };

    addAttachment = (fieldName, fileName, data) => {
        let consultant = {
            ...this.state.consultant,
            [fieldName]: CLOUDFRONT_ENDPOINT + data.contentStorageKey,
        };
        let formValidations = isFieldValid(
            ConsultantFields.find((fm) => fm.FieldName == fieldName),
            this.state.formValidations,
            consultant
        );

        this.setState((prevState) => {
            return {
                consultant,
                formValidations,
            };
        });
    };

    getConsultantById = (id) => {
        this.setState({
            isLoading: true,
        });

        const url = `/consultants/consultant/uuid/${id}`;
        this.serverlessService
            .get(url)
            .then((res) => {
                if (res && res.status && res.res.data) {
                    this.setState(
                        {
                            consultant: res.res.data,
                            isLoading: false,
                            isProfileValid: res.res.data.isProfileValid,
                        },

                        () => { }
                    );
                } else {
                    this.setState({
                        isLoading: false,
                    });

                    this.props.history.push("/");
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    componentDidMount() {
        // return
        if (this.props?.params?.id) {
            this.getConsultantById(this.props?.params.id);
        } else {
        }
    }

    showPreviewDoc = (file) => {
        if (file.status) {
            const newTab = window.open(file.path, "_blank");
        } else {
            const reader = new FileReader();

            reader.onload = function (event) {
                const data = event.target.result;
                const blob = new Blob([data], { type: file.type });
                const url = URL.createObjectURL(blob);

                // Open the file in a new tab
                const newTab = window.open(url, "_blank");
                newTab.focus();
            };
            reader.readAsArrayBuffer(file);
        }
    };

    renderSuccessMessage = () => {
        return (<div
            className="p-d-flex p-flex-column p-ai-center "
            style={{
                padding: "2em",
                borderRadius: "8px",
                backgroundColor: "white",
                maxWidth: "600px",
                width: "100%",
                boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                textAlign: "center",
                margin: "auto"
            }}
        >
            <i
                className="pi pi-check-circle"
                style={{
                    fontSize: "6em",
                    color: "#34A853",
                    marginBottom: "20px",
                }}
            ></i>
            <h1
                style={{
                    marginBottom: "20px",
                    fontSize: "2.5em",
                    fontWeight: "bold",
                    color: "#333",
                }}
            >
                Success!
            </h1>
            <p
                style={{
                    fontSize: "1.2em",
                    marginBottom: "20px",
                    color: "#555",
                }}
            >
                Your Details has been successfully
                submitted. Thank you for taking the time to
                provide us with your information.
            </p>
            <Button
                label="Go to Login"
                className="p-button-success"
                style={{
                    fontWeight: "bold",
                    fontSize: "1em",
                }}
                onClick={() =>
                    this.props.history.push("/login")
                }
            />
            {/* <p
                style={{
                    marginTop: "20px",
                    fontSize: "1em",
                    color: "#aaa",
                }}
            >
                Need assistance?{" "}
                <a href="#" style={{ color: "#34A853" }}>
                    Contact support
                </a>
            </p> */}
        </div>)

    }

    render() {
        const { consultant, formValidations } = this.state;

        return (
            <NavFooter>
                <Card style={{ height: '100%' }} >
                    <div className="m-5">
                        {this.state.isProfileValid ? (
                            this.renderSuccessMessage()
                        ) : (
                            <div>
                                <h6>Personal Details</h6>
                                <div className="p-grid row pl-6 pr-6">
                                    <div className="col-12 md:col-4 lg:col-4">
                                        <label className="font-bold block mb-2">
                                            Name
                                            <span className="sp-required">
                                                *
                                            </span>
                                        </label>
                                        <InputText
                                            onChange={(e) => {
                                                onTextChange(
                                                    e.target.value,
                                                    "name",
                                                    this,
                                                    ConsultantFields,
                                                    consultant,
                                                    formValidations,
                                                    "consultant",
                                                    "formValidations"
                                                );
                                            }}
                                            value={consultant.name}
                                        />
                                        {formValidations &&
                                            !formValidations.fields["name"]
                                                .isValid && (
                                                <p className="p-error">
                                                    {
                                                        formValidations.fields[
                                                            "name"
                                                        ].errorMsg
                                                    }
                                                </p>
                                            )}
                                    </div>
                                    <div className="col-12 md:col-4 lg:col-4">
                                        <label className="font-bold block mb-2">
                                            Phone
                                            <span className="sp-required">
                                                *
                                            </span>
                                        </label>
                                        <InputText
                                            onChange={(e) => {
                                                onNumberChange(
                                                    e.target.value,
                                                    "mobile",
                                                    this,
                                                    ConsultantFields,
                                                    consultant,
                                                    formValidations,
                                                    "consultant",
                                                    "formValidations"
                                                );
                                            }}
                                            value={consultant.mobile}
                                        />
                                        {formValidations &&
                                            !formValidations.fields["mobile"]
                                                .isValid && (
                                                <p className="p-error">
                                                    {
                                                        formValidations.fields[
                                                            "mobile"
                                                        ].errorMsg
                                                    }
                                                </p>
                                            )}
                                    </div>
                                    <div className="col-12 md:col-4 lg:col-4">
                                        <label className="font-bold block mb-2">
                                            Email
                                            <span className="sp-required">
                                                *
                                            </span>
                                        </label>
                                        <InputText
                                            onChange={(e) => {
                                                onEmailChange(
                                                    e.target.value,
                                                    "email",
                                                    this,
                                                    ConsultantFields,
                                                    consultant,
                                                    formValidations,
                                                    "consultant",
                                                    "formValidations"
                                                );
                                            }}
                                            value={consultant.email}
                                        />

                                        {formValidations &&
                                            !formValidations.fields["email"]
                                                .isValid && (
                                                <p className="p-error">
                                                    {
                                                        formValidations.fields[
                                                            "email"
                                                        ].errorMsg
                                                    }
                                                </p>
                                            )}
                                    </div>
                                    <div className="col-12 md:col-4 lg:col-4">
                                        <label className="font-bold block mb-2">
                                            Secondary Email
                                        </label>
                                        <InputText
                                            onChange={(e) => {
                                                onEmailChange(
                                                    e.target.value,
                                                    "emailSecond",
                                                    this,
                                                    ConsultantFields,
                                                    consultant,
                                                    formValidations,
                                                    "consultant",
                                                    "formValidations"
                                                );
                                            }}
                                            value={consultant.emailSecond}
                                        />

                                        {formValidations &&
                                            !formValidations.fields[
                                                "emailSecond"
                                            ].isValid && (
                                                <p className="p-error">
                                                    {
                                                        formValidations.fields[
                                                            "emailSecond"
                                                        ].errorMsg
                                                    }
                                                </p>
                                            )}
                                    </div>
                                    <div className="col-12 md:col-4 lg:col-4">
                                        <label className="font-bold block mb-2">
                                            Social Security Number
                                            <span className="sp-required">
                                                *
                                            </span>
                                        </label>
                                        <InputText
                                            onChange={(e) => {
                                                onNumberChange(
                                                    e.target.value,
                                                    "last4digitSSN",
                                                    this,
                                                    ConsultantFields,
                                                    consultant,
                                                    formValidations,
                                                    "consultant",
                                                    "formValidations"
                                                );
                                            }}
                                            value={consultant.last4digitSSN}
                                        />
                                        {formValidations &&
                                            !formValidations.fields[
                                                "last4digitSSN"
                                            ].isValid && (
                                                <p className="p-error">
                                                    {
                                                        formValidations.fields[
                                                            "last4digitSSN"
                                                        ].errorMsg
                                                    }
                                                </p>
                                            )}
                                    </div>
                                    <div className="col-12 md:col-4 lg:col-4">
                                        <label className="font-bold block mb-2">
                                            Landline number
                                        </label>
                                        <InputText
                                            onChange={(e) => {
                                                onNumberChange(
                                                    e.target.value,
                                                    "landLine",
                                                    this,
                                                    ConsultantFields,
                                                    consultant,
                                                    formValidations,
                                                    "consultant",
                                                    "formValidations"
                                                );
                                            }}
                                            value={consultant.landLine}
                                        />
                                        {formValidations &&
                                            !formValidations.fields["landLine"]
                                                .isValid && (
                                                <p className="p-error">
                                                    {
                                                        formValidations.fields[
                                                            "landLine"
                                                        ].errorMsg
                                                    }
                                                </p>
                                            )}
                                    </div>
                                    <div className="col-12 md:col-4 lg:col-4">
                                        <label className="font-bold block mb-2">
                                            Mother Tongue
                                            <span className="sp-required">
                                                *
                                            </span>
                                        </label>
                                        <InputText
                                            onChange={(e) => {
                                                onTextChange(
                                                    e.target.value,
                                                    "motherTongue",
                                                    this,
                                                    ConsultantFields,
                                                    consultant,
                                                    formValidations,
                                                    "consultant",
                                                    "formValidations"
                                                );
                                            }}
                                            value={consultant.motherTongue}
                                        />
                                        {formValidations &&
                                            !formValidations.fields[
                                                "motherTongue"
                                            ].isValid && (
                                                <p className="p-error">
                                                    {
                                                        formValidations.fields[
                                                            "motherTongue"
                                                        ].errorMsg
                                                    }
                                                </p>
                                            )}
                                    </div>
                                    <div className="col-12 md:col-4 lg:col-4">
                                        <label className="font-bold block mb-2">
                                            Date of birth{" "}
                                            <span className="sp-required">
                                                *
                                            </span>
                                        </label>
                                        <Calendar
                                            value={
                                                consultant.dateOfBirth
                                                    ? new Date(
                                                        consultant.dateOfBirth
                                                    )
                                                    : null
                                            }
                                            readOnlyInput={true}
                                            showIcon={true}
                                            maxDate={new Date()}
                                            placeholder="Date of Birth"
                                            // className="w-full"
                                            id="dateOfBirth"
                                            onChange={(e) =>
                                                onTextChange(
                                                    e?.value?.toString(),
                                                    "dateOfBirth",
                                                    this,
                                                    ConsultantFields,
                                                    consultant,
                                                    formValidations,
                                                    "consultant",
                                                    "formValidations"
                                                )
                                            }
                                        ></Calendar>
                                        {formValidations &&
                                            !formValidations.fields[
                                                "dateOfBirth"
                                            ].isValid && (
                                                <p className="p-error">
                                                    {
                                                        formValidations.fields[
                                                            "dateOfBirth"
                                                        ].errorMsg
                                                    }
                                                </p>
                                            )}
                                    </div>
                                    <div className="col-12 md:col-4 lg:col-4">
                                        <label className="font-bold block mb-2">
                                            Date of Entered US
                                            <span className="sp-required">
                                                *
                                            </span>
                                        </label>
                                        <Calendar
                                            maxDate={new Date()}
                                            value={
                                                consultant.usEnteredDate
                                                    ? new Date(
                                                        consultant.usEnteredDate
                                                    )
                                                    : null
                                            }
                                            showIcon={true}
                                            placeholder="US Entered Date"
                                            className="w-full"
                                            id="usEnteredDate"
                                            onChange={(e) =>
                                                onTextChange(
                                                    e?.value?.toString(),
                                                    "usEnteredDate",
                                                    this,
                                                    ConsultantFields,
                                                    consultant,
                                                    formValidations,
                                                    "consultant",
                                                    "formValidations"
                                                )
                                            }
                                        ></Calendar>
                                        {formValidations &&
                                            !formValidations.fields[
                                                "usEnteredDate"
                                            ].isValid && (
                                                <p className="p-error">
                                                    {
                                                        formValidations.fields[
                                                            "usEnteredDate"
                                                        ].errorMsg
                                                    }
                                                </p>
                                            )}
                                    </div>
                                    <div className="col-12 md:col-4 lg:col-4">
                                        <label className="font-bold block mb-2">
                                            VISA Status
                                            <span className="sp-required">
                                                *
                                            </span>
                                        </label>
                                        <InputText
                                            onChange={(e) => {
                                                onTextChange(
                                                    e.target.value,
                                                    "visaStatus",
                                                    this,
                                                    ConsultantFields,
                                                    consultant,
                                                    formValidations,
                                                    "consultant",
                                                    "formValidations"
                                                );
                                            }}
                                            value={consultant.visaStatus}
                                        />
                                        {formValidations &&
                                            !formValidations.fields[
                                                "visaStatus"
                                            ].isValid && (
                                                <p className="p-error">
                                                    {
                                                        formValidations.fields[
                                                            "visaStatus"
                                                        ].errorMsg
                                                    }
                                                </p>
                                            )}
                                    </div>
                                    <div className="col-12 md:col-4 lg:col-4">
                                        <label className="font-bold block mb-2">
                                            Driving License
                                        </label>
                                        <InputText
                                            onChange={(e) => {
                                                onTextChange(
                                                    e.target.value,
                                                    "drivingLicenseNo",
                                                    this,
                                                    ConsultantFields,
                                                    consultant,
                                                    formValidations,
                                                    "consultant",
                                                    "formValidations"
                                                );
                                            }}
                                            value={consultant.drivingLicenseNo}
                                        />
                                        {formValidations &&
                                            !formValidations.fields[
                                                "drivingLicenseNo"
                                            ].isValid && (
                                                <p className="p-error">
                                                    {
                                                        formValidations.fields[
                                                            "drivingLicenseNo"
                                                        ].errorMsg
                                                    }
                                                </p>
                                            )}
                                    </div>
                                    <div className="col-12 md:col-4 lg:col-4">
                                        <label className="font-bold block mb-2">
                                            State ID
                                        </label>
                                        <InputText
                                            onChange={(e) => {
                                                onTextChange(
                                                    e.target.value,
                                                    "stateId",
                                                    this,
                                                    ConsultantFields,
                                                    consultant,
                                                    formValidations,
                                                    "consultant",
                                                    "formValidations"
                                                );
                                            }}
                                            value={consultant.stateId}
                                        />
                                        {formValidations &&
                                            !formValidations.fields["stateId"]
                                                .isValid && (
                                                <p className="p-error">
                                                    {
                                                        formValidations.fields[
                                                            "stateId"
                                                        ].errorMsg
                                                    }
                                                </p>
                                            )}
                                    </div>
                                    <div className="col-12 md:col-4 lg:col-4">
                                        <label className="font-bold block mb-2">
                                            Skype ID
                                        </label>
                                        <InputText
                                            onChange={(e) => {
                                                onTextChange(
                                                    e.target.value,
                                                    "skypeId",
                                                    this,
                                                    ConsultantFields,
                                                    consultant,
                                                    formValidations,
                                                    "consultant",
                                                    "formValidations"
                                                );
                                            }}
                                            value={consultant.skypeId}
                                        />
                                        {formValidations &&
                                            !formValidations.fields["skypeId"]
                                                .isValid && (
                                                <p className="p-error">
                                                    {
                                                        formValidations.fields[
                                                            "skypeId"
                                                        ].errorMsg
                                                    }
                                                </p>
                                            )}
                                    </div>
                                </div>
                                <br />
                                <h6>Position Details</h6>
                                <div className="p-grid row pl-6 pr-6">
                                    <div className="col-12 md:col-4 lg:col-4">
                                        <label className="font-bold block mb-2">
                                            Role Prefered
                                            <span className="sp-required">
                                                *
                                            </span>
                                        </label>
                                        <InputText
                                            onChange={(e) => {
                                                onTextChange(
                                                    e.target.value,
                                                    "rolePrefered",
                                                    this,
                                                    ConsultantFields,
                                                    consultant,
                                                    formValidations,
                                                    "consultant",
                                                    "formValidations"
                                                );
                                            }}
                                            value={consultant.rolePrefered}
                                        />

                                        {formValidations &&
                                            !formValidations.fields[
                                                "rolePrefered"
                                            ].isValid && (
                                                <p className="p-error">
                                                    {
                                                        formValidations.fields[
                                                            "rolePrefered"
                                                        ].errorMsg
                                                    }
                                                </p>
                                            )}
                                    </div>
                                    <div className="col-12 md:col-8 lg:col-8">
                                        <label className="font-bold block mb-2">
                                            Technologies Prefered
                                            <span className="sp-required">
                                                *
                                            </span>
                                        </label>
                                        <Chips
                                            className="block"
                                            allowDuplicate={false}
                                            onChange={(e) => {
                                                onChipsChange(
                                                    e.target.value,
                                                    "technologiesPrefered",
                                                    this,
                                                    ConsultantFields,
                                                    consultant,
                                                    formValidations,
                                                    "consultant",
                                                    "formValidations"
                                                );
                                            }}
                                            value={
                                                consultant.technologiesPrefered
                                            }
                                        />

                                        {formValidations &&
                                            !formValidations.fields[
                                                "technologiesPrefered"
                                            ].isValid && (
                                                <p className="p-error">
                                                    {
                                                        formValidations.fields[
                                                            "technologiesPrefered"
                                                        ].errorMsg
                                                    }
                                                </p>
                                            )}
                                    </div>
                                </div>
                                <br />
                                <h6>Commuication address</h6>
                                <div className="p-grid row pl-6 pr-6">
                                    <div className="col-12 md:col-4 lg:col-4">
                                        <label className="font-bold block mb-2">
                                            Current Address
                                            <span className="sp-required">
                                                *
                                            </span>
                                        </label>
                                        <InputTextarea
                                            rows={3}
                                            onChange={(e) => {
                                                onTextChange(
                                                    e.target.value,
                                                    "currentAddress",
                                                    this,
                                                    ConsultantFields,
                                                    consultant,
                                                    formValidations,
                                                    "consultant",
                                                    "formValidations"
                                                );
                                            }}
                                            value={consultant.currentAddress}
                                        />
                                        {formValidations &&
                                            !formValidations.fields[
                                                "currentAddress"
                                            ].isValid && (
                                                <p className="p-error">
                                                    {
                                                        formValidations.fields[
                                                            "currentAddress"
                                                        ].errorMsg
                                                    }
                                                </p>
                                            )}
                                    </div>
                                    <div className="col-12 md:col-4 lg:col-4">
                                        <label className="font-bold block mb-2">
                                            Home Country Address
                                            <span className="sp-required">
                                                *
                                            </span>
                                        </label>
                                        <InputTextarea
                                            rows={3}
                                            onChange={(e) => {
                                                onTextChange(
                                                    e.target.value,
                                                    "homeCountryAddress",
                                                    this,
                                                    ConsultantFields,
                                                    consultant,
                                                    formValidations,
                                                    "consultant",
                                                    "formValidations"
                                                );
                                            }}
                                            value={
                                                consultant.homeCountryAddress
                                            }
                                        />
                                        {/* <label htmlFor={'33'} className="p-checkbox-label p-name">{''}</label> */}

                                        {formValidations &&
                                            !formValidations.fields[
                                                "homeCountryAddress"
                                            ].isValid && (
                                                <p className="p-error">
                                                    {
                                                        formValidations.fields[
                                                            "homeCountryAddress"
                                                        ].errorMsg
                                                    }
                                                </p>
                                            )}
                                    </div>

                                    <div className="col-12 md:col-4 lg:col-4">
                                        <label className="font-bold block mb-2">
                                            Current Location (City and State)
                                            <span className="sp-required">
                                                *
                                            </span>
                                        </label>
                                        <InputText
                                            onChange={(e) => {
                                                onTextChange(
                                                    e.target.value,
                                                    "currentLocation",
                                                    this,
                                                    ConsultantFields,
                                                    consultant,
                                                    formValidations,
                                                    "consultant",
                                                    "formValidations"
                                                );
                                            }}
                                            value={consultant.currentLocation}
                                        />
                                        {formValidations &&
                                            !formValidations.fields[
                                                "currentLocation"
                                            ].isValid && (
                                                <p className="p-error">
                                                    {
                                                        formValidations.fields[
                                                            "currentLocation"
                                                        ].errorMsg
                                                    }
                                                </p>
                                            )}
                                    </div>
                                    <div className="col-12 md:col-4 lg:col-4">
                                        <label className="font-bold block mb-2">
                                            Willing to Relocate
                                            <span className="sp-required">
                                                *
                                            </span>
                                        </label>
                                        <Checkbox
                                            inputId={"33"}
                                            checked={
                                                consultant.willingToRelocate
                                            }
                                            onChange={(e) => {
                                                onChoiceChange(
                                                    e.target.checked,
                                                    "willingToRelocate",
                                                    this,
                                                    ConsultantFields,
                                                    consultant,
                                                    formValidations,
                                                    "consultant",
                                                    "formValidations"
                                                );
                                            }}
                                        ></Checkbox>
                                        {/* <label htmlFor={'33'} className="p-checkbox-label p-name">{''}</label> */}

                                        {formValidations &&
                                            !formValidations.fields[
                                                "willingToRelocate"
                                            ].isValid && (
                                                <p className="p-error">
                                                    {
                                                        formValidations.fields[
                                                            "willingToRelocate"
                                                        ].errorMsg
                                                    }
                                                </p>
                                            )}
                                    </div>
                                </div>
                                <br />
                                <h6>Emergency Contact Details</h6>
                                <div className="p-grid row pl-6 pr-6">
                                    <div className="col-12 md:col-4 lg:col-4">
                                        <label className="font-bold block mb-2">
                                            Emergency Contact Person Name
                                            <span className="sp-required">
                                                *
                                            </span>
                                        </label>
                                        <InputText
                                            onChange={(e) => {
                                                onTextChange(
                                                    e.target.value,
                                                    "emergencyContactPerson",
                                                    this,
                                                    ConsultantFields,
                                                    consultant,
                                                    formValidations,
                                                    "consultant",
                                                    "formValidations"
                                                );
                                            }}
                                            value={
                                                consultant.emergencyContactPerson
                                            }
                                        />
                                        {formValidations &&
                                            !formValidations.fields[
                                                "emergencyContactPerson"
                                            ].isValid && (
                                                <p className="p-error">
                                                    {
                                                        formValidations.fields[
                                                            "emergencyContactPerson"
                                                        ].errorMsg
                                                    }
                                                </p>
                                            )}
                                    </div>
                                    <div className="col-12 md:col-4 lg:col-4">
                                        <label className="font-bold block mb-2">
                                            Emergency Contact Person Name Email
                                            <span className="sp-required">
                                                *
                                            </span>
                                        </label>
                                        <InputText
                                            onChange={(e) => {
                                                onEmailChange(
                                                    e.target.value,
                                                    "emergencyContactPersonEmail",
                                                    this,
                                                    ConsultantFields,
                                                    consultant,
                                                    formValidations,
                                                    "consultant",
                                                    "formValidations"
                                                );
                                            }}
                                            value={
                                                consultant.emergencyContactPersonEmail
                                            }
                                        />

                                        {formValidations &&
                                            !formValidations.fields[
                                                "emergencyContactPersonEmail"
                                            ].isValid && (
                                                <p className="p-error">
                                                    {
                                                        formValidations.fields[
                                                            "emergencyContactPersonEmail"
                                                        ].errorMsg
                                                    }
                                                </p>
                                            )}
                                    </div>
                                    <div className="col-12 md:col-4 lg:col-4">
                                        <label className="font-bold block mb-2">
                                            Emergency Contact Person Phone
                                            <span className="sp-required">
                                                *
                                            </span>
                                        </label>
                                        <InputText
                                            onChange={(e) => {
                                                onNumberChange(
                                                    e.target.value,
                                                    "emergencyContactPersonPhone",
                                                    this,
                                                    ConsultantFields,
                                                    consultant,
                                                    formValidations,
                                                    "consultant",
                                                    "formValidations"
                                                );
                                            }}
                                            value={
                                                consultant.emergencyContactPersonPhone
                                            }
                                        />
                                        {formValidations &&
                                            !formValidations.fields[
                                                "emergencyContactPersonPhone"
                                            ].isValid && (
                                                <p className="p-error">
                                                    {
                                                        formValidations.fields[
                                                            "emergencyContactPersonPhone"
                                                        ].errorMsg
                                                    }
                                                </p>
                                            )}
                                    </div>
                                </div>
                                <br />
                                <h6>Educational Details</h6>
                                <div className="p-grid row pl-6 pr-6">
                                    <div className="col-12 md:col-4 lg:col-4">
                                        <label className="font-bold block mb-2">
                                            Bachellors Degree
                                            <span className="sp-required">
                                                *
                                            </span>
                                        </label>
                                        <InputText
                                            onChange={(e) => {
                                                onTextChange(
                                                    e.target.value,
                                                    "bachellorsDegree",
                                                    this,
                                                    ConsultantFields,
                                                    consultant,
                                                    formValidations,
                                                    "consultant",
                                                    "formValidations"
                                                );
                                            }}
                                            value={consultant.bachellorsDegree}
                                        />
                                        {formValidations &&
                                            !formValidations.fields[
                                                "bachellorsDegree"
                                            ].isValid && (
                                                <p className="p-error">
                                                    {
                                                        formValidations.fields[
                                                            "bachellorsDegree"
                                                        ].errorMsg
                                                    }
                                                </p>
                                            )}
                                    </div>
                                    <div className="col-12 md:col-4 lg:col-4">
                                        <label className="font-bold block mb-2">
                                            Bachellors College Name
                                            <span className="sp-required">
                                                *
                                            </span>
                                        </label>
                                        <InputText
                                            onChange={(e) => {
                                                onTextChange(
                                                    e.target.value,
                                                    "bachellersCollegeName",
                                                    this,
                                                    ConsultantFields,
                                                    consultant,
                                                    formValidations,
                                                    "consultant",
                                                    "formValidations"
                                                );
                                            }}
                                            value={
                                                consultant.bachellersCollegeName
                                            }
                                        />
                                        {formValidations &&
                                            !formValidations.fields[
                                                "bachellersCollegeName"
                                            ].isValid && (
                                                <p className="p-error">
                                                    {
                                                        formValidations.fields[
                                                            "bachellersCollegeName"
                                                        ].errorMsg
                                                    }
                                                </p>
                                            )}
                                    </div>
                                    <div className="col-12 md:col-4 lg:col-4">
                                        <label className="font-bold block mb-2">
                                            Bachellers Completed Date
                                            <span className="sp-required">
                                                *
                                            </span>
                                        </label>
                                        <InputText
                                            onChange={(e) => {
                                                onTextChange(
                                                    e.target.value,
                                                    "bachellersCompletedDate",
                                                    this,
                                                    ConsultantFields,
                                                    consultant,
                                                    formValidations,
                                                    "consultant",
                                                    "formValidations"
                                                );
                                            }}
                                            value={
                                                consultant.bachellersCompletedDate
                                            }
                                        />
                                        {formValidations &&
                                            !formValidations.fields[
                                                "bachellersCompletedDate"
                                            ].isValid && (
                                                <p className="p-error">
                                                    {
                                                        formValidations.fields[
                                                            "bachellersCompletedDate"
                                                        ].errorMsg
                                                    }
                                                </p>
                                            )}
                                    </div>
                                    <div className="col-12 md:col-4 lg:col-4">
                                        <label className="font-bold block mb-2">
                                            US Entry Date
                                        </label>
                                        <InputText
                                            onChange={(e) => {
                                                onTextChange(
                                                    e.target.value,
                                                    "usEntry",
                                                    this,
                                                    ConsultantFields,
                                                    consultant,
                                                    formValidations,
                                                    "consultant",
                                                    "formValidations"
                                                );
                                            }}
                                            value={consultant.usEntry}
                                        />
                                        {formValidations &&
                                            !formValidations.fields[
                                                "usEntry"
                                            ].isValid && (
                                                <p className="p-error">
                                                    {
                                                        formValidations.fields[
                                                            "usEntry"
                                                        ].errorMsg
                                                    }
                                                </p>
                                            )}
                                    </div>
                                    <div className="col-12 md:col-4 lg:col-4">
                                        <label className="font-bold block mb-2">
                                            Masters Degree
                                        </label>
                                        <InputText
                                            onChange={(e) => {
                                                onTextChange(
                                                    e.target.value,
                                                    "masterDegree",
                                                    this,
                                                    ConsultantFields,
                                                    consultant,
                                                    formValidations,
                                                    "consultant",
                                                    "formValidations"
                                                );
                                            }}
                                            value={consultant.masterDegree}
                                        />
                                        {formValidations &&
                                            !formValidations.fields[
                                                "masterDegree"
                                            ].isValid && (
                                                <p className="p-error">
                                                    {
                                                        formValidations.fields[
                                                            "masterDegree"
                                                        ].errorMsg
                                                    }
                                                </p>
                                            )}
                                    </div>
                                    <div className="col-12 md:col-4 lg:col-4">
                                        <label className="font-bold block mb-2">
                                            Masters College Name
                                        </label>
                                        <InputText
                                            onChange={(e) => {
                                                onTextChange(
                                                    e.target.value,
                                                    "mastersCollegeName",
                                                    this,
                                                    ConsultantFields,
                                                    consultant,
                                                    formValidations,
                                                    "consultant",
                                                    "formValidations"
                                                );
                                            }}
                                            value={
                                                consultant.mastersCollegeName
                                            }
                                        />
                                        {formValidations &&
                                            !formValidations.fields[
                                                "mastersCollegeName"
                                            ].isValid && (
                                                <p className="p-error">
                                                    {
                                                        formValidations.fields[
                                                            "mastersCollegeName"
                                                        ].errorMsg
                                                    }
                                                </p>
                                            )}
                                    </div>
                                    <div className="col-12 md:col-4 lg:col-4">
                                        <label className="font-bold block mb-2">
                                            Masters Start Date
                                        </label>
                                        <InputText
                                            onChange={(e) => {
                                                onTextChange(
                                                    e.target.value,
                                                    "mastersStartDate",
                                                    this,
                                                    ConsultantFields,
                                                    consultant,
                                                    formValidations,
                                                    "consultant",
                                                    "formValidations"
                                                );
                                            }}
                                            value={
                                                consultant.mastersStartDate
                                            }
                                        />
                                        {formValidations &&
                                            !formValidations.fields[
                                                "mastersStartDate"
                                            ].isValid && (
                                                <p className="p-error">
                                                    {
                                                        formValidations.fields[
                                                            "mastersStartDate"
                                                        ].errorMsg
                                                    }
                                                </p>
                                            )}
                                    </div>
                                    <div className="col-12 md:col-4 lg:col-4">
                                        <label className="font-bold block mb-2">
                                            Masters Completed Date
                                        </label>
                                        <InputText
                                            onChange={(e) => {
                                                onTextChange(
                                                    e.target.value,
                                                    "mastersCompletedDate",
                                                    this,
                                                    ConsultantFields,
                                                    consultant,
                                                    formValidations,
                                                    "consultant",
                                                    "formValidations"
                                                );
                                            }}
                                            value={
                                                consultant.mastersCompletedDate
                                            }
                                        />
                                        {formValidations &&
                                            !formValidations.fields[
                                                "mastersCompletedDate"
                                            ].isValid && (
                                                <p className="p-error">
                                                    {
                                                        formValidations.fields[
                                                            "mastersCompletedDate"
                                                        ].errorMsg
                                                    }
                                                </p>
                                            )}
                                    </div>
                                </div>
                                <br />
                                <h6>Previous/Current experience</h6>
                                <div className="p-grid row pl-6 pr-6">
                                    <div className="col-12 md:col-4 lg:col-4">
                                        <label className="font-bold block mb-2">
                                            Work Authorization Start Date
                                        </label>
                                        <Calendar
                                            value={
                                                consultant.workAuthorizationStartDate
                                                    ? new Date(
                                                        consultant.workAuthorizationStartDate
                                                    )
                                                    : null
                                            }
                                            showIcon={true}
                                            placeholder="Start Date"
                                            className="w-full"
                                            id="workAuthorizationStartDate"
                                            onChange={(e) =>
                                                onTextChange(
                                                    e?.value?.toString(),
                                                    "workAuthorizationStartDate",
                                                    this,
                                                    ConsultantFields,
                                                    consultant,
                                                    formValidations,
                                                    "consultant",
                                                    "formValidations"
                                                )
                                            }
                                        ></Calendar>
                                        {formValidations &&
                                            !formValidations.fields[
                                                "workAuthorizationStartDate"
                                            ].isValid && (
                                                <p className="p-error">
                                                    {
                                                        formValidations.fields[
                                                            "workAuthorizationStartDate"
                                                        ].errorMsg
                                                    }
                                                </p>
                                            )}
                                    </div>
                                    <div className="col-12 md:col-4 lg:col-4">
                                        <label className="font-bold block mb-2">
                                            Work Authorization End Date
                                        </label>
                                        <Calendar
                                            value={
                                                consultant.workAuthorizationEndDate
                                                    ? new Date(
                                                        consultant.workAuthorizationEndDate
                                                    )
                                                    : null
                                            }
                                            showIcon={true}
                                            placeholder="End Date"
                                            className="w-full"
                                            id="workAuthorizationEndDate"
                                            onChange={(e) =>
                                                onTextChange(
                                                    e?.value?.toString(),
                                                    "workAuthorizationEndDate",
                                                    this,
                                                    ConsultantFields,
                                                    consultant,
                                                    formValidations,
                                                    "consultant",
                                                    "formValidations"
                                                )
                                            }
                                        ></Calendar>
                                        {formValidations &&
                                            !formValidations.fields[
                                                "workAuthorizationEndDate"
                                            ].isValid && (
                                                <p className="p-error">
                                                    {
                                                        formValidations.fields[
                                                            "workAuthorizationEndDate"
                                                        ].errorMsg
                                                    }
                                                </p>
                                            )}
                                    </div>

                                    <div className="col-12 md:col-4 lg:col-4">
                                        <label className="font-bold block mb-2">
                                            Any Work Experience
                                        </label>
                                        <Checkbox
                                            inputId={"33"}
                                            checked={
                                                consultant.isHaveAnyWorkExperience
                                            }
                                            onChange={(e) => {
                                                onChoiceChange(
                                                    e.target.checked,
                                                    "isHaveAnyWorkExperience",
                                                    this,
                                                    ConsultantFields,
                                                    consultant,
                                                    formValidations,
                                                    "consultant",
                                                    "formValidations"
                                                );
                                            }}
                                        ></Checkbox>
                                        {/* <label htmlFor={'33'} className="p-checkbox-label p-name">{''}</label> */}

                                        {formValidations &&
                                            !formValidations.fields[
                                                "isHaveAnyWorkExperience"
                                            ].isValid && (
                                                <p className="p-error">
                                                    {
                                                        formValidations.fields[
                                                            "isHaveAnyWorkExperience"
                                                        ].errorMsg
                                                    }
                                                </p>
                                            )}
                                    </div>

                                    {consultant.isHaveAnyWorkExperience && (
                                        <>
                                            <div className="col-12 md:col-4 lg:col-4">
                                                <label className="font-bold block mb-2">
                                                    Work Experience Title
                                                </label>
                                                <InputText
                                                    onChange={(e) => {
                                                        onTextChange(
                                                            e.target.value,
                                                            "workExperiencTitle",
                                                            this,
                                                            ConsultantFields,
                                                            consultant,
                                                            formValidations,
                                                            "consultant",
                                                            "formValidations"
                                                        );
                                                    }}
                                                    value={
                                                        consultant.workExperiencTitle
                                                    }
                                                />
                                                {formValidations &&
                                                    !formValidations.fields[
                                                        "workExperiencTitle"
                                                    ].isValid && (
                                                        <p className="p-error">
                                                            {
                                                                formValidations
                                                                    .fields[
                                                                    "workExperiencTitle"
                                                                ].errorMsg
                                                            }
                                                        </p>
                                                    )}
                                            </div>
                                            <div className="col-12 md:col-4 lg:col-4">
                                                <label className="font-bold block mb-2">
                                                    Work Experience Technologies
                                                </label>
                                                <InputText
                                                    onChange={(e) => {
                                                        onTextChange(
                                                            e.target.value,
                                                            "workExperiencTechnologies",
                                                            this,
                                                            ConsultantFields,
                                                            consultant,
                                                            formValidations,
                                                            "consultant",
                                                            "formValidations"
                                                        );
                                                    }}
                                                    value={
                                                        consultant.workExperiencTechnologies
                                                    }
                                                />
                                                {formValidations &&
                                                    !formValidations.fields[
                                                        "workExperiencTechnologies"
                                                    ].isValid && (
                                                        <p className="p-error">
                                                            {
                                                                formValidations
                                                                    .fields[
                                                                    "workExperiencTechnologies"
                                                                ].errorMsg
                                                            }
                                                        </p>
                                                    )}
                                            </div>
                                            <div className="col-12 md:col-4 lg:col-4">
                                                <label className="font-bold block mb-2">
                                                    Total Years Of Experience
                                                </label>
                                                <InputText
                                                    onChange={(e) => {
                                                        onTextChange(
                                                            e.target.value,
                                                            "totalYearsOfExperience",
                                                            this,
                                                            ConsultantFields,
                                                            consultant,
                                                            formValidations,
                                                            "consultant",
                                                            "formValidations"
                                                        );
                                                    }}
                                                    value={
                                                        consultant.totalYearsOfExperience
                                                    }
                                                />
                                                {formValidations &&
                                                    !formValidations.fields[
                                                        "totalYearsOfExperience"
                                                    ].isValid && (
                                                        <p className="p-error">
                                                            {
                                                                formValidations
                                                                    .fields[
                                                                    "totalYearsOfExperience"
                                                                ].errorMsg
                                                            }
                                                        </p>
                                                    )}
                                            </div>
                                            <div className="col-12 md:col-4 lg:col-4">
                                                <label className="font-bold block mb-2">
                                                    Previous working company
                                                </label>
                                                <InputText
                                                    onChange={(e) => {
                                                        onTextChange(
                                                            e.target.value,
                                                            "previousWorkingCompany",
                                                            this,
                                                            ConsultantFields,
                                                            consultant,
                                                            formValidations,
                                                            "consultant",
                                                            "formValidations"
                                                        );
                                                    }}
                                                    value={
                                                        consultant.previousWorkingCompany
                                                    }
                                                />
                                                {formValidations &&
                                                    !formValidations.fields[
                                                        "previousWorkingCompany"
                                                    ].isValid && (
                                                        <p className="p-error">
                                                            {
                                                                formValidations
                                                                    .fields[
                                                                    "previousWorkingCompany"
                                                                ].errorMsg
                                                            }
                                                        </p>
                                                    )}
                                            </div>
                                            <div className="col-12 md:col-4 lg:col-4">
                                                <label className="font-bold block mb-2">
                                                    Previous Work Experience
                                                    Title
                                                </label>
                                                <InputText
                                                    onChange={(e) => {
                                                        onTextChange(
                                                            e.target.value,
                                                            "previousWorkExperiencTitle",
                                                            this,
                                                            ConsultantFields,
                                                            consultant,
                                                            formValidations,
                                                            "consultant",
                                                            "formValidations"
                                                        );
                                                    }}
                                                    value={
                                                        consultant.previousWorkExperiencTitle
                                                    }
                                                />
                                                {formValidations &&
                                                    !formValidations.fields[
                                                        "previousWorkExperiencTitle"
                                                    ].isValid && (
                                                        <p className="p-error">
                                                            {
                                                                formValidations
                                                                    .fields[
                                                                    "previousWorkExperiencTitle"
                                                                ].errorMsg
                                                            }
                                                        </p>
                                                    )}
                                            </div>
                                            <div className="col-12 md:col-4 lg:col-4">
                                                <label className="font-bold block mb-2">
                                                    Previous Work Experience
                                                    Technologies
                                                </label>
                                                <InputText
                                                    onChange={(e) => {
                                                        onTextChange(
                                                            e.target.value,
                                                            "previousCompanyWorkExperiencTechnologies",
                                                            this,
                                                            ConsultantFields,
                                                            consultant,
                                                            formValidations,
                                                            "consultant",
                                                            "formValidations"
                                                        );
                                                    }}
                                                    value={
                                                        consultant.previousCompanyWorkExperiencTechnologies
                                                    }
                                                />
                                                {formValidations &&
                                                    !formValidations.fields[
                                                        "previousCompanyWorkExperiencTechnologies"
                                                    ].isValid && (
                                                        <p className="p-error">
                                                            {
                                                                formValidations
                                                                    .fields[
                                                                    "previousCompanyWorkExperiencTechnologies"
                                                                ].errorMsg
                                                            }
                                                        </p>
                                                    )}
                                            </div>
                                            <div className="col-12 md:col-4 lg:col-4">
                                                <label className="font-bold block mb-2">
                                                    Total Years Of Experience In
                                                    Previous Company
                                                </label>
                                                <InputText
                                                    onChange={(e) => {
                                                        onTextChange(
                                                            e.target.value,
                                                            "previousCompanyYearsOfExperience",
                                                            this,
                                                            ConsultantFields,
                                                            consultant,
                                                            formValidations,
                                                            "consultant",
                                                            "formValidations"
                                                        );
                                                    }}
                                                    value={
                                                        consultant.previousCompanyYearsOfExperience
                                                    }
                                                />
                                                {formValidations &&
                                                    !formValidations.fields[
                                                        "previousCompanyYearsOfExperience"
                                                    ].isValid && (
                                                        <p className="p-error">
                                                            {
                                                                formValidations
                                                                    .fields[
                                                                    "previousCompanyYearsOfExperience"
                                                                ].errorMsg
                                                            }
                                                        </p>
                                                    )}
                                            </div>
                                        </>
                                    )}
                                </div>
                                <br />
                                <h6>Marketing Details</h6>
                                <div className="p-grid row pl-6 pr-6">
                                    <div className="col-12 md:col-4 lg:col-4">
                                        <label className="font-bold block mb-2">
                                            Marketing Email
                                            <span className="sp-required">
                                                *
                                            </span>
                                        </label>
                                        <InputText
                                            onChange={(e) => {
                                                onEmailChange(
                                                    e.target.value,
                                                    "marketingMailId",
                                                    this,
                                                    ConsultantFields,
                                                    consultant,
                                                    formValidations,
                                                    "consultant",
                                                    "formValidations"
                                                );
                                            }}
                                            value={consultant.marketingMailId}
                                        />

                                        {formValidations &&
                                            !formValidations.fields[
                                                "marketingMailId"
                                            ].isValid && (
                                                <p className="p-error">
                                                    {
                                                        formValidations.fields[
                                                            "marketingMailId"
                                                        ].errorMsg
                                                    }
                                                </p>
                                            )}
                                    </div>
                                    <div className="col-12 md:col-4 lg:col-4">
                                        <label className="font-bold block mb-2">
                                            Marketing Mail Password
                                            <span className="sp-required">
                                                *
                                            </span>
                                        </label>
                                        <InputText
                                            //  type="password"
                                            onChange={(e) => {
                                                onTextChange(
                                                    e.target.value,
                                                    "marketingMailPassword",
                                                    this,
                                                    ConsultantFields,
                                                    consultant,
                                                    formValidations,
                                                    "consultant",
                                                    "formValidations"
                                                );
                                            }}
                                            value={
                                                consultant.marketingMailPassword
                                            }
                                        />
                                        {formValidations &&
                                            !formValidations.fields[
                                                "marketingMailPassword"
                                            ].isValid && (
                                                <p className="p-error">
                                                    {
                                                        formValidations.fields[
                                                            "marketingMailPassword"
                                                        ].errorMsg
                                                    }
                                                </p>
                                            )}
                                    </div>
                                    <div className="col-12 md:col-4 lg:col-4">
                                        <label className="font-bold block mb-2">
                                            Marketing Phone Google Voice
                                            <span className="sp-required">
                                                *
                                            </span>
                                        </label>
                                        <InputText
                                            onChange={(e) => {
                                                onNumberChange(
                                                    e.target.value,
                                                    "marketingPhone",
                                                    this,
                                                    ConsultantFields,
                                                    consultant,
                                                    formValidations,
                                                    "consultant",
                                                    "formValidations"
                                                );
                                            }}
                                            value={consultant.marketingPhone}
                                        />
                                        {formValidations &&
                                            !formValidations.fields[
                                                "marketingPhone"
                                            ].isValid && (
                                                <p className="p-error">
                                                    {
                                                        formValidations.fields[
                                                            "marketingPhone"
                                                        ].errorMsg
                                                    }
                                                </p>
                                            )}
                                    </div>
                                    <div className="col-12 md:col-4 lg:col-4">
                                        <label className="font-bold block mb-2">
                                            Available For Web Camera Interview
                                            <span className="sp-required">
                                                *
                                            </span>
                                        </label>
                                        <Checkbox
                                            inputId={"33"}
                                            checked={
                                                consultant.availableForWebCamInterview
                                            }
                                            onChange={(e) => {
                                                onChoiceChange(
                                                    e.target.checked,
                                                    "availableForWebCamInterview",
                                                    this,
                                                    ConsultantFields,
                                                    consultant,
                                                    formValidations,
                                                    "consultant",
                                                    "formValidations"
                                                );
                                            }}
                                        ></Checkbox>
                                        {/* <label htmlFor={'33'} className="p-checkbox-label p-name">{''}</label> */}

                                        {formValidations &&
                                            !formValidations.fields[
                                                "availableForWebCamInterview"
                                            ].isValid && (
                                                <p className="p-error">
                                                    {
                                                        formValidations.fields[
                                                            "availableForWebCamInterview"
                                                        ].errorMsg
                                                    }
                                                </p>
                                            )}
                                    </div>
                                </div>
                                <br />
                                <h6>Attachments</h6>
                                <div className="p-grid row pl-6 pr-6">
                                    <div className="col-12 md:col-4 lg:col-4">
                                        <label className="font-bold block mb-2">
                                            Driving License
                                        </label>
                                        <span
                                            onClick={() => {
                                                this.showPreviewDoc(
                                                    consultant.drivingLicenseURL
                                                );
                                            }}
                                        >
                                            {consultant.drivingLicenseURL?.name}
                                        </span>
                                        <FileUpload
                                            id="drivingLicenseURL"
                                            multiple={false}
                                            accept={"documents"}
                                            afterFilesUploaded={(files) => {
                                                if (files.length) {
                                                    onFileChange(
                                                        files[0],
                                                        "drivingLicenseURL",
                                                        this,
                                                        ConsultantFields,
                                                        consultant,
                                                        formValidations,
                                                        "consultant",
                                                        "formValidations"
                                                    );
                                                }
                                            }}
                                            inputRef={this.fileUploadRef}
                                        />

                                        {formValidations &&
                                            !formValidations.fields[
                                                "drivingLicenseURL"
                                            ].isValid && (
                                                <p className="p-error">
                                                    {
                                                        formValidations.fields[
                                                            "drivingLicenseURL"
                                                        ].errorMsg
                                                    }
                                                </p>
                                            )}
                                    </div>
                                    <div className="col-12 md:col-4 lg:col-4">
                                        <label className="font-bold block mb-2">
                                            State ID
                                        </label>
                                        <span
                                            onClick={() => {
                                                this.showPreviewDoc(
                                                    consultant.stateIdURL
                                                );
                                            }}
                                        >
                                            {consultant.stateIdURL?.name}
                                        </span>
                                        <FileUpload
                                            id="stateIdURL"
                                            multiple={false}
                                            accept={"documents"}
                                            afterFilesUploaded={(files) => {
                                                if (files.length) {
                                                    onFileChange(
                                                        files[0],
                                                        "stateIdURL",
                                                        this,
                                                        ConsultantFields,
                                                        consultant,
                                                        formValidations,
                                                        "consultant",
                                                        "formValidations"
                                                    );
                                                }
                                            }}
                                            inputRef={this.fileUploadRef}
                                        />

                                        {formValidations &&
                                            !formValidations.fields[
                                                "stateIdURL"
                                            ].isValid && (
                                                <p className="p-error">
                                                    {
                                                        formValidations.fields[
                                                            "stateIdURL"
                                                        ].errorMsg
                                                    }
                                                </p>
                                            )}
                                    </div>
                                    <div className="col-12 md:col-4 lg:col-4">
                                        <label className="font-bold block mb-2">
                                            I94
                                            <Required />
                                        </label>

                                        <span
                                            onClick={() => {
                                                this.showPreviewDoc(
                                                    consultant.i94URL
                                                );
                                            }}
                                        >
                                            {consultant.i94URL?.name}
                                        </span>
                                        <FileUpload
                                            id="i94URL"
                                            multiple={false}
                                            accept={"documents"}
                                            afterFilesUploaded={(files) => {
                                                if (files.length) {
                                                    onFileChange(
                                                        files[0],
                                                        "i94URL",
                                                        this,
                                                        ConsultantFields,
                                                        consultant,
                                                        formValidations,
                                                        "consultant",
                                                        "formValidations"
                                                    );
                                                }
                                            }}
                                            inputRef={this.fileUploadRef}
                                        />

                                        {formValidations &&
                                            !formValidations.fields["i94URL"]
                                                .isValid && (
                                                <p className="p-error">
                                                    {
                                                        formValidations.fields[
                                                            "i94URL"
                                                        ].errorMsg
                                                    }
                                                </p>
                                            )}
                                    </div>

                                    <div className="col-12 md:col-4 lg:col-4">
                                        <label className="font-bold block mb-2">
                                            Work Authorization Doc
                                            <Required />
                                        </label>

                                        <span
                                            onClick={() => {
                                                this.showPreviewDoc(
                                                    consultant.workAuthorizationURL
                                                );
                                            }}
                                        >
                                            {
                                                consultant.workAuthorizationURL
                                                    ?.name
                                            }
                                        </span>
                                        <FileUpload
                                            id="workAuthorizationURL"
                                            multiple={false}
                                            accept={"documents"}
                                            afterFilesUploaded={(files) => {
                                                if (files.length) {
                                                    onFileChange(
                                                        files[0],
                                                        "workAuthorizationURL",
                                                        this,
                                                        ConsultantFields,
                                                        consultant,
                                                        formValidations,
                                                        "consultant",
                                                        "formValidations"
                                                    );
                                                }
                                            }}
                                            inputRef={this.fileUploadRef}
                                        />
                                        {formValidations &&
                                            !formValidations.fields[
                                                "workAuthorizationURL"
                                            ].isValid && (
                                                <p className="p-error">
                                                    {
                                                        formValidations.fields[
                                                            "workAuthorizationURL"
                                                        ].errorMsg
                                                    }
                                                </p>
                                            )}
                                    </div>
                                    <div className="col-12 md:col-4 lg:col-4">
                                        <label className="font-bold block mb-2">
                                            Passport
                                            <Required />
                                        </label>

                                        <span
                                            onClick={() => {
                                                this.showPreviewDoc(
                                                    consultant.passportURL
                                                );
                                            }}
                                        >
                                            {consultant.passportURL?.name}
                                        </span>
                                        <FileUpload
                                            id="passportURL"
                                            multiple={false}
                                            accept={"documents"}
                                            afterFilesUploaded={(files) => {
                                                if (files.length) {
                                                    onFileChange(
                                                        files[0],
                                                        "passportURL",
                                                        this,
                                                        ConsultantFields,
                                                        consultant,
                                                        formValidations,
                                                        "consultant",
                                                        "formValidations"
                                                    );
                                                }
                                            }}
                                            inputRef={this.fileUploadRef}
                                        />

                                        {formValidations &&
                                            !formValidations.fields[
                                                "passportURL"
                                            ].isValid && (
                                                <p className="p-error">
                                                    {
                                                        formValidations.fields[
                                                            "passportURL"
                                                        ].errorMsg
                                                    }
                                                </p>
                                            )}
                                    </div>
                                    <div className="col-12 md:col-4 lg:col-4">
                                        <label className="font-bold block mb-2">
                                            Passphoto
                                            <Required />
                                        </label>

                                        <span
                                            onClick={() => {
                                                this.showPreviewDoc(
                                                    consultant.passphotoURL
                                                );
                                            }}
                                        >
                                            {consultant.passphotoURL?.name}
                                        </span>
                                        <FileUpload
                                            id="passphotoURL"
                                            multiple={false}
                                            accept={"documents"}
                                            afterFilesUploaded={(files) => {
                                                if (files.length) {
                                                    onFileChange(
                                                        files[0],
                                                        "passphotoURL",
                                                        this,
                                                        ConsultantFields,
                                                        consultant,
                                                        formValidations,
                                                        "consultant",
                                                        "formValidations"
                                                    );
                                                }
                                            }}
                                            inputRef={this.fileUploadRef}
                                        />

                                        {formValidations &&
                                            !formValidations.fields[
                                                "passphotoURL"
                                            ].isValid && (
                                                <p className="p-error">
                                                    {
                                                        formValidations.fields[
                                                            "passphotoURL"
                                                        ].errorMsg
                                                    }
                                                </p>
                                            )}
                                    </div>
                                </div>

                                <hr />

                                <div>
                                    <Button
                                        label="Cancel"
                                        className="p-button-text hover:bg-primary "
                                        onClick={() => {
                                            this.props.history.push(
                                                "/admin/content-writer"
                                            );
                                        }}
                                    />
                                    <Button
                                        label={"Submit"}
                                        onClick={this.addOrUpdate}
                                        autoFocus
                                        className="p-button-primary ml-3"
                                    />
                                </div>
                            </div>
                        )}
                        <Toast
                            ref={(el) => (this.toast = el)}
                            position="bottom-right"
                        />
                        {(this.state.isLoading || this.props.isLoading) && (
                            <LoadingComponent />
                        )}
                    </div>
                </Card>
            </NavFooter>
        );
    }
}

const mapStateToProps = (state) => ({
    orgId: state?.orgData?.orgId,
});

export default BaseComponent(EmailConsultantDetails, mapStateToProps, {});
