import React, { Component } from 'react'
import { DOCUMENT_EDITING } from '../../../Utils/constants';
import { Button } from 'primereact/button';
import ServerlessService from '../../../Service/ServerlessService';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Authentication from '../../../session/index'
import FileUpload from '../../common/fileUpload';
import axios from 'axios';
import { Toast } from 'primereact/toast';
import LoadingComponent from '../../../LoadingComponent';
import { v4 as uuidv4 } from 'uuid';
import { logOut } from '../../../Utils';

class DocumentEditorActions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            editedDocURL: null,
            uploadedFilesInfo: []
        }
        this.fileUploadRef = React.createRef();



        this.serverlessService = new ServerlessService();
    }


    uploadFilesToS3 = async (files) => {
        let url = 'https://v2ao5dt0v3.execute-api.ap-south-1.amazonaws.com/prod/v1/data/presign/url';
        let cloudFrontEndpoint = 'https://d1wqfechwjsewm.cloudfront.net/';
        let uploadedFiles = [];
        let thisObj = this;
        for (let i = 0; i < files.length; i++) {



            if (!files[i].status) {


                let { data } = await axios.post(url, {
                    "type": "insert",
                    "key": thisObj.props.user.user.orgId + '/' + uuidv4() + files[i].name.replace(/\s/g, "")
                });
                let uploadStatus = await axios.put(data.data.presignedURL, files[i], {
                    headers: {
                        'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
                        "Content-Type": "binary/octet-stream",
                        "Access-Control-Allow-Origin": "*",
                    }
                });
                uploadedFiles.push({
                    path: cloudFrontEndpoint + data.data.contentStorageKey,
                    key: data.data.contentStorageKey,
                    name: files[i].name,
                    type: files[i].type,
                    size: files[i].size,
                    status: uploadStatus.status == 200 ? true : false
                });


            } else {
                uploadedFiles.push(files[i]);
            }
        }

        return uploadedFiles;
    }


    changeStatus = () => {
        this.setState({
            isLoading: true
        });


        const url = `/consultants/document-editor/`;
        this.serverlessService.put(url, {
            _id: this.props.documentEditingRequest?._id,
            status: DOCUMENT_EDITING.INPROGRESS
        }, true).then((res) => {

            if (res && res.status && res.res.status) {
                this.setState({
                    isLoading: false
                }, this.props.onChangeStatusToInProgress)

            } else {

                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occurred', detail: res.res.message, life: 3000 });
            }

        }).catch(e => {
            if (e?.response?.status > 400 && e?.response?.status < 500) {
                logOut()
            }
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occurred', detail: e.message, life: 3000 });
        })

    }


    uploadAndSubmit = async () => {

        if (!this.state.uploadedFilesInfo.length) {

            return this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please upload document', life: 3000 });

        }


        let uploadedFiles = await this.uploadFilesToS3(this.state.uploadedFilesInfo);


        let fileUploadRef = this.fileUploadRef;



        this.setState({
            isLoading: true
        });
        const url = `/consultants/document-editor/`;
        this.serverlessService.put(url, {
            _id: this.props.documentEditingRequest?._id,
            documentURL: uploadedFiles[0],
            status: DOCUMENT_EDITING.SUBMITTED
        }, true).then((res) => {
            fileUploadRef.current.value = null;

            if (res && res.status && res.res.status) {
                this.setState({
                    isLoading: false
                }, this.props.onUploaded)

            } else {

                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occurred', detail: res.res.message, life: 3000 });
            }

        }).catch(e => {
            if (e?.response?.status > 400 && e?.response?.status < 500) {
                logOut()
            }
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occurred', detail: e.message, life: 3000 });
        })

    }




    _afterFilesUploaded = (files) => {
        for (let i = 0; i < files.length; i++) {
            this.setState((prevState, prevProps) => ({
                uploadedFilesInfo: [...prevState.uploadedFilesInfo, files[i]]
            }));
        }
    }



    render() {

        const { documentEditingRequest } = this.props;
        return (<>
            {documentEditingRequest?.status == DOCUMENT_EDITING.SUBMITTED && <p>Work completed</p>}
            {
                (
                    documentEditingRequest?.status == DOCUMENT_EDITING.REQUESTED ||
                    documentEditingRequest?.status == DOCUMENT_EDITING.REREQUESTED
                ) && <>
                    <p>Accept request and start work</p>

                    <Button label='Accept' className="p-button-primary mt-3" onClick={this.changeStatus}></Button>
                </>
            }
            {
                documentEditingRequest?.status == DOCUMENT_EDITING.INPROGRESS && <>
                    <FileUpload id="returnWindow"
                        multiple={false}
                        accept={'documents'}
                        afterFilesUploaded={this._afterFilesUploaded}
                        inputRef={this.fileUploadRef}
                    />

                    <Button label='Upload and Submit' className="p-button-primary ml-3" onClick={this.uploadAndSubmit}></Button>
                </>
            }
            <Toast
                ref={(el) => (this.toast = el)}
                position="bottom-right"
            />
            {(this.state.isLoading || this.props.isLoading) && (
                <LoadingComponent />
            )}
        </>
        )
    }
}






const mapStateToProps = (state) => ({
    user: state.user,
    userType: state?.user?.userType,
    authenticated: state?.user?.authenticated,
    isSessionChecked: state?.user?.isSessionChecked,
});


export default connect(mapStateToProps, {

})(Authentication(withRouter(DocumentEditorActions)));
