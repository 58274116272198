import { BasicLazyParams } from "../../Utils/constants";
import { ACTIONS } from "../actionTypes";
export default (
    state = {
        tags: [],
        allconsultants: [],
        isLoading: false,
        error: false,
        errorMessage: "",
        lazyParams: {
            ...BasicLazyParams,
            sortField: "-createdAt",
            sortOrder: 1,
        },
        globalSearch: "",

        selectedStatus: '',
        totalRecords: 0,
    },
    action
) => {
    switch (action.type) {
        case ACTIONS.REQUEST_ALL_CONSULTANTS:
            return {
                ...state,
                lazyParams: {
                    ...state.lazyParams,
                    ...action?.data?.lazyParams,
                },
                globalSearch: action?.data?.globalSearch || state?.globalSearch,
                selectedStatus: action?.data?.status,
                isLoading: true,
                error: false,
            };
        case ACTIONS.RECEIVED_ALL_CONSULTANTS:
            return {
                ...state,
                isLoading: false,
                allconsultants: action?.data?.data,
                totalRecords: action?.data?.totalRecords,
            };
        case ACTIONS.ERROR_ALL_CONSULTANTS:
            return {
                ...state,
                isLoading: false,
                error: true,
                errorMessage: action?.err?.message,
            };
        case ACTIONS.CLEAR_ALL_CONSULTANTS:
            return {
                isLoading: false,
                error: false,
                errorMessage: "",
                allconsultants: [],
            };

        default:
            return state;
    }
};
