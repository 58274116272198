import React, { Component } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { v4 as uuidv4 } from "uuid";

import { Toast } from "primereact/toast";
import LoadingComponent from "../../../LoadingComponent";
import ServerlessService from "../../../Service/ServerlessService";
import {
    getFormFields,
    isFormValid,
    onTextChange,
} from "../../../Utils/formHelper";
import { logOut, trimObj } from "../../../Utils";
import ConsultantFields from "./addConsultant.json";
import BaseComponent from "../../common/BaseComponent";
import AddResourceWidget from "./addResourceWidget";
import "./styles.scss";
import { addResource } from "../../../store/actions";
import { InputTextarea } from "primereact/inputtextarea";

class AddResource extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(
            ConsultantFields,
            this.props.editData || {}
        );
        this.state = {
            consultant: {
                ...this.formFields.data,
                resources: [],
            },
            formValidations: this.formFields.formValidations,
        };
        this.serverlessService = new ServerlessService();
    }

    isAdditionalValid = () => {
        let consultant = JSON.parse(JSON.stringify(this.state.consultant));
        let { formValidations } = this.state;
        let { resources } = consultant;

        let isValid = true;

        if (!(resources && resources.length)) {
            formValidations.fields["resources"] = {
                ...formValidations.fields["resources"],
                isValid: false,
                errorMsg: `Please assign section`,
            };
            isValid = false;
        } else if (!resources.every((i) => i.assigned)) {
            isValid = false;
            formValidations.fields["resources"] = {
                ...formValidations.fields["resources"],
                isValid: false,
                errorMsg: `Please assign section`,
            };
        } else {
            formValidations.fields["resources"] = {
                ...formValidations.fields["resources"],
                isValid: true,
                errorMsg: ``,
            };
        }

        this.setState((prevState) => {
            return {
                formValidations,
            };
        });

        return isValid;
    };

    addOrUpdate = async () => {
        // debugger
        let consultant = trimObj(this.state.consultant);
        console.log(consultant)
        let isValid = true;
        const formStatus = isFormValid(
            ConsultantFields,
            this.formFields.formValidations,
            consultant
        );
        console.log(formStatus)

        let isCompleteFormValid = true;

        if (!this.isAdditionalValid()) {
            isCompleteFormValid = false;
        }

        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
        }
        if (isValid && isCompleteFormValid) {
            consultant.resources = consultant.resources.map((ia) => ia.data);
            await this.props.addResource(consultant);
        }
    };

    onAssignClick = (curriculum) => {
        let resources = this.state.consultant.resources.map((item) => {
            if (item.id === curriculum.id) {
                return curriculum;
            } else {
                return item;
            }
        });

        this.setState(
            (prevState) => {
                return {
                    consultant: {
                        ...prevState.consultant,
                        resources: resources,
                    },
                };
            },
            () => {
                if (curriculum.assigned) {
                    this.isAdditionalValid();
                }
            }
        );
    };

    addMore = () => {
        // debugger;
        if (this.state.consultant.resources.every((s) => s.assigned === true)) {
            this.setState((prevState) => {
                return {
                    consultant: {
                        ...prevState.consultant,
                        resources: [
                            ...prevState.consultant.resources,
                            { id: uuidv4(), assigned: false },
                        ],
                    },
                };
            });
        } else {
            this.toast.show({
                severity: "warn",
                summary: "Warning",
                detail: "Please assign subject to add more",
                life: 3000,
            });
        }
    };

    removeTempSubject = (id) => {
        if (this.state.consultant.resources.length) {
            let resources = this.state.consultant.resources.filter(
                (item) => item.id != id
            );
            this.setState((prevState) => {
                return {
                    consultant: {
                        ...prevState.consultant,
                        resources,
                    },
                };
            });
        }
    };

    getResorcesById = (id) => {
        this.setState({
            isLoading: true,
        });

        const url = `/consultants/resources/id/${id}`;
        this.serverlessService
            .get(url, true)
            .then((res) => {
                if (res && res.status && res.res.data) {
                    let consultant = res.res.data;
                    consultant.resources = consultant.resources.map((a) => {
                        return {
                            id: uuidv4(),
                            assigned: true,
                            data: a,
                        };
                    });

                    this.setState((prevState) => {
                        return {
                            consultant,
                            isLoading: false,
                        };
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });

                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    componentDidMount() {
        if (this.props?.params?.operation == "edit") {
            if (this.props?.params?.id) {
                this.getResorcesById(this.props?.params.id);
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.consultantAddedOrUpdated !=
            this.props.consultantAddedOrUpdated
        ) {
            this.props.history.push("/admin/learning-resources");
        }
    }
    render() {
        const { consultant, formValidations } = this.state;
        return (
            <div className="h-screen overflow-auto ss-data-table">
                <div className="font-bold block mb-2">
                    <h1>Learning Resources</h1>
                </div>
                <div className="p-grid row pl-6 pr-6">
                    <div className="col-12 md:col-4 lg:col-4">
                        <label className="font-bold block mb-0">
                            Name<span className="sp-required">*</span>
                        </label>
                        <InputText
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "title",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                            value={consultant.title}
                        />
                        {formValidations &&
                            !formValidations.fields["title"].isValid && (
                                <p className="p-error">
                                    {formValidations.fields["title"].errorMsg}
                                </p>
                            )}
                    </div>

                    <div className="col-12 md:col-4 lg:col-4">
                        <label className="font-bold block mb-0">
                            Description<span className="sp-required">*</span>
                        </label>
                        <InputTextarea
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "description",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                            value={consultant.description}
                        />
                        {formValidations &&
                            !formValidations.fields["description"].isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields["description"]
                                            .errorMsg
                                    }
                                </p>
                            )}
                    </div>
                </div>
                <div className="p-grid row pl-6 pr-6">
                    <div className="col-12 md:col-4 lg:col-10">
                        <label className="font-bold block mb-0">
                            Resources<span className="sp-required">*</span>
                        </label>
                        {this.state.consultant &&
                            this.state.consultant.resources &&
                            this.state.consultant.resources.length
                            ? this.state.consultant.resources.map((temp) => {
                                return (
                                    <div key={temp.id}>
                                        <AddResourceWidget
                                            data={temp.data}
                                            resources={
                                                this.state.consultant
                                                    .resources
                                            }
                                            key={temp.id}
                                            id={temp.id}
                                            onAssignClick={this.onAssignClick}
                                            removeTempSubject={
                                                this.removeTempSubject
                                            }
                                        />
                                    </div>
                                );
                            })
                            : null}

                        <Button
                            icon="pi pi-plus-circle"
                            className="p-button-rounded addmore m-2"
                            onClick={this.addMore}
                        />
                        {formValidations &&
                            !formValidations.fields["resources"].isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields["resources"]
                                            .errorMsg
                                    }
                                </p>
                            )}
                    </div>
                </div>
                <div className="mt-3">
                    <Button
                        label="Cancel"
                        className="p-button-text hover:bg-primary mr-3"
                        onClick={() => {
                            this.props.history.push(
                                "/admin/learning-resources"
                            );
                        }}
                    />
                    <Button
                        label={
                            this.props?.params?.operation == "edit"
                                ? "Update"
                                : "Add"
                        }
                        onClick={this.addOrUpdate}
                        autoFocus
                        className="p-button-primary "
                    />
                </div>

                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
                {(this.state.isLoading || this.props.isLoading) && (
                    <LoadingComponent />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({

    lazyParams: state?.learningResources?.lazyParams,
    isLoading: state?.learningResources?.isLoading,
    consultantAddedOrUpdated:
        state?.learningResources?.consultantAddedOrUpdated,
});

export default BaseComponent(AddResource, mapStateToProps, {
    addResource,

});
