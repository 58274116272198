import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import React, { Component } from "react";
import { PackagesCount } from "../../../Utils/constants";
import ServerlessService from "../../../Service/ServerlessService";
import {
    getFormFields,
    isFormValid,
    onNumberChange,
    onTextChange,
    trimAll,
} from "../../../Utils/formHelper";
import PackagesFeeFields from "./packageFee.json";
import { logOut } from "../../../Utils";

class PackagesFee extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(PackagesFeeFields, {});
        this.state = {
            newPackagesData: {
                packages: [...PackagesCount].map((a) => a),
            },
        };
        this.serverlessService = new ServerlessService();
    }

    onHide = () => {
        this.setState({
            visible: false,
            newPackagesData: null,
        });
    };

    addOrEdit = (a) => {
        let { newPackagesData } = this.state;

        let isValid = true;

        newPackagesData.packages.forEach((f) => {
            if (!f.credits || f.credits == 0 || f.credits == "") {
                isValid = false;
            }
        });

        if (!isValid) {
            alert("please enter all the fee detail.");
        }

        if (this.state.newPackagesData?._id) {
            this.setState({
                isLoading: true,
            });

            const url = `/package-fee/update`;
            this.serverlessService
                .post(url, this.state.newPackagesData, true)
                .then((res) => {

                    if (res && res.status && res.res.status) {
                        this.setState(
                            {
                                isLoading: false,
                            },
                            this.getPackageFee
                        );
                    } else {
                        this.setState({
                            isLoading: false,
                        });

                        this.toast.show({
                            severity: "error",
                            summary: "Some error occurred",
                            detail: res.errMessage,
                            life: 3000,
                        });
                    }
                })
                .catch((e) => {
                    if (e?.response?.status > 400 && e?.response?.status < 500) {
                        logOut()
                    }
                    console.log(e);
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: e.message,
                        life: 3000,
                    });
                });
        } else {
            this.setState({
                isLoading: true,
            });

            const url = `/package-fee/save`;
            this.serverlessService
                .post(url, this.state.newPackagesData, true)
                .then((res) => {

                    if (res && res.status && res.res.status) {
                        this.setState({
                            isLoading: false,
                        });
                        console.log("added");
                    } else {
                        this.setState({
                            isLoading: false,
                        });

                        this.toast.show({
                            severity: "error",
                            summary: "Some error occurred",
                            detail: res.errMessage,
                            life: 3000,
                        });
                    }
                })
                .catch((e) => {
                    if (e?.response?.status > 400 && e?.response?.status < 500) {
                        logOut()
                    }
                    console.log(e);
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: e.message,
                        life: 3000,
                    });
                });
        }
    };

    getPackageFee = () => {
        this.setState({
            isLoading: true,
        });

        const url = `/package-fee`;

        this.serverlessService
            .get(url, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    console.log(res.res.data[0], "get packages data");


                    this.setState({
                        isLoading: false,
                        newPackagesData: res.res.data[0],
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    componentDidMount() {
        this.getPackageFee();
    }

    onFeeChange = (id, value) => {
        this.setState((prevState) => {
            return {
                newPackagesData: {
                    ...prevState.newPackagesData,
                    packages: prevState.newPackagesData.packages.map((day) => {
                        if (day.id == id) {
                            day.amount = value;
                        }
                        return day;
                    }),
                },
            };
        });
    };
    render() {
        console.log(this.state.newPackagesData?.packages, "newPackage");
        return (
            <>
                <div className="surface-ground">
                    <div className="px-6 py-5">
                        <div className="surface-card p-4 shadow-2 border-round">
                            <div className="font-medium text-3xl text-900 mb-3">
                                Packages Fee
                            </div>
                            <ul className="list-none p-0 m-0 border-top-1 border-300">
                                {this.state.newPackagesData?.packages?.map(
                                    (pack) => (
                                        <li className="flex align-items-center py-3 px-2 flex-wrap">
                                            <>
                                                <div className="text-500 w-full md:w-2 font-medium">
                                                    {pack?.credits} -credits
                                                </div>
                                                <div className="text-900 w-full md:w-10 line-height-3">
                                                    Rs.{" "}
                                                    <InputNumber
                                                        onChange={(e) => {
                                                            this.onFeeChange(
                                                                pack.id,
                                                                e.value
                                                            );
                                                        }}
                                                        value={pack?.amount}
                                                    />
                                                </div>
                                            </>
                                        </li>
                                    )
                                )}
                            </ul>
                            <div>
                                <Button
                                    label={
                                        this.state.newPackagesData?._id
                                            ? "Update"
                                            : "Add"
                                    }
                                    onClick={this.addOrEdit}
                                    autoFocus
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default PackagesFee;
