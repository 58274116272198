import { Dialog } from 'primereact/dialog'
import React, { Component } from 'react'
import BaseComponent from '../../../common/BaseComponent'

 class SubmissionStatus extends Component {
    
    goBack = () => {
        this.props.history.goBack()
    }
    
    
    
    render() {
        return (
            <Dialog
                header="Submission Status"
                visible={true}
                draggable={false}
                style={{ width: "80%", height: "50vh" }}
                onHide={this.goBack}
            >

                <p>Submission tracker</p>


            </Dialog>
        )
    }
}
export default BaseComponent(SubmissionStatus)