import { ACTIONS } from '../actionTypes';
export default (state = {

    logoRectangle: null,
    logoSquare: null,
    orgId: null,
    orgName: null,
    isLoading: false,
    error: false,
    errorMessage: '',
}, action) => {
    switch (action.type) {
        case ACTIONS.REQUEST_ORG:
            return {
                ...state,
                isLoading: true,
                error: false,
            };
        case ACTIONS.RECEIVED_ORG:
            return {
                ...state,
                isLoading: false,
                assignedModules: action.data.assignedModules,
                logoRectangle: action.data.logoRectangle,
                logoSquare: action.data.logoSquare,
                orgId: action.data._id,
                orgName: action.data.orgName,
                isSuper: action.data.isSuper,
                orgCode: action.data.orgCode,
                emailPricings: action.data.emailPricings,
                smsPricings: action.data.smsPricings,
                contactNumber1: action.data.contactNumber1,
                contactNumber2: action.data.contactNumber2,
                contactEmail: action.data.contactEmail,
                address: action.data.address,
                city: action.data.city,
                state: action.data.state,
                country: action.data.country,
                pincode: action.data.pincode,
                logoVisible: action.data.logoVisible,
                brandNameVisible: action.data.brandNameVisible,
                logoSize: action.data.logoSize,
                theme: action.data.theme,
                error: false
            };
        case ACTIONS.ERROR_ORG:
            return {
                ...state,
                isLoading: false,
                error: true,
                errorMessage: action.err.message
            };
        case ACTIONS.CLEAR_ORG_DATA:
            return {
                orgInfo: null,
                isLoading: false,
                error: false,
                errorMessage: '',
            };

        case ACTIONS.REQUEST_SAVE_PRICING:
            return {
                ...state,
                isLoading: true,
                error: false,
            };
        case ACTIONS.RECEIVED_SAVE_PRICING:
            return {
                ...state,
                isLoading: false,
                emailPricings: action.data.emailPricings,
                smsPricings: action.data.smsPricings,
                error: false
            };
        case ACTIONS.ERROR_SAVE_PRICING:
            return {
                ...state,
                isLoading: false,
                error: true,
                errorMessage: action.err.message
            };
        case ACTIONS.REQUEST_UPDATE_ORG:
            return {
                ...state,
                isLoading: true,
                error: false,
            };
        case ACTIONS.RECEIVED_UPDATE_ORG:
            return {
                ...state,
                isLoading: false,
                logoVisible: action.data.logoVisible,
                brandNameVisible: action.data.brandNameVisible,
                theme: action.data.theme,
                logoSquare: action.data.logoSquare,
                logoSize: action.data.logoSize,
                error: false
            };
        case ACTIONS.ERROR_UPDATE_ORG:
            return {
                ...state,
                isLoading: false,
                error: true,
                errorMessage: action.err.message
            };
        default:
            return state;
    }
}