import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import LoadingComponent from "../../../LoadingComponent";
import { CONSULTANT_STATUS } from "../../../Utils/constants";
import { Dropdown } from "primereact/dropdown";
import { BasicLazyParams } from "../../../Utils/constants";
import ServerlessService from "../../../Service/ServerlessService";
import { Tooltip } from "primereact/tooltip";
import Authorize, { PERMISSIONS } from "../../../session/authorize";
import ConsultantNotes from "../documentPreperator/consultantNotes";
import ConsultantMonitor from "../documentPreperator/consultantMonitor";
import { Card } from "primereact/card";
import BaseComponent from "../../common/BaseComponent";
import {
    getAllConsultants,
    getLearningPlans,
    resetLazyAllConsultants,
} from "../../../store/actions";
import { BreadCrumb } from "primereact/breadcrumb";
import { Toolbar } from "primereact/toolbar";
import { MultiSelect } from "primereact/multiselect";
import AssignContentWriter from "../consultants/assignContentWriter";
import AssignDocPreparator from './../contentWriter/assignDocPreparotor';
import AssignToBenchSales from "../salesTeamHead/assignToBenchSales";
class Consultants extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowView: false,
            viewTable: [],
            visible: false,
            isShowConfirmationDialog: false,
            consultants: [],
            customers: [],
            isShowAssignTable: false,
            isShowUnAssignTable: false,
            editData: null,
            id: null,
            renewalData: null,
            tableHistorysData: null,
            isShowRenewal: false,
            globalSearch: "",
            lazyParams: {
                ...BasicLazyParams,
                sortField: "createdAt",
                sortOrder: 1,
            },
            selectedReason: "",
            operation: null,
            prevLocation: props.location,
            selectedStatus: "",
        };
        this.serverlessService = new ServerlessService();
    }

    onHide = () => {
        this.setState({
            visible: false,
            isShowRenewal: false,
            editData: null,
        });
    };

    componentDidMount() {
        if (!this.props.allconsultants?.length) {
            this.props.getAllConsultants({ status: this.props.selectedStatus });
            this.props.getLearningPlans();
        }
    }

    componentDidUpdate(prevProps) {
        const prevPathname = this.props.prevPathname;
    }

    onGlobalSearch = (e) => {
        this.props.getAllConsultants({
            globalSearch: e.target.value,
            status: this.props.selectedStatus,
        });
    };

    onPage = (event) => {
        this.props.getAllConsultants({
            lazyParams: event,
            status: this.props.selectedStatus,
        });
    };

    onSort = (event) => {
        this.props.getAllConsultants({
            lazyParams: event,
            status: this.props.selectedStatus,
        });
    };

    onFilter = (event) => {
        event["first"] = 0;
        this.props.getAllConsultants({
            lazyParams: event,
            status: this.props.selectedStatus,
        });
    };

    showConsultantNotes = (rowData) => {
        this.setState({
            isShowConsultantNotes: true,
            editData: rowData,
        });
    };

    showConsultantMonitor = (rowData) => {
        this.setState({
            isShowConsultantMonitor: true,
            editData: rowData,
        });
    };

    registerUser = () => {
        const { _id } = this.state.rowData;
        const url = `/consultants/user/create/${_id}`;

        this.setState({
            isLoading: true,
        });

        this.serverlessService
            .get(url, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState(
                        {
                            isLoading: false,
                            showUserDialog: false,
                        },
                        () => {
                            this.props.getAllConsultants();
                        }
                    );
                    this.toast.show({
                        severity: "success",
                        summary: "Success",
                        detail: "User Created successfully",
                        life: 3000,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Error",
                        detail: res.message,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };



    onclose = () => {
        this.setState({
            showUserDialog: false,
        });
    };

    assignPlan = (rowData) => {
        if (Array.isArray(rowData.learningplan)) {
            const assignPlan = this.props.data.filter((plan) =>
                rowData.learningplan.includes(plan._id)
            );
            this.setState({ selectedPlan: assignPlan });
        } else {
            console.error("rowData.learningplan is not an array");
        }
    };

    footerContentforUser = () => (
        <div>
            <Button
                label="Cancel"
                className=" p-button-text hover:bg-primary "
                onClick={() => {
                    this.setState({
                        showUserDialog: false,
                    });
                }}
            />
            <Button
                label="Ok"
                onClick={() => {
                    this.registerUser();
                }}
                autoFocus
                className=" p-button-primary "
            />
        </div>
    );

    onPlanChange = (e) => {
        debugger
        this.setState({ selectedPlan: e.value });
    };

    actionsBody = (rowData) => {
        const showAssignToContentWriterButton =
            !rowData.contentWritingAssignedTo;
        const columnSize = showAssignToContentWriterButton ? "col-2" : "col-3";
        return (
            <div className="p-grid row">
                <Authorize permId={PERMISSIONS.ALL_CONSULTANTS_SHOW_CONSULTANT}>
                    <div className="col-2">
                        <span
                            onClick={() => {
                                this.props.history.push(
                                    `/admin/cosultant/view/${rowData._id}`
                                );
                            }}
                            data-pr-tooltip="Show Consultant"
                            data-pr-position="top"
                            className={`p-text abc${rowData._id}`}
                        >
                            <i className="pi pi-eye" />
                        </span>
                        <Tooltip target={`.abc${rowData._id}`} />
                    </div>
                </Authorize>
                {rowData.status === CONSULTANT_STATUS.DOCUMENT_PREPERATING && <Authorize
                    permId={
                        PERMISSIONS.ALL_CONSULTANTS_CHANGE_DOCUMENT_PREPERATION
                    }
                >
                    <div className="col-2">
                        <span
                            onClick={() => {
                                if (rowData.isProfileValid) {
                                    this.setState({
                                        isShowAssignToDocPreparator: true,
                                        selectedCustomer: rowData,
                                    });
                                } else {
                                }
                            }}
                            data-pr-tooltip="Change Document Preparator"
                            data-pr-position="top"
                            className={`p-text tableDelet1e${rowData._id}`}
                        >
                            <i className="pi pi-user-edit" />
                        </span>
                        <Tooltip target={`.tableDelet1e${rowData._id}`} />
                    </div>
                </Authorize>}
                {rowData.status === CONSULTANT_STATUS.CONTENT_WRITING && (
                    <Authorize
                        permId={
                            PERMISSIONS.ALL_CONSULTANTS_CHANGE_CONTENT_WRITER
                        }
                    >
                        <div className="col-2">
                            {!rowData.contentWritingAssignedTo && (
                                <>
                                    <span
                                        onClick={() =>
                                            this.setState({
                                                isShowAssignToContentWriter: true,
                                                selectedCustomer: rowData,
                                            })
                                        }
                                        data-pr-tooltip="Change Content Writer"
                                        data-pr-position="top"
                                        className={`p-text tableDelete${rowData._id}`}
                                    >
                                        <i className="pi pi-user-edit" />
                                    </span>
                                    <Tooltip
                                        target={`.tableDelete${rowData._id}`}
                                        content="Change Content Writer" // Provide content for the tooltip
                                    />
                                </>
                            )}
                        </div>
                    </Authorize>
                )}
                {(rowData.status === CONSULTANT_STATUS.MARKETING || rowData.status === CONSULTANT_STATUS.READY_TO_MARKET) && (
                    <Authorize
                        permId={PERMISSIONS.ALL_CONSULTANTS_CHANGE_BENCH_SALES}
                    >
                        <div className="col-2">
                            <span
                                onClick={() =>
                                    this.setState({
                                        isShowAssignToBenchSales: true,
                                        selectedCustomer: rowData,
                                    })
                                }
                                data-pr-tooltip={
                                    rowData.marketingAssignedTo
                                        ? "Reassign To Bench Sale Recruiter"
                                        : "Assign To Bench Sale Recruiter"
                                }
                                data-pr-position="top"
                                className={`p-text tableDele${rowData._id.substr(
                                    4
                                )}`}
                            >
                                <i className="pi pi-user-edit " />
                            </span>
                            <Tooltip
                                target={`.tableDele${rowData._id.substr(4)}`}
                            />
                        </div>
                    </Authorize>
                )}
                {rowData.isProfileValid && rowData.isUserCreated && (
                    <div className="col-2">
                        <Authorize
                            permId={PERMISSIONS.ALL_CONSULTANTS_ASSIGN_PLAN}
                        >
                            <span
                                onClick={() => {
                                    this.setState(
                                        {
                                            studyPlan: true,
                                            consultandId: rowData._id,
                                        },
                                        () => {
                                            this.assignPlan(rowData);
                                        }
                                    );
                                }}
                                data-pr-tooltip="Assign Study Plan"
                                data-pr-position="top"
                                className={`p-text assign${rowData._id.substr(
                                    4
                                )}`}
                            >
                                <i className="pi pi-envelope" />
                            </span>
                            <Tooltip
                                target={`.assign${rowData._id.substr(4)}`}
                            />
                        </Authorize>
                    </div>
                )}
                {rowData.isProfileValid && !rowData.isUserCreated && (
                    <div className="col-2">
                        <Authorize
                            permId={PERMISSIONS.ALL_CONSULTANTS_USER_CREATE}
                        >
                            <span
                                onClick={() => {
                                    this.setState({
                                        rowData: rowData,
                                        showUserDialog: true,
                                    });
                                }}
                                data-pr-tooltip="Create Login For User Consultant"
                                data-pr-position="top"
                                className={`p-text User${rowData._id.substr(
                                    4
                                )}`}
                            >
                                <i className="pi pi-user-plus" />
                            </span>
                        </Authorize>
                        <Tooltip target={`.User${rowData._id.substr(4)}`} />
                    </div>
                )}
                <Authorize permId={PERMISSIONS.ALL_CONSULTANTS_MONITOR}>
                    <div className="col-2">
                        <span
                            onClick={() => {
                                this.setState({
                                    isShowConsultantMonitor: true,
                                    editData: rowData,
                                });
                            }}
                            data-pr-tooltip="Consultant Monitor"
                            data-pr-position="top"
                            className={`p-text tableHistory${rowData._id}`}
                        >
                            <i className="pi pi-comments" />
                        </span>
                        <Tooltip target={`.tableHistory${rowData._id}`} />
                    </div>
                </Authorize>
                <Authorize permId={PERMISSIONS.ALL_CONSULTANTS_TRACKER}>
                    <div className="col-2">
                        <span
                            onClick={() => {
                                this.props.history.push(
                                    `/admin/cosultant/tracker/${rowData._id}`
                                );
                            }}
                            data-pr-tooltip="Consultant Tracker"
                            data-pr-position="top"
                            className={`p-text tableHistory${rowData._id}`}
                        >
                            <i className="pi pi-building" />
                        </span>
                        <Tooltip target={`.tableHistory${rowData._id}`} />
                    </div>
                </Authorize>
                <Authorize permId={PERMISSIONS.ALL_CONSULTANTS_NOTES}>
                    <div className="col-2">
                        <span
                            onClick={() => {
                                this.setState({
                                    isShowConsultantNotes: true,
                                    editData: rowData,
                                });
                            }}
                            data-pr-tooltip="Notes (Only visible to you.)"
                            data-pr-position="top"
                            className={`p-text tableHistory${rowData._id}`}
                        >
                            <i className="pi pi-book" />
                        </span>
                        <Tooltip target={`.tableHistory${rowData._id}`} />
                    </div>
                </Authorize>

                <Authorize permId={PERMISSIONS.ALL_CONSULTANTS_SUBMISSIONS}>
                    <div className="col-2">
                        <span
                            onClick={() => {
                                this.props.history.push(
                                    `/admin/allconsultant/submission/${rowData._id}`
                                );
                            }}
                            data-pr-tooltip="Submissions"
                            data-pr-position="top"
                            className={`p-text tableHistory${rowData._id}`}
                        >
                            <i className="pi pi-images" />
                        </span>
                        <Tooltip target={`.tableHistory${rowData._id}`} />
                    </div>
                </Authorize>
                <Authorize permId={PERMISSIONS.ALL_CONSULTANTS_INTERVIEWS}>
                    <div className="col-2">
                        <span
                            onClick={() => {
                                this.props.history.push(
                                    `/admin/allconsultant/interview/${rowData._id}`
                                );
                            }}
                            data-pr-tooltip="Interviews"
                            data-pr-position="top"
                            className={`p-text tableHistory${rowData._id}`}
                        >
                            <i className="pi pi-users" />
                        </span>
                        <Tooltip target={`.tableHistory${rowData._id}`} />
                    </div>
                </Authorize>
                <Authorize permId={PERMISSIONS.ALL_CONSULTANTS_SCREENINGCALLS}>
                    <div className="col-2">
                        <span
                            onClick={() => {
                                this.props.history.push(
                                    `/admin/consultant/screeningcalls/${rowData._id}`
                                );
                            }}
                            data-pr-tooltip="Screening Calls"
                            data-pr-position="top"
                            className={`p-text tableHistory${rowData._id}`}
                        >
                            <i className="pi pi-phone" />
                        </span>
                        <Tooltip target={`.tableHistory${rowData._id}`} />
                    </div>
                </Authorize>
            </div>
        );
    };

    getElapsedDays = (createdAt) => {
        const currentTime = new Date();
        const elapsedTime = currentTime - new Date(createdAt);
        const elapsedDays = Math.floor(elapsedTime / (1000 * 60 * 60 * 24));
        return elapsedDays;
    };

    getColorCode = (createdAt) => {
        const currentTime = new Date();
        const elapsedTime = currentTime - new Date(createdAt);

        const elapsedMinutes = elapsedTime / (1000 * 60);

        const percentage = (elapsedMinutes / (60 * 24 * 60)) * 100;

        if (percentage <= 30) {
            return "green";
        } else if (percentage <= 60) {
            return "yellow";
        } else if (percentage <= 90) {
            return "orange";
        } else {
            return "red";
        }
    };
    exportCSV = () => {
        this.dt.exportCSV();
    };

    onFilterChange = (e) => {
        this.setState({ selectedStatus: e.value });
        this.props.getAllConsultants({ status: e.value });
    };

    getStatusDetails(status) {
        switch (status) {
            case CONSULTANT_STATUS.CREATED:
                return {
                    message: "Created",
                    details: "The process has been initiated.",
                };
            case CONSULTANT_STATUS.CONTENT_WRITING:
                return {
                    message: "Content Writing",
                    details: "Content is being written.",
                };
            case CONSULTANT_STATUS.DOCUMENT_PREPERATING:
                return {
                    message: "Document Preparing",
                    details: "Documents are being prepared.",
                };
            case CONSULTANT_STATUS.DOCUMENT_EDITING:
                return {
                    message: "Document Editing",
                    details: "Documents are being edited.",
                };
            case CONSULTANT_STATUS.DOCUMENT_VERIFYING:
                return {
                    message: "Document Verifying",
                    details: "Documents are being verified.",
                };
            case CONSULTANT_STATUS.TECH_PREPARING:
                return {
                    message: "Technical Preparing",
                    details: "Technical aspects are being prepared.",
                };
            case CONSULTANT_STATUS.MARKETING_REVIEW:
                return {
                    message: "Marketing Review",
                    details: "Undergoing marketing review.",
                };
            case CONSULTANT_STATUS.READY_TO_MARKET:
                return {
                    message: "Ready to Market",
                    details: "Ready to be marketed.",
                };
            case CONSULTANT_STATUS.MARKETING:
                return {
                    message: "Marketing",
                    details: "Currently being marketed.",
                };
            case CONSULTANT_STATUS.COMPLETED:
                return {
                    message: "Completed",
                    details: "The process is completed.",
                };
            case CONSULTANT_STATUS.HOLD:
                return {
                    message: "On Hold",
                    details: "The process is currently on hold.",
                };
            case CONSULTANT_STATUS.STOPPED:
                return {
                    message: "Stopped",
                    details: "The process has been stopped.",
                };
            default:
                return { message: "Unknown", details: "Status is unknown." };
        }
    }
    statusTemplate = (rowData) => {
        const { message, details } = this.getStatusDetails(rowData.status);
        return (
            <div>
                {message}

            </div>
        );
    };

    assignLearningPlan = () => {
        const { consultandId, selectedPlan } = this.state;
        const learningPlanIds = selectedPlan.map((plan) => plan._id);
        const requestBody = {
            _id: consultandId,
            learningplan: learningPlanIds,
        };

        const url = `/consultants/consultant`;

        this.serverlessService
            .put(url, requestBody, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState(
                        {
                            isLoading: false,
                            studyPlan: false,
                        },
                        () => {
                            this.props.getAllConsultants();
                        }
                    );
                } else {
                    this.setState({
                        isLoading: false,
                    });

                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    render() {
        const planOptions = this.props.data.map((plan) => ({
            label: plan.planName,
            value: plan,
        }));
        const dialogFooter = (
            <div className="flex justify-content-end">
                <Button
                    label="Close"
                    onClick={() => this.setState({ studyPlan: false })}
                    className="p-button-text hover:bg-primary mr-3"
                />
                <Button
                    label="Assign"
                    onClick={this.assignLearningPlan}
                    className="p-button-primary "
                    autoFocus
                />
            </div>
        );

        const header = (
            <div className="">
                <div class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 top-100 z-1 shadow-2 lg:shadow-none border-1 lg:border-none border-gray-800">
                    <ul class="list-none flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li>
                            <p className="mx-0 my-1 ss-header">
                                All Consultants
                            </p>
                        </li>
                    </ul>
                    <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li class="flex-order-2 lg:flex-order-0">
                            <ul className="right-0">
                                <ul className="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                                    <Authorize
                                        permId={
                                            PERMISSIONS.ALL_CONSULTANTS_GENERAL_SEARCH
                                        }
                                    >
                                        <li className="flex-order-2 lg:flex-order-0 ml-4 ">
                                            {this.state.showSearch && (
                                                <InputText
                                                    placeholder="Search"
                                                    className=""
                                                    value={
                                                        this.props.globalSearch
                                                    }
                                                    onChange={
                                                        this.onGlobalSearch
                                                    }
                                                />
                                            )}
                                        </li>
                                    </Authorize>
                                    <Authorize
                                        permId={
                                            PERMISSIONS.ALL_CONSULTANTS_GENERAL_SEARCH
                                        }
                                    >
                                        <li className="flex-order-2 lg:flex-order-0 ml-4 cursor-pointer">
                                            <i
                                                data-pr-tooltip="Search"
                                                data-pr-position="bottom"
                                                className="pi pi-search text-base lg:text-2xl mr-2 ss-tbl-srch"
                                                onClick={(e) => {
                                                    this.setState({
                                                        showSearch:
                                                            !this.state
                                                                .showSearch,
                                                    });
                                                }}
                                            ></i>
                                        </li>
                                        <Tooltip
                                            className="table-li-tooltip"
                                            autoHide={false}
                                            target=".ss-tbl-srch"
                                        />
                                    </Authorize>
                                    <Authorize
                                        permId={
                                            PERMISSIONS.ALL_CONSULTANTS_GENERAL_DOWNLOAD
                                        }
                                    >
                                        <li className="border-top-1 border-gray-800 lg:border-top-none ml-4 mr-4 cursor-pointer">
                                            <i
                                                data-pr-tooltip="Download"
                                                data-pr-position="bottom"
                                                className="pi pi-download  text-base lg:text-2xl mr-2 ss-tbl-dwnld"
                                                onClick={this.exportCSV}
                                            ></i>
                                        </li>
                                        <Tooltip
                                            className="table-li-tooltip"
                                            target=".ss-tbl-dwnld"
                                        />
                                    </Authorize>
                                </ul>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="flex justify-content-between flex-wrap">
                    <div class="flex font-bold border-round w-4"></div>
                    <div class="flex absolute right-0 align-items-right  font-bold border-round w-8 "></div>
                </div>
            </div>
        );

        const statusOptions = [
            { label: "Created", value: CONSULTANT_STATUS.CREATED },
            {
                label: "Content Writing",
                value: CONSULTANT_STATUS.CONTENT_WRITING,
            },
            {
                label: "Document Preparing",
                value: CONSULTANT_STATUS.DOCUMENT_PREPERATING,
            },
            {
                label: "Document Editing",
                value: CONSULTANT_STATUS.DOCUMENT_EDITING,
            },
            {
                label: "Document Verifying",
                value: CONSULTANT_STATUS.DOCUMENT_VERIFYiNG,
            },
            {
                label: "Tech Preparing",
                value: CONSULTANT_STATUS.TECH_PREPARING,
            },
            {
                label: "Marketing Review",
                value: CONSULTANT_STATUS.MARKETING_REVIEW,
            },
            {
                label: "Ready to Market",
                value: CONSULTANT_STATUS.READY_TO_MARKET,
            },
            { label: "Marketing", value: CONSULTANT_STATUS.MARKETING },
            { label: "Completed", value: CONSULTANT_STATUS.COMPLETED },
            { label: "Hold", value: CONSULTANT_STATUS.HOLD },
            { label: "Stopped", value: CONSULTANT_STATUS.STOPPED },
            { label: "All", value: "" },
        ];
        const items = [{ label: "Admin" }, { label: "All Consultants" }];

        const home = {
            icon: "pi pi-home",
            url: "http://localhost:3000/#/admin",
        };

        return (
            <>
                <div className="h-screen overflow-auto ss-data-table">
                    <Card>
                        <BreadCrumb model={items} home={home} />
                        <Toolbar
                            className="sp-toolbar sp-mt20"
                            right={() => {
                                return (
                                    <div className="p-col">
                                        <Dropdown
                                            value={this.props.selectedStatus}
                                            options={statusOptions}
                                            style={{ width: "210px" }}
                                            onChange={this.onFilterChange}
                                            placeholder="Select a status"
                                        />
                                    </div>
                                );
                            }}
                            left={() => {
                                return <></>;
                            }}
                        ></Toolbar>
                        <Authorize permId={PERMISSIONS.ALL_CONSULTANTS_LIST}>
                            <DataTable
                                ref={(el) => (this.dt = el)}
                                value={this.props.allconsultants}
                                lazy
                                header={header}
                                scrollHeight="400px"
                                onSort={this.onSort}
                                onPage={this.onPage}
                                onFilter={this.onFilter}
                                first={this.props.lazyParams.first}
                                last={this.props.totalRecords}
                                rows={this.props.lazyParams.rows}
                                totalRecords={this.props.totalRecords}
                                paginator
                                paginatorLeft
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                rowsPerPageOptions={[
                                    5, 10, 25, 50, 100, 200, 300, 500,
                                ]}
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                scrollable
                                responsiveLayout="scroll"
                                rowHover
                                dataKey="_id"
                                selection={this.state.selectedCustomer}
                                onSelectionChange={(e) =>
                                    this.setState({ selectedCustomer: e.value })
                                }
                                selectionMode="single"
                            >
                                <Column
                                    field="name"
                                    header="Full Name"
                                ></Column>
                                <Column field="mobile" header="Phone"></Column>
                                <Column field="email" header="Email"></Column>
                                <Column
                                    field="statusbar"
                                    header="Timeline"
                                    body={(rowData) => {
                                        const { createdAt } = rowData;
                                        const elapsedDays =
                                            this.getElapsedDays(createdAt);
                                        const color =
                                            this.getColorCode(createdAt);


                                        return (
                                            <>
                                                <button
                                                    className={`p-button p-button-rounded`}
                                                    style={{
                                                        backgroundColor: color,
                                                        color: "black",
                                                    }}
                                                >
                                                    {elapsedDays} days
                                                </button>
                                            </>
                                        );
                                    }}
                                ></Column>

                                <Column
                                    field="rolePrefered"
                                    header="Role"
                                ></Column>
                                <Column
                                    field="status"
                                    header="Status"
                                    body={this.statusTemplate}
                                ></Column>
                                <Column
                                    header="Actions"
                                    body={(rowData) =>
                                        this.actionsBody(rowData)
                                    }
                                ></Column>
                            </DataTable>
                        </Authorize>
                    </Card>
                </div>
                {this.state.isShowConsultantNotes && (
                    <ConsultantNotes
                        consultant={this.state.editData}
                        onHide={() => {
                            this.setState({
                                isShowConsultantNotes: false,
                                editData: null,
                            });
                        }}
                    />
                )}
                {this.state.isShowConsultantMonitor && (
                    <ConsultantMonitor
                        consultant={this.state.editData}
                        onHide={() => {
                            this.setState({
                                isShowConsultantMonitor: false,
                                editData: null,
                            });
                        }}
                    />
                )}
                {this.state.showUserDialog && (
                    <Dialog
                        header="Are you Sure ?"
                        draggable={false}
                        visible={this.state.showUserDialog}
                        style={{ width: "15vw" }}
                        onHide={this.onclose}
                        footer={this.footerContentforUser}
                    >
                        <p>User will be created</p>
                    </Dialog>
                )}
                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
                {(this.state.isLoading || this.props.isLoading) && (
                    <LoadingComponent />
                )}
                <Dialog
                    visible={this.state.studyPlan}
                    style={{ width: "450px" }}
                    header="Select a Study Plan"
                    modal
                    draggable={false}
                    footer={dialogFooter}
                    onHide={() => this.setState({ studyPlan: false })}
                    className="p-dialog-custom"
                >
                    <div className="p-fluid">
                        {" "}
                        <div className="p-field">
                            <label htmlFor="selectedPlan">Select a Plan</label>

                            <MultiSelect
                                inputId="selectedPlan"
                                value={this.state.selectedPlan}
                                options={planOptions}
                                onChange={this.onPlanChange}
                                placeholder="Select Plans"
                                className="p-inputtext p-component"
                                display="chip"
                            />
                        </div>
                    </div>
                </Dialog>
                {this.state.isShowAssignToContentWriter && (
                    <AssignContentWriter
                        selectedCustomer={this.state.selectedCustomer}
                        onCancel={() => {
                            this.setState({
                                isShowAssignToContentWriter: false,
                                selectedCustomer: null,
                            });
                        }}
                        onHide={() => {
                            let thisObj = this;
                            this.setState(
                                {
                                    isShowAssignToContentWriter: false,
                                    selectedCustomer: null,
                                },
                                () => {
                                    thisObj.toast.show({
                                        severity: "success",
                                        summary:
                                            "User assigned to content writer.",
                                        detail: "User assigned successfully",
                                        life: 3000,
                                    });

                                    thisObj.props.getAllConsultants({
                                        status: this.props.selectedStatus,
                                    });
                                }
                            );
                        }}
                    />
                )}
                {this.state.isShowAssignToDocPreparator && (
                    <AssignDocPreparator
                        selectedCustomer={
                            this.state.selectedCustomer
                        }
                        onCancel={() => {
                            this.setState({
                                isShowAssignToDocPreparator: false,
                                selectedCustomer: null,
                            });
                        }}
                        onHide={() => {
                            let thisObj = this;

                            this.setState(
                                {
                                    isShowAssignToDocPreparator: false,
                                    selectedCustomer: null,
                                },
                                () => {
                                    thisObj.toast.show({
                                        severity: "success",
                                        summary:
                                            "Assign to Document Preparation Team.",
                                        detail: "User assigned successfully",
                                        life: 3000,
                                    });
                                    this.props.getAllConsultants();
                                }
                            );
                        }}
                    />
                )}
                {this.state.isShowAssignToBenchSales && (
                    <AssignToBenchSales
                        isAssingToPreperationTeam={
                            this.state.isAssingToPreperationTeam
                        }
                        selectedCustomer={
                            this.state.selectedCustomer
                        }
                        onCancel={() => {
                            this.setState({
                                isShowAssignToBenchSales: false,
                                selectedCustomer: null,
                            });
                        }}
                        onHide={() => {
                            let thisObj = this;

                            this.setState(
                                {
                                    isShowAssignToBenchSales: false,
                                    selectedCustomer: null,
                                },
                                () => {
                                    thisObj.toast.show({
                                        severity: "success",
                                        summary: this.state
                                            .selectedCustomer
                                            ?.marketingAssignedTo
                                            ? "User Reassigned To Recruiter"
                                            : "User Assigned To Recruiter",
                                        detail: "User Assigned Successfully",
                                        life: 3000,
                                    });
                                    thisObj.props.getAllConsultants(
                                        {
                                            status: this.state
                                                .selectedStatus,
                                        }
                                    );
                                }
                            );
                        }}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    allconsultants: state?.allConsultants?.allconsultants,
    totalRecords: state?.allConsultants?.totalRecords,
    lazyParams: state?.allConsultants?.lazyParams,
    isLoading: state?.allConsultants?.isLoading,
    selectedStatus: state?.allConsultants?.selectedStatus,
    data: state?.learningPlans?.data,
});

export default BaseComponent(Consultants, mapStateToProps, {
    getAllConsultants,
    resetLazyAllConsultants,
    getLearningPlans
});
