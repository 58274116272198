import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import React, { Component } from 'react'
import ServerlessService from '../../../Service/ServerlessService';
import LoadingComponent from '../../../LoadingComponent';
import { Toast } from 'primereact/toast';
import NoteEntryComponent from '../documentPreperator/noteEntryComponent';
import { logOut } from '../../../Utils';

export default class AssignToBenchSales extends Component {

    constructor(props) {
        super(props);

        this.state = {
            contentWriters: [],
            marketingAssignedTo: null,
            marketingAssignedToErr: false,
            notesToSales: ''

        }
        this.serverlessService = new ServerlessService();
    }


    getPrivileges = () => {
        this.setState({
            isLoading: true,
            contentWriters: [],
        });

        const url = `/admin/get-privilege-users`;
        this.serverlessService
            .post(url, { privilegeType: 'isBenchSales' }, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                        contentWriters: res.res.data,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    componentDidMount() {
        this.getPrivileges();
    }


    assignToContentWriter = () => {


        if (!this.state.marketingAssignedTo) {
            this.setState({
                marketingAssignedToErr: true,
            });
            return;
        }
        this.setState({
            isLoading: true,
        });

        const { notesToSales } = this.state;


        const url = `/consultants/consultant/assign-to-sales`;
        this.serverlessService
            .post(url, {
                _id: this.props.selectedCustomer._id,
                notesToSales,
                marketingAssignedTo: this.state.marketingAssignedTo
            }, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,

                    }, this.props.onHide);
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });

    }

    footerContent = () => (
        <div>
            <Button
                label="Cancel"
                className=" p-button-text hover:bg-primary mr-3"
                onClick={this.props.onCancel}
            />
            <Button
                label={"Assign"}
                onClick={this.assignToContentWriter}
                autoFocus
                className="p-button-primary"
            />
        </div>
    );

    render() {
        return (
            <Dialog
                header={"Assign to Bench Sale"}
                visible={true}
                style={{ width: "65vw" }}
                draggable={false}
                onHide={() =>
                    this.props.onCancel()
                }
                footer={this.footerContent}
            >
                <div>
                    <div className="p-grid row pl-6 pr-6">
                        <div className="col-12 md:col-8 lg:col-8">
                            <label className="font-bold block mb-2">Sales Recruiter</label>
                            <Dropdown
                                onChange={(e) => {
                                    this.setState({
                                        marketingAssignedTo: e.value,
                                        marketingAssignedToErr: false
                                    })
                                }}
                                value={this.state.marketingAssignedTo}
                                options={this.state.contentWriters}
                                optionValue="_id"
                                optionLabel="name"
                                placeholder="Select a user"
                                className="w-full md:w-14rem"
                            />
                            {
                                this.state.marketingAssignedToErr && <p className='p-error'>Please select the user</p>
                            }

                        </div>


                    </div>

                    <div className="p-grid row pl-6 pr-6">
                        <div className="col-12 md:col-8 lg:col-8">
                            <label className="font-bold block mb-2">Notes</label>
                            <NoteEntryComponent
                                onChange={(e) => {
                                    this.setState({
                                        notesToSales: e.target.value
                                    })
                                }}
                                value={this.state.notesToSales}
                            />
                        </div>
                    </div>

                </div>

                {
                    this.state.isLoading && <LoadingComponent />
                }
                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />

            </Dialog>
        )
    }
}
