import { BasicLazyParams } from '../../Utils/constants';
import { ACTIONS } from '../actionTypes';
export default (state = {
    tags: [],

    isLoading: false,
    error: false,
    errorMessage: '',
    lazyParams: {
        ...BasicLazyParams,
        sortField: "-createdAt",
        sortOrder: 1,
    },
    globalSearch: "",
    data: [],
    totalRecords: 0,
}, action) => {
    switch (action.type) {
        case ACTIONS.REQUEST_LEARNINGRESOURCES:
            return {
                ...state,
                lazyParams: {
                    ...state.lazyParams,
                    ...action?.data?.lazyParams
                },
                globalSearch: action?.data?.globalSearch || state?.globalSearch,
                isLoading: true,
                error: false,
            };
        case ACTIONS.RECEIVED_LEARNINGRESOURCES:

            return {
                ...state,
                isLoading: false,
                data: action?.data?.data,
                totalRecords: action?.data?.totalRecords
            };
        case ACTIONS.ERROR_LEARNINGRESOURCES:
            return {
                ...state,
                isLoading: false,
                error: true,
                errorMessage: action?.err?.message
            };
        case ACTIONS.CLEAR_ORG_LEARNINGRESOURCES:
            return {
                orgInfo: null,
                isLoading: false,
                error: false,
                errorMessage: '',
            };

        case ACTIONS.REQUEST_ADD_LEARNINGRESOURCES:
            return {
                ...state,

                error: false,
            };
        case ACTIONS.RECEIVED_ADD_LEARNINGRESOURCES:
            // debugger
            return {
                ...state,
                data: [action?.data, ...state.data],
                totalRecords: state?.totalRecords + 1,
                isLoading: false,
                consultantAddedOrUpdated: !state.consultantAddedOrUpdated
            };
        case ACTIONS.RECEIVED_UPDATE_LEARNINGRESOURCES:
            // debugger
            return {
                ...state,
                data: state.data.map(a => {
                    if (a._id === action?.data._id) {
                        return action?.data;
                    }
                    return a
                }),
                isLoading: false,
                consultantAddedOrUpdated: !state.consultantAddedOrUpdated
            };

        case ACTIONS.RECEIVED_DELETE_LEARNINGRESOURCES:

            return {
                ...state,
                data: state.data.filter(a => a._id !== action?.data?._id),
                totalRecords: state?.totalRecords - 1,
                isLoading: false,
                consultantAddedOrUpdated: !state.consultantAddedOrUpdated
            };
        case ACTIONS.ERROR_ADD_CONSULTANTS:
            return {
                ...state,
                error: true,
                errorMessage: action?.err?.message,
            };
        default:
            return state;
    }
}