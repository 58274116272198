import React, { Component } from 'react'
import _ from 'lodash';
import { Checkbox } from 'primereact/checkbox';
export default class PermissionsTab extends Component {
    constructor(props) {
        super(props);

    }


    handleCheckBoxChange = (groupId, permissionId) => (event) => {

        this.props.handleCheckBoxClick(groupId, permissionId, event.target.checked);
    };

    isChecked = (id, permissions) => {
        return Object.keys(permissions)
            .reduce((acc, curr) => {
                acc = acc.concat(permissions[curr]);
                return acc;
            }, [])
            .includes(id);
    };

    render() {
        const { classes, name, details, id, handleChange, expanded, selectedPermissions } = this.props;
        const data = _.chain(this.props.details)
            .groupBy('category')
            .map((value, key) => ({ category: key, checkboxes: value }))
            .value();

        return (
            <div className='permission-tab'>
                {data.map((groupedItem, itr) => (<>
                    <div className="p-grid" key={`${groupedItem}-${itr}`}>
                        <label className='text-xl font-medium'>
                            {groupedItem.category}
                        </label>
                    </div>

                    <div className="grid formgrid p-fluid">

                        {groupedItem.checkboxes.map((item, index) => (
                            <div className="field mb-4 col-12 md:col-3" id={item.id} key={`check-${index}`}>
                                <Checkbox
                                    checked={this.isChecked(item.id, selectedPermissions)}
                                    value={item.id}
                                    inputId={`a${itr}_${index}`}
                                    onChange={this.handleCheckBoxChange(this.props.id, item.id)}
                                ></Checkbox>
                                <label htmlFor={`a${itr}_${index}`} className="font-medium pt-1 ml-2 absolute" >{item.name}</label>
                            </div>
                        ))}

                    </div>
                </>

                ))
                }

            </div >
        )
    }
}
