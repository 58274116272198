import { ACTIONS } from "../actionTypes";
import axios from "axios";
import { SERVERLESS_URL } from "./../../Service/config";
import { BasicLazyParams, CONSULTANT_STATUS } from "../../Utils/constants";
import { logOut, removeNullUndefinedKeys } from "../../Utils";

const intialTableFilter = {
    lazyParams: {
        ...BasicLazyParams,
        sortField: "createdAt",
        sortOrder: 1,
    },
    globalSearch: "",
};

const dispatchRequestConsultantsData = (data) => ({
    type: ACTIONS.REQUEST_CONSULTANTS,
    data,
});

const dispatchReceivedConsultantsData = (data) => ({
    type: ACTIONS.RECEIVED_CONSULTANTS,
    data,
});

const dispatchErrorConsultantsData = (err) => ({
    type: ACTIONS.ERROR_CONSULTANTS,
    err,
});

const dispatchClearConsultantsData = () => ({
    type: ACTIONS.CLEAR_CONSULTANTS,
});

const dispatchAddConsultants = (data) => ({
    type: ACTIONS.REQUEST_ADD_CONSULTANTS,
    data,
});

const dispatchReceivedAddConsultants = (data) => ({
    type: ACTIONS.RECEIVED_ADD_CONSULTANTS,
    data,
});

const dispatchReceivedUpdateConsultants = (data) => ({
    type: ACTIONS.RECEIVED_UPDATE_CONSULTANTS,
    data,
});
const dispatchReceivedDeleteConsultants = (data) => ({
    type: ACTIONS.RECEIVED_DELETE_CONSULTANTS,
    data,
});

const dispatchErrorAddConsultants = (err) => ({
    type: ACTIONS.ERROR_ADD_CONSULTANTS,
    err,
});

export const addConsultants = (data) => (dispatch) => {
    dispatch(dispatchAddConsultants(data));
    axios({
        method: data?._id ? "put" : "post",
        url: `${SERVERLESS_URL}/consultants/consultant${data?._id ? "" : "/add"
            }`,
        headers: {
            'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
            Authorization: `Bearer ${localStorage.getItem("Bearer")
                ? localStorage.getItem("Bearer")
                : ""
                }`,
        },
        data,
    })
        .then((res) => {
            console.log(res.data, "documentEditing action");
            if (res && res.data) {
                if (data?._id) {
                    dispatch(dispatchReceivedUpdateConsultants(res.data?.data));
                } else {
                    dispatch(dispatchReceivedAddConsultants(res.data?.data));
                }
            } else {
                dispatch(
                    dispatchErrorAddConsultants({
                        message: "Some error occurred",
                    })
                );
            }
        })
        .catch((e) => {
            if (e?.response?.status > 400 && e?.response?.status < 500) {
                logOut()
            }
            dispatch(dispatchErrorAddConsultants(e));
        });
};

export const deleteConsultants = (data) => (dispatch) => {
    axios({
        method: "delete",
        url: `${SERVERLESS_URL}/consultants/consultant/${data._id}`,
        headers: {
            'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
            Authorization: `Bearer ${localStorage.getItem("Bearer")
                ? localStorage.getItem("Bearer")
                : ""
                }`,
        },
    })
        .then((res) => {
            console.log(res.data, "documentEditing action");
            if (res && res.data) {

                dispatch(dispatchReceivedDeleteConsultants(data));
            } else {
            }
        })
        .catch((e) => {
            if (e?.response?.status > 400 && e?.response?.status < 500) {
                logOut()
            }
        });
};

export const getConsultants = (data) => (dispatch, getState) => {
    let _data;

    const currentState = getState().consultants;
    console.log(currentState, "action consultant");
    if (data?.lazyParams) {
        currentState.lazyParams = {
            ...currentState.lazyParams,
            ...removeNullUndefinedKeys(data.lazyParams),
        };
    }
    if (data?.globalSearch !== undefined) {
        currentState.globalSearch = data.globalSearch;
    }

    const { lazyParams, globalSearch } = currentState;
    dispatch(dispatchRequestConsultantsData(data));

    axios({
        method: "post",
        url:
            `${SERVERLESS_URL}/consultants/consultant?limit=${lazyParams.rows
            }&page=${lazyParams.page + 1}` +
            `${globalSearch ? `&search=${globalSearch}` : ""}` +
            `${lazyParams.sortField
                ? `&sort=${lazyParams.sortOrder === 1 ? "" : "-"}${lazyParams.sortField
                }`
                : ""
            }`,
        headers: {
            'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
            Authorization: `Bearer ${localStorage.getItem("Bearer")
                ? localStorage.getItem("Bearer")
                : ""
                }`,
        },
        data: data,
    })
        .then((res) => {
            _data = res.data;
            if (res && res.data) {
                dispatch(dispatchReceivedConsultantsData(res.data));
            } else {
                dispatch(
                    dispatchErrorConsultantsData({
                        message: "Some error occurred",
                    })
                );
            }
        })
        .catch((e) => {
            if (e?.response?.status > 400 && e?.response?.status < 500) {
                logOut()
            }
            dispatch(dispatchErrorConsultantsData(e));
        });
};

export const resetLazyConsultants = () => (dispatch) => {
    let _data;

    const { lazyParams, globalSearch } = intialTableFilter;
    dispatch(dispatchRequestConsultantsData(intialTableFilter));
    axios({
        method: "post",
        url: `${SERVERLESS_URL}/consultants/consultant?limit=${lazyParams.rows
            }&page=${lazyParams.page + 1}&search=${globalSearch}${lazyParams.sortField
                ? `&sort=${lazyParams.sortOrder === 1 ? "" : "-"}${lazyParams.sortField
                }`
                : ""
            }`,
        headers: {
            'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
            Authorization: `Bearer ${localStorage.getItem("Bearer")
                ? localStorage.getItem("Bearer")
                : ""
                }`,
        },
        body: { status: CONSULTANT_STATUS.CREATED },
    })
        .then((res) => {
            _data = res.data;
            if (res && res.data) {
                dispatch(dispatchReceivedConsultantsData(res.data));
            } else {
                dispatch(
                    dispatchErrorConsultantsData({
                        message: "Some error occurred",
                    })
                );
            }
        })
        .catch((e) => {
            if (e?.response?.status > 400 && e?.response?.status < 500) {
                logOut()
            }
            dispatch(dispatchErrorConsultantsData(e));
        });
};

export const clearConsltants = () => dispatchClearConsultantsData();
