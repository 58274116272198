import permissions from './permissions.json'

import CryptoJS from "crypto-js";
import { secretkey } from './../Service/config';

import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

export const trimObj = (obj) => {
    if (obj != null && !Array.isArray(obj) && typeof obj != 'object') return obj;
    return Object.keys(obj).reduce(function (acc, key) {
        acc[key.trim()] = typeof obj[key] == 'string' ? obj[key].trim() : (obj[key] == null || obj[key] == undefined) ? obj[key] : trimObj(obj[key]);
        return acc;
    }, Array.isArray(obj) ? [] : {});
}

export const warningDailogInit = {
    visible: false,
    headerMsg: '',
    message: ''
}

export function logOut() {
    localStorage.clear();
    // props.history.push("/");

    window.location.replace('/');

}

export const getTimeSlotByKey = (key) => {

    switch (key) {
        case 1000:
            return '10:00 AM'
        case 1030:
            return '10:30 AM'
        case 1100:
            return '11:00 AM'
        case 1130:
            return '11:30 AM'
        case 1200:
            return '12:00 PM'
        case 1230:
            return '12:30 PM'
        case 1400:
            return '02:00 PM'
        case 1430:
            return '02:30 PM'
        case 1500:
            return '03:00 PM'
        case 1530:
            return '03:30 PM'
        case 1600:
            return '04:00 PM'
        case 1630:
            return '04:30 PM'
        case 1800:
            return '06:00 PM'
        case 1830:
            return '06:30 PM'
        case 1900:
            return '07:00 PM'
        case 1930:
            return '07:30 PM'

    }

}



export const bytesToSize = (bytes) => {

    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}


export const isValidFileSize = (bytes) => {
    return bytes <= 20971520

}


export const getCredits = (length = 0, language) => {
    if (language == 'english') {
        if (length <= 160) {
            return 1;
        } else {
            return Math.ceil(length / 153)
        }
    } else {
        if (length <= 70) {
            return 1;
        } else {
            return Math.ceil(length / 67)
        }
    }
}


export const calcSegment = (messageBodyText) => {
    if (window.SegmentedMessage) {
        const segmentedMessage = new window.SegmentedMessage(messageBodyText, 'auto');

        segmentedMessage.language = segmentedMessage.encodingName == 'GSM-7' ? 'english' : 'others'
        segmentedMessage.messageLength = segmentedMessage.numberOfUnicodeScalars
        return segmentedMessage;
    } else {
        throw new Error();

    }
}


var gsm = `@ 
£$¥èéùìòÇØøÅåΔ_ΦΓΛΩΠΨΣΘΞ^{}\[~]|€ÆæßÉ!\"#¤%&'()*+,-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà`;

export const calcMessage = (message = '') => {
    let isGSM7 = message.split('').every(a => gsm.indexOf(a) !== -1);

    return {
        language: isGSM7 ? 'english' : 'others',
        length: message.length,
        credits: getCredits(message.length, isGSM7 ? 'english' : 'others'),
        data_coding: isGSM7 ? 0 : 8
    }
}


export const onCopyClick = (text) => {
    navigator.clipboard.writeText(text);
}




function convertToCSV(objArray) {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';

    for (var i = 0; i < array.length; i++) {
        var line = '';
        for (var index in array[i]) {
            if (line !== '') line += ','

            line += array[i][index];
        }

        str += line + '\r\n';
    }

    return str;
}

export function exportCSVFile(headers, items, fileTitle) {
    if (headers) {
        items.unshift(headers);
    }

    // Convert Object to JSON
    var jsonObject = JSON.stringify(items);

    var csv = convertToCSV(jsonObject);

    var exportedFilenmae = fileTitle + '.csv' || 'export.csv';

    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilenmae);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}


export const formatter = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
    minimumFractionDigits: 0,
});





export const FeeDays = [
    { days: "1 day", id: "1_day" },
    { days: "3 days", id: "3_days" },
    { days: "7 days", id: "7_days" },
    { days: "15 days", id: "15_days" },
    { days: "30 days", id: "30_days" },
    { days: "2 months", id: "2_months" },
    { days: "3 months", id: "3_months" },
    { days: "6 months", id: "6_months" },
    { days: "1 year", id: "1_year" },

];

export const PaymentModes = [
    { type: "Online", id: "online" },
    { type: "Offline", id: "offline" },
];

export const Status = [
    { type: "Pending", id: "pending" },
    { type: "Paid", id: "paid" },
];

export const PolicyTypes = [
    { type: "type1", id: "type1" },
    { type: "type2", id: "type2" },
];

export const Months = [
    { name: "1st Month", id: "1stMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },

    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },

]


export const getPermissionIdsOfGroup = (groupId) => {
    return permissions.filter(p => p.id == groupId)[0]?.permissions?.map(a => a.id)



}


export const countWords = (text) => {
    text = text.trim();
    if (text.length === 0) {
        return 0;
    }
    // Split the text by white spaces
    const words = text.split(/\s+/);
    return words.length;

}




// Encode function
export function encodeString(str, secretkey) {
    return CryptoJS.AES.encrypt(str, secretkey).toString();
}

// Decode function
export function decodeString(encodedStr, secretkey) {
    var bytes = CryptoJS.AES.decrypt(encodedStr, secretkey);
    return bytes.toString(CryptoJS.enc.Utf8);
}






export function removeNullUndefinedKeys(obj) {
    for (const key in obj) {
        if (obj[key] === null || obj[key] === undefined) {
            delete obj[key];
        }
    }
    return obj;
}


export const fileToS3 = async (orgId, file) => {
    let url = 'https://v2ao5dt0v3.execute-api.ap-south-1.amazonaws.com/prod/v1/data/presign/url';
    let cloudFrontEndpoint = 'https://d1wqfechwjsewm.cloudfront.net/';

    if (!file.status && file.name) {
        let { data } = await axios.post(url, {
            "type": "insert",
            "key": orgId + '/' + uuidv4() + file.name.replace(/\s/g, "")
        });
        let uploadStatus = await axios.put(data.data.presignedURL, file, {
            headers: {
                'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
                "Content-Type": "binary/octet-stream",
                "Access-Control-Allow-Origin": "*",
            }
        });
        return {
            path: cloudFrontEndpoint + data.data.contentStorageKey,
            key: data.data.contentStorageKey,
            name: file.name,
            type: file.type,
            size: file.size,
            status: uploadStatus.status == 200 ? true : false,
        }
    } else {
        return file;
    }
}


export const showPreviewDoc = (file) => {

    if (file?.name) {


        if (file?.status) {

            const newTab = window.open(file.path, '_blank');
            newTab.focus();
        } else {

            const reader = new FileReader();

            reader.onload = function (event) {
                const data = event.target.result;
                const blob = new Blob([data], { type: file.type });
                const url = URL.createObjectURL(blob);

                // Open the file in a new tab
                const newTab = window.open(url, '_blank');
                newTab.focus();
            };
            reader.readAsArrayBuffer(file);

        }
    }

}

export const ResourceTypes = {
    Youtube: "youtube",
    ExternalLink: "externalLink",
    Upload: "upload",
    Textbox: "texbox"
};

export const extractYoutubeVideoID = (url) => {
    let n = url.indexOf("embed");
    let index = 0;
    if (n !== -1) {
        index = n + 6;
    } else if (url.indexOf("watch") !== -1) {
        let temp = url.indexOf("watch");
        index = temp + 8;
    } else {
        let temp = url.indexOf('youtu.be');
        index = temp + 9;
    }
    return url.substring(index);
}



export const showPreviewDocMain = async (file) => {
    // debugger
    if (file.status) {
        if (file.path) {
            const newTab = window.open(file.path, '_blank');
        } else if (file.resourceKey) {

            let url =
                "https://btycikcg7g.execute-api.ap-south-1.amazonaws.com/prod/v1/data/presign/url";

            let { data } = await axios.post(url, {
                "type": "read",
                "key": file.resourceKey
            });

            const newTab = window.open(data.data, '_blank');

        }




    } else {

        const reader = new FileReader();

        reader.onload = function (event) {
            const data = event.target.result;
            const blob = new Blob([data], { type: file.type });
            const url = URL.createObjectURL(blob);

            // Open the file in a new tab
            const newTab = window.open(url, '_blank');
            newTab.focus();
        };
        reader.readAsArrayBuffer(file);

    }


}


export function getFileType(mimeType) {
    switch (mimeType) {
        // Image MIME types
        case 'image/jpeg':
        case 'image/png':
        case 'image/gif':
        case 'image/bmp':
        case 'image/webp':
        case 'image/svg+xml':
        case 'image/tiff':
        case 'image/x-icon':
            return 'image';

        // Video MIME types
        case 'video/mp4':
        case 'video/webm':
        case 'video/ogg':
        case 'video/quicktime':
        case 'video/x-msvideo':
        case 'video/x-ms-wmv':
            return 'video';

        // Audio MIME types
        case 'audio/mpeg':
        case 'audio/ogg':
        case 'audio/wav':
        case 'audio/aac':
        case 'audio/flac':
        case 'audio/x-wav':
        case 'audio/x-ms-wma':
            return 'audio';

        // Document MIME types
        case 'application/pdf':
        case 'application/msword':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': // .docx
        case 'application/vnd.ms-excel':
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': // .xlsx
        case 'application/vnd.ms-powerpoint':
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation': // .pptx
            return 'document';

        // Text MIME types
        case 'text/plain':
        case 'text/html':
        case 'text/css':
        case 'text/javascript':
        case 'application/json':
        case 'application/xml':
            return 'text';

        // Archive MIME types
        case 'application/zip':
        case 'application/x-tar':
        case 'application/x-rar-compressed':
        case 'application/x-7z-compressed':
        case 'application/gzip':
            return 'archive';

        // Other common MIME types
        case 'application/octet-stream':
        case 'application/x-sh':
        case 'application/x-httpd-php':
        case 'application/x-sql':
            return 'binary';

        default:
            return 'unknown';
    }
}

