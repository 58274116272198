import React, { Component } from 'react'
import { Dialog } from 'primereact/dialog';
import Service from './../../../Service';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import userFields from './adminUser.json';
import LoadingComponent from './../../../LoadingComponent';
import { getFormFields, isFormValid, onChoiceChange, onEmailChange, onNumberChange, onTextChange } from './../../../Utils/formHelper';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Authentication from './../../../session/index'
import { logOut, trimObj } from './../../../Utils';
import ServerlessService from './../../../Service/ServerlessService';
import { Checkbox } from 'primereact/checkbox';
import Required from '../../common/required';

class CreateAdminUser extends Component {
    constructor(props) {
        super(props);

        this.formFields = getFormFields(userFields, this.props.user ? { ...this.props.adminUser } : {});
        this.state = {
            user: this.formFields.data,
            formValidations: this.formFields.formValidations,
            rolesList: []
        }
        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }


    isAdditionalValid = () => {

        let user = JSON.parse(JSON.stringify(this.state.user));
        let { formValidations } = this.state;
        let isValid = true;
        if (user.password != user.passwordConfirm) {
            isValid = false;
            formValidations.fields['passwordConfirm'] = { ...formValidations.fields['passwordConfirm'], isValid: false, errorMsg: `Password and Confirm password is not matched.` }
        }

        this.setState((prevState) => {
            return {
                formValidations
            };
        });

        return isValid

    }

    saveAdminUser = () => {


        let isCompleteFormValid = true;
        let userData = trimObj(this.state.user)
        const formStatus = isFormValid(userFields, this.formFields.formValidations, userData);
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
        }


        // if (!this.isAdditionalValid()) {
        //     isCompleteFormValid = false;
        // }



        if (isCompleteFormValid) {
            let url = `/admin/save`
            if (this.props.adminUser) {
                url = `/admin/update-user-privileges`
            }



            this.setState({
                isLoading: true,
            });

            this.serverlessService.post(url, userData, true).then((res) => {

                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                    }, () => {


                        this.props.onSave(this.props.user ? true : false);
                    })
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occurred', detail: res.message, life: 3000 });
                }

            }).catch(e => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                console.log(e);
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occurred', detail: e.message, life: 3000 });
            })

        }

    }


    getRoles = () => {
        this.setState({
            isLoading: true,
        });

        let url = `/authorize/getRolesNamesids`

        this.serverlessService.get(url, true).then((res) => {

            if (res && res.status && res.res.status) {
                this.setState({
                    rolesList: res.res.data,
                    isLoading: false,
                }, () => {


                })
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occurred', detail: res.message, life: 3000 });
            }

        }).catch(e => {
            if (e?.response?.status > 400 && e?.response?.status < 500) {
                logOut()
            }
            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occurred', detail: e.message, life: 3000 });
        })

    }

    componentDidMount() {
        if (this.props.isSessionChecked && !this.props.authenticated) {
            this.props.history.push('/login')
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isSessionChecked != this.props.isSessionChecked && (this.props.isSessionChecked && !this.props.authenticated)) {
            this.props.history.push('/login')
        }

    }

    footer = () => {
        return (<div>
            <Button label={`${this.props.user ? 'Update' : 'Save'} Admin User`} className='sp-m-lr10'
                onClick={this.saveAdminUser}
            />
        </div>)
    }


    render() {
        const { user, rolesList, formValidations } = this.state;
        const { adminUser } = this.props;
        console.log(adminUser)
        return (<>
            <Dialog header={`${adminUser.name} Privileges`}
                blockScroll={true}
                draggable={false}
                closeOnEscape={true}
                dismissableMask={true}
                visible={true}
                footer={this.footer}
                closable={true}
                style={{ width: '70%', height: '80vh' }}
                onHide={this.props.onHide}>

                <>

                    {/* <div className="grid formgrid p-fluid">
                        <div className="field mb-4 col-12">
                            <p>User: {adminUser.name}</p>
                        </div>
                    </div> */}

                    <div className="grid formgrid p-fluid">
                        <div className="field mb-4 col-12 md:col-4">
                            <Checkbox inputId={'isContentWriter'}
                                className='pt-2'
                                checked={user.isContentWriter}
                                onChange={(e) => {
                                    onChoiceChange(
                                        e.target.checked,
                                        "isContentWriter",
                                        this,
                                        userFields,
                                        user,
                                        formValidations,
                                        "user",
                                        "formValidations"
                                    );
                                }}
                            ></Checkbox>
                            <label htmlFor='isContentWriter' className="font-medium pt-1 ml-2 absolute" >Content Writer</label>
                        </div>
                        <div className="field mb-4 col-12 md:col-4">
                            <Checkbox inputId={'isOPTRecruiter'}
                                className='pt-2'
                                checked={user.isOPTRecruiter}
                                onChange={(e) => {
                                    onChoiceChange(
                                        e.target.checked,
                                        "isOPTRecruiter",
                                        this,
                                        userFields,
                                        user,
                                        formValidations,
                                        "user",
                                        "formValidations"
                                    );
                                }}
                            ></Checkbox>
                            <label htmlFor='isOPTRecruiter' className="font-medium pt-1 ml-2 absolute" >OPT Recruiter</label>
                        </div>
                        <div className="field mb-4 col-12 md:col-4">
                            <Checkbox inputId={'isDocumentPreparator'}
                                checked={user.isDocumentPreparator}
                                className='pt-2'

                                onChange={(e) => {
                                    onChoiceChange(
                                        e.target.checked,
                                        "isDocumentPreparator",
                                        this,
                                        userFields,
                                        user,
                                        formValidations,
                                        "user",
                                        "formValidations"
                                    );
                                }}
                            ></Checkbox>
                            <label htmlFor='isDocumentPreparator' className="font-medium pt-1 ml-2 absolute" >Document Preparator</label>
                        </div>
                        <div className="field mb-4 col-12 md:col-4">
                            <Checkbox inputId={'isDocumentEditor'}
                                checked={user.isDocumentEditor}
                                onChange={(e) => {
                                    onChoiceChange(
                                        e.target.checked,
                                        "isDocumentEditor",
                                        this,
                                        userFields,
                                        user,
                                        formValidations,
                                        "user",
                                        "formValidations"
                                    );
                                }}
                            ></Checkbox>
                            <label htmlFor='isDocumentEditor' className="font-medium pt-1 ml-2 absolute" >Document Editor</label>
                        </div>
                        <div className="field mb-4 col-12 md:col-4">
                            <Checkbox inputId={'isTechPreparator'}
                                checked={user.isTechPreparator}
                                onChange={(e) => {
                                    onChoiceChange(
                                        e.target.checked,
                                        "isTechPreparator",
                                        this,
                                        userFields,
                                        user,
                                        formValidations,
                                        "user",
                                        "formValidations"
                                    );
                                }}
                            ></Checkbox>
                            <label htmlFor='isTechPreparator' className="font-medium pt-1 ml-2 absolute" >Tech Preperator</label>
                        </div>
                        <div className="field mb-4 col-12 md:col-4">
                            <Checkbox inputId={'isDocVerifier'}
                                checked={user.isDocVerifier}
                                onChange={(e) => {
                                    onChoiceChange(
                                        e.target.checked,
                                        "isDocVerifier",
                                        this,
                                        userFields,
                                        user,
                                        formValidations,
                                        "user",
                                        "formValidations"
                                    );
                                }}
                            ></Checkbox>
                            <label htmlFor='isDocVerifier' className="font-medium pt-1 ml-2 absolute" >Content Writer</label>
                        </div>
                        <div className="field mb-4 col-12 md:col-4">
                            <Checkbox inputId={'isBenchSales'}
                                checked={user.isBenchSales}
                                onChange={(e) => {
                                    onChoiceChange(
                                        e.target.checked,
                                        "isBenchSales",
                                        this,
                                        userFields,
                                        user,
                                        formValidations,
                                        "user",
                                        "formValidations"
                                    );
                                }}
                            ></Checkbox>
                            <label htmlFor='isBenchSales' className="font-medium pt-1 ml-2 absolute" >Bench Sales</label>
                        </div>
                        <div className="field mb-4 col-12 md:col-4">
                            <Checkbox inputId={'isPaperWorkMaker'}
                                checked={user.isPaperWorkMaker}
                                onChange={(e) => {
                                    onChoiceChange(
                                        e.target.checked,
                                        "isPaperWorkMaker",
                                        this,
                                        userFields,
                                        user,
                                        formValidations,
                                        "user",
                                        "formValidations"
                                    );
                                }}
                            ></Checkbox>
                            <label htmlFor='isPaperWorkMaker' className="font-medium pt-1 ml-2 absolute" >Paper work maker</label>
                        </div>
                    </div>
                </>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {(this.state.isLoading || this.props.isLoading) && (
                    <LoadingComponent />
                )}
            </Dialog>
        </>
        )
    }
}






const mapStateToProps = (state) => ({
    user: state.user,
    userType: state?.user?.userType,
    authenticated: state?.user?.authenticated,
    isSessionChecked: state?.user?.isSessionChecked,
});


export default connect(mapStateToProps, {

})(Authentication(withRouter(CreateAdminUser)));
