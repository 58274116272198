import { Dialog } from 'primereact/dialog'
import React, { Component } from 'react'
import BaseComponent from '../../../common/BaseComponent'

 class InterviewStatus extends Component {

    goBack = () => {
        this.props.history.goBack()
    }
    render() {
        return (
            <Dialog
                header="Interview Status"
                visible={true}
                draggable={false}
                style={{ width: "80%", height: "50vh" }}
                onHide={this.goBack}
            >

                <p>Interview tracker</p>


            </Dialog>
        )
    }
}


export default BaseComponent(InterviewStatus);