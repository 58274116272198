import React, { Component } from "react";
import { Chart } from "primereact/chart";
import ServerlessService from "../../../Service/ServerlessService";
import { CONSULTANT_STATUS } from "../../../Utils/constants";
import { BasicLazyParams } from "../../../Utils/constants";

class CreatedByConsultant extends Component {
    constructor(props) {
        super(props);
        this.state = {
            consultants: [],
            chartData: null,
            isLoading: false,
            UsersList: [],
        };
        this.serverlessService = new ServerlessService();
    }

    componentDidMount() {
        this.fetchUsersAndConsultants();
    }

    fetchUsersAndConsultants = () => {
        const urlEndUsers = "/admin/all";
        const urlEndConsultants = "/consultants/consultant/all";

        Promise.all([
            this.serverlessService.post(urlEndUsers, {}, true),
            this.serverlessService.get(urlEndConsultants, {}, true),
        ])
            .then((responses) => {
                const usersResponse = responses[0];
                const consultantsResponse = responses[1];
                
                if (
                    usersResponse &&
                    usersResponse.status &&
                    usersResponse.res.status &&
                    consultantsResponse &&
                    consultantsResponse.status &&
                    consultantsResponse.res.status
                ) {
                    const users = usersResponse.res.data;
                    const consultants = consultantsResponse.res.data;
                    this.setState({ UsersList: users });
                    this.prepareChartData(users, consultants);
                }
            })
            .catch((error) => {
                console.error("Error fetching users and consultants:", error);
            });
    };

    prepareChartData(users, consultants) {
        const chartData = {
            labels: users.map((user) => user.name),
            datasets: [
                {
                    label: "Total Profiles",
                    backgroundColor: "#42A5F5",
                    data: users.map((user) =>
                        consultants.filter((consultant) => consultant.createdBy === user._id).length
                    ),
                },
            ],
        };

        this.setState({ chartData });
    }

    render() {
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <h2>Total Profiles by Users Category</h2>
                    {this.state.chartData && <Chart type="bar" data={this.state.chartData} />}
                </div>
            </div>
        );
    }
}

export default CreatedByConsultant;
