import { ACTIONS } from '../actionTypes';
import axios from 'axios';
import { SERVERLESS_URL } from '../../Service/config';

import { logOut, removeNullUndefinedKeys } from '../../Utils';


const dispatchRequestNote = data => ({
    type: ACTIONS.REQUEST_CONSULTANT_NOTE,
    data
});

const dispatchReceivedNote = (data) => ({
    type: ACTIONS.RECEIVED_CONSULTANT_NOTE,
    data
});

const dispatchErrorNote = err => ({
    type: ACTIONS.ERROR_CONSULTANT_NOTE,
    err
});

const dispatchClearNote = () => ({
    type: ACTIONS.CLEAR_CONSULTANT_NOTE
});

const dispatchAddNote = data => ({
    type: ACTIONS.REQUEST_ADD_NOTE,
    data
});

const dispatchReceivedAddNote = (data) => ({
    type: ACTIONS.RECEIVED_ADD_NOTE,
    data
});

const dispatchErrorAddNote = err => ({
    type: ACTIONS.ERROR_ADD_NOTE,
    err
});



export const getNotes = (_id, data) => (dispatch, getState) => {
    let _data;
    // debugger
    const currentState = getState().notes;
    console.log(currentState, "currentstate")
    if (data?.lazyParams) {
        currentState.lazyParams = {
            ...currentState.lazyParams,
            ...removeNullUndefinedKeys(data.lazyParams)
        }
    }
    const { lazyParams } = currentState;
    console.log(data, lazyParams, currentState, "lazycheck")

    dispatch(dispatchRequestNote({ ...data, _id }));

    axios({
        method: 'get',
        url: `${SERVERLESS_URL}/consultants/notes/${_id}?limit=${lazyParams.rows}&page=${lazyParams.page + 1
            }`,
        headers: {
            'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
            'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`
        }
    }).then((res) => {
        _data = res.data
        console.log(res.data, "documentEditing action")
        if (res && res.data) {
            dispatch(dispatchReceivedNote(res.data));
        } else {
            dispatch(dispatchErrorNote({
                message: 'Some error occurred'
            }));
        }
    }).catch(e => {
        if (e?.response?.status > 400 && e?.response?.status < 500) {
            logOut()
        }
        dispatch(dispatchErrorNote(e));
    });
}


export const addNote = (data) => (dispatch) => {

    dispatch(dispatchAddNote(data));
    axios({
        method: 'post',
        url: `${SERVERLESS_URL}/consultants/notes/add`,
        headers: {
            'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
            'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`
        },
        data
    }).then((res) => {
        // debugger

        console.log(res.data, "documentEditing action")
        if (res && res.data) {
            dispatch(dispatchReceivedAddNote(res.data?.data));
        } else {
            dispatch(dispatchErrorAddNote({
                message: 'Some error occurred'
            }));
        }
    }).catch(e => {
        if (e?.response?.status > 400 && e?.response?.status < 500) {
            logOut()
        }
        dispatch(dispatchErrorAddNote(e));
    });
}




export const clearNotes = () => dispatchClearNote();