import React, { Component } from 'react'
import ServerlessService from "../../../Service/ServerlessService";
import { logOut } from '../../../Utils';


export default class CreditsCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            credits: 0,
        }
        this.serverlessService = new ServerlessService();
    }

    getCredits = () => {
        this.setState({
            isLoading: true
        });
        const url = `/user/get-credits`;



        this.serverlessService
            .get(url, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                        credits: res.res.data,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                console.log(e);
                this.setState({
                    isLoading: false,
                });
            });

    }

    componentDidMount() {
        this.getCredits();
    }

    render() {
        return (
            <div className="shadow-2 surface-card p-4 border-2 border-transparent cursor-pointer border-blue-500">
                Total Credits : {this.state.credits}
            </div>
        )
    }
}
