import { ACTIONS } from '../actionTypes';
import axios from 'axios';
import { SERVERLESS_URL } from '../../Service/config';
import { BasicLazyParams, CONSULTANT_STATUS } from '../../Utils/constants';
import { logOut, removeNullUndefinedKeys } from '../../Utils';



const intialTableFilter = {
    lazyParams: {
        ...BasicLazyParams,
        sortField: "createdAt",
        sortOrder: 1,
    },
    globalSearch: ''
}




const dispatchRequestContact = data => ({
    type: ACTIONS.REQUEST_CONTACT,
    data
}

);

const dispatchReceivedContact = (data) => ({
    type: ACTIONS.RECEIVED_CONTACT,
    data
}
);

const dispatchErrorContact = err => ({
    type: ACTIONS.ERROR_CONTACT,
    err
});

const dispatchClearContact = () => ({
    type: ACTIONS.CLEAR_CONTACT
});


export const getContact = (data) => (dispatch, getState) => {
    let _data;

    const currentState = getState().consultants;
    console.log(currentState, "currentstate")
    if (data?.lazyParams) {
        currentState.lazyParams = {
            ...currentState.lazyParams,
            ...removeNullUndefinedKeys(data.lazyParams)
        }
    }
    if (data?.globalSearch !== undefined) {
        currentState.globalSearch = data.globalSearch;
    }

    const { lazyParams, globalSearch } = currentState;
    console.log(data, lazyParams, currentState, "lazycheck")
    dispatch(dispatchRequestContact(data));

    axios({
        method: 'get',
        url: `${SERVERLESS_URL}/consultants/contact?limit=${lazyParams.rows}&page=${lazyParams.page + 1
            }&search=${globalSearch}${lazyParams.sortField
                ? `&sort=${lazyParams.sortOrder === 1 ? "" : "-"}${lazyParams.sortField
                }`
                : ""
            }`,
        headers: {
            'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
            'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`
        },
        body: { status: CONSULTANT_STATUS.CREATED }
    }).then((res) => {
        _data = res.data
        console.log(res.data, "documentEditing action")
        if (res && res.data) {
            dispatch(dispatchReceivedContact(res.data));
        } else {
            dispatch(dispatchErrorContact({
                message: 'Some error occurred'
            }));
        }
    }).catch(e => {
        if (e?.response?.status > 400 && e?.response?.status < 500) {
            logOut()
        }
        dispatch(dispatchErrorContact(e));
    });
}

export const resetLazyContact = () => (dispatch) => {
    let _data;

    const { lazyParams, globalSearch } = intialTableFilter;
    dispatch(dispatchRequestContact(intialTableFilter));
    axios({
        method: 'get',
        url: `${SERVERLESS_URL}/consultants/contact?limit=${lazyParams.rows}&page=${lazyParams.page + 1
            }&search=${globalSearch}${lazyParams.sortField
                ? `&sort=${lazyParams.sortOrder === 1 ? "" : "-"}${lazyParams.sortField
                }`
                : ""
            }`,
        headers: {
            'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
            'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`
        },
        body: { status: CONSULTANT_STATUS.CREATED }
    }).then((res) => {
        _data = res.data
        if (res && res.data) {
            dispatch(dispatchReceivedContact(res.data));
        } else {
            dispatch(dispatchErrorContact({
                message: 'Some error occurred'
            }));
        }
    }).catch(e => {
        if (e?.response?.status > 400 && e?.response?.status < 500) {
            logOut()
        }
        dispatch(dispatchErrorContact(e));
    });
}


export const clearConsltants = () => dispatchClearContact();