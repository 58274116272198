import { BasicLazyParams } from "../../Utils/constants";
import { ACTIONS } from "../actionTypes";
export default (
    state = {
        questions: [],
        isLoading: false,
        error: false,
        errorMessage: "",
        lazyParams: {
            ...BasicLazyParams,
            sortField: "-createdAt",
            sortOrder: 1,
        },
        globalSearch: "",
        totalRecords: 0,
        selectedStatus: "",
        consultantAddedOrUpdated: false,
    },
    action
) => {
    switch (action.type) {
        case ACTIONS.REQUEST_QUESTIONBANK:
            return {
                ...state,
                lazyParams: {
                    ...state.lazyParams,
                    ...action?.data?.lazyParams,
                },
                globalSearch: action?.data?.globalSearch || state?.globalSearch,
                selectedStatus: action?.data?.status,
                isLoading: true,
                error: false,
            };

        case ACTIONS.RECEIVED_QUESTIONBANK:
            return {
                ...state,
                isLoading: false,
                questions: action?.data?.data,
                totalRecords: action?.data?.totalRecords,
                lazyParams: {
                    ...state.lazyParams,
                    ...action?.data?.lazyParams,
                },
            };
        case ACTIONS.ERROR_QUESTIONBANK:
            return {
                ...state,
                isLoading: false,
                error: true,
                errorMessage: action?.err?.message,
            };
        case ACTIONS.CLEAR_QUESTIONBANK:
            return {
                isLoading: false,
                error: false,
                errorMessage: "",
                questions: [],
            };

        case ACTIONS.REQUEST_ADD_QUESTIONBANK:
            return {
                ...state,

                error: false,
            };
        case ACTIONS.RECEIVED_ADD_QUESTIONBANK:
            return {
                ...state,
                questions: [action?.data, ...state.questions],
                totalRecords: state?.totalRecords + 1,
                isLoading: false,
                consultantAddedOrUpdated: !state.consultantAddedOrUpdated,
            };
        case ACTIONS.RECEIVED_UPDATE_QUESTIONBANK:
            return {
                ...state,
                questions: state.questions.map((a) => {
                    console.log(a)
                    if (a._id === action?.data._id) {
                        return action?.data;
                    }
                    return a;
                }),
                totalRecords: state?.totalRecords + 1,
                isLoading: false,
                consultantAddedOrUpdated: !state.consultantAddedOrUpdated,
            };

        case ACTIONS.RECEIVED_DELETE_QUESTIONBANK:
            return {
                ...state,
                questions: state.questions.filter(
                    (a) => a._id !== action?.data?._id
                ),
                totalRecords: state?.totalRecords - 1,
                isLoading: false,
                consultantAddedOrUpdated: !state.consultantAddedOrUpdated,
            };
        case ACTIONS.ERROR_ADD_QUESTIONBANK:
            return {
                ...state,
                error: true,
                errorMessage: action?.err?.message,
            };

        default:
            return state;
    }
};
