import { BasicLazyParams } from "../../Utils/constants";
import { ACTIONS } from "../actionTypes";
export default (
    state = {
        tags: [],
        consultants: [],
        isLoading: false,
        error: false,
        errorMessage: "",
        lazyParams: {
            ...BasicLazyParams,
            sortField: "-createdAt",
            sortOrder: 1,
        },
        globalSearch: "",
        selectedStatus: '',
        totalRecords: 0,
        consultantAddedOrUpdated: false
    },
    action
) => {
    switch (action.type) {
        case ACTIONS.REQUEST_CONSULTANTS:
            return {
                ...state,
                lazyParams: {
                    ...state.lazyParams,
                    ...action?.data?.lazyParams,
                },
                globalSearch: action?.data?.globalSearch || state?.globalSearch,
                selectedStatus: action?.data?.status,
                isLoading: true,
                error: false,
            };
        case ACTIONS.RECEIVED_CONSULTANTS:
            return {
                ...state,
                isLoading: false,
                consultants: action?.data?.data,
                totalRecords: action?.data?.totalRecords,
            };
        case ACTIONS.ERROR_CONSULTANTS:
            return {
                ...state,
                isLoading: false,
                error: true,
                errorMessage: action?.err?.message,
            };
        case ACTIONS.CLEAR_CONSULTANTS:
            return {
                orgInfo: null,
                isLoading: false,
                error: false,
                errorMessage: "",
            };

        case ACTIONS.REQUEST_ADD_CONSULTANTS:
            return {
                ...state,

                error: false,
            };
        case ACTIONS.RECEIVED_ADD_CONSULTANTS:
            return {
                ...state,
                consultants: [action?.data, ...state.consultants],
                totalRecords: state?.totalRecords + 1,
                isLoading: false,
                consultantAddedOrUpdated: !state.consultantAddedOrUpdated
            };
        case ACTIONS.RECEIVED_UPDATE_CONSULTANTS:
            return {
                ...state,
                consultants: state.consultants.map(a => {
                    if (a._id === action?.data._id) {
                        return action?.data;
                    }
                    return a
                }),
                totalRecords: state?.totalRecords + 1,
                isLoading: false,
                consultantAddedOrUpdated: !state.consultantAddedOrUpdated
            };

        case ACTIONS.RECEIVED_DELETE_CONSULTANTS:
            
            return {
                ...state,
                consultants: state.consultants.filter(a => a._id !== action?.data?._id),
                totalRecords: state?.totalRecords - 1,
                isLoading: false,
                consultantAddedOrUpdated: !state.consultantAddedOrUpdated
            };
        case ACTIONS.ERROR_ADD_CONSULTANTS:
            return {
                ...state,
                error: true,
                errorMessage: action?.err?.message,
            };
        default:
            return state;
    }
};
