import React, { Component } from 'react'
import ProfileAttachment from './profileAttachment';
import { CLOUDFRONT_ENDPOINT } from '../../../Service/config';
export default class ContactDetails extends Component {




    render() {

        const { profile } = this.props;
        console.log(profile, 'ddd')
        return (
            <>
                <div className="grid grid-nogutter border-top-1  pt-2">

                    <div className="col-12 md:col-6 p-3">
                        <div className="text-500 font-medium mb-2">State</div>
                        <div className="text-900">{profile?.state}</div>
                    </div>
                    <div className="col-12 md:col-6 p-3">
                        <div className="text-500 font-medium mb-2">Country</div>
                        <div className="text-900">{profile?.country}</div>
                    </div>
                    <div className="col-12 md:col-6 p-3">
                        <div className="text-500 font-medium mb-2">City</div>
                        <div className="text-900">{profile?.city}</div>
                    </div>
                    <div className="col-12 md:col-6 p-3">
                        <div className="text-500 font-medium mb-2">Address</div>
                        <div className="text-900">{profile?.address}</div>
                    </div>
                    <div className="col-12 md:col-6 p-3">
                        <div className="text-500 font-medium mb-2">Phone</div>
                        <div className="text-900">{profile?.phoneNumber}</div>
                    </div>
                    <div className="col-12 md:col-6 p-3">
                        <div className="text-500 font-medium mb-2">Email</div>
                        <div className="text-900">{profile?.email}</div>
                    </div>
                </div>
                <div>
                    <div className="col-12 sp-mt2">
                        <p className='text-label' >Attachments<span className='sp-required'>*</span></p>
                        <div className="attachment-wrapper" >
                            <div className="grid  -mt-3 -ml-3 -mr-3">
                                {profile?.attachments?.map((fileInfo, ind) => {
                                    return <>
                                        <ProfileAttachment _removefile={this._removefile} isShowRemoveBtn={false} key={'t' + ind} imageSrc={CLOUDFRONT_ENDPOINT + fileInfo.key} isBlob={false} />
                                    </>
                                })}
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}
