import React, { Component } from "react";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { BreadCrumb } from "primereact/breadcrumb";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import LoadingComponent from "../../../LoadingComponent";
import { Chip } from "primereact/chip";
import { Dialog } from "primereact/dialog";
import { BasicLazyParams } from "../../../Utils/constants";
import ServerlessService from "../../../Service/ServerlessService";
import { Tooltip } from "primereact/tooltip";
import Authorize, { PERMISSIONS } from "../../../session/authorize";
import { Card } from "primereact/card";
import BaseComponent from "../../common/BaseComponent";
import {
    getCopyHelper,
    resetLazyCopyHelper,
    deleteCopyhelper,
} from "../../../store/actions/copy-helperActions";
import { logOut, warningDailogInit } from "../../../Utils";
import TextExpandable from "../../utils/expand";
import { Toolbar } from "primereact/toolbar";
import LinesEllipsis from 'react-lines-ellipsis'

class CopyHelper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowView: false,
            viewTable: [],
            visible: false,
            isShowConfirmationDialog: false,
            consultants: [],
            customers: [],
            isShowAssignTable: false,
            isShowUnAssignTable: false,
            editData: null,
            id: null,
            renewalData: null,
            tableHistorysData: null,
            isShowRenewal: false,
            globalSearch: "",
            copiedIndex: null,
            lazyParams: {
                ...BasicLazyParams,
                sortField: "createdAt",
                sortOrder: 1,
            },
            selectedReason: "",
            consultantId: "",
            consultant: "",
            warningDialog: warningDailogInit,
        };
        this.serverlessService = new ServerlessService();
    }

    onHide = () => {
        this.setState({
            visible: false,
            isShowRenewal: false,
            editData: null,
        });
    };

    onTableAddOrUpdate = () => {
        this.setState({
            visible: false,
            editData: null,
        });
    };

    deleteTable = () => {
        this.setState({
            isShowConfirmationDialog: false,
        });
        var _id = this.state.selectedId;

        this.props.deleteCopyhelper({ _id });
    };
    getCopyHelper = (_id) => {
        let urlEnd = `/consultants/consultant/id/${_id}`;

        this.setState({
            isLoading: true,
            consultants: [],
        });

        const url = `${urlEnd}`;
        this.serverlessService
            .get(url, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                        consultant: res?.res?.data,
                        totalRecords: res.res.totalRecords,
                    });

                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };
    componentDidMount() {
        if (this.props?.params?.id) {
            this.setState({
                consultantId: this.props.params.id,
            });
        }

        this.props.getCopyHelper({ consultant: this.props.params.id });
        this.getCopyHelper(this.props.params.id);
    }

    footerContentfotDelete = () => {
        <div>
            <Button
                label="Cancel"
                className=" p-button-text hover:bg-primary "
                onClick={this.onCloseConfirmation}
            />
            <Button
                label="Ok"
                onClick={this.deleteTable()}
                autoFocus
                className=" p-button-primary "
            />
        </div>;
    };

    showDeleteTableDialog = (id) => {
        this.setState({
            isShowConfirmationDialog: true,
            selectedId: id,
        });
    };

    footerContentforDelete = () => (
        <div>
            <Button
                label="Cancel"
                className=" p-button-text hover:bg-primary "
                onClick={() => {
                    this.setState({
                        isShowConfirmationDialog: false,
                    });
                }}
            />
            <Button
                label="Ok"
                onClick={this.deleteTable}
                autoFocus
                className=" p-button-primary "
            />
        </div>
    );

    actionsBody = (rowData) => {
        return (
            <div className="p-grid row m-2">
                <Authorize permId={PERMISSIONS.CONSULTANTS_EDIT}>
                    <div className="col-1 ml-2 mr-2">
                        <span
                            onClick={() => {
                                const _rowData = { ...rowData };
                                this.props.history.push(
                                    `/admin/copy-helper/edit/${rowData._id}`,
                                    { fromOperation: "edit" }
                                );
                            }}
                            data-pr-tooltip="Edit Info"
                            data-pr-position="top"
                            className={`p-text tableEdit${rowData._id}`}
                        >
                            <i className="pi pi-pencil" />
                        </span>
                        <Tooltip target={`.tableEdit${rowData._id}`} />
                    </div>
                </Authorize>

                <Authorize permId={PERMISSIONS.CONSULTANTS_DELETE}>
                    <div className="col-1 ml-2 mr-2">
                        <span
                            onClick={() =>
                                this.showDeleteTableDialog(rowData._id)
                            }
                            data-pr-tooltip="Delete Info"
                            data-pr-position="top"
                            className={`p-text tableDelete${rowData._id}`}
                        >
                            <i className="pi pi-trash" />
                        </span>
                        <Tooltip target={`.tableDelete${rowData._id}`} />
                    </div>
                </Authorize>
                <Authorize permId={PERMISSIONS.CONSULTANTS_EDIT}>
                    <div className="col-1 ml-2 mr-2">
                        <span
                            onClick={() => {

                                this.previewQuestion(rowData)
                            }}
                            data-pr-tooltip="Preview"
                            data-pr-position="top"
                            className={`p-text tableEdit${rowData._id}`}
                        >
                            <i className="pi pi-eye" />
                        </span>
                        <Tooltip target={`.tableEdit${rowData._id}`} />
                    </div>
                </Authorize>
            </div>
        );
    };
    handleCopy = (text, index) => {
        navigator.clipboard
            .writeText(text)
            .then(() => {
                this.setState({ copiedIndex: index });
                setTimeout(() => {
                    this.setState({ copiedIndex: null });
                }, 2000);
            })
            .catch((err) => {
                console.error("Error copying text: ", err);
            });
    };

    handleMouseEnter = (index) => {
        this.setState({ hoveredIndex: index });
    };

    handleMouseLeave = () => {
        this.setState({ hoveredIndex: null });
    };

    exportCSV = () => {
        this.dt.exportCSV();
    };


    previewQuestion = (rowData) => {

        this.setState({
            warningDialog: {
                visible: true,
                headerMsg: rowData?.question,
                message: rowData.answer
            },
            //   editReseller: rowData
        });

    }

    render() {
        const { copiedIndex, consultantId } = this.state;
        const { copyHelper } = this.props;

        // const consultantElements = copyHelper?.map((consultant, index) => (
        //     <li
        //         key={index}
        //         className={
        //             index % 2 === 0
        //                 ? "flex align-items-center py-3 px-2 flex-wrap surface-ground"
        //                 : "flex align-items-center py-3 px-2 flex-wrap"
        //         }
        //         onMouseEnter={() => this.handleMouseEnter(index)}
        //         onMouseLeave={() => this.handleMouseLeave(index)}
        //         style={{
        //             backgroundColor:
        //                 this.state.hoveredIndex === index
        //                     ? "#f0f0f0"
        //                     : "transparent",
        //         }}
        //     >
        //         <div className="flex w-full">
        //             <div className="text-500 w-full md:w-6 font-medium">
        //                 {consultant.question}
        //             </div>
        //             <div
        //                 className="text-900 w-full md:w-2 line-height-3"
        //                 style={{ position: "relative" }}
        //             >
        //                 <div
        //                     className={`tooltip-target-${index}`}
        //                     onClick={() =>
        //                         this.handleCopy(consultant.answer, index)
        //                     }
        //                 >
        //                     {consultant.answer}
        //                 </div>

        //                 <Tooltip
        //                     target={`.tooltip-target-${index}`}
        //                     content="Click To Copy"
        //                     position="top"
        //                 />

        //                 {copiedIndex === index && (
        //                     <Chip
        //                         className="ml-2 p-chip-success"
        //                         icon="pi pi-copy"
        //                         label="Copied"
        //                         style={{
        //                             backgroundColor: "#4CAF50",
        //                             color: "white",
        //                         }}
        //                     />
        //                 )}
        //             </div>
        //             <div className="text-900 w-full md:w-2 line-height-3">

        //             </div>
        //         </div>
        //     </li>
        // ));

        const header = (
            <div className="">
                <div class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 top-100 z-1 shadow-2 lg:shadow-none border-1 lg:border-none border-gray-800">
                    <ul class="list-none flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li>
                            <p className="mx-0 my-1 ss-header">Copy Helper</p>
                        </li>
                    </ul>
                    <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li class="flex-order-2 lg:flex-order-0">
                            <ul className="right-0">
                                <ul className="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                                    <Authorize
                                        permId={
                                            PERMISSIONS.MARKETING_COPYHELPER_SEARCH
                                        }
                                    >
                                        <li className="flex-order-2 lg:flex-order-0 ml-4 ">
                                            {this.state.showSearch && (
                                                <InputText
                                                    placeholder="Search"
                                                    className=""
                                                    value={
                                                        this.props.globalSearch
                                                    }
                                                    onChange={
                                                        this.onGlobalSearch
                                                    }
                                                />
                                            )}
                                        </li>
                                    </Authorize>
                                    <Authorize
                                        permId={
                                            PERMISSIONS.MARKETING_COPYHELPER_SEARCH
                                        }
                                    >
                                        <li className="flex-order-2 lg:flex-order-0 ml-4 cursor-pointer">
                                            <i
                                                data-pr-tooltip="Search"
                                                data-pr-position="bottom"
                                                className="pi pi-search text-base lg:text-2xl mr-2 ss-tbl-srch"
                                                onClick={(e) => {
                                                    this.setState({
                                                        showSearch:
                                                            !this.state
                                                                .showSearch,
                                                    });
                                                }}
                                            ></i>
                                        </li>
                                        <Tooltip
                                            className="table-li-tooltip"
                                            autoHide={false}
                                            target=".ss-tbl-srch"
                                        />
                                    </Authorize>
                                    <Authorize
                                        permId={
                                            PERMISSIONS.MARKETING_COPYHELPER_DOWNLOAD
                                        }
                                    >
                                        <li className="border-top-1 border-gray-800 lg:border-top-none ml-4 mr-4 cursor-pointer">
                                            <i
                                                data-pr-tooltip="Download"
                                                data-pr-position="bottom"
                                                className="pi pi-download  text-base lg:text-2xl mr-2 ss-tbl-dwnld"
                                                onClick={this.exportCSV}
                                            ></i>
                                        </li>
                                        <Tooltip
                                            className="table-li-tooltip"
                                            target=".ss-tbl-dwnld"
                                        />
                                    </Authorize>
                                </ul>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="flex justify-content-between flex-wrap">
                    <div class="flex font-bold border-round w-4"></div>
                    <div class="flex absolute right-0 align-items-right  font-bold border-round w-8 "></div>
                </div>
            </div>
        );

        const items = [{ label: "Admin" }, { label: "Marketing" }, { label: "Copy helper" }];

        const home = {
            icon: "pi pi-home",
            url: "http://localhost:3000/#/admin",
        };



        return (
            <>
                <div className="h-screen overflow-auto ss-data-table" style={{ marginBottom: '20px' }}>
                    <div className="font-bold block mb-2">
                        <h1>{`${this.state.consultant?.name} Details`}</h1>
                    </div>
                    <Authorize permId={PERMISSIONS.CONSULTANTS_CREATE}>
                        <Button
                            label="New"
                            icon="pi pi-plus"
                            className="m-3"
                            outlined
                            aria-label="Create"
                            onClick={() =>
                                this.props.history.push(
                                    `/admin/copy-helper/add/${consultantId}`
                                )
                            }
                        />
                    </Authorize>

                    <Card>
                        <BreadCrumb model={items} home={home} />
                        <DataTable
                            ref={(el) => (this.dt = el)}
                            value={this.props.copyHelper}
                            header={header}
                            // onSort={this.onSort}
                            // onPage={this.onPage}
                            // onFilter={this.onFilter}
                            // first={this.props.lazyParams?.first}
                            // last={this.props.totalRecords}
                            // rows={this.props.lazyParams?.rows}
                            rows={400}
                            totalRecords={this.props.copyHelper?.length}

                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            rowsPerPageOptions={[
                                5, 10, 25, 50, 100, 200, 300, 500,
                            ]}
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                            scrollable
                            responsiveLayout="scroll"
                            rowHover
                            dataKey="_id"
                        >
                            <Column
                                field="question"
                                header="Question"
                            ></Column>
                            <Column
                                header="Document Status"
                                body={(rowData, { rowIndex }) => {
                                    return <>
                                        <div
                                            className={`tooltip-1-${rowIndex}`}
                                            onClick={() =>
                                                this.handleCopy(rowData.answer, rowIndex)
                                            }>
                                            <LinesEllipsis
                                                text={rowData.answer}
                                                maxLine='3'
                                                ellipsis='...'
                                                trimRight
                                                basedOn='letters'
                                            />
                                        </div>
                                        <Tooltip
                                            target={`.tooltip-1-${rowIndex}`}
                                            content="Click To Copy"
                                            position="top"
                                        />
                                        {
                                            copiedIndex === rowIndex && (
                                                <Chip
                                                    className="ml-2 p-chip-success"
                                                    icon="pi pi-copy"
                                                    label="Copied"
                                                    style={{
                                                        backgroundColor: "#4CAF50",
                                                        color: "white",
                                                    }} />)}
                                    </>
                                }}
                            ></Column>
                            <Column
                                header="Actions"
                                body={(rowData) =>
                                    this.actionsBody(rowData)
                                }
                            ></Column>
                        </DataTable>
                    </Card>
                </div>
                {this.state.isShowConfirmationDialog && (
                    <Dialog
                        header="Are you Sure ?"
                        visible={this.state.isShowConfirmationDialog}
                        style={{ width: "15vw" }}
                        onHide={this.onCloseConfirmation}
                        footer={this.footerContentforDelete}
                        draggable={false}
                    >
                        <p>Deleted ConsultantInfo can't be restored.</p>
                    </Dialog>
                )}
                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
                <Dialog
                    draggable={false}
                    blockScroll={true}
                    closeOnEscape={true}
                    className='sp-alert-box'
                    dismissableMask={true}
                    closable={false}
                    visible={this.state.warningDialog.visible} style={{ width: '80%', minWidth: '300px', height: '80vh' }}
                    footer={() => {
                        return (<div>
                            <Button label="No" className='p-button-outlined' onClick={() => { this.setState({ warningDialog: warningDailogInit, editReseller: null }) }} />
                            <Button label="Yes" className='sp-m-lr10' onClick={this.onConfirmChange} />

                        </div>)
                    }}
                    onHide={() => this.setState({ warningDialog: warningDailogInit, editReseller: null })}>
                    <p className='sp-alert-head'>{this.state.warningDialog.headerMsg}</p>
                    <div style={{ height: '300px' }} className='sp-alert-msg'>{this.state.warningDialog.message}</div>
                </Dialog>

                {this.props.isLoading ||
                    (this.state.isLoading && <LoadingComponent />)}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    copyHelper: state?.copyhelper?.copyHelper,
    totalRecords: state?.copyhelper?.totalRecords,
    lazyParams: state?.copyhelper?.lazyParams,
    isLoading: state?.copyhelper?.isLoading,
    consultantAddedOrUpdated: state?.copyhelper?.consultantAddedOrUpdated,
});

export default BaseComponent(CopyHelper, mapStateToProps, {
    getCopyHelper,
    resetLazyCopyHelper,
    deleteCopyhelper,
});
