import { ACTIONS } from '../actionTypes';
export default (state = {
    tags: [],
    consultants: [],
    isLoading: false,
    error: false,
    errorMessage: '',
}, action) => {
    switch (action.type) {
        case ACTIONS.REQUEST_CONSULTANT_TAGS:
            return {
                ...state,
                isLoading: true,
                error: false,
            };
        case ACTIONS.RECEIVED_CONSULTANT_TAGS:
            return {
                ...state,
                isLoading: false,
                tags: action.data.tags,
                consultants: action.data.consultants
            };
        case ACTIONS.ERROR_CONSULTANT_TAGS:
            return {
                ...state,
                isLoading: false,
                error: true,
                errorMessage: action.err.message
            };
        case ACTIONS.CLEAR_ORG_CONSULTANT_TAGS:
            return {
                orgInfo: null,
                isLoading: false,
                error: false,
                errorMessage: '',
            };
        default:
            return state;
    }
}