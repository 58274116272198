import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { Component } from "react";
import Service from "../../../Service";

import { withRouter } from 'react-router-dom';

import ServerlessService from "../../../Service/ServerlessService";
import { BasicLazyParams } from "../../../Utils/constants";
import CheckoutFields from "./userCheckout.json";
import {
  getFormFields,
  isFormValid,
  onEmailChange,
  onNumberChange,
  onTextChange,

} from "../../../Utils/formHelper";
import UserOrders from "./userOrders";
import { logOut, trimObj } from "../../../Utils";
import { Toast } from "primereact/toast";


class UserCheckout extends Component {
  constructor(props) {
    super(props);
    this.formFields = getFormFields(CheckoutFields, {});

    this.state = {
      billingDetails: this.formFields.data,
      formValidations: this.formFields.formValidations,

      isAddProfile: false,
      profiles: [],
      isShowOrders: false,
      isShowCheckout: true,
      lazyParams: {
        ...BasicLazyParams,
        sortField: "updatedAt",
        sortOrder: 1,
      },
      globalSearch: "",
    };
    this.service = new Service();
    this.serverlessService = new ServerlessService();
  }


  onPaymentSuccuess(obj) {
    console.log(obj);
    if (obj && obj.razorpay_payment_id) {
      this.setState({ isLoading: true });
      let objee = {};
      objee.paymentSuccess = obj;
      objee.paymentInit = this.state.response.paymentInit;
      objee.orderId = this.state.response.orderId;

      const url = `/orders/update`;
      this.serverlessService
        .post(url, objee, true)
        .then((res) => {

          if (res && res.status && res.res?.status) {
            this.setState({
              isLoading: false,
            });

            this.props.history.push('/orders')
          } else {
            this.setState({
              isLoading: false,
            });

            this.toast.show({
              severity: "error",
              summary: "Some error occurred",
              detail: res.errMessage,
              life: 3000,
            });
          }
        })
        .catch((e) => {
          if (e?.response?.status > 400 && e?.response?.status < 500) {
            logOut()
          }
          console.log(e);
          this.setState({
            isLoading: false,
          });
          this.toast.show({
            severity: "error",
            summary: "Some error occurred",
            detail: e.message,
            life: 3000,
          });
        })
    }
  }

  openPaymentWindow = (data) => {
    var thisObj = this;
    let options = {
      "key": data.key,
      "amount": data.paymentInit.amount,
      "name": "Q GROUP MEDIA",
      "currency": "INR",
      "order_id": data.paymentInit.id,
      "description": "Please complete the payment.",
      "handler": function (response) {
        thisObj.onPaymentSuccuess(response);
      },

      "theme": {
        "color": "#ca1c1c"
      }
    };
    var thisWindow = window;
    let rzp = new thisWindow.Razorpay(options);
    rzp.open();
  }



  placeOrder = () => {
    let billingDetails = trimObj(this.state.billingDetails)

    const formStatus = isFormValid(
      CheckoutFields,
      this.state.formValidations,
      billingDetails
    );

    let isCompleteFormValid = true;
    if (!formStatus.formValidations.isFormValid) {
      this.setState({
        formValidations: formStatus.formValidations,
      });
      isCompleteFormValid = false;
    }

    if (isCompleteFormValid) {
      const url = `/orders/initiate`;
      this.serverlessService
        .post(url, {
          billingDetails: billingDetails,
          selectedPackage: this.props?.selectedPackage
        }, true)
        .then((res) => {

          if (res && res.status && res.res?.status) {

            this.setState({
              isLoading: false,
              response: res.res.data
            }, () => {
              this.openPaymentWindow(res.res.data)
            });
          } else {
            this.setState({
              isLoading: false,
            });

            this.toast.show({
              severity: "error",
              summary: "Some error occurred",
              detail: res.errMessage,
              life: 3000,
            });
          }
        })
        .catch((e) => {
          if (e?.response?.status > 400 && e?.response?.status < 500) {
            logOut()
          }
          console.log(e);
          this.setState({
            isLoading: false,
          });
          this.toast.show({
            severity: "error",
            summary: "Some error occurred",
            detail: e.message,
            life: 3000,
          });
        })

    }
  };






  render() {
    const { selectedPackage } = this.props;
    const {
      billingDetails,
      formValidations,
      isShowCheckout,
      isShowOrders,
    } = this.state;
    console.log(this.props.selectedPackage, "props");

    return (
      <>
        {isShowCheckout && (
          <div className="surface-50 px-4 py-8 md:px-6 lg:px-8">
            <div className="text-900 font-medium text-3xl mb-5">
              Your Package/Subscription is almost done
            </div>
            <div className="grid -mr-3 -ml-3">
              <div className="col-12 lg:col-9 p-3">
                <div className="surface-card grid formgrid p-fluid border-round shadow-2 p-4">
                  <div className="col-12 mt-4 lg:mt-0 mb-4">
                    <div className="text-900 font-medium text-xl">
                      Billing Details
                    </div>
                  </div>
                  <div className="col-12 lg:col-6 field mb-0">
                    <label
                      htmlFor="name2"
                      className="text-900 font-medium mb-3"
                    >
                      Name
                    </label>
                    <InputText
                      onChange={(e) => {
                        onTextChange(
                          e.target.value,
                          "name",
                          this,
                          CheckoutFields,
                          billingDetails,
                          formValidations,
                          "billingDetails",
                          "formValidations"
                        );
                      }}
                      value={billingDetails?.name}
                    />
                    {formValidations &&
                      !formValidations.fields["name"]
                        .isValid && (
                        <p className="p-error">
                          {
                            formValidations.fields[
                              "name"
                            ].errorMsg
                          }
                        </p>
                      )}
                  </div>
                  <div className="col-12 lg:col-6 field mb-0">
                    <label
                      htmlFor="surname2"
                      className="text-900 font-medium mb-3"
                    >
                      Surname
                    </label>
                    <InputText
                      onChange={(e) => {
                        onTextChange(
                          e.target.value,
                          "surName",
                          this,
                          CheckoutFields,
                          billingDetails,
                          formValidations,
                          "billingDetails",
                          "formValidations"
                        );
                      }}
                      value={billingDetails?.surName}
                    />
                    {formValidations &&
                      !formValidations.fields["surName"]
                        .isValid && (
                        <p className="p-error">
                          {
                            formValidations.fields[
                              "surName"
                            ].errorMsg
                          }
                        </p>
                      )}
                  </div>
                  <div className="col-12 lg:col-6  field mb-0">
                    <label
                      htmlFor="phone"
                      className="text-900 font-medium mb-3"
                    >
                      Phone
                    </label>
                    <InputText
                      onChange={(e) => {
                        onNumberChange(
                          e.target.value,
                          "phone",
                          this,
                          CheckoutFields,
                          billingDetails,
                          formValidations,
                          "billingDetails",
                          "formValidations"
                        );
                      }}
                      value={billingDetails?.phone}
                    />
                    {formValidations &&
                      !formValidations.fields["phone"]
                        .isValid && (
                        <p className="p-error">
                          {
                            formValidations.fields[
                              "phone"
                            ].errorMsg
                          }
                        </p>
                      )}
                  </div>
                  <div className="col-12 lg:col-6  field mb-0">
                    <label
                      htmlFor="email"
                      className="text-900 font-medium mb-3"
                    >
                      Email
                    </label>
                    <InputText
                      onChange={(e) => {
                        onEmailChange(
                          e.target.value,
                          "email",
                          this,
                          CheckoutFields,
                          billingDetails,
                          formValidations,
                          "billingDetails",
                          "formValidations"
                        );
                      }}
                      value={billingDetails?.email}
                    />
                    {formValidations &&
                      !formValidations.fields["email"]
                        .isValid && (
                        <p className="p-error">
                          {
                            formValidations.fields[
                              "email"
                            ].errorMsg
                          }
                        </p>
                      )}
                  </div>
                  <div className="col-12 lg:col-6 field mb-0">
                    <label
                      htmlFor="pc2"
                      className="text-900 font-medium mb-3"
                    >
                      Postal Code
                    </label>
                    <InputText
                      onChange={(e) => {
                        onNumberChange(
                          e.target.value,
                          "postalCode",
                          this,
                          CheckoutFields,
                          billingDetails,
                          formValidations,
                          "billingDetails",
                          "formValidations"
                        );
                      }}
                      value={billingDetails?.postalCode}
                    />
                    {formValidations &&
                      !formValidations.fields[
                        "postalCode"
                      ].isValid && (
                        <p className="p-error">
                          {
                            formValidations.fields[
                              "postalCode"
                            ].errorMsg
                          }
                        </p>
                      )}
                  </div>
                  <div className="col-12 lg:col-6 field mb-0">
                    <label
                      htmlFor="city2"
                      className="text-900 font-medium mb-3"
                    >
                      City
                    </label>
                    <InputText
                      onChange={(e) => {
                        onTextChange(
                          e.target.value,
                          "city",
                          this,
                          CheckoutFields,
                          billingDetails,
                          formValidations,
                          "billingDetails",
                          "formValidations"
                        );
                      }}
                      value={billingDetails?.city}
                    />
                    {formValidations &&
                      !formValidations.fields["city"]
                        .isValid && (
                        <p className="p-error">
                          {
                            formValidations.fields[
                              "city"
                            ].errorMsg
                          }
                        </p>
                      )}
                  </div>
                  <div className="col-12 field mb-0">
                    <label
                      htmlFor="country2"
                      className="text-900 font-medium mb-3"
                    >
                      Country
                    </label>
                    <InputText
                      onChange={(e) => {
                        onTextChange(
                          e.target.value,
                          "country",
                          this,
                          CheckoutFields,
                          billingDetails,
                          formValidations,
                          "billingDetails",
                          "formValidations"
                        );
                      }}
                      value={billingDetails?.country}
                    />
                    {formValidations &&
                      !formValidations.fields["country"]
                        .isValid && (
                        <p className="p-error">
                          {
                            formValidations.fields[
                              "country"
                            ].errorMsg
                          }
                        </p>
                      )}
                  </div>

                  <div className="col-12 field mb-0">
                    <label
                      htmlFor="address3"
                      className="text-900 font-medium mb-3"
                    >
                      Full Address
                    </label>
                    <InputText
                      onChange={(e) => {
                        onTextChange(
                          e.target.value,
                          "apartment",
                          this,
                          CheckoutFields,
                          billingDetails,
                          formValidations,
                          "billingDetails",
                          "formValidations"
                        );
                      }}
                      value={billingDetails?.apartment}
                    />
                    {formValidations &&
                      !formValidations.fields["apartment"]
                        .isValid && (
                        <p className="p-error">
                          {
                            formValidations.fields[
                              "apartment"
                            ].errorMsg
                          }
                        </p>
                      )}
                  </div>


                </div>
              </div>
              <div className="col-12 lg:col-3 p-3">
                <div className="surface-card p-4 border-round shadow-2">
                  <div className="border-bottom-1 surface-border pb-4">
                    <span className="text-900 text-xl block">
                      OrderSummary
                    </span>
                  </div>
                  <div className="border-bottom-1 surface-border my-3 py-2">
                    <span className="text-900 text-xl block">
                      Credits -{selectedPackage?.credits}
                    </span>

                    <div className="flex justify-content-between mb-3">
                      <span className="text-900">
                        Price
                      </span>
                      <span className="text-900">
                        Rs.{selectedPackage?.price}
                      </span>
                    </div>


                    <div className="flex justify-content-between mb-3">
                      <span className="text-900">
                        Discount
                      </span>
                      <span className="text-900">
                        Rs.0
                      </span>
                    </div>
                  </div>
                  <div className="border-bottom-1 surface-border my-3 py-2">
                    <div className="flex justify-content-between mb-3">
                      <span className="text-900 font-medium">
                        Total
                      </span>
                      <span className="text-900 font-bold">
                        Rs.{selectedPackage?.price}
                      </span>
                    </div>

                  </div>
                  <Button
                    className="w-full border-none"
                    label="Place Order"
                    autoFocus
                    onClick={() => this.placeOrder()}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {isShowOrders && <UserOrders billingDetails={billingDetails} selectedPackage={selectedPackage}></UserOrders>}
        <Toast ref={(el) => this.toast = el} position="bottom-right" />
      </>
    );
  }
}

export default withRouter(UserCheckout);
