import React, { Component } from 'react'
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from "primereact/inputtextarea";
import { v4 as uuidv4 } from 'uuid';
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { getFormFields, isFormValid, onNumberChange, onTextChange } from '../../../Utils/formHelper';
import Service from '../../../Service';
import ServerlessService from '../../../Service/ServerlessService';
import { isValidFileSize, trimObj, bytesToSize, logOut } from '../../../Utils';
import formFields from './addProfile.json'
import { RadioButton } from 'primereact/radiobutton';
import { Calendar } from 'primereact/calendar';
import { Divider } from 'primereact/divider';
import FileSelect from '../../fileUpload/fileSelect';
import { CLOUDFRONT_ENDPOINT } from '../../../Service/config';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Authentication from '../../../session/index'
import LoadingComponent from '../../../LoadingComponent';
import { Toast } from 'primereact/toast';

import FileUpload from '../../common/fileUpload';
import { Ripple } from 'primereact/ripple';
import FileUploadWithCButton from '../../common/fileUploadWithCButton';
import axios from 'axios'
import ProfileAttachment from '../common/profileAttachment';


let _dummyProfile = {

    "firstName": "katkam",
    "lastName": "shashidhar",
    "profession": "Software engineer",
    "gender": "male",
    "motherTongue": "telugu",
    "dateOfBirth": "Tue Jun 11 1996 00:00:00 GMT+0530 (India Standard Time)",
    "religion": "Hindu",
    "caste": "bc-b",
    "subCaste": "padmashali",
    "height": "5.8",
    "color": "fair",
    "education": "bTech cse",
    "country": "India",
    "state": "telangana",
    "city": "hyderabad",
    "address": "."
};
class AddProfile extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(formFields, this.props.editProfile || { ..._dummyProfile, appType: process.env.REACT_APP_TARGET });
        this.state = {
            profile: this.formFields.data,
            formValidations: this.formFields.formValidations,
            isNewUser: false,
            privacyAccepted: false,
            isSubmitClicked: false,
            uploadedFilesInfo1: this.props.editProfile ? this.props.editProfile.profileUrl : null,
            uploadedFilesInfo: this.props.editProfile ? this.props.editProfile.attachments : []
        }

        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }

    _calculateAge(date) {
        let birthday = new Date(date) // birthday is a date;
        var ageDifMs = Date.now() - birthday.getTime();
        var ageDate = new Date(ageDifMs); // miliseconds from epoch
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    }

    onSubmit = async () => {


        let _profile = trimObj(this.state.profile)
        const formStatus = isFormValid(formFields, this.formFields.formValidations, _profile);




        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
                isSubmitClicked: true
            });
            isCompleteFormValid = false;
        }


        if (isCompleteFormValid) {
            _profile.age = this._calculateAge(_profile.dateOfBirth)



            if (this.state.uploadedFilesInfo1) {
                let abc = await this.uploadFilesToS3([this.state.uploadedFilesInfo1]);

                _profile.profileUrl = abc[0];

            }

            if (this.state.uploadedFilesInfo && this.state.uploadedFilesInfo.length) {
                this.state.uploadedFilesInfo = await this.uploadFilesToS3(this.state.uploadedFilesInfo);
                _profile.attachments = this.state.uploadedFilesInfo || [];

            }



            let data = _profile;
            data.orgCode = this.props.orgCode;
            data.phoneNumber = this.props.phoneNumber;
            data.email = this.props.email;

            console.log(this.state)

            this.setState({
                isLoading: true
            });
            var url = `/mprofiles/save`;
            this.props.onAfterProfiledAdded();

            if (this.props.editProfile) {
                url = `/mprofiles/update`;

            }

            this.serverlessService.post(url, data, true).then((res) => {

                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false
                    }, () => {
                        this.props.onAfterProfiledAdded()
                    })

                } else {

                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occurred', detail: res.res.message, life: 3000 });
                }

            }).catch(e => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                console.log(e);
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occurred', detail: e.message, life: 3000 });
            })




        }




    }




    _removefile = (fileInf) => {


        let uploadedFiles = [];
        this.state.uploadedFilesInfo.forEach((fileInfo) => {
            if (fileInfo.name !== fileInf.name || fileInfo != fileInf) {
                uploadedFiles = [...uploadedFiles, fileInfo];
            }
        });

        this.setState({
            uploadedFilesInfo: uploadedFiles
        });
    }

    showAttachment = (attachment) => {
        if (attachment.path) {
            window.open(attachment.path);
        } else {
            let src = URL.createObjectURL(attachment);
            window.open(src);
        }
    }

    convertBase64 = (file) => {

        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    uploadImage = async (event) => {

        const file = event.target.files[0];
        const base64 = await this.convertBase64(file);

        console.log(base64);
        this.setState((prevState) => {
            return {
                profile: {
                    ...prevState.profile,
                    profileUrl: base64
                }
            };
        });
    };


    _afterFilesUploaded = (files) => {

    }

    uploadFilesToS3 = async (files) => {
        let url = 'https://v2ao5dt0v3.execute-api.ap-south-1.amazonaws.com/prod/v1/data/presign/url-mat';
        let cloudFrontEndpoint = 'https://d1wqfechwjsewm.cloudfront.net/';
        let uploadedFiles = [];
        let thisObj = this;
        //  let files = e.target.files;
        for (let i = 0; i < files.length; i++) {



            if (!files[i].status) {

                let key = thisObj.props.user.user.orgId + '/' + uuidv4() + files[i].name.replace(/\s/g, "");
                let { data } = await axios.post(url, {
                    "type": "insert",
                    "key": key
                });
                let uploadStatus = await axios.put(data.data.presignedURL, files[i], {
                    headers: {
                        'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
                        "Content-Type": "binary/octet-stream",
                        "Access-Control-Allow-Origin": "*",
                    }
                });
                uploadedFiles.push({
                    path: cloudFrontEndpoint + data.data.contentStorageKey,
                    name: files[i].name,
                    type: files[i].type,
                    size: files[i].size,
                    key,
                    status: uploadStatus.status == 200 ? true : false
                });
            } else {
                uploadedFiles.push(files[i]);
            }
        }

        return uploadedFiles;
        //  console.log(uploadedFiles, 'uploadedFiles')
    }



    filesUploadedBindingInfo = (filesInfo = []) => {
        console.log('ddwdd', filesInfo)
        let temp;
        temp = filesInfo.map((fileInfo, ind) => {
            return <>
                {
                    fileInfo?.key ? <ProfileAttachment fileInfo={fileInfo} _removefile={this._removefile} isShowRemoveBtn={true} key={'t' + ind} imageSrc={CLOUDFRONT_ENDPOINT + fileInfo.key} isBlob={false} /> : <ProfileAttachment isShowRemoveBtn={true} key={fileInfo.name} fileInfo={fileInfo} isBlob={true} />
                }
            </>
        });
        return temp;
    }


    _filesUploadedBindingInfo = (filesInfo = []) => {
        let temp;
        temp = filesInfo.map((fileInfo) => {
            return <div key={fileInfo.name}>
                <div className="p-grid upload" dir="ltr">

                    <div style={{ width: '100%', overflow: 'hidden' }}>
                        <div style={{ width: '30px', float: 'right' }}>
                            <span className="btn-remove-file sp-float-right"
                                onClick={() => this._removefile(fileInfo)}><i className='pi pi-times' /></span>

                        </div>
                        <div style={{ marginRight: '30px' }} >
                            <p onClick={() => { this.showAttachment(fileInfo) }} className={`filename ${isValidFileSize(fileInfo.size) ? 'valid-file' : "invalid-file"}`}>{fileInfo.name} <span className='sp-right'> {bytesToSize(fileInfo.size)}</span></p>
                        </div>
                    </div>


                </div>
            </div>
        });
        return temp;
    }

    _afterFilesUploaded = (files) => {
        for (let i = 0; i < files.length; i++) {
            this.setState((prevState, prevProps) => ({
                uploadedFilesInfo: [...prevState.uploadedFilesInfo, files[i]]
            }));
        }
    }


    _afterFilesUploadedProfile = (files) => {
        for (let i = 0; i < files.length; i++) {
            this.setState((prevState, prevProps) => ({
                uploadedFilesInfo1: files[i]
            }));
        }
    }

    render() {
        const { profile, formValidations, privacyAccepted, isSubmitClicked, uploadedFilesInfo1 } = this.state;
        console.log(profile, 'profile-stae', this.state.uploadedFilesInfo1)
        console.log(this.state.profile, "profile body");

        return (<>

            <div className="surface-section px-4 py-8 md:px-6 lg:px-8">
                <div className="grid">
                    {/* <div className="col-12 lg:col-2">
                        <div className="text-900 font-medium text-xl mb-3">Profile</div>
                        <p className="m-0 p-0 text-600 line-height-3 mr-3">Odio euismod lacinia at quis risus sed vulputate odio.</p>
                    </div> */}
                    <div className="col-12 lg:col-12">
                        <div className="grid formgrid p-fluid">
                            <div className="field mb-4 col-12">
                                <label htmlFor="avatar1" className="font-medium text-900">Profile Image</label>
                                <div className="flex align-items-center">
                                    {
                                        // (uploadedFilesInfo1 && !uploadedFilesInfo1.key) &&
                                        <img src={uploadedFilesInfo1?.key ? (CLOUDFRONT_ENDPOINT + uploadedFilesInfo1.key) : uploadedFilesInfo1 ? URL.createObjectURL(uploadedFilesInfo1) : ''} style={{ height: '200px', width: '200px' }} alt="avatar-f-4" className="mr-4" />
                                    }
                                    {/* {

                                        profile.profileUrl &&
                                        <img src={CLOUDFRONT_ENDPOINT + (profile?.profileUrl?.key ? profile?.profileUrl?.key : '')} style={{ height: '200px' }} alt="avatar-f-4" className="mr-4" />
                                    } */}
                                    {/* <FileUpload onChange={(e) => {
                                        this.uploadImage(e);
                                    }} id="avatar1" mode="basic" name="avatar" url="" accept="image/*" maxFileSize={1000000} chooseOptions={{ className: 'p-button-plain p-button-outlined' }} chooseLabel="Upload Image" /> */}

                                    <FileUploadWithCButton id="wsw"
                                        multiple={true}
                                        afterFilesUploaded={this._afterFilesUploadedProfile}
                                    >
                                        <span>Choose</span>
                                    </FileUploadWithCButton>
                                    {formValidations && !formValidations.fields['profileUrl'].isValid && <p className="p-error">{formValidations.fields['profileUrl'].errorMsg}</p>}
                                </div>
                            </div>
                            <div className="field mb-4 col-12 md:col-6">
                                <label htmlFor="firstName" className="font-medium text-900">First Name</label>
                                <InputText id="firstName" type="text"
                                    value={profile.firstName}
                                    onChange={(e) => { onTextChange(e.target.value, 'firstName', this, formFields, profile, formValidations, 'profile', 'formValidations') }}
                                />
                                {formValidations && !formValidations.fields['firstName'].isValid && <p className="p-error">{formValidations.fields['firstName'].errorMsg}</p>}
                            </div>
                            <div className="field mb-4 col-12 md:col-6">
                                <label htmlFor="lastName" className="font-medium text-900">Last Name</label>
                                <InputText id="lastName" type="text" rows={5} autoResize
                                    value={profile.lastName}
                                    onChange={(e) => { onTextChange(e.target.value, 'lastName', this, formFields, profile, formValidations, 'profile', 'formValidations') }}

                                />
                                {formValidations && !formValidations.fields['lastName'].isValid && <p className="p-error">{formValidations.fields['lastName'].errorMsg}</p>}
                            </div>
                            <div className="field mb-4 col-12 md:col-6">
                                <label htmlFor="profession" className="font-medium text-900">Profession</label>
                                <InputText id="profession" type="text"
                                    value={profile.profession}
                                    onChange={(e) => { onTextChange(e.target.value, 'profession', this, formFields, profile, formValidations, 'profile', 'formValidations') }}

                                />
                                {formValidations && !formValidations.fields['profession'].isValid && <p className="p-error">{formValidations.fields['profession'].errorMsg}</p>}
                            </div>
                            <div className="field mb-4 col-12 md:col-6">
                                <label htmlFor="education" className="font-medium text-900">Education</label>
                                <InputText id="education" type="text"
                                    value={profile.education}
                                    onChange={(e) => { onTextChange(e.target.value, 'education', this, formFields, profile, formValidations, 'profile', 'formValidations') }}

                                />
                                {formValidations && !formValidations.fields['education'].isValid && <p className="p-error">{formValidations.fields['education'].errorMsg}</p>}
                            </div>
                            <div className="field mb-4 col-12 md:col-6">
                                <label htmlFor="dateOfBirth" className="font-medium text-900">Date Of Birth</label>
                                <Calendar value={profile.dateOfBirth ? new Date(profile.dateOfBirth) : null}
                                    showIcon={true}
                                    placeholder='Date of Birth'
                                    id='dateOfBirth'
                                    onChange={(e) => onTextChange(e?.value?.toString(), 'dateOfBirth', this, formFields, profile, formValidations, 'profile', 'formValidations')}
                                ></Calendar>
                                {formValidations && !formValidations.fields['dateOfBirth'].isValid && <p className="p-error">{formValidations.fields['dateOfBirth'].errorMsg}</p>}
                            </div>
                            <div className="field mb-4 col-12 md:col-6">
                                <label htmlFor="city1" className="font-medium text-900">Gender</label>
                                <div className="grid m-2">
                                    <div className="col-6 md:col-6">
                                        <div className=" justify-content-between ">
                                            <span className="mr-2">Male</span>
                                            <RadioButton name="gender" value="male"
                                                onChange={(e) => { onTextChange(e.value, 'gender', this, formFields, profile, formValidations, 'profile', 'formValidations') }}
                                                checked={profile.gender === 'male'}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-6 md:col-6">
                                        <div className=" justify-content-between align-items-center">
                                            <span className="mr-2">Female</span>
                                            <RadioButton name="gender" value="female"
                                                onChange={(e) => { onTextChange(e.value, 'gender', this, formFields, profile, formValidations, 'profile', 'formValidations') }}
                                                checked={profile.gender === 'female'}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {formValidations && !formValidations.fields['gender'].isValid && <p className="p-error">{formValidations.fields['gender'].errorMsg}</p>}
                            </div>
                            <div className="field mb-4 col-12 md:col-6">
                                <label htmlFor="motherTongue" className="font-medium text-900">Language</label>
                                <InputText id="motherTongue" type="text"
                                    value={profile.motherTongue}
                                    onChange={(e) => { onTextChange(e.target.value, 'motherTongue', this, formFields, profile, formValidations, 'profile', 'formValidations') }}

                                />
                                {formValidations && !formValidations.fields['motherTongue'].isValid && <p className="p-error">{formValidations.fields['motherTongue'].errorMsg}</p>}
                            </div>
                            <div className="field mb-4 col-12 md:col-6">
                                <label htmlFor="religion" className="font-medium text-900">Religion</label>
                                <InputText id="religion" type="text"
                                    value={profile.religion}
                                    onChange={(e) => { onTextChange(e.target.value, 'religion', this, formFields, profile, formValidations, 'profile', 'formValidations') }}
                                />
                                {formValidations && !formValidations.fields['religion'].isValid && <p className="p-error">{formValidations.fields['religion'].errorMsg}</p>}
                            </div>
                            <div className="field mb-4 col-12 md:col-6">
                                <label htmlFor="caste" className="font-medium text-900">Caste</label>
                                <InputText id="caste" type="text"
                                    value={profile.caste}
                                    onChange={(e) => { onTextChange(e.target.value, 'caste', this, formFields, profile, formValidations, 'profile', 'formValidations') }}
                                />
                                {formValidations && !formValidations.fields['caste'].isValid && <p className="p-error">{formValidations.fields['caste'].errorMsg}</p>}
                            </div>
                            <div className="field mb-4 col-12 md:col-6">
                                <label htmlFor="subCaste" className="font-medium text-900">Sub Caste</label>
                                <InputText id="subCaste" type="text"
                                    value={profile.subCaste}
                                    onChange={(e) => { onTextChange(e.target.value, 'subCaste', this, formFields, profile, formValidations, 'profile', 'formValidations') }}
                                />
                                {formValidations && !formValidations.fields['subCaste'].isValid && <p className="p-error">{formValidations.fields['subCaste'].errorMsg}</p>}
                            </div>
                            <div className="field mb-4 col-12 md:col-6">
                                <label htmlFor="color" className="font-medium text-900">Color</label>
                                <InputText id="color" type="text"
                                    value={profile.color}
                                    onChange={(e) => { onTextChange(e.target.value, 'color', this, formFields, profile, formValidations, 'profile', 'formValidations') }}
                                />
                                {formValidations && !formValidations.fields['color'].isValid && <p className="p-error">{formValidations.fields['color'].errorMsg}</p>}
                            </div>
                            <div className="field mb-4 col-12 md:col-6">
                                <label htmlFor="height" className="font-medium text-900">Height</label>
                                <div className="p-inputgroup">

                                    <InputText id="height" type="text"
                                        value={profile.height}
                                        onChange={(e) => { onTextChange(e.target.value, 'height', this, formFields, profile, formValidations, 'profile', 'formValidations') }}
                                    />
                                    <span className="p-inputgroup-addon">Feet</span>
                                </div>
                                {formValidations && !formValidations.fields['height'].isValid && <p className="p-error">{formValidations.fields['height'].errorMsg}</p>}
                            </div>
                            <div className="field mb-4 col-12 md:col-6">
                                <label htmlFor="height" className="font-medium text-900">Salary</label>
                                <div className="p-inputgroup">

                                    <InputText id="salary" type="text"
                                        value={profile.salary}
                                        onChange={(e) => { onNumberChange(e.target.value, 'salary', this, formFields, profile, formValidations, 'profile', 'formValidations') }}
                                    />
                                    <span className="p-inputgroup-addon">per month</span>
                                </div>
                                {formValidations && !formValidations.fields['salary'].isValid && <p className="p-error">{formValidations.fields['salary'].errorMsg}</p>}
                            </div>
                            <div className="field mb-4 col-12 md:col-6">
                                <label htmlFor="country" className="font-medium text-900">Country</label>
                                <InputText id="country" type="text"
                                    value={profile.country}
                                    onChange={(e) => { onTextChange(e.target.value, 'country', this, formFields, profile, formValidations, 'profile', 'formValidations') }}
                                />
                                {formValidations && !formValidations.fields['country'].isValid && <p className="p-error">{formValidations.fields['country'].errorMsg}</p>}
                            </div>
                            <div className="field mb-4 col-12 md:col-6">
                                <label htmlFor="state" className="font-medium text-900">State</label>
                                <InputText id="state" type="text"
                                    value={profile.state}
                                    onChange={(e) => { onTextChange(e.target.value, 'state', this, formFields, profile, formValidations, 'profile', 'formValidations') }}
                                />
                                {formValidations && !formValidations.fields['state'].isValid && <p className="p-error">{formValidations.fields['state'].errorMsg}</p>}
                            </div>
                            <div className="field mb-4 col-12 md:col-6">
                                <label htmlFor="city" className="font-medium text-900">City</label>
                                <InputText id="city" type="text"
                                    value={profile.city}
                                    onChange={(e) => { onTextChange(e.target.value, 'city', this, formFields, profile, formValidations, 'profile', 'formValidations') }}
                                />
                                {formValidations && !formValidations.fields['city'].isValid && <p className="p-error">{formValidations.fields['city'].errorMsg}</p>}
                            </div>
                            <div className="field mb-4 col-12 md:col-6">
                                <label htmlFor="address" className="font-medium text-900">Village and Mandal</label>
                                <InputText id="address" type="text"
                                    value={profile.address}

                                    onChange={(e) => { onTextChange(e.target.value, 'address', this, formFields, profile, formValidations, 'profile', 'formValidations') }}
                                />
                                {formValidations && !formValidations.fields['address'].isValid && <p className="p-error">{formValidations.fields['address'].errorMsg}</p>}
                            </div>
                            <div className="field mb-4 col-12 md:col-6">
                                <label htmlFor="additionalInfo" className="font-medium text-900">Additional Info</label>
                                <InputText id="additionalInfo" type="text"
                                    value={profile.additionalInfo}

                                    onChange={(e) => { onTextChange(e.target.value, 'additionalInfo', this, formFields, profile, formValidations, 'profile', 'formValidations') }}
                                />
                                {formValidations && !formValidations.fields['additionalInfo'].isValid && <p className="p-error">{formValidations.fields['additionalInfo'].errorMsg}</p>}
                            </div>
                        </div>
                        {/* <div className="grid ">

                            <div className="col-12 sp-mt2">
                                <p className='text-label' >Attachments<span className='sp-required'>*</span></p>
                                <div className="attachment-wrapper" >
                                    <div className="grid  -mt-3 -ml-3 -mr-3">
                                        {profile.attachments.map((attachent, ind) => {
                                            return <ProfileAttachment key={'t' + ind} imageSrc={CLOUDFRONT_ENDPOINT + attachent} isBlob={false} />
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div> */}


                        <div className="grid ">

                            <div className="col-12 sp-mt2">
                                <p className='text-label' >Attachments<span className='sp-required'>*</span></p>
                                <div className="attachment-wrapper" >
                                    <div className="grid  -mt-3 -ml-3 -mr-3">
                                        {this.filesUploadedBindingInfo(this.state.uploadedFilesInfo)}

                                    </div>
                                    <p className="sp-danger">{this.state.needHelpErr?.filesErr}</p>
                                    <div className='sp-clearFix'></div>

                                    <div className='file-upload-w'>
                                        <FileUpload id="returnWindow"
                                            multiple={true}
                                            afterFilesUploaded={this._afterFilesUploaded}
                                        />
                                    </div>
                                </div>
                                <p className='upload-note sp-mt20'><span>Note:</span>Attachments can not be more than 20MB.</p>


                            </div>
                        </div>

                        <Divider />

                        <div className="field mb-4 col-12">
                            <label htmlFor="privacy1" className="font-medium text-900">Privacy</label>
                            <div className="flex align-items-center">
                                <Checkbox inputId="privacy1" checked={privacyAccepted} onChange={(e) => {

                                    this.setState({
                                        privacyAccepted: e.target.checked
                                    })
                                }} />
                                <span className="ml-2 text-900">I am agree to share my profile to  </span>
                            </div>
                            {isSubmitClicked && !privacyAccepted && <p className="p-error">{'Please agree to the terms.'}</p>}
                        </div>


                        <div className="col-12">
                            <Button label="Save" onClick={this.onSubmit} className="w-auto mt-3" />
                            <Button label="Cancel" className='w-auto ml-3 p-button-outlined' onClick={this.props.onCancel} />
                        </div>

                    </div>
                </div>
            </div>
            <Toast ref={(el) => this.toast = el} position="bottom-right" />
            {
                this.state.isLoading && <>
                    <LoadingComponent />
                </>
            }

        </>
        )
    }

}




const mapStateToProps = (state) => ({
    user: state.user,
    userType: state?.user?.userType,
    phoneNumber: state?.user?.user?.phoneNumber,
    email: state?.user?.user?.email,
    authenticated: state?.user?.authenticated,
    orgCode: state?.orgData?.orgCode,
    isLoading: state?.orgData?.isLoading,
});


export default connect(mapStateToProps, {
    //  saveUserSession,
})(Authentication(withRouter(AddProfile)));
