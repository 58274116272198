import React, { Component } from "react";

import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import LoadingComponent from "../../../../LoadingComponent";
import ServerlessService from "../../../../Service/ServerlessService";

import {
    getFormFields,
    isFormValid,
    onTextChange,
    onNumberChange,
    onEmailChange,
    isFieldValid,
    onFileChange,
} from "../../../../Utils/formHelper";
import { fileToS3, logOut, showPreviewDoc, trimObj } from "../../../../Utils";
import ConsultantFields from "./documentEditingRequest.json";
import { Calendar } from "primereact/calendar";
import { CLOUDFRONT_ENDPOINT } from "../../../../Service/config";
import { timeZones } from "../../../../Utils/constants";
import BaseComponent from "../../../common/BaseComponent";
import FileUpload from "../../../common/fileUpload";
import { addInterviews } from "../../../../store/actions";

class EditingRequest extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(
            ConsultantFields,
            this.props.editData || {}
        );
        this.state = {
            consultant: this.formFields.data,
            formValidations: this.formFields.formValidations,
        };
        this.serverlessService = new ServerlessService();
        this.fileUploadRef = React.createRef();
    }


    addOrUpdate = async () => {
        let consultant = trimObj(this.state.consultant);
        consultant.interviewProofURL = await fileToS3(
            this.props.orgId,
            consultant.interviewProofURL
        );
        const formStatus = isFormValid(
            ConsultantFields,
            this.formFields.formValidations,
            consultant
        );

        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
        }

        consultant.consultant = this.props?.params?.id;

        if (isCompleteFormValid) {
            await this.props.addInterviews(consultant);
        }
    };

    footerContent = () => (
        <div>
            <Button
                label="Cancel"
                className="p-button-text hover:bg-primary"
                onClick={this.props.onHide}
            />
            <Button
                label={this.props.editData ? "Update" : "Add"}
                onClick={this.addOrUpdate}
                autoFocus
                className="p-button-primary "
            />
        </div>
    );

    getPrivileges = () => {
        this.setState({
            isLoading: true,
            documentEditors: [],
        });

        const url = `/admin/get-privilege-users`;
        this.serverlessService
            .post(url, { privilegeType: "isDocumentEditor" }, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                        documentEditors: res.res.data,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    componentDidMount() {
        this.getPrivileges();
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.consultantAddedOrUpdated !=
            this.props.consultantAddedOrUpdated
        ) {
            this.props.history.push(
                `/admin/interview/${this.props?.params?.id}`
            );
        }
    }



    render() {
        const { consultant, formValidations } = this.state;
        return (

            <div className="h-screen overflow-auto ss-data-table">
                <div className="font-bold block mb-2">
                    <h1>Interviews</h1>
                </div>
                <div className="p-grid row pl-6 pr-6">
                    <div className="col-4">
                        <label className="font-bold block mb-2">
                            Interview Date<span className="sp-required">*</span>
                        </label>
                        <Calendar
                            value={
                                consultant.interviewDate
                                    ? new Date(consultant.interviewDate)
                                    : null
                            }
                            showIcon={true}
                            placeholder="Interview Date"
                            className="w-full"
                            id="interviewDate"
                            onChange={(e) =>
                                onTextChange(
                                    e?.value?.toString(),
                                    "interviewDate",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                )
                            }
                            minDate={new Date()}
                        ></Calendar>
                        {formValidations &&
                            !formValidations.fields["interviewDate"]
                                .isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields["interviewDate"]
                                            .errorMsg
                                    }
                                </p>
                            )}
                    </div>
                    <div className="col-4">
                        <label className="font-bold block mb-2">
                            Interview From<span className="sp-required">*</span>
                        </label>
                        <Calendar
                            timeOnly
                            value={
                                consultant.interviewFrom
                                    ? new Date(consultant.interviewFrom)
                                    : null
                            }
                            showIcon={true}
                            placeholder="Interview From"
                            className="w-full"
                            hourFormat="12"
                            id="inteviewTimeFrom"
                            onChange={(e) =>
                                onTextChange(
                                    e?.value?.toString(),
                                    "inteviewTimeFrom",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                )
                            }
                        ></Calendar>
                        {formValidations &&
                            !formValidations.fields["inteviewTimeFrom"]
                                .isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields[
                                            "inteviewTimeFrom"
                                        ].errorMsg
                                    }
                                </p>
                            )}
                    </div>
                    <div className="col-4">
                        <label className="font-bold block mb-2">
                            Interview To<span className="sp-required">*</span>
                        </label>
                        <Calendar
                            timeOnly
                            value={
                                consultant.interviewTo
                                    ? new Date(consultant.interviewTo)
                                    : null
                            }
                            showIcon={true}
                            placeholder="Interview To"
                            className="w-full"
                            hourFormat="12"
                            id="inteviewTimeTo"
                            onChange={(e) =>
                                onTextChange(
                                    e?.value?.toString(),
                                    "inteviewTimeTo",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                )
                            }
                        ></Calendar>
                        {formValidations &&
                            !formValidations.fields["inteviewTimeTo"]
                                .isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields["inteviewTimeTo"]
                                            .errorMsg
                                    }
                                </p>
                            )}
                    </div>
                    <div className="col-4">
                        <label className="font-bold block mb-2">
                            InteviewTimeZone
                            <span className="sp-required">*</span>
                        </label>
                        <Dropdown
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "inteviewTimeZone",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                            value={consultant.inteviewTimeZone}
                            options={timeZones}
                            optionValue="value"
                            optionLabel="label"
                            placeholder="Select Time Zone"
                            className="w-full md:w-35rem"
                        />
                        {formValidations &&
                            !formValidations.fields["inteviewTimeZone"]
                                .isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields[
                                            "inteviewTimeZone"
                                        ].errorMsg
                                    }
                                </p>
                            )}
                    </div>
                    <div className="col-4">
                        <label className="font-bold block mb-2">
                            JobRole<span className="sp-required">*</span>
                        </label>
                        <InputText
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "jobRole",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                            value={consultant.jobRole}
                        />
                        {formValidations &&
                            !formValidations.fields["jobRole"].isValid && (
                                <p className="p-error">
                                    {formValidations.fields["jobRole"].errorMsg}
                                </p>
                            )}
                    </div>
                    <div className="col-4">
                        <label className="font-bold block mb-2">
                            RecruiterName<span className="sp-required">*</span>
                        </label>
                        <InputText
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "recruiterName",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                            value={consultant.recruiterName}
                        />
                        {formValidations &&
                            !formValidations.fields["recruiterName"]
                                .isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields["recruiterName"]
                                            .errorMsg
                                    }
                                </p>
                            )}
                    </div>
                    <div className="col-4">
                        <label className="font-bold block mb-2">
                            RecruiterEmail<span className="sp-required">*</span>
                        </label>
                        <InputText
                            onChange={(e) => {
                                onEmailChange(
                                    e.target.value,
                                    "recruiterEmail",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                            value={consultant.recruiterEmail}
                        />
                        {formValidations &&
                            !formValidations.fields["recruiterEmail"]
                                .isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields["recruiterEmail"]
                                            .errorMsg
                                    }
                                </p>
                            )}
                    </div>
                    <div className="col-4">
                        <label className="font-bold block mb-2">
                            RecruiterPhone<span className="sp-required">*</span>
                        </label>
                        <InputText
                            onChange={(e) => {
                                onNumberChange(
                                    e.target.value,
                                    "recruiterPhone",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                            value={consultant.recruiterPhone}
                        />
                        {formValidations &&
                            !formValidations.fields["recruiterPhone"]
                                .isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields["recruiterPhone"]
                                            .errorMsg
                                    }
                                </p>
                            )}
                    </div>
                    <div className="col-4">
                        <label className="font-bold block mb-2">
                            Client<span className="sp-required">*</span>
                        </label>
                        <InputText
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "client",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                            value={consultant.client}
                        />
                        {formValidations &&
                            !formValidations.fields["client"].isValid && (
                                <p className="p-error">
                                    {formValidations.fields["client"].errorMsg}
                                </p>
                            )}
                    </div>
                    <div className="col-4">
                        <label className="font-bold block mb-2">
                            InterviewMode<span className="sp-required">*</span>
                        </label>
                        <InputText
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "interviewMode",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                            value={consultant.interviewMode}
                        />
                        {formValidations &&
                            !formValidations.fields["interviewMode"]
                                .isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields["interviewMode"]
                                            .errorMsg
                                    }
                                </p>
                            )}
                    </div>
                    <div className="col-4">
                        <label className="font-bold block mb-2">
                            InterviewType<span className="sp-required">*</span>
                        </label>
                        <InputText
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "interviewType",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                            value={consultant.interviewType}
                        />
                        {formValidations &&
                            !formValidations.fields["interviewType"]
                                .isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields["interviewType"]
                                            .errorMsg
                                    }
                                </p>
                            )}
                    </div>
                    <div className="col-4">
                        <label className="font-bold block mb-2">
                            InterviewRound<span className="sp-required">*</span>
                        </label>
                        <InputText
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "interviewRound",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                            value={consultant.interviewRound}
                        />
                        {formValidations &&
                            !formValidations.fields["interviewRound"]
                                .isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields["interviewRound"]
                                            .errorMsg
                                    }
                                </p>
                            )}
                    </div>
                    <div className="col-12 md:col-4 lg:col-4">
                        <label className="font-bold block mb-2">
                            Interview Screenshot
                            <span className="sp-required">*</span>
                        </label>
                        <span
                            onClick={() => {
                                showPreviewDoc(consultant.interviewProofURL);
                            }}
                        >
                            {consultant.interviewProofURL?.name}
                        </span>
                        <FileUpload
                            id="interviewProofURL"
                            multiple={false}
                            accept={"documents"}
                            afterFilesUploaded={(files) => {
                                if (files.length) {
                                    onFileChange(
                                        files[0],
                                        "interviewProofURL",
                                        this,
                                        ConsultantFields,
                                        consultant,
                                        formValidations,
                                        "consultant",
                                        "formValidations"
                                    );
                                }
                            }}
                            inputRef={this.fileUploadRef}

                        />

                        {formValidations &&
                            !formValidations.fields["interviewProofURL"]
                                .isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields[
                                            "interviewProofURL"
                                        ].errorMsg
                                    }
                                </p>
                            )}
                    </div>
                    <div></div>
                </div>
                <div className="mt-3">
                    <Button
                        label="Cancel"
                        className="p-button-text hover:bg-primary mr-3"
                        onClick={() => {
                            this.props.history.goBack();
                        }}
                    />
                    <Button
                        label={this.props.editData ? "Update" : "Add"}
                        onClick={this.addOrUpdate}
                        autoFocus
                        className="p-button-primary "
                    />
                </div>

                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
                {(this.state.isLoading || this.props.isLoading) && (
                    <LoadingComponent />
                )}
            </div>

        );
    }
}

const mapStateToProps = (state) => ({
    consultants: state?.interviews?.consultants,
    totalRecords: state?.interviews?.totalRecords,
    lazyParams: state?.interviews?.lazyParams,
    isLoading: state?.interviews?.isLoading,
    selectedStatus: state?.interviews?.selectedStatus,
    consultantAddedOrUpdated: state?.interviews?.consultantAddedOrUpdated,
});

export default BaseComponent(EditingRequest, mapStateToProps, {
    addInterviews,
});
