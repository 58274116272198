import { ACTIONS } from '../actionTypes';
import axios from 'axios';
import { SERVERLESS_URL } from '../../Service/config';
import { BasicLazyParams, CONSULTANT_STATUS } from '../../Utils/constants';
import { CodeGuruReviewer } from 'aws-sdk';
import { logOut, removeNullUndefinedKeys } from '../../Utils';



const intialTableFilter = {
    lazyParams: {
        ...BasicLazyParams,
        sortField: "-createdAt",
        sortOrder: 1,
    },
    globalSearch: ''
}




const dispatchRequestQuestionBankData = data => ({
    type: ACTIONS.REQUEST_QUESTIONBANK,
    data
}

);

const dispatchReceivedQuestionBankData = (data) => ({
    type: ACTIONS.RECEIVED_QUESTIONBANK,
    data
}
);

const dispatchErrorQuestionBankData = err => ({
    type: ACTIONS.ERROR_QUESTIONBANK,
    err
});

const dispatchClearQuestionBankData = () => ({
    type: ACTIONS.CLEAR_QUESTIONBANK
});

const dispatchReceivedAddQuestionBankData = (data) => ({
    type: ACTIONS.RECEIVED_ADD_QUESTIONBANK,
    data
});

const dispatchReceivedUpdateQuestionBankData = (data) => ({
    type: ACTIONS.RECEIVED_UPDATE_QUESTIONBANK,
    data
});


const dispatchErrorAddQuestionBankData = err => ({
    type: ACTIONS.ERROR_ADD_QUESTIONBANK,
    err
});


export const addQuestionBankData = (data) => (dispatch) => {

    // dispatch(dispatchReceivedAddQuestionBankData(data));
    axios({
        method: data?._id ? 'put' : 'post',
        url: `${SERVERLESS_URL}/consultants/questionbank${data?._id ? '/update' : '/add'}`,
        headers: {
            'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
            'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`
        },
        data
    }).then((res) => {
        console.log(res.data, "documentEditing action")
        if (res && res.data) {

            if (data?._id) {
                dispatch(dispatchReceivedUpdateQuestionBankData(res.data?.data));
            } else {
                dispatch(dispatchReceivedAddQuestionBankData(res.data?.data));
            }

        } else {
            dispatch(dispatchErrorAddQuestionBankData({
                message: 'Some error occurred'
            }));
        }
    }).catch(e => {
        if (e?.response?.status > 400 && e?.response?.status < 500) {
            logOut()
        }
        dispatch(dispatchErrorAddQuestionBankData(e));
    });
}


export const getQuestionBank = (data) => (dispatch, getState) => {
    let _data;
    const currentState = getState().questionBanks;
    console.log(currentState, "currentstate")
    if (data?.lazyParams) {
        currentState.lazyParams = {
            ...currentState.lazyParams,
            ...removeNullUndefinedKeys(data.lazyParams)
        }
    }
    if (data?.globalSearch !== undefined) {
        currentState.globalSearch = data.globalSearch;
    }

    const { lazyParams, globalSearch } = currentState;
    console.log(data, lazyParams, currentState, "lazycheck")
    dispatch(dispatchRequestQuestionBankData(data));

    console.log(data)
    axios({
        method: 'post',
        url: `${SERVERLESS_URL}/consultants/questionbank?limit=${lazyParams.rows
            }&page=${lazyParams.page + 1}` +
            `${globalSearch ? `&search=${globalSearch}` : ""}` +
            `${lazyParams.sortField
                ? `&sort=${lazyParams.sortOrder === 1 ? "" : "-"}${lazyParams.sortField
                }`
                : ""
            }`,
        headers: {
            'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
            'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`
        },
        data: data
    }).then((res) => {
        _data = res.data
        console.log(res.data, "questionAction")
        if (res && res.data) {
            dispatch(dispatchReceivedQuestionBankData(res.data));
        } else {
            dispatch(dispatchErrorQuestionBankData({
                message: 'Some error occurred'
            }));
        }
    }).catch(e => {
        if (e?.response?.status > 400 && e?.response?.status < 500) {
            logOut()
        }
        dispatch(dispatchErrorQuestionBankData(e));
    });
}

export const resetLazyQuestionBank = () => (dispatch) => {
    let _data;

    const { lazyParams, globalSearch } = intialTableFilter;
    dispatch(dispatchRequestQuestionBankData(intialTableFilter));
    axios({
        method: 'post',
        url: `${SERVERLESS_URL}consultants/questionbank?limit=${lazyParams.rows}&page=${lazyParams.page + 1
            }&search=${globalSearch}${lazyParams.sortField
                ? `&sort=${lazyParams.sortOrder === 1 ? "" : "-"}${lazyParams.sortField
                }`
                : ""
            }`,
        headers: {
            'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
            'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`
        },
        body: { status: CONSULTANT_STATUS.CREATED }
    }).then((res) => {
        _data = res.data
        if (res && res.data) {
            dispatch(dispatchReceivedQuestionBankData(res.data));
        } else {
            dispatch(dispatchErrorQuestionBankData({
                message: 'Some error occurred'
            }));
        }
    }).catch(e => {
        if (e?.response?.status > 400 && e?.response?.status < 500) {
            logOut()
        }
        dispatch(dispatchErrorQuestionBankData(e));
    });
}


export const clearConsltants = () => dispatchClearQuestionBankData();