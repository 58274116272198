import { ACTIONS } from "../actionTypes";
import axios from "axios";
import { SERVERLESS_URL } from "../../Service/config";
import { BasicLazyParams, CONSULTANT_STATUS } from "../../Utils/constants";
import { CodeGuruReviewer } from "aws-sdk";

import { logOut, removeNullUndefinedKeys } from "../../Utils";

const intialTableFilter = {
    lazyParams: {
        ...BasicLazyParams,
        sortField: "createdAt",
        sortOrder: 1,
    },
    globalSearch: "",
};

const dispatchRequestUserQuestions = (data) => ({
    type: ACTIONS.REQUEST_USERQUESTIONS,
    data,
});

const dispatchReceivedUserQuestions = (data) => ({
    type: ACTIONS.RECEIVED_USERQUESTIONS,
    data,
});

const dispatchErrorUserQuestions = (err) => ({
    type: ACTIONS.ERROR_USERQUESTIONS,
    err,
});

const dispatchClearUserQuestions = () => ({
    type: ACTIONS.CLEAR_USERQUESTIONS,
});


const dispatchReceivedAddUserQuestions = (data) => ({
    type: ACTIONS.RECEIVED_ADD_USERQUESTIONS,
    data
});

const dispatchReceivedUpdateUserQuestions = (data) => ({
    type: ACTIONS.RECEIVED_UPDATE_USERQUESTIONS,
    data
});


const dispatchErrorAddUserQuestions = err => ({
    type: ACTIONS.ERROR_ADD_USERQUESTIONS,
    err
});

const dispatchReceivedDeleteUserQuestions = (data) => ({
    type: ACTIONS.RECEIVED_DELETE_USERQUESTIONS,
    data,
});


export const addUserQuestionData = (data) => (dispatch) => {

    // dispatch(dispatchReceivedAddQuestionBankData(data));
    axios({
        method: data?._id ? 'put' : 'post',
        url: `${SERVERLESS_URL}/consultants/questionbank${data?._id ? '/update' : '/add'}`,
        headers: {
            'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
            'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`
        },
        data: data
    }).then((res) => {
        console.log(res.data, "documentEditing action")
        if (res && res.data) {

            if (data?._id) {
                dispatch(dispatchReceivedUpdateUserQuestions(res.data?.data));
            } else {
                dispatch(dispatchReceivedAddUserQuestions(res.data?.data));
            }

        } else {
            dispatch(dispatchErrorAddUserQuestions({
                message: 'Some error occurred'
            }));
        }
    }).catch(e => {
        if (e?.response?.status > 400 && e?.response?.status < 500) {
            logOut()
        }
        dispatch(dispatchErrorAddUserQuestions(e));
    });
}


export const deleteUserQuestions = (data) => (dispatch) => {
    axios({
        method: "post",
        url: `${SERVERLESS_URL}/consultants/questionbank/delete`,
        headers: {
            'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
            Authorization: `Bearer ${localStorage.getItem("Bearer")
                ? localStorage.getItem("Bearer")
                : ""
                }`,
        },
        data: data
    })
        .then((res) => {
            console.log(res.data, "documentEditing action");
            if (res && res.data) {

                dispatch(dispatchReceivedDeleteUserQuestions(data));
            } else {
            }
        })
        .catch((e) => {
            if (e?.response?.status > 400 && e?.response?.status < 500) {
                logOut()
            }
        });
};
export const getUserQuestions = (data) => (dispatch, getState) => {
    let _data;

    const currentState = getState().userQuestions;
    console.log(currentState, "currentstate");
    if (data?.lazyParams) {
        currentState.lazyParams = {
            ...currentState.lazyParams,
            ...removeNullUndefinedKeys(data.lazyParams),
        };
    }
    if (data?.globalSearch !== undefined) {
        currentState.globalSearch = data.globalSearch;
    }
    console.log(data);
    const { lazyParams, globalSearch } = currentState;

    console.log(data, lazyParams, currentState, "lazycheck");
    dispatch(dispatchRequestUserQuestions(data));
    const status = data;
    const url =
        `${SERVERLESS_URL}/consultants/questionbank/createdby?limit=${lazyParams.rows
        }&page=${lazyParams.page + 1}` +
        `${globalSearch ? `&search=${globalSearch}` : ""}` +
        `${lazyParams.sortField
            ? `&sort=${lazyParams.sortOrder === 1 ? "" : "-"}${lazyParams.sortField
            }`
            : ""
        }`;
    axios({
        method: "post",
        url,
        headers: {
            'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
            Authorization: `Bearer ${localStorage.getItem("Bearer")
                ? localStorage.getItem("Bearer")
                : ""
                }`,
        },

        data: status,
    })
        .then((res) => {
            // debugger
            _data = res.data;

            if (res && res.data) {
                dispatch(dispatchReceivedUserQuestions(res.data));
            } else {
                dispatch(
                    dispatchErrorUserQuestions({
                        message: "Some error occurred",
                    })
                );
            }
        })
        .catch((e) => {
            if (e?.response?.status > 400 && e?.response?.status < 500) {
                logOut()
            }
            if (e?.response?.status > 400 && e?.response?.status < 500) {
                logOut()
            }
            dispatch(dispatchErrorUserQuestions(e));
        });
};

export const resetLazyUserQuestions = (data) => (dispatch) => {
    let _data;

    const { lazyParams, globalSearch } = intialTableFilter;
    dispatch(dispatchRequestUserQuestions(intialTableFilter));
    const status = data;
    const url =
        `${SERVERLESS_URL}/consultants/questionbank/createdby?limit=${lazyParams.rows
        }&page=${lazyParams.page + 1}` +
        `${globalSearch ? `&search=${globalSearch}` : ""}` +
        `${lazyParams.sortField
            ? `&sort=${lazyParams.sortOrder === 1 ? "" : "-"}${lazyParams.sortField
            }`
            : ""
        }`;
    axios({
        method: "post",
        url,
        headers: {
            'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
            Authorization: `Bearer ${localStorage.getItem("Bearer")
                ? localStorage.getItem("Bearer")
                : ""
                }`,
        },
        data: status,
    })
        .then((res) => {
            // debugger
            _data = res.data;
            if (res && res.data) {
                dispatch(dispatchReceivedUserQuestions(res.data));
            } else {
                dispatch(
                    dispatchErrorUserQuestions({
                        message: "Some error occurred",
                    })
                );
            }
        })
        .catch((e) => {
            if (e?.response?.status > 400 && e?.response?.status < 500) {
                logOut()
            }
            if (e?.response?.status > 400 && e?.response?.status < 500) {
                logOut()
            }

            dispatch(dispatchErrorUserQuestions(e));
        });
};

export const clearConsltants = () => dispatchClearUserQuestions();
