import React, { Component } from "react";
import NavFooter from "../navFooter";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./styles.scss";
import { Accordion, AccordionTab } from "primereact/accordion";

class LandingPage extends Component {
    render() {
        return (
            <NavFooter>
                <div className="bg-white px-4 md:px-6 lg:px-8 itro-card">
                    <div className="flex flex-wrap">
                        <div className="w-12 lg:w-6 intro-title-card">
                            <h1 className="text-8xl font-bold">
                                Welcome to {this.props.orgName}
                            </h1>

                            <p className="text-2xl  mt-0 mb-5">
                                A matrimony services where can you find the
                                guenuine and verified profiles.
                            </p>
                        </div>
                        <div className="w-full h-full lg:w-6 text-center lg:text-center overflow-hidden pt-8">
                            <img
                                src="assets/images/blocks/hero/rings-2.png"
                                alt="Image"
                                className="w-full lg:w-auto pt-6 h-full"
                            />
                        </div>
                    </div>
                </div>
                <div className="surface-section px-4 py-8 md:px-6 lg:px-8 text-center">
                    <div className="mb-3 font-bold text-3xl">
                        <span
                            className="text-900"
                            style={{ fontSize: "inherit" }}
                        >
                            One Product,{" "}
                        </span>
                        <span
                            className="text-blue-600"
                            style={{ fontSize: "inherit" }}
                        >
                            Our Features
                        </span>
                    </div>
                    <div className="text-700 mb-6">
                        We designed our platform considering below points.
                    </div>
                    <div className="grid">
                        <div className="col-12 md:col-4 mb-4 px-5">
                            <span
                                className="p-3 shadow-2 mb-3 inline-block surface-card"
                                style={{ borderRadius: "10px" }}
                            >
                                <i className="pi pi-check-square text-4xl text-blue-500"></i>
                            </span>
                            <div className="text-900 mb-3 font-medium">
                                Verified Profiles
                            </div>
                            <span className="text-700 line-height-3">
                                You can find 100% verified profiles here.
                            </span>
                        </div>
                        <div className="col-12 md:col-4 mb-4 px-5">
                            <span
                                className="p-3 shadow-2 mb-3 inline-block surface-card"
                                style={{ borderRadius: "10px" }}
                            >
                                <i className="pi pi-phone text-4xl text-blue-500"></i>
                            </span>
                            <div className="text-900 mb-3 font-medium">
                                Verified Phones
                            </div>
                            <span className="text-700 line-height-3">
                                Verified mobile numbers through OTP.
                            </span>
                        </div>
                        <div className="col-12 md:col-4 mb-4 px-5">
                            <span
                                className="p-3 shadow-2 mb-3 inline-block surface-card"
                                style={{ borderRadius: "10px" }}
                            >
                                <i className="pi pi-check-circle text-4xl text-blue-500"></i>
                            </span>
                            <div className="text-900 mb-3 font-medium">
                                Easy to Use
                            </div>
                            <span className="text-700 line-height-3">
                                Designed to use easily and effectively by any
                                user
                            </span>
                        </div>
                        <div className="col-12 md:col-4 mb-4 px-5">
                            <span
                                className="p-3 shadow-2 mb-3 inline-block surface-card"
                                style={{ borderRadius: "10px" }}
                            >
                                <i className="pi pi-users text-4xl text-blue-500"></i>
                            </span>
                            <div className="text-900 mb-3 font-medium">
                                Multiples Profiles
                            </div>
                            <span className="text-700  line-height-3">
                                Can add multiple profiles in single account
                            </span>
                        </div>
                        <div className="col-12 md:col-4 mb-4 px-5">
                            <span
                                className="p-3 shadow-2 mb-3 inline-block surface-card"
                                style={{ borderRadius: "10px" }}
                            >
                                <i className="pi pi-history text-4xl text-blue-500"></i>
                            </span>
                            <div className="text-900 mb-3 font-medium">
                                History View
                            </div>
                            <span className="text-700  line-height-3">
                                Can tackle history of profiles simply
                            </span>
                        </div>
                        <div className="col-12 md:col-4 md:mb-4 mb-0 px-3">
                            <span
                                className="p-3 shadow-2 mb-3 inline-block surface-card"
                                style={{ borderRadius: "10px" }}
                            >
                                <i className="pi pi-shield text-4xl text-blue-500"></i>
                            </span>
                            <div className="text-900 mb-3 font-medium">
                                Trusted Security
                            </div>
                            <span className="text-700  line-height-3">
                                All data stored in secured cloud services
                            </span>
                        </div>
                    </div>
                </div>

                <div className="surface-section px-4 py-8 md:px-6 lg:px-8">
                    <div className="font-bold text-900 text-6xl mb-3 text-center">
                        A simple steps to Begin
                    </div>
                    <div className="flex flex-wrap">
                        <div className="w-full lg:w-6 xl:w-4 p-5 hover:shadow-4">
                            <img
                                src="assets/images/blocks/feature/feature-illustration-1.svg"
                                alt="Image"
                                className="w-full"
                            />
                            <div className="mt-3 mb-2 font-medium text-900 text-2xl">
                                Rigister
                            </div>
                            <span className="text-700 line-height-3">
                                Register with Phone and add n number of
                                profiles of your family.
                            </span>
                        </div>

                        <div className="w-full lg:w-6 xl:w-4 p-5 hover:shadow-4 bg-red">
                            <img
                                src="assets/images/blocks/feature/feature-illustration-3.svg"
                                alt="Image"
                                className="w-full"
                            />
                            <div className="mt-3 mb-2 font-medium text-900 text-2xl">
                                Search
                            </div>
                            <span className="text-700 line-height-3">
                                Search the profile that matches your criteria
                                and get the contact details.
                            </span>
                        </div>
                        <div className="w-full lg:w-6 xl:w-4 p-5 hover:shadow-4">
                            <img
                                src="assets/images/blocks/feature/feature-illustration-4.svg"
                                alt="Image"
                                className="w-full"
                            />
                            <div className="mt-3 mb-2 font-medium text-900 text-2xl">
                                Interact
                            </div>
                            <span className="text-700 line-height-3">
                                Interact with profiles and choose best matched
                                life partner.
                            </span>
                        </div>
                    </div>
                </div>
                <div className="px-4 py-8 md:px-6 lg:px-8">
                    <Accordion activeIndex={0}>
                        <AccordionTab
                            header={`How to register on ${this.props.orgName}?`}
                        >
                            <p className="m-0">
                                Register on {this.props.orgName} for free by
                                providing necessary details.
                            </p>
                        </AccordionTab>
                        <AccordionTab
                            header={`How ${this.props.orgName} store your data?`}
                        >
                            <p className="m-0">
                                {this.props.orgName} stores your data securily
                                on cloud which?
                            </p>
                        </AccordionTab>
                    </Accordion>
                </div>
            </NavFooter>
        );
    }
}

const mapStateToProps = (state) => ({
    orgName: state?.orgData?.orgName,
});
export default connect(mapStateToProps, {})(withRouter(LandingPage));
