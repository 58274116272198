import React, { Component } from "react";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import _ from "lodash";
import LoadingComponent from "../../../LoadingComponent";
import { FeeDays, PaymentModes } from "../../../Utils/constants";
import ServerlessService from "../../../Service/ServerlessService";
import _AssignTableFields from "./assignTable.json";
import { logOut, trimObj } from "../../../Utils";
import {
    getFormFields,
    isFormValid,
    onTextChange,
    onDropDownChange,
} from "../../../Utils/formHelper";
let AssignTableFields = _.cloneDeep(_AssignTableFields);

class AssignTable extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(
            AssignTableFields,
            this.props.editData || {}
        );
        this.state = {
            // assignData: {
            //     assignedTo: "",
            // },
            isLoading: true,
            customers: [],
            assignData: this.formFields.data,
            formValidations: this.formFields.formValidations,
        };

        this.serverlessService = new ServerlessService();
    }

    getCustomers = () => {
        this.setState({
            isLoading: true,
            customers: [],
        });

        const url = `/co-space/customers/unassigned`;
        this.serverlessService
            .get(url, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                        customers: res.res.data,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };
    componentDidMount() {
        this.getCustomers();
    }

    onDaysSelect = (e) => {
        let fee = this.props.editData?.feeType?.fees?.find(
            (a) => a.id === e.value
        )?.fee;
        return fee;
    };

    onAssignClick = () => {
        let assignData = trimObj(this.state.assignData);
        const formStatus = isFormValid(
            AssignTableFields,
            this.formFields.formValidations,
            assignData
        );

        let isCompleteFormValid = true;
        console.log(
            "formStatus.formValidations.isFormValid",
            formStatus.formValidations.isFormValid
        );
        console.log("formStatus.formValidations", formStatus.formValidations);
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
        }

        if (isCompleteFormValid) {
            this.setState({
                isLoading: true,
            });

            const {
                assignedTo,
                days,
                fee,
                feeType,
                paymentGateway,
                paymentMode,
                paymentReferenceNumber,
                referenceId,
                ...rest

            } = this.state.assignData;


            let payload = {
                assignedTo,
                days,
                fee,
                feeType,
                paymentGateway,
                paymentMode,
                paymentReferenceNumber,
                referenceId,
                assignTable: this.props.editData._id,
            };

            const url = `/co-space/tables/assign-user`;
            this.serverlessService
                .post(url, payload, true)
                .then((res) => {
                    if (res && res.status && res.res.status) {
                        this.setState(
                            {
                                isLoading: false,
                            },
                            () => {
                                this.props.onSuccessFullyAssigned();
                            }
                        );
                    } else {
                        this.setState({
                            isLoading: false,
                        });
                        this.toast.show({
                            severity: "error",
                            summary: "Some error occurred",
                            detail: res.errMessage,
                            life: 3000,
                        });
                    }
                })
                .catch((e) => {
                    if (e?.response?.status > 400 && e?.response?.status < 500) {
                        logOut()
                    }
                    console.log(e);
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: e.message,
                        life: 3000,
                    });
                });
        }
    };
    footerContent = () => {
        return (
            <Button
                label="Assign"
                onClick={this.onAssignClick}
                className=" p-button-primary "
            />
        );
    };

    onPaymentUpdate = () => {
        const { assignData } = this.state;

        AssignTableFields = AssignTableFields.map((field) => {
            if (
                field.FieldName == "paymentReferenceNumber" ||
                field.FieldName == "paymentGateway"
            ) {
                field.Required = assignData.paymentMode == "online";
            }
            return field;
        });

        this.formFields = getFormFields(
            AssignTableFields,
            this.state.assignData
        );

        this.setState({
            formValidations: this.formFields.formValidations,
        });
    };

    render() {
        console.log(this.state, "ddd");
        const { assignData, formValidations } = this.state;
        return (
            <Dialog
                header="Assign Customer"
                visible={true}
                style={{ width: "65vw" }}
                onHide={this.props.onHide}
                footer={this.footerContent()}
                draggable={false}
            >
                <div className="p-grid row pl-6 pr-6">
                    <div className="col-4">
                        <label className="font-bold block mb-2">User</label>
                        <Dropdown
                            onChange={(e) => {
                                onDropDownChange(
                                    e.value,
                                    "assignedTo",
                                    this,
                                    AssignTableFields,
                                    assignData,
                                    formValidations,
                                    "assignData",
                                    "formValidations"
                                );
                            }}
                            value={this.state.assignData.assignedTo}
                            options={this.state.customers}
                            optionValue="_id"
                            optionLabel="name"
                            placeholder="Select a user"
                            className="w-full md:w-14rem"
                        />
                        {formValidations &&
                            !formValidations.fields["assignedTo"].isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields["assignedTo"]
                                            .errorMsg
                                    }
                                </p>
                            )}
                    </div>

                    <div className="col-4">
                        <label className="font-bold block mb-2">Days</label>
                        <Dropdown
                            onChange={(e) => {
                                onDropDownChange(
                                    e.value,
                                    "days",
                                    this,
                                    AssignTableFields,
                                    assignData,
                                    formValidations,
                                    "assignData",
                                    "formValidations",
                                    () => {
                                        this.setState((prevState) => {
                                            return {
                                                assignData: {
                                                    ...prevState.assignData,
                                                    fee: this.onDaysSelect(e),
                                                },
                                            };
                                        });
                                    }
                                );
                            }}
                            value={this.state.assignData.days}
                            options={FeeDays}
                            optionValue="id"
                            optionLabel="days"
                            placeholder="Select a day"
                            className="w-full md:w-14rem"
                        />
                        {formValidations &&
                            !formValidations.fields["days"].isValid && (
                                <p className="p-error">
                                    {formValidations.fields["days"].errorMsg}
                                </p>
                            )}
                    </div>

                    <div className="col-4">
                        <label className="font-bold block mb-2">Fee</label>

                        <InputNumber
                            value={this.state.assignData.fee}
                            readOnly
                        />
                    </div>

                    <div className="col-4">
                        <label className="font-bold block mb-2">
                            Payment Mode
                        </label>
                        <Dropdown
                            onChange={(e) => {
                                onDropDownChange(
                                    e.value,
                                    "paymentMode",
                                    this,
                                    AssignTableFields,
                                    assignData,
                                    formValidations,
                                    "assignData",
                                    "formValidations",
                                    this.onPaymentUpdate
                                );
                            }}
                            value={this.state.assignData.paymentMode}
                            options={PaymentModes}
                            optionValue="id"
                            optionLabel="type"
                            placeholder="Select a payment type"
                            className="w-full md:w-14rem"
                        />
                        {formValidations &&
                            !formValidations.fields["paymentMode"].isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields["paymentMode"]
                                            .errorMsg
                                    }
                                </p>
                            )}
                    </div>

                    {this.state.assignData.paymentMode == "online" && (
                        <>
                            <div className="col-4">
                                <label className="font-bold block mb-2">
                                    Payment Reference Number
                                </label>
                                <InputText
                                    onChange={(e) => {
                                        onTextChange(
                                            e.target.value,
                                            "paymentReferenceNumber",
                                            this,
                                            AssignTableFields,
                                            assignData,
                                            formValidations,
                                            "assignData",
                                            "formValidations"
                                        );
                                    }}
                                ></InputText>
                                {formValidations &&
                                    !formValidations.fields[
                                        "paymentReferenceNumber"
                                    ].isValid && (
                                        <p className="p-error">
                                            {
                                                formValidations.fields[
                                                    "paymentReferenceNumber"
                                                ].errorMsg
                                            }
                                        </p>
                                    )}
                            </div>
                            <div className="col-4">
                                <label className="font-bold block mb-2">
                                    Payment Gateway
                                </label>
                                <InputText
                                    onChange={(e) => {
                                        onTextChange(
                                            e.target.value,
                                            "paymentGateway",
                                            this,
                                            AssignTableFields,
                                            assignData,
                                            formValidations,
                                            "assignData",
                                            "formValidations"
                                        );
                                    }}
                                ></InputText>
                                {formValidations &&
                                    !formValidations.fields["paymentGateway"]
                                        .isValid && (
                                        <p className="p-error">
                                            {
                                                formValidations.fields[
                                                    "paymentGateway"
                                                ].errorMsg
                                            }
                                        </p>
                                    )}
                            </div>
                        </>
                    )}
                </div>

                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
                {(this.state.isLoading || this.props.isLoading) && (
                    <LoadingComponent />
                )}
            </Dialog>
        );
    }
}

export default AssignTable;
