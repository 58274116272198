import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import LoadingComponent from "../../../LoadingComponent";
import { InputTextarea } from "primereact/inputtextarea";

import { Dialog } from "primereact/dialog";

import { BasicLazyParams } from "../../../Utils/constants";
import ServerlessService from "../../../Service/ServerlessService";
import { Tooltip } from "primereact/tooltip";
import Authorize, { PERMISSIONS } from "../../../session/authorize";

import AssignDocPreparator from "./assignDocPreparotor";
import ConsultantNotes from "../documentPreperator/consultantNotes";
import ConsultantMonitor from "../documentPreperator/consultantMonitor";
import { Card } from "primereact/card";
import BaseComponent from "../../common/BaseComponent";
import {
    getContentWriters,
    resetLazyContentWriters,
} from "../../../store/actions";
import { BreadCrumb } from "primereact/breadcrumb";
import Required from "../../common/required";
import { logOut } from "../../../Utils";

class ContentWriter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowView: false,
            viewTable: [],
            visible: false,
            isShowConfirmationDialog: false,
            consultants: [],
            customers: [],
            isShowAssignTable: false,
            isShowUnAssignTable: false,
            editData: null,
            id: null,
            renewalData: null,
            tableHistorysData: null,
            rowDataOfTable: null,
            isShowRenewal: false,
            globalSearch: "",
            lazyParams: {
                ...BasicLazyParams,
                sortField: "createdAt",
                sortOrder: 1,
            },
            selectedReason: "",
            emailMessage: "",
        };
        this.serverlessService = new ServerlessService();
    }

    onHide = () => {
        this.setState({
            visible: false,
            isShowRenewal: false,
            editData: null,
        });
    };

    componentDidMount() {
        if (!this.props.contentWriterConsultants?.length) {
            this.props.getContentWriters();
        }
    }

    onGlobalSearch = (e) => {
        this.props.getContentWriters({ globalSearch: e.target.value });
    };

    onPage = (event) => {
        this.props.getContentWriters({ lazyParams: event });
    };

    onSort = (event) => {
        this.props.getContentWriters({ lazyParams: event });
    };

    onFilter = (event) => {
        event["first"] = 0;
        this.props.getContentWriters({ lazyParams: event });
    };

    sendEmailToConsultant = (data) => {
        if (this.state.showTextarea && this.state.emailMessage === "") {
            this.setState({
                emailMessageErr: true,
            });
            return;
        }

        const { _id, email, orgId } = this.state.EmailData;

        const emailbody = {
            id: _id,
            email: email,
            ordid: orgId,
            emailBody: this.state.emailMessage,
            documentStatus: "Requested",
        };

        const url = `/consultants/consultant/email`;
        this.serverlessService
            .post(url, emailbody, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState(
                        {
                            isShowConfirmationDialog: false,
                            emailMessage: "",
                            emailMessageErr: false,
                        },
                        () => {
                            this.props.getContentWriters();
                        }
                    );
                    this.toast.show({
                        severity: "success",
                        summary: "Mail Sent",
                        detail: `Mail sent to ${email}`,
                        life: 3000,
                    });
                } else {
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: e.message,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    onCloseConfirmation = () => {
        this.setState({
            isShowConfirmationDialog: false,
        });
    };

    actionsBody = (rowData) => {
        return (
            <div className="p-grid row">
                <Authorize permId={PERMISSIONS.CONTENT_WRITER_EDIT}>
                    <div className="col-2">
                        <span
                            onClick={() => {
                                const _rowData = { ...rowData };
                                this.props.history.push(
                                    `/admin/contentwriter/edit/${rowData._id}`
                                );
                            }}
                            data-pr-tooltip="Vendor Script"
                            data-pr-position="top"
                            className={`p-text tableEdit${rowData._id}`}
                        >
                            <i className="pi pi-pencil" />
                        </span>
                        <Tooltip target={`.tableEdit${rowData._id}`} />
                    </div>
                </Authorize>
                <Authorize
                    permId={
                        PERMISSIONS.CONTENT_WRITER_ASSIGN_TO_DOCUMENT_PREPERATION
                    }
                >
                    <div className="col-2">
                        <span
                            onClick={() => {
                                if (rowData.isProfileValid) {
                                    this.setState({
                                        isShowAssignToDocPreparator: true,
                                        selectedCustomer: rowData,
                                    });
                                } else {
                                }
                            }}
                            data-pr-tooltip="Assign To Document Preparation Team"
                            data-pr-position="top"
                            className={`p-text tableDelete${rowData._id}`}
                        >
                            <i className="pi pi-directions" />
                        </span>
                        <Tooltip target={`.tableDelete${rowData._id}`} />
                    </div>
                </Authorize>

                <Authorize permId={PERMISSIONS.CONTENT_WRITER_SHOW_CONSULTANT}>
                    <div className="col-2">
                        <span
                            onClick={() => {
                                this.props.history.push(
                                    `/admin/cosultant/view/${rowData._id}`
                                );
                            }}
                            data-pr-tooltip="Show Consultant"
                            data-pr-position="top"
                            className={`p-text abc${rowData._id}`}
                        >
                            <i className="pi pi-eye" />
                        </span>
                        <Tooltip target={`.abc${rowData._id}`} />
                    </div>
                </Authorize>
                <Authorize permId={PERMISSIONS.CONTENT_WRITER_MONITOR}>
                    <div className="col-2">
                        <span
                            onClick={() => {
                                this.setState({
                                    isShowConsultantMonitor: true,
                                    editData: rowData,
                                });
                            }}
                            data-pr-tooltip="Consultant Monitor"
                            data-pr-position="top"
                            className={`p-text tableHistory${rowData._id}`}
                        >
                            <i className="pi pi-comments" />
                        </span>
                        <Tooltip target={`.tableHistory${rowData._id}`} />
                    </div>
                </Authorize>
                <Authorize permId={PERMISSIONS.CONTENT_WRITER_TRACKER}>
                    <div className="col-2">
                        <span
                            onClick={() => {
                                this.props.history.push(
                                    `/admin/cosultant/tracker/${rowData._id}`
                                );
                            }}
                            data-pr-tooltip="Consultant Tracker"
                            data-pr-position="top"
                            className={`p-text tableHistory${rowData._id}`}
                        >
                            <i className="pi pi-building" />
                        </span>
                        <Tooltip target={`.tableHistory${rowData._id}`} />
                    </div>
                </Authorize>
                <Authorize permId={PERMISSIONS.CONTENT_WRITER_NOTES}>
                    <div className="col-2">
                        <span
                            onClick={() => {
                                this.setState({
                                    isShowConsultantNotes: true,
                                    editData: rowData,
                                });
                            }}
                            data-pr-tooltip="Notes (Only visible to you.)"
                            data-pr-position="top"
                            className={`p-text tableHistory${rowData._id}`}
                        >
                            <i className="pi pi-book" />
                        </span>
                        <Tooltip target={`.tableHistory${rowData._id}`} />
                    </div>
                </Authorize>
                <Authorize permId={PERMISSIONS.CONTENT_WRITER_EMAIL}>
                    <div className="col-2">
                        <span
                            onClick={() => this.showDeleteTableDialog(rowData)}
                            data-pr-tooltip={
                                rowData.documentCollectionRequestSend
                                    ? "Resend"
                                    : "Send Mail To Collect Documents"
                            }
                            data-pr-position="top"
                            className={`p-text tableHistory${rowData._id}`}
                        >
                            <i className="pi pi-send" />
                        </span>
                        <Tooltip target={`.tableHistory${rowData._id}`} />
                    </div>
                </Authorize>
            </div>
        );
    };

    showDeleteTableDialog = (rowData) => {
        this.setState({
            isShowConfirmationDialog: true,
            selectedId: rowData._id,
            EmailData: rowData,
            showTextarea: rowData.documentCollectionRequestSend,
        });
    };

    onCloseConfirmation = () => {
        this.setState({
            isShowConfirmationDialog: false,
            selectedId: null,
            EmailData: null,
            showTextarea: false,
            emailMessage: "",
        });
    };
    handleTextareaChange = (event) => {
        this.setState({
            emailMessage: event.target.value,
            emailMessageErr: event.target.value == "" ? true : false,
        });
    };

    footerContentforEmail = () => (
        <div>
            <Button
                label="Cancel"
                className=" p-button-text hover:bg-primary mr-3"
                onClick={() => {
                    this.setState({
                        isShowConfirmationDialog: false,
                    });
                }}
            />
            <Button
                label="Ok"
                onClick={this.sendEmailToConsultant}
                autoFocus
                className=" p-button-primary "
            />
        </div>
    );
    exportCSV = () => {
        this.dt.exportCSV();
    };

    documentStatusTemplate = (rowData) => {
        return rowData.documentStatus &&
            rowData.documentStatus.trim().length > 0
            ? rowData.documentStatus
            : "Pending";
    };
    render() {
        const header = (
            <div className="">
                <div class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 top-100 z-1 shadow-2 lg:shadow-none border-1 lg:border-none border-gray-800">
                    <ul class="list-none flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li>
                            <p className="mx-0 my-1 ss-header">Consultants</p>
                        </li>
                    </ul>
                    <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li class="flex-order-2 lg:flex-order-0">
                            <ul className="right-0">
                                <ul className="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                                    <Authorize
                                        permId={
                                            PERMISSIONS.CONTENT_WRITER_GENERAL_SEARCH
                                        }
                                    >
                                        <li className="flex-order-2 lg:flex-order-0 ml-4 ">
                                            {this.state.showSearch && (
                                                <InputText
                                                    placeholder="Search"
                                                    className=""
                                                    value={
                                                        this.props.globalSearch
                                                    }
                                                    onChange={
                                                        this.onGlobalSearch
                                                    }
                                                />
                                            )}
                                        </li>
                                    </Authorize>
                                    <Authorize
                                        permId={
                                            PERMISSIONS.CONTENT_WRITER_GENERAL_SEARCH
                                        }
                                    >
                                        <li className="flex-order-2 lg:flex-order-0 ml-4 cursor-pointer">
                                            <i
                                                data-pr-tooltip="Search"
                                                data-pr-position="bottom"
                                                className="pi pi-search text-base lg:text-2xl mr-2 ss-tbl-srch"
                                                onClick={(e) => {
                                                    this.setState({
                                                        showSearch:
                                                            !this.state
                                                                .showSearch,
                                                    });
                                                }}
                                            ></i>
                                        </li>
                                        <Tooltip
                                            className="table-li-tooltip"
                                            autoHide={false}
                                            target=".ss-tbl-srch"
                                        />
                                    </Authorize>
                                    <Authorize
                                        permId={
                                            PERMISSIONS.CONTENT_WRITER_GENERAL_DOWNLOAD
                                        }
                                    >
                                        <li className="border-top-1 border-gray-800 lg:border-top-none ml-4 mr-4 cursor-pointer">
                                            <i
                                                data-pr-tooltip="Download"
                                                data-pr-position="bottom"
                                                className="pi pi-download  text-base lg:text-2xl mr-2 ss-tbl-dwnld"
                                                onClick={this.exportCSV}
                                            ></i>
                                        </li>
                                        <Tooltip
                                            className="table-li-tooltip"
                                            target=".ss-tbl-dwnld"
                                        />
                                    </Authorize>
                                </ul>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="flex justify-content-between flex-wrap">
                    <div class="flex font-bold border-round w-4"></div>
                    <div class="flex absolute right-0 align-items-right  font-bold border-round w-8 "></div>
                </div>
            </div>
        );

        const items = [{ label: "Admin" }, { label: "Content Writer" }];

        const home = {
            icon: "pi pi-home",
            url: "http://localhost:3000/#/admin",
        };
        return (
            <>
                <div className="h-screen overflow-auto ss-data-table">
                    <Card>
                        <BreadCrumb model={items} home={home} />
                        <Authorize permId={PERMISSIONS.CONTENT_WRITER_LIST}>
                            <div>
                                <DataTable
                                    ref={(el) => (this.dt = el)}
                                    value={this.props.contentWriterConsultants}
                                    lazy
                                    header={header}
                                    scrollHeight="400px"
                                    onSort={this.onSort}
                                    onPage={this.onPage}
                                    onFilter={this.onFilter}
                                    first={this.props.lazyParams?.first}
                                    last={this.props.totalRecords}
                                    rows={this.props.lazyParams?.rows}
                                    totalRecords={this.props.totalRecords}
                                    paginator
                                    paginatorLeft
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    rowsPerPageOptions={[
                                        5, 10, 25, 50, 100, 200, 300, 500,
                                    ]}
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                    scrollable
                                    responsiveLayout="scroll"
                                    rowHover
                                    dataKey="_id"
                                    selection={this.state.selectedCustomer}
                                    onSelectionChange={(e) =>
                                        this.setState({
                                            selectedCustomer: e.value,
                                        })
                                    }
                                    selectionMode="single"
                                >
                                    <Column
                                        field="name"
                                        header="Full Name"
                                    ></Column>
                                    <Column
                                        field="mobile"
                                        header="Phone"
                                    ></Column>
                                    <Column
                                        field="email"
                                        header="Email"
                                    ></Column>
                                    <Column
                                        field="rolePrefered"
                                        header="Role"
                                    ></Column>

                                    <Column
                                        header="Document Status"
                                        body={this.documentStatusTemplate}
                                    ></Column>
                                    <Column
                                        header="Actions"
                                        body={(rowData) =>
                                            this.actionsBody(rowData)
                                        }
                                    ></Column>
                                </DataTable>

                                {this.state.isShowAssignToDocPreparator && (
                                    <AssignDocPreparator
                                        selectedCustomer={
                                            this.state.selectedCustomer
                                        }
                                        onCancel={() => {
                                            this.setState({
                                                isShowAssignToDocPreparator: false,
                                                selectedCustomer: null,
                                            });
                                        }}
                                        onHide={() => {
                                            let thisObj = this;

                                            this.setState(
                                                {
                                                    isShowAssignToDocPreparator: false,
                                                    selectedCustomer: null,
                                                },
                                                () => {
                                                    thisObj.toast.show({
                                                        severity: "success",
                                                        summary:
                                                            "Assign to Document Preparation Team.",
                                                        detail: "User assigned successfully",
                                                        life: 3000,
                                                    });
                                                    this.props.getContentWriters();
                                                }
                                            );
                                        }}
                                    />
                                )}
                            </div>
                        </Authorize>
                    </Card>
                </div>

                {this.state.isShowNotes && (
                    <Dialog
                        header={"Notes"}
                        visible={true}
                        style={{ width: "49vw" }}
                        draggable={false}
                        onHide={() => {
                            this.setState({
                                isShowNotes: false,
                                editData: null,
                            });
                        }}
                    >
                        <p>{this.state.editData.notesToContentWriter}</p>
                    </Dialog>
                )}

                {this.state.isShowConsultantNotes && (
                    <ConsultantNotes
                        consultant={this.state.editData}
                        onHide={() => {
                            this.setState({
                                isShowConsultantNotes: false,
                                editData: null,
                            });
                        }}
                    />
                )}
                {this.state.isShowConsultantMonitor && (
                    <ConsultantMonitor
                        consultant={this.state.editData}
                        onHide={() => {
                            this.setState({
                                isShowConsultantMonitor: false,
                                editData: null,
                            });
                        }}
                    />
                )}

                {this.state.isShowConfirmationDialog && (
                    <Dialog
                        header="Send email confirmation"
                        visible={this.state.isShowConfirmationDialog}
                        draggable={false}
                        className="p-fluid"
                        style={{ width: "30vw" }}
                        onHide={this.onCloseConfirmation}
                        footer={this.footerContentforEmail}
                        contentStyle={{
                            padding: "1.5rem",
                        }}
                    >
                        {this.state.showTextarea ? (
                            <>
                                <p className="p-mb-3">
                                    Please enter your message: This message will
                                    be included in the email body.
                                    <Required />
                                </p>

                                <InputTextarea
                                    value={this.state.emailMessage || ""}
                                    onChange={this.handleTextareaChange}
                                    className="p-inputtext p-inputtextarea p-component p-filled"
                                    style={{
                                        width: "100%",
                                        minHeight: "100px",
                                    }}
                                ></InputTextarea>
                                <p className="p-error">
                                    {this.state.emailMessageErr
                                        ? "Please enter notes for email"
                                        : ""}{" "}
                                </p>
                            </>
                        ) : (
                            <p className="p-mb-3">
                                This email is being sent to the consultant to
                                collect documents.
                            </p>
                        )}
                    </Dialog>
                )}

                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
                {(this.state.isLoading || this.props.isLoading) && (
                    <LoadingComponent />
                )}
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    contentWriterConsultants: state?.contentWriters?.contentWriterConsultants,
    totalRecords: state?.contentWriters?.totalRecords,
    lazyParams: state?.contentWriters?.lazyParams,
    isLoading: state?.contentWriters?.isLoading,
});

export default BaseComponent(ContentWriter, mapStateToProps, {
    getContentWriters,
    resetLazyContentWriters,
});
