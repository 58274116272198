import { BasicLazyParams } from "../../Utils/constants";
import { ACTIONS } from "../actionTypes";
import { v4 as uuidv4 } from "uuid";
export default (
    state = {
        notes: [],
        isLoading: false,
        error: false,
        errorMessage: "",
        lazyParams: {
            ...BasicLazyParams,
            sortField: "-createdAt",
            sortOrder: 1,
        },
        globalSearch: "",
        totalRecords: 0,
        isAddLoading: false,
        currentConsultantId: null
    },
    action
) => {
    switch (action.type) {
        case ACTIONS.REQUEST_CONSULTANT_NOTE:
            // debugger
            return {
                ...state,
                lazyParams: {
                    ...state.lazyParams,
                    ...action?.data?.lazyParams,
                },
                notes: action?.data?._id !== state.currentConsultantId ? [] : state.notes,
                currentConsultantId: action?.data?._id,
                globalSearch: action?.data?.globalSearch || state?.globalSearch,
                isLoading: true,
                error: false,
            };
        case ACTIONS.RECEIVED_CONSULTANT_NOTE:
            return {
                ...state,
                isLoading: false,
                notes: action?.data?.data,
                totalRecords: action?.data?.totalRecords,
                lazyParams: {
                    ...state.lazyParams,
                    ...action?.data?.lazyParams,
                },
            };
        case ACTIONS.ERROR_CONSULTANT_NOTE:
            return {
                ...state,
                isLoading: false,
                error: true,
                errorMessage: action?.err?.message,
            };
        case ACTIONS.CLEAR_CONSULTANT_NOTE:
            return {
                isLoading: false,
                error: false,
                errorMessage: "",
                notes: []
            };
        case ACTIONS.REQUEST_ADD_NOTE:
            return {
                ...state,
                isAddLoading: true,
                error: false,


            };
        case ACTIONS.RECEIVED_ADD_NOTE:
            return {
                ...state,
                isAddLoading: false,
                notes: [
                    action?.data,
                    ...state.notes
                ],
                uuid: uuidv4(),
                totalRecords: state?.totalRecords + 1,
            };




        case ACTIONS.ERROR_ADD_NOTE:
            return {
                ...state,
                isAddLoading: false,
                error: true,
                errorMessage: action?.err?.message,
            };


        default:
            return state;
    }
};
