import { Toast } from "primereact/toast";
import React, { Component } from "react";
import NavFooter from "../../navFooter";
import Service from "../../../Service";
import ServerlessService from "../../../Service/ServerlessService";
import { BasicLazyParams } from "../../../Utils/constants";
import { CLOUDFRONT_ENDPOINT } from "../../../Service/config";
import ViewFullProfie from "../common/viewFullProfile";
import { logOut } from "../../../Utils";

export default class VisitedProfiles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profiles: [],
            lazyParams: {
                ...BasicLazyParams,
                sortField: "updatedAt",
                sortOrder: 1,
            },
            globalSearch: "",
        };
        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }

    getVisitedProfiles = () => {
        let { lazyParams, globalSearch } = this.state;

        this.setState({
            isLoading: true,
            profiles: [],
        });
        const url = `/mvisited/all?limit=${lazyParams.rows}&page=${lazyParams.page + 1
            }&search=${globalSearch}${lazyParams.sortField
                ? `&sort=${lazyParams.sortOrder == 1 ? "" : "-"}${lazyParams.sortField
                }`
                : ""
            }`;
        this.serverlessService
            .post(url, {}, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                        profiles: res.res.data,
                        totalRecords: res.res.totalRecords,
                    });

                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.message,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    componentDidMount() {
        this.getVisitedProfiles();
    }

    onProfileClick = (_id) => {

        this.setState({
            isShowProfile: true,
            selectedProfileId: _id,
        });
    };

    onBack = () => {
        this.setState({
            isShowProfile: false,
        });
    };

    render() {
        const { profiles } = this.state;
        return (
            <NavFooter>
                {this.state.isShowProfile ? (
                    <ViewFullProfie
                        isVisited={true}
                        onBack={this.onBack}
                        hideEdit={true}
                        selectedProfileId={this.state.selectedProfileId}
                    />
                ) : (
                    <>
                        <div className="layout-content">
                            <div>
                                <div className="surface-ground px-4 py-8 md:px-6 lg:px-8">
                                    <div className="font-bold text-900 text-4xl mb-3 ">
                                        Visited Profiles
                                    </div>

                                    <div className="grid ">
                                        {profiles.map((profile) => {
                                            return (
                                                <>
                                                    <div
                                                        className="col-12 md:col-6 xl:col-3 p-3"
                                                        key={profile._id}
                                                    >
                                                        <div
                                                            className="surface-card shadow-3 border-rounded p-4"
                                                            onClick={() => {
                                                                this.onProfileClick(
                                                                    profile._id
                                                                );
                                                            }}
                                                        >
                                                            <div className="flex flex-column align-items-center border-bottom-1 surface-border pb-3">
                                                                <img
                                                                    src={
                                                                        CLOUDFRONT_ENDPOINT +
                                                                        profile
                                                                            ?.profileUrl
                                                                            ?.key
                                                                    }
                                                                    alt="avatar-f-1"
                                                                    className="mb-3 w-full"
                                                                />
                                                                <span className="text-2xl text-800 block mb-3 font-semibold">
                                                                    {
                                                                        profile.firstName
                                                                    }{" "}
                                                                </span>
                                                                <span className="text-lg text-900 font-medium mb-2">
                                                                    {
                                                                        profile.lastName
                                                                    }
                                                                </span>
                                                                <span className="text-600 font-medium mb-3">
                                                                    {
                                                                        profile.profession
                                                                    }
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Toast
                            ref={(el) => (this.toast = el)}
                            position="bottom-right"
                        />
                    </>
                )}
            </NavFooter>
        );
    }
}
