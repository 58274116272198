export const Specialization = [{ key: 'cardiologist', value: "Cardiologist" },
{ key: 'neurologist', value: "Neurologist" },
{ key: 'gastroenterologist', value: "Gastroenterologist" },
{ key: 'orthopedic', value: "Orthopedic" },
{ key: 'pulmonologist', value: "Pulmonologist" },
{ key: 'oncologist', value: "Oncologist" },
{ key: 'ent', value: "Ent Specialist" },
{ key: 'urologist', value: "Urologist" },
{ key: 'general', value: "General Physician" },
]


export const BasicLazyParams = {
    first: 0,
    rows: 10,
    page: 0,
    search: '',
    sortField: null,
    sortOrder: null,
    filters: {
        'name': { value: '', matchMode: 'contains' },
        'country.name': { value: '', matchMode: 'contains' },
        'company': { value: '', matchMode: 'contains' },
        'representative.name': { value: '', matchMode: 'contains' },
    }
}


export const BookingStatus = {
    Booked: 1,
    Visited: 2,
    Cancelled: 3,
}


export const S3_UPLOAD_CONFIG = {
    bucketName: "sendshortlyemailattachments",
    dirName: "uploads/" /* optional */,
    region: "ap-south-1",
    accessKeyId: "AKIAWLDIXTISNJCUYZSY",
    secretAccessKey: "dPmQoE4BfQr6UFQGkouMeDGwv22HrdWVmzY5xBWT",
    // s3Url: 'https:/your-custom-s3-url.com/', /* optional */
};


export const PackagesCount = [
    { credits: "25", id: "pack_25" },
    { credits: "50", id: "pack_50" },
    { credits: "75", id: "pack_75" },
    { credits: "100", id: "pack_100" },

];

export const typeOptions = [
    { type: "Package", id: "Package" },
    { type: "Subscription", id: "Subscription" },


];




export const FeeDays = [
    { days: "1 day", id: "1_day" },
    { days: "3 days", id: "3_days" },
    { days: "7 days", id: "7_days" },
    { days: "15 days", id: "15_days" },
    { days: "30 days", id: "30_days" },
    { days: "2 months", id: "2_months" },
    { days: "3 months", id: "3_months" },
    { days: "6 months", id: "6_months" },
    { days: "1 year", id: "1_year" },

];

export const PaymentModes = [
    { type: "Online", id: "online" },
    { type: "Offline", id: "offline" },
];

export const Status = [
    { type: "Pending", id: "pending" },
    { type: "Paid", id: "paid" },
];

export const PolicyTypes = [
    { type: "Type 1- Fixed (start 5k- lift 6k)", id: "type1" },
    { type: "type2", id: "type2" },
];

export const Months = [
    { name: "1st Month", id: "1stMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },

    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },

]



export const APP_TARGETS = {
    MATRIMONY: 'matrimony',
    CO_SPACE: 'cospace',
    CHITFUNDS: 'chitfunds',
    CONSULTANT: 'consultant'
}

export const TRACKER_ACTIONS = {
    CONSULTANT_ADDED: 'CONSULTANT_ADDED',
    CONSULTANT_UPDATED: 'CONSULTANT_UPDATED',
    CONSULTANT_DELETED: 'CONSULTANT_DELETED',
    CONSULTANT_ASSIGNED_TO_CONTENTWRITER: 'CONSULTANT_ASSIGNED_TO_CONTENTWRITER',
    CONSULTANT_ASSIGNED_TO_DOCUMENTPREPERATOR: 'CONSULTANT_ASSIGNED_TO_DOCUMENTPREPERATOR',
    CONSULTANT_ASSIGNED_TO_DOC_VERIFIER: 'CONSULTANT_ASSIGNED_TO_DOC_VERIFIER',
    CONSULTANT_ASSIGNED_TO_TECH_PREPERATOR: 'CONSULTANT_ASSIGNED_TO_TECH_PREPERATOR',
    CONSULTANT_ASSIGNED_TO_MARKETING_REVIEW: 'CONSULTANT_ASSIGNED_TO_MARKETING_REVIEW',
    CONSULTANT_ASSIGNED_TO_SALES_HEAD: 'CONSULTANT_ASSIGNED_TO_SALES_HEAD',
    CONSULTANT_ASSIGNED_TO_MARKETING: 'CONSULTANT_ASSIGNED_TO_MARKETING',
    CONSULTANT_CHANGED_STATUS: 'CONSULTANT_CHANGED_STATUS'

}


export const CONSULTANT_STATUS = {
    CREATED: 'CREATED',
    CONTENT_WRITING: 'CONTENT_WRITING',
    DOCUMENT_PREPERATING: 'DOCUMENT_PREPERATING',
    DOCUMENT_EDITING: 'DOCUMENT_EDITING',
    DOCUMENT_VERIFYING: 'DOCUMENT_VERIFYING',
    TECH_PREPARING: 'TECH_PREPARING',
    MARKETING_REVIEW: 'MARKETING_REVIEW',
    READY_TO_MARKET: 'READY_TO_MARKET',
    MARKETING: 'MARKETING',
    COMPLETED: 'COMPLETED',
    HOLD: 'HOLD',
    STOPPED: 'STOPPED'
}


export const DOCUMENT_EDITING = {
    REQUESTED: 'REQUESTED',
    INPROGRESS: 'INPROGRESS',
    SUBMITTED: 'SUBMITTED',
    COMPLETED: 'COMPLETED',
    BLOCKED: 'BLOCKED',
    PENDING: 'PENDING',
    REREQUESTED: 'REREQUESTED',

}



export const CONSULTANT_MENU_TAB = {
    CONSULTANT: 'CONSULTANT',
    CONTENT_WRITER: 'CONTENT_WRITER',
    SUBMITTED: 'SUBMITTED',
    COMPLETED: 'COMPLETED',
    BLOCKED: 'BLOCKED',
    PENDING: 'PENDING'

}


export const timeZones = [
    { label: 'Central Standard Time', value: 'CST' },
    { label: 'Eastern Standard Time', value: 'EST' },
    { label: 'Pacific Standard Time', value: 'PST' },
    { label: 'Indian Standard Time', value: 'IST' },
];

export const ResourceTypes = {
YOUTUBE:'youtube',
EXTERNALLINK :'externalLink',
TEXTBOX:'texbox',
UPLOAD:'upload'
}
