import React, { Component } from "react";
;
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import LoadingComponent from "../../../LoadingComponent";
import ServerlessService from "../../../Service/ServerlessService";
import {
    getFormFields,
    isFormValid,
    onTextChange,
} from "../../../Utils/formHelper";
import { logOut, trimObj } from "../../../Utils";
import QuestionFields from "./addConsultant.json";
import BaseComponent from "../../common/BaseComponent";
import { addCopyHelperData } from "../../../store/actions";


class AddCopyHelper extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(
            QuestionFields,
            this.props.editQuestion ||
            {

            }
        );
        this.state = {
            question: this.formFields.data,
            formValidations: this.formFields.formValidations,
        };
        this.serverlessService = new ServerlessService();
    }

    addOrEdit = () => {
        let question = trimObj(this.state.question);

        const formStatus = isFormValid(
            QuestionFields,
            this.formFields.formValidations,
            question
        );

        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
        }
        const requestBody = {
            ...this.state.question,
            consultant: this.state.consultantId,
        };
        if (isCompleteFormValid) {
            this.props.addCopyHelperData(requestBody);
            if (this.props?.params?.operation == "edit") {
                this.props.addCopyHelperData(question);
            }

        }
    };

    getCopyhelperById = (id) => {
        this.setState({
            isLoading: true,
        });

        const url = `/consultants/copy-helper/id/${id}`;
        this.serverlessService
            .get(url, true)
            .then((res) => {
                if (res && res.status && res.res.data) {
                    this.setState(
                        {
                            question: res.res.data,
                            isLoading: false,
                        },
                        () => {

                        },

                    );
                } else {
                    this.setState({
                        isLoading: false,
                    });

                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    componentDidMount() {
        if (this.props?.params?.operation == "edit") {
            if (this.props?.params?.id) {
                this.getCopyhelperById(this.props?.params.id);

            }
        }
        if (this.props?.params?.id) {
            this.setState({
                consultantId: this.props.params.id,
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.consultantAddedOrUpdated !=
            this.props.consultantAddedOrUpdated
        ) {
            if (this.props?.params?.operation == "edit") {

                // this.props.history.push(`/admin/copy-helper/${this.props.params.id}`)
                this.props.history.goBack();
            } else {

                this.setState({
                    question: {
                        question: "",
                        answer: "",
                    },
                });
                this.toast.show({
                    severity: "success",
                    summary: "Saved.",
                    detail: "Successfully saved.",
                    life: 3000,
                });

            }
        }
    }
    render() {
        const { question, formValidations } = this.state;

        return (
            <div>
                <Button
                    icon="pi pi-arrow-left"
                    className="p-button-rounded p-button-text p-button-secondary m-3"
                    onClick={() => this.props.history.goBack()}
                    style={{
                        marginRight: "0.5rem",
                        padding: "0.5rem",
                        backgroundColor: "#6366F1",
                        color: "#ffffff",
                        fontSize: "1.5rem",
                    }}
                />
                <div className="h-screen overflow-auto ss-data-table">
                    <div className="font-bold block mb-2">
                        <h1>Copy Helper</h1>
                    </div>

                    <div className="p-grid row pl-6 pr-6">
                        <div className="col-12">
                            <label className="font-bold block mb-2">
                                Question
                            </label>
                            <InputTextarea
                                onChange={(e) => {
                                    onTextChange(
                                        e.target.value,
                                        "question",
                                        this,
                                        QuestionFields,
                                        question,
                                        formValidations,
                                        "question",
                                        "formValidations"
                                    );
                                }}
                                value={question.question}
                            />
                            {formValidations &&
                                !formValidations.fields["question"].isValid && (
                                    <p className="p-error">
                                        {
                                            formValidations.fields["question"]
                                                .errorMsg
                                        }
                                    </p>
                                )}
                        </div>

                        <div className="col-12">
                            <label className="font-bold block mb-2">
                                Answer
                            </label>
                            <InputTextarea
                                rows={10}
                                onChange={(e) => {
                                    onTextChange(
                                        e.target.value,
                                        "answer",
                                        this,
                                        QuestionFields,
                                        question,
                                        formValidations,
                                        "question",
                                        "formValidations"
                                    );
                                }}
                                value={question.answer}
                            />
                            {formValidations &&
                                !formValidations.fields["answer"].isValid && (
                                    <p className="p-error">
                                        {
                                            formValidations.fields["answer"]
                                                .errorMsg
                                        }
                                    </p>
                                )}
                        </div>

                        <div className="mt-3">
                            <Button
                                label="Cancel"
                                className="p-button-text hover:bg-primary mr-3"
                                onClick={() => {
                                    this.props.history.goBack();
                                }}
                            />
                            <Button
                                label={
                                    this.props?.params?.operation == "edit"
                                        ? "Update"
                                        : "Add"
                                }
                                onClick={this.addOrEdit}
                                autoFocus
                                className="p-button-primary "
                            />
                        </div>
                    </div>
                </div>

                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
                {(this.state.isLoading || this.props.isLoading) && (
                    <LoadingComponent />
                )}
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    copyHelper: state?.copyhelper?.copyHelper,
    totalRecords: state?.copyhelper?.totalRecords,
    lazyParams: state?.copyhelper?.lazyParams,
    isLoading: state?.copyhelper?.isLoading,
    consultantAddedOrUpdated: state?.copyhelper?.consultantAddedOrUpdated,
});

export default BaseComponent(AddCopyHelper, mapStateToProps, {
    addCopyHelperData,
});
