import React, { Component } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import { Toast } from "primereact/toast";
import LoadingComponent from "../../../LoadingComponent";
import ServerlessService from "../../../Service/ServerlessService";
import {
    getFormFields,
    isFormValid,
    onTextChange,
    onNumberChange,
} from "../../../Utils/formHelper";
import { logOut, trimObj } from "../../../Utils";
import ConsultantFields from "./addConsultant.json";
import BaseComponent from "../../common/BaseComponent";
import { addorupdate } from "../../../store/actions";

class AddLearningPlan extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(
            ConsultantFields,
            this.props.editData || {}
        );
        this.state = {
            consultant: this.formFields.data,
            formValidations: this.formFields.formValidations,
        };
        this.serverlessService = new ServerlessService();
    }

    addOrUpdate = async () => {
        let consultant = trimObj(this.state.consultant);

        const planDetailsCount = consultant.planDetails
            ? Object.keys(consultant.planDetails).length
            : 0;

        if (consultant.days >= planDetailsCount) {
            const formStatus = isFormValid(
                ConsultantFields,
                this.formFields.formValidations,
                consultant
            );

            let isCompleteFormValid = true;
            if (!formStatus.formValidations.isFormValid) {
                this.setState({
                    formValidations: formStatus.formValidations,
                });
                isCompleteFormValid = false;
            }

            if (isCompleteFormValid) {
                await this.props.addorupdate(consultant);
            }
        } else {

            this.showToast(
                "error",
                "Number of days cannot be less than total items in planDetails."
            );
        }
    };

    showToast = (severity, message) => {
        this.toast.show({
            severity: severity,
            summary: message,
            detail: "",
            life: 3000,
        });
    };

    getLearningPlanById = (id) => {
        this.setState({
            isLoading: true,
        });

        const url = `/consultants/learning-plans/id/${id}`;
        this.serverlessService
            .get(url, true)
            .then((res) => {
                if (res && res.status && res.res.data) {
                    this.setState(
                        {
                            consultant: res.res.data,
                            isLoading: false,
                        },
                        () => { }
                    );
                } else {
                    this.setState({
                        isLoading: false,
                    });

                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    componentDidMount() {
        if (this.props?.params?.operation == "edit") {
            if (this.props?.params?.id) {
                this.getLearningPlanById(this.props?.params.id);
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.consultantAddedOrUpdated !=
            this.props.consultantAddedOrUpdated
        ) {
            this.props.history.push("/admin/learning-plans");
        }
    }

    render() {
        const { consultant, formValidations } = this.state;
        return (
            <div className="h-screen overflow-auto ss-data-table">
                <div className="font-bold block mb-2">
                    <h1>Learning Plans</h1>
                </div>
                <div className="p-grid row pl-6 pr-6">
                    <div className="col-12 md:col-4 lg:col-4">
                        <label className="font-bold block mb-0">
                            Plan Name<span className="sp-required">*</span>
                        </label>
                        <InputText
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "planName",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                            value={consultant.planName}
                        />
                        {formValidations &&
                            !formValidations.fields["planName"].isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields["planName"]
                                            .errorMsg
                                    }
                                </p>
                            )}
                    </div>
                    <div className="col-12 md:col-4 lg:col-4">
                        <label className="font-bold block mb-0">
                            Days<span className="sp-required">*</span>
                        </label>
                        <InputText
                            onChange={(e) => {
                                onNumberChange(
                                    e.target.value,
                                    "days",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                            value={consultant.days}
                        />
                        {formValidations &&
                            !formValidations.fields["days"].isValid && (
                                <p className="p-error">
                                    {formValidations.fields["days"].errorMsg}
                                </p>
                            )}
                    </div>
                </div>
                <div className="p-grid row pl-6 pr-6">
                    <div className="col-12 md:col-4 lg:col-4"></div>
                </div>
                <div className="mt-2">
                    <Button
                        label="Cancel"
                        className="p-button-text hover:bg-primary mr-2"
                        onClick={() => {
                            this.props.history.push("/admin/learning-plans");
                        }}
                    />
                    <Button
                        label={
                            this.props?.params?.operation == "edit"
                                ? "Update"
                                : "Add"
                        }
                        onClick={this.addOrUpdate}
                        autoFocus
                        className="p-button-primary  ml-2 "
                    />
                </div>

                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
                {(this.state.isLoading || this.props.isLoading) && (
                    <LoadingComponent />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    consultants: state?.learningPlans?.consultants,
    totalRecords: state?.learningPlans?.totalRecords,
    lazyParams: state?.learningPlans?.lazyParams,
    isLoading: state?.learningPlans?.isLoading,
    consultantAddedOrUpdated: state?.learningPlans?.consultantAddedOrUpdated,
});

export default BaseComponent(AddLearningPlan, mapStateToProps, {
    addorupdate,
});
