import React, { useState } from 'react';
import './style.scss'; // Import CSS for styles

const TextExpandable = ({ text }) => {
    const [expanded, setExpanded] = useState(false);

    return (
        <div>
            <div className={`text ${expanded ? 'expanded' : 'collapsed'}`}>
                {text}
            </div>
            <button onClick={() => setExpanded(!expanded)}>
                {expanded ? 'Show Less' : 'Show More'}
            </button>
        </div>
    );
};

export default TextExpandable;
