import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import Service from "../../../Service";
import ServerlessService from "../../../Service/ServerlessService";
import LoadingComponent from "../../../LoadingComponent";

import AddQuestion from "./addContact";
import { BasicLazyParams } from "../../../Utils/constants";
import { Tooltip } from "primereact/tooltip";
import Authorize, { PERMISSIONS } from "../../../session/authorize";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import BaseComponent from "../../common/BaseComponent";
import { getContact, resetLazyContact } from "../../../store/actions";
import { BreadCrumb } from "primereact/breadcrumb";
import { logOut } from "../../../Utils";

class Contacts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            editContact: null,
            isShowConfirmationDialog: false,
            globalSearch: "",
            lazyParams: {
                ...BasicLazyParams,
                sortField: "createdAt",
                sortOrder: 1,
            },
        };

        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }

    deleteQuestion = (_id) => {
        this.setState({
            isLoading: true,
        });
        var _id = this.state.selectedId || _id;

        const url = `/consultants/contact/${_id}`;
        this.serverlessService
            .delete(url, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState(
                        {
                            isLoading: false,
                            isShowConfirmationDialog: false,
                        },
                        () => {
                            this.props.getContact();
                        }
                    );
                } else {
                    this.setState({
                        isLoading: false,
                        isShowConfirmationDialog: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                    isShowConfirmationDialog: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    componentDidMount() {
        // if (!this.props.contacts?.length) {
        this.props.getContact();
        // }
    }
    onGlobalSearch = (e) => {
        this.props.getContact({ globalSearch: e.target.value });
    };

    onPage = (event) => {
        this.props.getContact({ lazyParams: event });
    };

    onSort = (event) => {
        this.props.getContact({ lazyParams: event });
    };

    onFilter = (event) => {
        event["first"] = 0;
        this.props.getContact({ lazyParams: event });
    };

    onHide = () => {
        this.setState({
            isShowConfirmationDialog: false,
            editContact: null,
            visible: false,
        });
    };

    actionsBody = (rowData) => {
        return (
            <div className="p-grid row">
                <div className="col-2 ml-2 mr-2 ">
                    <span
                        onClick={() => {
                            this.props.history.push(
                                `/admin/contact/edit/${rowData._id}`
                            );
                        }}
                        data-pr-tooltip="Edit Question"
                        data-pr-position="top"
                        className={`cursor-pointer edit${rowData._id.substr(
                            4
                        )}`}
                    >
                        <i className="pi pi-pencil" />
                    </span>
                    <Tooltip
                        className="table-li-tooltip2"
                        autoHide={false}
                        target={`.edit${rowData._id.substr(4)}`}
                    />
                </div>

                <div className="col-2">
                    <span
                        onClick={() =>
                            this.showDeleteQuestionDialog(rowData._id)
                        }
                        data-pr-tooltip="Delete Question"
                        data-pr-position="top"
                        className={`cursor-pointer delete${rowData._id.substr(
                            4
                        )}`}
                    >
                        <i className="pi pi-trash" />
                    </span>
                    <Tooltip
                        className="table-li-tooltip2"
                        autoHide={false}
                        target={`.delete${rowData._id.substr(4)}`}
                    />
                </div>
            </div>
        );
    };

    showDeleteQuestionDialog = (id) => {
        this.setState({
            isShowConfirmationDialog: true,
            selectedId: id,
        });
    };

    footerContent = () => (
        <div>
            <Button
                label="Cancel"
                className=" p-button-text hover:bg-primary "
                onClick={this.onHide}
            />
            <Button
                label="Ok"
                onClick={this.deleteQuestion}
                autoFocus
                className=" p-button-primary "
            />
        </div>
    );

    onCloseConfirmation = () => {
        this.setState({
            isShowConfirmationDialog: false,
        });
    };

    exportCSV = () => {
        this.dt.exportCSV();
    };

    render() {
        const header = (
            <div className="">
                <div class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 top-100 z-1 shadow-2 lg:shadow-none border-1 lg:border-none border-gray-800">
                    <ul class="list-none flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li>
                            <p className="mx-0 my-1 ss-header">Questions</p>
                        </li>
                    </ul>
                    <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li class="flex-order-2 lg:flex-order-0">
                            <ul className="right-0">
                                <ul className="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                                    <li className="flex-order-2 lg:flex-order-0 ml-4 ">
                                        {this.state.showSearch && (
                                            <InputText
                                                placeholder="Search"
                                                className=""
                                                value={
                                                    this.props.globalSearch
                                                }
                                                onChange={
                                                    this.onGlobalSearch
                                                }
                                            />
                                        )}
                                    </li>
                                    <li className="flex-order-2 lg:flex-order-0 ml-4 cursor-pointer">
                                        <i
                                            data-pr-tooltip="Search"
                                            data-pr-position="bottom"
                                            className="pi pi-search text-base lg:text-2xl mr-2 ss-tbl-srch"
                                            onClick={(e) => {
                                                this.setState({
                                                    showSearch:
                                                        !this.state
                                                            .showSearch,
                                                });
                                            }}
                                        ></i>
                                    </li>
                                    <Tooltip
                                        className="table-li-tooltip"
                                        autoHide={false}
                                        target=".ss-tbl-srch"
                                    />
                                    <li className="border-top-1 border-gray-800 lg:border-top-none ml-4 mr-4 cursor-pointer">
                                        <i
                                            data-pr-tooltip="Download"
                                            data-pr-position="bottom"
                                            className="pi pi-download  text-base lg:text-2xl mr-2 ss-tbl-dwnld"
                                            onClick={this.exportCSV}
                                        ></i>
                                    </li>
                                    <Tooltip
                                        className="table-li-tooltip"
                                        target=".ss-tbl-dwnld"
                                    />
                                </ul>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="flex justify-content-between flex-wrap">
                    <div class="flex font-bold border-round w-4"></div>
                    <div class="flex absolute right-0 align-items-right  font-bold border-round w-8 "></div>
                </div>
            </div>
        );

        const items = [{ label: "Admin" }, { label: "Contacts" }];

        const home = {
            icon: "pi pi-home",
            url: "http://localhost:3000/#/admin",
        };

        return (
            <>
                <div className="h-screen overflow-auto ss-data-table">
                    <Card>
                        <BreadCrumb model={items} home={home} />
                        <Button
                            label="New"
                            icon="pi pi-plus"
                            className="m-3"
                            outlined
                            aria-label="Create"
                            onClick={() =>
                                this.props.history.push(`/admin/contact/add`)
                            }
                        />
                        <DataTable
                            ref={(el) => (this.dt = el)}
                            header={header}
                            value={this.props.contacts}
                            lazy
                            scrollHeight="calc(100vh - 370px)"
                            onSort={this.onSort}
                            onPage={this.onPage}
                            onFilter={this.onFilter}
                            first={this.props.lazyParams.first}
                            last={this.props.totalRecords}
                            rows={this.props.lazyParams.rows}
                            totalRecords={this.props.totalRecords}
                            paginator
                            paginatorLeft
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            rowsPerPageOptions={[
                                5, 10, 25, 50, 100, 200, 300, 500,
                            ]}
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                            scrollable
                            responsiveLayout="scroll"
                            rowHover
                            dataKey="_id"
                            selection={this.state.selectedQuestion}
                            onSelectionChange={(e) =>
                                this.setState({ selectedQuestion: e.value })
                            }
                            selectionMode="single"
                        >
                            <Column
                                field="name"
                                header="Name"
                            ></Column>
                            <Column
                                field="designation"
                                header="Designation"
                            ></Column>
                            <Column field="phoneNumber" header="Phone Number"></Column>
                            <Column
                                header="Actions"
                                body={(rowData) =>
                                    this.actionsBody(rowData)
                                }
                            ></Column>
                        </DataTable>

                    </Card>
                </div>

                {this.state.visible && (
                    <AddQuestion
                        onHide={this.onHide}
                        editContact={this.state.editContact}
                        getQuestions={this.props.getQuestions}
                    ></AddQuestion>
                )}
                {this.state.isShowConfirmationDialog && (
                    <Dialog
                        draggable={false}
                        header="Are you Sure ?"
                        visible={this.state.isShowConfirmationDialog}
                        style={{ width: "15vw" }}
                        onHide={this.onCloseConfirmation}
                        footer={this.footerContent}
                    >
                        <p>Deleted Question can't be restore.</p>
                    </Dialog>
                )}
                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
                {(this.state.isLoading || this.props.isLoading) && (
                    <LoadingComponent />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    contacts: state?.contact?.contacts,
    totalRecords: state?.contact?.totalRecords,
    lazyParams: state?.contact?.lazyParams,
    isLoading: state?.contact?.isLoading,
});

export default BaseComponent(Contacts, mapStateToProps, {
    getContact,
    resetLazyContact,
});
