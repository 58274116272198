import React from 'react';
import { InputText } from 'primereact/inputtext';
import { Card } from 'primereact/card';
import TotalProfilesCharts from './totalProfilesWibget';
import ClosureCharts from './closureWibget';
import InterviewCharts from './interviewsWibget';
import ProfilesToRecruiter from './ProfilesAssignedToRecruiter';
import ScreeningCallsCharts from './screeningcallsWibget';
import InterSubCharts from './sub&interWibget';
import SubmissionCharts from './submissionWibget';
import CreatedByListCharts from './createdByListofProfilesWibget';
import CreatedByConsultant from './createdByConsultantCount';
import ClosureByRecruiterCharts from './closureByRecruiterWibget';

const Dashboard = () => {
    return (
        <div style={{ padding: '20px' }}>
            <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                <InputText style={{ marginRight: '10px' }} placeholder="Search..." />
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
                <div style={{ flex: '1 1 50%' }}>
                    <div style={{ display: 'flex', gap: '20px' }}>
                        <Card style={{ width: '100%' }}>
                            <TotalProfilesCharts />
                        </Card>
                        <Card style={{ width: '100%' }}>
                            <ClosureCharts />
                        </Card>
                    </div>
                </div>
                <div style={{ flex: '1 1 50%' }}>
                    <div style={{ display: 'flex', gap: '20px' }}>
                        <Card style={{ width: '100%' }}>
                            <InterviewCharts />
                        </Card>
                        <Card style={{ width: '100%' }}>
                            <ProfilesToRecruiter />
                        </Card>
                    </div>
                </div>
                <div style={{ flex: '1 1 50%' }}>
                    <div style={{ display: 'flex', gap: '20px' }}>
                        <Card style={{ width: '100%' }}>
                            <ScreeningCallsCharts />
                        </Card>
                        <Card style={{ width: '100%' }}>
                            <SubmissionCharts />
                        </Card>
                    </div>
                </div>
                <div style={{ flex: '1 1 50%' }}>
                    <div style={{ display: 'flex', gap: '20px' }}>
                        <Card style={{ width: '100%' }}>
                            <CreatedByListCharts />
                        </Card>
                        <Card style={{ width: '100%' }}>
                            <CreatedByConsultant />
                        </Card>
                    </div>
                </div>
                <div style={{ flex: '1 1 50%' }}>
                    <div style={{ display: 'flex', gap: '20px' }}>
                        <Card style={{ width: '100%' }}>
                            <InterSubCharts />
                        </Card>
                        <Card style={{ width: '100%' }}>
                            <ClosureByRecruiterCharts />
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
