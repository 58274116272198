import React from "react";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import {
    getFormFields,
    isFormValid,
    onEmailChange,
} from "../../../Utils/formHelper";
import { Button } from "primereact/button";
import { logOut, trimObj } from "../../../Utils";
import registerFormFields from "./registerForm.json";
import Service from "../../../Service";
import BaseComponent from "../../common/BaseComponent";

class Register extends React.Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(registerFormFields, {});
        this.state = {
            user: this.formFields.data,
            formValidations: this.formFields.formValidations,
            isNewUser: false,
            isShowVerifyOtp: false,
            consultant: this.formFields.data,
            orginfo: this.formFields.data,
        };
        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }

    isAdditionalValid = () => {
        let user = JSON.parse(JSON.stringify(this.state.user));
        let { formValidations } = this.state;
        let isValid = true;
        if (user.password != user.passwordConfirm) {
            isValid = false;
            formValidations.fields["passwordConfirm"] = {
                ...formValidations.fields["passwordConfirm"],
                isValid: false,
                errorMsg: `Password and Confirm password is not matched.`,
            };
        }

        this.setState((prevState) => {
            return {
                formValidations,
            };
        });

        return isValid;
    };

    onRegisterSuccess = (user) => { };

    onRegister = () => {

        const formStatus = isFormValid(
            registerFormFields,
            this.formFields.formValidations,
            trimObj(this.state.user)
        );

        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
        }

        if (!this.isAdditionalValid()) {
            isCompleteFormValid = false;
        }

        if (isCompleteFormValid) {
            let data = trimObj(this.state.user);
            data.orgCode = this.state.orginfo.orgCode;
            data.userType = 'client';
            data.email = this.state.consultant.email;
            data.phoneNumber = this.state.consultant.mobile;
            data.name = this.state.consultant.name;


            this.setState({
                isLoading: true,
            });
            const url = `/admin/consultant-sign-up`;

            this.serverlessService
                .post(url, data, true)
                .then((res) => {
                    if (res && res.status && res.res.status) {
                        if (res.res.statuscode == 2) {
                            this.setState({
                                userId: res.res.user,
                                isLoading: false,
                                isShowVerifyOtp: true,
                            });
                        } else {
                        }
                    } else {
                        this.setState({
                            isLoading: false,
                        });
                        this.toast.show({
                            severity: "error",
                            summary: "Error",
                            detail: res.message,
                            life: 3000,
                        });
                    }
                })
                .catch((e) => {
                    if (e?.response?.status > 400 && e?.response?.status < 500) {
                        logOut()
                    }
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: e.message,
                        life: 3000,
                    });
                });
        }
    };



    getConsultantById = (id) => {
        this.setState({
            isLoading: true,
        });

        const url = `/consultants/consultant/id/${id}`;
        this.serverlessService
            .get(url, true)
            .then((res) => {

                if (res && res.status && res.res.data) {
                    this.setState(
                        {
                            consultant: res.res.data,
                            isLoading: false,
                        },
                        () => {

                            this.getOrgById(this.state.consultant.orgId);
                        },
                        this.toast.show({
                            severity: "success",
                            summary: "Success",
                            detail: "Table added successfully",
                            life: 3000,
                        }),
                    );
                } else {
                    this.setState({
                        isLoading: false,
                    });

                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });


    }
    getOrgById = (id) => {
        this.setState({
            isLoading: true,
        });

        const url = `/organization/id/${id}`;
        this.serverlessService
            .get(url, true)
            .then((res) => {

                if (res && res.status && res.res.data) {
                    this.setState(
                        {
                            orginfo: res.res.data,
                            isLoading: false,
                        },
                        () => {
                        },
                        this.toast.show({
                            severity: "success",
                            summary: "Success",
                            detail: "Table added successfully",
                            life: 3000,
                        })
                    );
                } else {
                    this.setState({
                        isLoading: false,
                    });

                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };


    componentDidMount() {
        if (this.props?.params?.id) {
            this.getConsultantById(this.props.params.id)
        }
    }

    render() {
        const { user, formValidations, isNewUser, isShowVerifyOtp, userId, consultant } =
            this.state;
        return (
            <>
                <div className="">
                    <div>

                        <>
                            <div className="h-screen overflow-auto ss-data-table">

                                <label
                                    htmlFor="phoneNumber"
                                    className="block text-900 font-medium mt-3"
                                >
                                    Email
                                </label>
                                <InputText
                                    readOnly={true}
                                    value={consultant.email}
                                    className="w-full"
                                    onChange={(e) => {
                                        onEmailChange(
                                            consultant.email,
                                            "email",
                                            this,
                                            registerFormFields,
                                            user,
                                            formValidations,
                                            "user",
                                            "formValidations"
                                        );
                                    }}
                                />
                                {formValidations &&
                                    !formValidations.fields["email"]
                                        .isValid && (
                                        <p className="p-error">
                                            {
                                                formValidations.fields[
                                                    "email"
                                                ].errorMsg
                                            }
                                        </p>
                                    )}
                                <label
                                    htmlFor="phoneNumber"
                                    className="block text-900 font-medium mt-3"
                                >
                                    Password
                                </label>
                                <InputText
                                    value={user.password}
                                    type="password"
                                    autoComplete="new-password"
                                    className="w-full"
                                    onChange={(e) => {
                                        onTextChange(
                                            e.target.value,
                                            "password",
                                            this,
                                            registerFormFields,
                                            user,
                                            formValidations,
                                            "user",
                                            "formValidations"
                                        );
                                    }}
                                />
                                {formValidations &&
                                    !formValidations.fields["password"]
                                        .isValid && (
                                        <p className="p-error">
                                            {
                                                formValidations.fields[
                                                    "password"
                                                ].errorMsg
                                            }
                                        </p>
                                    )}
                                <label
                                    htmlFor="phoneNumber"
                                    className="block text-900 font-medium mt-3"
                                >
                                    Confirm Password
                                </label>
                                <InputText
                                    value={user.passwordConfirm}
                                    type="password"
                                    autoComplete="new-password"
                                    className="w-full"
                                    onChange={(e) => {
                                        onTextChange(
                                            e.target.value,
                                            "passwordConfirm",
                                            this,
                                            registerFormFields,
                                            user,
                                            formValidations,
                                            "user",
                                            "formValidations"
                                        );
                                    }}
                                />
                                {formValidations &&
                                    !formValidations.fields[
                                        "passwordConfirm"
                                    ].isValid && (
                                        <p className="p-error">
                                            {
                                                formValidations.fields[
                                                    "passwordConfirm"
                                                ].errorMsg
                                            }
                                        </p>
                                    )}


                                <Button
                                    label="Register"
                                    className="w-full py-3 font-medium mt-6"
                                    onClick={this.onRegister}
                                />
                            </div>

                        </>

                    </div>
                </div>
                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
                {this.state.isLoading && (
                    <>
                        <LoadingComponent />
                    </>
                )}
            </>
        );
    }
}

export default BaseComponent(Register);
