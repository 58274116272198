import { Dialog } from "primereact/dialog";
import React, { Component } from "react";
import CkEditorComponent from "../../common/ckEditor";
import { extractYoutubeVideoID } from "../../../Utils";

export default class ResourceLearningWidget extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isShowYoutubePlayer: false,
            isUploadPlayer: false,
        };
    }
    render() {
        const { resourceType, resource, title } = this.props.resource;
        console.log(this.props, 'eeeee')
        return (
            <>
                <Dialog
                    style={{ width: "90%", maxWidth: "800px", height: "70vh" }}
                    header={"Resource"}
                    modal
                    draggable={false}
                    blockScroll={true}
                    closeOnEscape={true}
                    dismissableMask={false}
                    visible={true}
                    closable={true}
                    onHide={this.props.hideLearningWidget}
                >
                    <p>{title}</p>
                    {resourceType == "texbox" && (
                        <CkEditorComponent
                            value={resource}
                            disabled={true}
                            editor={null}
                        />
                    )}

                    {resourceType === "youtube" && (
                        <>
                            {this.state.isShowYoutubePlayer ? (
                                <div className="embed-responsive embed-responsive-16by9">
                                    <iframe
                                        className="embed-responsive-item"
                                        src={`https://www.youtube.com/embed/${extractYoutubeVideoID(
                                            resource
                                        )}`}
                                        allowFullScreen
                                    ></iframe>
                                </div>
                            ) : (
                                <img
                                    className="card-img-top sp-img img-fluid"
                                    src={`https://i.ytimg.com/vi/${extractYoutubeVideoID(
                                        resource
                                    )}/hqdefault.jpg`}
                                    alt="Card cap"
                                    onClick={() => {
                                        this.setState({
                                            isShowYoutubePlayer: true,
                                        });
                                    }}
                                    style={{ cursor: "pointer" }}
                                />
                            )}
                        </>
                    )}

                    {resourceType === "upload" && (<>
                        {resource.filetype == 'video' && <>
                            <div className="embed-responsive embed-responsive-16by9">
                                <video
                                    controls
                                    className="embed-responsive-item"
                                >
                                    <source
                                        src={resource.resourceUpload}
                                        type="video/mp4"
                                    />
                                    Your browser does not support the video
                                    tag.
                                </video>
                            </div>
                        </>}
                        {
                            resource.filetype == 'image' && <>
                                <img
                                    className="card-img-top sp-img img-fluid"
                                    src={resource.resourceUpload}
                                    alt="Card cap"
                                    onClick={() => {
                                        this.setState({
                                            isUploadPlayer: true,
                                        });
                                    }}
                                    style={{ cursor: "pointer" }}
                                />
                            </>
                        }


                    </>
                    )}
                </Dialog>
            </>
        );
    }
}
