import { BasicLazyParams } from "../../Utils/constants";
import { ACTIONS } from "../actionTypes";
export default (
    state = {
        userQuestions: [],
        isLoading: false,
        error: false,
        errorMessage: "",
        lazyParams: {
            ...BasicLazyParams,
            sortField: "-createdAt",
            sortOrder: 1,
        },
        globalSearch: "",
        totalRecords: 0,
        selectedStatus: '',
        consultantAddedOrUpdated: false
    },
    action
) => {
    switch (action.type) {
        case ACTIONS.REQUEST_USERQUESTIONS:
            return {
                ...state,
                lazyParams: {
                    ...state.lazyParams,
                    ...action?.data?.lazyParams,
                },
                globalSearch: action?.data?.globalSearch || state?.globalSearch,
                selectedStatus: action?.data?.status,
                isLoading: true,
                error: false,
            };
        case ACTIONS.RECEIVED_USERQUESTIONS:

            return {
                ...state,
                isLoading: false,
                userQuestions: action?.data?.data,
                totalRecords: action?.data?.totalRecords,
                lazyParams: {
                    ...state.lazyParams,
                    ...action?.data?.lazyParams,
                },
            };
        case ACTIONS.ERROR_USERQUESTIONS:
            return {
                ...state,
                isLoading: false,
                error: true,
                errorMessage: action?.err?.message,
            };
        case ACTIONS.CLEAR_USERQUESTIONS:
            return {
                isLoading: false,
                error: false,
                errorMessage: "",
                userQuestions: []
            };
        case ACTIONS.REQUEST_ADD_USERQUESTIONS:
            return {
                ...state,

                error: false,
            };
        case ACTIONS.RECEIVED_ADD_USERQUESTIONS:
            return {
                ...state,
                userQuestions: [action?.data, ...state.userQuestions],
                totalRecords: state?.totalRecords + 1,
                isLoading: false,

                consultantAddedOrUpdated: !state.consultantAddedOrUpdated
            };
        case ACTIONS.RECEIVED_UPDATE_USERQUESTIONS:
            return {
                ...state,
                userQuestions: state.userQuestions.map(a => {
                    if (a._id === action?.data._id) {
                        return action?.data;
                    }
                    return a
                }),
                totalRecords: state?.totalRecords + 1,
                isLoading: false,
                consultantAddedOrUpdated: !state.consultantAddedOrUpdated
            };

        case ACTIONS.RECEIVED_DELETE_USERQUESTIONS:
            return {
                ...state,
                userQuestions: state.userQuestions.filter(a => a._id !== action?.data?.id),
                totalRecords: state?.totalRecords - 1,
                isLoading: false,
                consultantAddedOrUpdated: !state.consultantAddedOrUpdated
            };
        case ACTIONS.ERROR_ADD_USERQUESTIONS:
            return {
                ...state,
                error: true,
                errorMessage: action?.err?.message,
            };
        default:
            return state;
    }
};
