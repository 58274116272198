export const ACTIONS = {
    SESSION_CHECKED: "SESSION_CHECKED",
    SAVE_USER_SESSION: "SAVE_USER_SESSION",
    GET_PERMISSIONS_REQUESTED: "GET_PERMISSIONS_REQUESTED",
    GET_PERMISSIONS_SUCCEEDED: "GET_PERMISSIONS_SUCCEEDED",
    GET_PERMISSIONS_FAILED: "GET_PERMISSIONS_FAILED",

    REQUEST_ORG: "REQUEST_ORG",
    RECEIVED_ORG: "RECEIVED_ORG",
    ERROR_ORG: "ERROR_ORG",
    CLEAR_ORG_DATA: "CLEAR_ORG_DATA",

    REQUEST_SAVE_PRICING: "REQUEST_SAVE_PRICING",
    RECEIVED_SAVE_PRICING: "RECEIVED_SAVE_PRICING",
    ERROR_SAVE_PRICING: "ERROR_SAVE_PRICING",

    REQUEST_UPDATE_ORG: "REQUEST_UPDATE_ORG",
    RECEIVED_UPDATE_ORG: "RECEIVED_UPDATE_ORG",
    ERROR_UPDATE_ORG: "ERROR_UPDATE_ORG",

    REQUEST_CONSULTANT_TAGS: "REQUEST_CONSULTANT_TAGS",
    RECEIVED_CONSULTANT_TAGS: "RECEIVED_CONSULTANT_TAGS",
    ERROR_CONSULTANT_TAGS: "ERROR_CONSULTANT_TAGS",
    CLEAR_ORG_CONSULTANT_TAGS: "CLEAR_ORG_CONSULTANT_TAGS",

    REQUEST_CONSULTANTS: "REQUEST_CONSULTANTS",
    RECEIVED_CONSULTANTS: "RECEIVED_CONSULTANTS",
    ERROR_CONSULTANTS: "ERROR_CONSULTANTS",
    CLEAR_CONSULTANTS: "CLEAR_CONSULTANTS",

    REQUEST_ALL_CONSULTANTS: "REQUEST_ALL_CONSULTANTS",
    RECEIVED_ALL_CONSULTANTS: "RECEIVED_ALL_CONSULTANTS",
    ERROR_ALL_CONSULTANTS: "ERROR_ALL_CONSULTANTS",
    CLEAR_ALL_CONSULTANTS: "CLEAR_ALL_CONSULTANTS",

    REQUEST_ADD_CONSULTANTS: "REQUEST_ADD_CONSULTANTS",
    RECEIVED_ADD_CONSULTANTS: "RECEIVED_ADD_CONSULTANTS",
    RECEIVED_UPDATE_CONSULTANTS: "RECEIVED_UPDATE_CONSULTANTS",
    RECEIVED_DELETE_CONSULTANTS: "RECEIVED_DELETE_CONSULTANTS",
    ERROR_ADD_CONSULTANTS: "ERROR_ADD_CONSULTANTS",

    REQUEST_APPROVED_QUESTIONS: "REQUEST_APPROVED_QUESTIONS",
    RECEIVED_APPROVED_QUESTIONS: "RECEIVED_APPROVED_QUESTIONS",
    ERROR_APPROVED_QUESTIONS: "ERROR_APPROVED_QUESTIONS",
    CLEAR_ORG_APPROVED_QUESTIONS: "CLEAR_ORG_APPROVED_QUESTIONS",
    RECEIVED_DELETE_APPROVED_QUESTIONS: "RECEIVED_DELETE_APPROVED_QUESTIONS",

    REQUEST_CONTENTWRITER: "REQUEST_CONTENTWRITER",
    RECEIVED_CONTENTWRITER: "RECEIVED_CONTENTWRITER",
    ERROR_CONTENTWRITER: "ERROR_CONTENTWRITER",
    CLEAR_CONTENTWRITER: "CLEAR_CONTENTWRITER",



    REQUEST_DOCUMENTEDITING: "REQUEST_DOCUMENTEDITING",
    RECEIVED_DOCUMENTEDITING: "RECEIVED_DOCUMENTEDITING",
    ERROR_DOCUMENTEDITING: "ERROR_DOCUMENTEDITING",
    CLEAR_DOCUMENTEDITING: "CLEAR_DOCUMENTEDITING",


    REQUEST_DOCUMENTPREPERATOR: "REQUEST_DOCUMENTPREPERATOR",
    RECEIVED_DOCUMENTPREPERATOR: "RECEIVED_DOCUMENTPREPERATOR",
    ERROR_DOCUMENTPREPERATOR: "ERROR_DOCUMENTPREPERATOR",
    CLEAR_DOCUMENTPREPERATOR: "CLEAR_DOCUMENTPREPERATOR",

    REQUEST_USERQUESTIONS: "REQUEST_USERQUESTIONS",
    RECEIVED_USERQUESTIONS: "RECEIVED_USERQUESTIONS",
    ERROR_USERQUESTIONS: "ERROR_USERQUESTIONS",
    CLEAR_USERQUESTIONS: "CLEAR_USERQUESTIONS",

    REQUEST_ADD_USERQUESTIONS: "REQUEST_ADD_USERQUESTIONS",
    RECEIVED_ADD_USERQUESTIONS: "RECEIVED_ADD_USERQUESTIONS",
    RECEIVED_UPDATE_USERQUESTIONS: "RECEIVED_UPDATE_USERQUESTIONS",
    RECEIVED_DELETE_USERQUESTIONS: "RECEIVED_DELETE_USERQUESTIONS",
    ERROR_ADD_USERQUESTIONS: "ERROR_ADD_USERQUESTIONS",


    REQUEST_FAQ: "REQUEST_FAQ",
    RECEIVED_FAQ: "RECEIVED_FAQ",
    ERROR_FAQ: "ERROR_FAQ",
    CLEAR_FAQ: "CLEAR_FAQ",

    REQUEST_CONTACT: "REQUEST_CONTACT",
    RECEIVED_CONTACT: "RECEIVED_CONTACT",
    ERROR_CONTACT: "ERROR_CONTACT",
    CLEAR_CONTACT: "CLEAR_CONTACT",


    REQUEST_CONSULTANT_MONITOR: "REQUEST_CONSULTANT_MONITOR",
    RECEIVED_CONSULTANT_MONITOR: "RECEIVED_CONSULTANT_MONITOR",
    ERROR_CONSULTANT_MONITOR: "ERROR_CONSULTANT_MONITOR",
    CLEAR_CONSULTANT_MONITOR: "CLEAR_CONSULTANT_MONITOR",


    REQUEST_ADD_COMMENT: "REQUEST_ADD_COMMENT",
    RECEIVED_ADD_COMMENT: "RECEIVED_ADD_COMMENT",
    ERROR_ADD_COMMENT: "ERROR_ADD_COMMENT",

    REQUEST_CONSULTANT_NOTE: "REQUEST_CONSULTANT_NOTE",
    RECEIVED_CONSULTANT_NOTE: "RECEIVED_CONSULTANT_NOTE",
    ERROR_CONSULTANT_NOTE: "ERROR_CONSULTANT_NOTE",
    CLEAR_CONSULTANT_NOTE: "CLEAR_CONSULTANT_NOTE",

    REQUEST_ADD_NOTE: "REQUEST_ADD_NOTE",
    RECEIVED_ADD_NOTE: "RECEIVED_ADD_NOTE",
    ERROR_ADD_NOTE: "ERROR_ADD_NOTE",


    REQUEST_CONSULTANT_FAQ: "REQUEST_CONSULTANT_FAQ",
    RECEIVED_CONSULTANT_FAQ: "RECEIVED_CONSULTANT_FAQ",
    ERROR_CONSULTANT_FAQ: "ERROR_CONSULTANT_FAQ",
    CLEAR_CONSULTANT_FAQ: "CLEAR_CONSULTANT_FAQ",

    REQUEST_DOCUMENTVERIFIER: "REQUEST_DOCUMENTVERIFIER",
    RECEIVED_DOCUMENTVERIFIER: "RECEIVED_DOCUMENTVERIFIER",
    ERROR_DOCUMENTVERIFIER: "ERROR_DOCUMENTVERIFIER",
    CLEAR_DOCUMENTVERIFIER: "CLEAR_DOCUMENTVERIFIER",

    REQUEST_Users: "REQUEST_Users",
    RECEIVED_Users: "RECEIVED_Users",
    ERROR_Users: "ERROR_Users",
    CLEAR_Users: "CLEAR_Users",


    REQUEST_PREMARKETREVIEW: "REQUEST_PREMARKETREVIEW",
    RECEIVED_PREMARKETREVIEW: "RECEIVED_PREMARKETREVIEW",
    ERROR_PREMARKETREVIEW: "ERROR_PREMARKETREVIEW",
    CLEAR_PREMARKETREVIEW: "CLEAR_PREMARKETREVIEW",

    REQUEST_QUESTIONBANK: "REQUEST_QUESTIONBANK",
    RECEIVED_QUESTIONBANK: "RECEIVED_QUESTIONBANK",
    ERROR_QUESTIONBANK: "ERROR_QUESTIONBANK",
    CLEAR_QUESTIONBANK: "CLEAR_QUESTIONBANK",

    REQUEST_ADD_QUESTIONBANK: "REQUEST_ADD_QUESTIONBANK",
    RECEIVED_ADD_QUESTIONBANK: "RECEIVED_ADD_QUESTIONBANK",
    RECEIVED_UPDATE_QUESTIONBANK: "RECEIVED_UPDATE_QUESTIONBANK",
    RECEIVED_DELETE_QUESTIONBANK: "RECEIVED_DELETE_QUESTIONBANK",
    ERROR_ADD_QUESTIONBANK: "ERROR_ADD_QUESTIONBANK",


    REQUEST_RECRUITER: "REQUEST_RECRUITER",
    RECEIVED_RECRUITER: "RECEIVED_RECRUITER",
    ERROR_RECRUITER: "ERROR_RECRUITER",
    CLEAR_RECRUITER: "CLEAR_RECRUITER",

    REQUEST_SALESTEAM: "REQUEST_SALESTEAM",
    RECEIVED_SALESTEAM: "RECEIVED_SALESTEAM",
    ERROR_SALESTEAM: "ERROR_SALESTEAM",
    CLEAR_SALESTEAM: "CLEAR_SALESTEAM",

    REQUEST_DOCUMENTEDITINGREQUEST: "REQUEST_DOCUMENTEDITINGREQUEST",
    RECEIVED_DOCUMENTEDITINGREQUEST: "RECEIVED_DOCUMENTEDITINGREQUEST",
    ERROR_DOCUMENTEDITINGREQUEST: "ERROR_DOCUMENTEDITINGREQUEST",
    CLEAR_DOCUMENTEDITINGREQUEST: "CLEAR_DOCUMENTEDITINGREQUEST",


    REQUEST_INTERVIEWS: "REQUEST_INTERVIEWS",
    RECEIVED_INTERVIEWS: "RECEIVED_INTERVIEWS",
    ERROR_INTERVIEWS: "ERROR_INTERVIEWS",
    CLEAR_INTERVIEWS: "CLEAR_INTERVIEWS",

    REQUEST_ADD_INTERVIEWS: "REQUEST_ADD_INTERVIEWS",
    RECEIVED_ADD_INTERVIEWS: "RECEIVED_ADD_INTERVIEWS",
    RECEIVED_UPDATE_INTERVIEWS: "RECEIVED_UPDATE_INTERVIEWS",
    RECEIVED_DELETE_INTERVIEWS: "RECEIVED_DELETE_INTERVIEWS",
    ERROR_ADD_INTERVIEWS: "ERROR_ADD_INTERVIEWS",

    REQUEST_INTERVIEWS_DROPDOWN: "REQUEST_INTERVIEWS_DROPDOWN",
    RECEIVED_INTERVIEWS_Dropdown: "RECEIVED_INTERVIEWS_Dropdown",
    ERROR_INTERVIEWS_Dropdown: "ERROR_INTERVIEWS_Dropdown",
    CLEAR_INTERVIEWS_Dropdown: "CLEAR_INTERVIEWS_Dropdown",

    REQUEST_SALESTEAMHEAD: "REQUEST_SALESTEAMHEAD",
    RECEIVED_SALESTEAMHEAD: "RECEIVED_SALESTEAMHEAD",
    ERROR_SALESTEAMHEAD: "ERROR_SALESTEAMHEAD",
    CLEAR_SALESTEAMHEAD: "CLEAR_SALESTEAMHEAD",

    REQUEST_SCREENINGCALLS: "REQUEST_SCREENINGCALLS",
    RECEIVED_SCREENINGCALLS: "RECEIVED_SCREENINGCALLS",
    ERROR_SCREENINGCALLS: "ERROR_SCREENINGCALLS",
    CLEAR_SCREENINGCALLS: "CLEAR_SCREENINGCALLS",

    REQUEST_ADD_SCREENINGCALLS: "REQUEST_ADD_SCREENINGCALLS",
    RECEIVED_ADD_SCREENINGCALLS: "RECEIVED_ADD_SCREENINGCALLS",
    RECEIVED_UPDATE_SCREENINGCALLS: "RECEIVED_UPDATE_SCREENINGCALLS",
    RECEIVED_DELETE_SCREENINGCALLS: "RECEIVED_DELETE_SCREENINGCALLS",
    ERROR_ADD_SCREENINGCALLS: "ERROR_ADD_SCREENINGCALLS",


    REQUEST_ADMIN_SCREENINGCALLS: "REQUEST_ADMIN_SCREENINGCALLS",
    RECEIVED_ADMIN_SCREENINGCALLS: "RECEIVED_ADMIN_SCREENINGCALLS",
    ERROR_ADMIN_SCREENINGCALLS: "ERROR_ADMIN_SCREENINGCALLS",
    CLEAR_ADMIN_SCREENINGCALLS: "CLEAR_ADMIN_SCREENINGCALLS",



    REQUEST_TECHNICALPREPERATOR: "REQUEST_TECHNICALPREPERATOR",
    RECEIVED_TECHNICALPREPERATOR: "RECEIVED_TECHNICALPREPERATOR",
    ERROR_TECHNICALPREPERATOR: "ERROR_TECHNICALPREPERATOR",
    CLEAR_TECHNICALPREPERATOR: "CLEAR_TECHNICALPREPERATOR",


    REQUEST_SUBMISSIONS: "REQUEST_SUBMISSIONS",
    RECEIVED_SUBMISSIONS: "RECEIVED_SUBMISSIONS",
    ERROR_SUBMISSIONS: "ERROR_SUBMISSIONS",
    CLEAR_SUBMISSIONS: "CLEAR_SUBMISSIONS",

    REQUEST_ADD_SUBMISSIONS: "REQUEST_ADD_SUBMISSIONS",
    RECEIVED_ADD_SUBMISSIONS: "RECEIVED_ADD_SUBMISSIONS",
    RECEIVED_UPDATE_SUBMISSIONS: "RECEIVED_UPDATE_SUBMISSIONS",
    RECEIVED_DELETE_SUBMISSIONS: "RECEIVED_DELETE_SUBMISSIONS",
    ERROR_ADD_SUBMISSIONS: "ERROR_ADD_SUBMISSIONS",

    REQUEST_COPYHELPER: "REQUEST_COPYHELPER",
    RECEIVED_COPYHELPER: "RECEIVED_COPYHELPER",
    ERROR_COPYHELPER: "ERROR_COPYHELPER",
    CLEAR_COPYHELPER: "CLEAR_COPYHELPER",

    REQUEST_ADD_COPYHELPER: "REQUEST_ADD_COPYHELPER",
    RECEIVED_ADD_COPYHELPER: "RECEIVED_ADD_COPYHELPER",
    RECEIVED_UPDATE_COPYHELPER: "RECEIVED_UPDATE_COPYHELPER",
    RECEIVED_DELETE_COPYHELPER: "RECEIVED_DELETE_COPYHELPER",
    ERROR_ADD_COPYHELPER: "ERROR_ADD_COPYHELPER",

    REQUEST_LEARNINGRESOURCES: "REQUEST_LEARNINGRESOURCES",
    RECEIVED_LEARNINGRESOURCES: "RECEIVED_LEARNINGRESOURCES",
    ERROR_LEARNINGRESOURCES: "ERROR_LEARNINGRESOURCES",
    CLEAR_ORG_LEARNINGRESOURCES: "CLEAR_ORG_LEARNINGRESOURCES",

    REQUEST_ADD_LEARNINGRESOURCES: "REQUEST_ADD_LEARNINGRESOURCES",
    RECEIVED_ADD_LEARNINGRESOURCES: "RECEIVED_ADD_LEARNINGRESOURCES",
    RECEIVED_UPDATE_LEARNINGRESOURCES: "RECEIVED_UPDATE_LEARNINGRESOURCES",
    RECEIVED_DELETE_LEARNINGRESOURCES: "RECEIVED_DELETE_LEARNINGRESOURCES",
    ERROR_ADD_LEARNINGRESOURCES: "ERROR_ADD_LEARNINGRESOURCES",

    REQUEST_LEARNING_PLANS: "REQUEST_LEARNING_PLANS",
    RECEIVED_LEARNING_PLANS: "RECEIVED_LEARNING_PLANS",
    ERROR_LEARNING_PLANS: "ERROR_LEARNING_PLANS",
    CLEAR_ORG_LEARNING_PLANS: "CLEAR_ORG_LEARNING_PLANS",

    REQUEST_ADD_LEARNING_PLANS: "REQUEST_ADD_LEARNING_PLANS",
    RECEIVED_ADD_LEARNING_PLANS: "RECEIVED_ADD_LEARNING_PLANS",
    RECEIVED_UPDATE_LEARNING_PLANS: "RECEIVED_UPDATE_LEARNING_PLANS",
    RECEIVED_DELETE_LEARNING_PLANS: "RECEIVED_DELETE_LEARNING_PLANS",
    ERROR_ADD_LEARNING_PLANS: "ERROR_ADD_LEARNING_PLANS",


    REQUEST_MARKETING_HISTORY: "REQUEST_MARKETING_HISTORY",
    RECEIVED_MARKETING_HISTORY: "RECEIVED_MARKETING_HISTORY",
    ERROR_MARKETING_HISTORY: "ERROR_MARKETING_HISTORY",
    CLEAR_MARKETING_HISTORY: "CLEAR_ORG_MARKETING_HISTORY",


    REQUEST_CONSTULTANT_LEARNING_PLANS: "REQUEST_CONSTULTANT_LEARNING_PLANS",
    RECEIVED_CONSTULTANT_LEARNING_PLANS: "RECEIVED_CONSTULTANT_LEARNING_PLANS",
    ERROR_CONSTULTANT_LEARNING_PLANS: "ERROR_CONSTULTANT_LEARNING_PLANS",
    CLEAR_CONSTULTANT_LEARNING_PLANS: "CLEAR_CONSTULTANT_LEARNING_PLANS",

    // Get Roles
};
