import axios from 'axios';


export default class OtherService {
    get(url, isAuthRequired) {
        let config;
        if (isAuthRequired) {
            config = {
                headers: {
                    'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
                    'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`,
                    'Access-Control-Allow-Origin': '*',
                }
            };
        } else {
            config = {};
        }

        return axios.get(url, config)
            .then(res => {
                if (res) {
                    return {
                        status: true,
                        res: res.data,
                        etag: res.headers.etag
                    };
                }
            }).catch((err) => {

                if (err) {
                    if (err.response && err.response.status == 401) {

                        localStorage.clear();
                        window.location.reload();



                    } else if (err.response && err.response.data) {

                        if (err.response.data.errorResponse) {
                            return {
                                status: false,
                                errMessage: err.response.data.errorResponse[0] ? err.response.data.errorResponse[0].message : ''
                            };

                        } else {
                            return err.response.data
                        }

                    } else {
                        return {
                            status: false,
                            errMessage: err.message
                        };
                    }

                }
            });
    }
    getWithCustonAuthToken(url, isAuthRequired, token) {
        let config;
        if (isAuthRequired) {
            config = {
                headers: {
                    'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
                    'Authorization': `Bearer ${token}`,
                    'Access-Control-Allow-Origin': '*',
                }
            };
        } else {
            config = {};
        }

        return axios.get(url, config)
            .then(res => {
                if (res) {
                    return {
                        status: true,
                        res: res.data
                    };
                }
            }).catch((err) => {
                if (err) {
                    if (err.response && err.response.status == 401) {

                        localStorage.clear();
                        window.location.reload();



                    } else if (err.response && err.response.data) {

                        if (err.response.data.errorResponse) {
                            return {
                                status: false,
                                errMessage: err.response.data.errorResponse[0] ? err.response.data.errorResponse[0].message : ''
                            };

                        } else {
                            return err.response.data
                        }

                    } else {
                        return {
                            status: false,
                            errMessage: err.message
                        };
                    }

                }
            });
    }

    post(url, data, isAuthRequired, addHeaders) {
        let config;
        if (isAuthRequired) {
            config = {
                headers: {
                    'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
                    'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`,
                    'Access-Control-Allow-Origin': '*',
                    ...addHeaders
                }
            };
        } else {
            config = {};
        }
        return axios.post(url, data, config)
            .then(res => {

                if (res) {
                    return {
                        status: true,
                        res: res.data
                    };
                }
            }).catch((err) => {

                if (err) {
                    if (err.response && err.response.status == 401) {

                        localStorage.clear();
                        window.location.reload();



                    } else if (err.response && err.response.data) {

                        if (err.response.data.errorResponse) {
                            return {
                                status: false,
                                errMessage: err.response.data.errorResponse[0] ? err.response.data.errorResponse[0].message : ''
                            };

                        } else {
                            return err.response.data
                        }

                    } else {
                        return {
                            status: false,
                            errMessage: err.message
                        };
                    }
                }

            });
    }
    put(url, data, isAuthRequired, addHeaders) {
        let config = {};
        if (isAuthRequired) {
            config = {
                headers: {
                    'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
                    'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`,
                    'Access-Control-Allow-Origin': '*',
                    ...addHeaders
                }
            };
        } else {
            config = {};
        }

        // const etag = localStorage.getItem('etag');
        // if (etag) {
        //     if (config && config.headers) {
        //         config.headers["If-Match"] = etag;
        //     } else {
        //         config = {
        //             headers
        //                 : { "If-Match": etag }
        //         }
        //             ;
        //     }

        // }


        return axios.put(url, data, config)
            .then(res => {
                if (res) {
                    return {
                        status: true,
                        res: res.data
                    };
                }
            }).catch((err) => {
                if (err) {
                    if (err.response && err.response.status == 401) {

                        localStorage.clear();
                        window.location.reload();



                    } else if (err.response && err.response.data) {

                        if (err.response.data.errorResponse) {
                            return {
                                status: false,
                                errMessage: err.response.data.errorResponse[0] ? err.response.data.errorResponse[0].message : ''
                            };

                        } else {
                            return err.response.data
                        }

                    } else {
                        return {
                            status: false,
                            errMessage: err.message
                        };
                    }

                }
            });
    }
    delete(url, isAuthRequired) {
        let config;
        if (isAuthRequired) {
            config = {
                headers: {
                    'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
                    'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`,
                    'Access-Control-Allow-Origin': '*',
                }
            };
        } else {
            config = {};
        }
        return axios.delete(url, config)
            .then(res => {
                if (res) {
                    return {
                        status: true,
                        res: res.data
                    };
                }
            }).catch((err) => {
                if (err) {
                    if (err.response && err.response.status == 401) {

                        localStorage.clear();
                        window.location.reload();



                    } else if (err.response && err.response.data) {

                        if (err.response.data.errorResponse) {
                            return {
                                status: false,
                                errMessage: err.response.data.errorResponse[0] ? err.response.data.errorResponse[0].message : ''
                            };

                        } else {
                            return err.response.data
                        }

                    } else {
                        return {
                            status: false,
                            errMessage: err.message
                        };
                    }

                }
            });
    }
    postWithCustonAuthToken(url, data, isAuthRequired, authToken) {
        let config;
        if (isAuthRequired) {
            config = {
                headers: {
                    'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
                    'Authorization': `Bearer ${authToken}`,
                    'Access-Control-Allow-Origin': '*',
                }
            };
        } else {
            config = {};
        }
        return axios.post(url, data, config)
            .then(res => {
                if (res) {
                    return {
                        status: true,
                        res: res.data
                    };
                }
            }).catch((err) => {
                if (err) {
                    if (err.response && err.response.status == 401) {

                        localStorage.clear();
                        window.location.reload();



                    } else if (err.response && err.response.data) {

                        if (err.response.data.errorResponse) {
                            return {
                                status: false,
                                errMessage: err.response.data.errorResponse[0] ? err.response.data.errorResponse[0].message : ''
                            };

                        } else {
                            return err.response.data
                        }

                    } else {
                        return {
                            status: false,
                            errMessage: err.message
                        };
                    }

                }
            });
    }
    postWithCustonAuthToken1(url, data, isAuthRequired, authToken) {
        let config;
        if (isAuthRequired) {
            config = {
                headers: {
                    'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
                    'Authorization': authToken,
                    'Access-Control-Allow-Origin': '*',
                }
            };
        } else {
            config = {};
        }
        return axios.post(url, data, config)
            .then(res => {
                if (res) {
                    return {
                        status: true,
                        res: res.data
                    };
                }
            }).catch((err) => {
                if (err) {
                    if (err.response && err.response.status == 401) {

                        localStorage.clear();
                        window.location.reload();



                    } else if (err.response && err.response.data) {

                        if (err.response.data.errorResponse) {
                            return {
                                status: false,
                                errMessage: err.response.data.errorResponse[0] ? err.response.data.errorResponse[0].message : ''
                            };

                        } else {
                            return err.response.data
                        }

                    } else {
                        return {
                            status: false,
                            errMessage: err.message
                        };
                    }

                }
            });
    }
    getWithCustonAuthToken1(url, isAuthRequired, token) {
        let config;
        if (isAuthRequired) {
            config = {
                headers: {
                    'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
                    'Authorization': token,
                    'Access-Control-Allow-Origin': '*',
                }
            };
        } else {
            config = {};
        }

        return axios.get(url, config)
            .then(res => {
                if (res) {
                    return {
                        status: true,
                        res: res.data
                    };
                }
            }).catch((err) => {
                if (err) {
                    if (err.response && err.response.status == 401) {

                        localStorage.clear();
                        window.location.reload();



                    } else if (err.response && err.response.data) {

                        if (err.response.data.errorResponse) {
                            return {
                                status: false,
                                errMessage: err.response.data.errorResponse[0] ? err.response.data.errorResponse[0].message : ''
                            };

                        } else {
                            return err.response.data
                        }

                    } else {
                        return {
                            status: false,
                            errMessage: err.message
                        };
                    }

                }
            });
    }
}