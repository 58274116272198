import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import LoadingComponent from "../../../LoadingComponent";

import { Dialog } from "primereact/dialog";

import {
    CONSULTANT_MENU_TAB,
    CONSULTANT_STATUS,
} from "../../../Utils/constants";
import { Dropdown } from "primereact/dropdown";
import { BasicLazyParams } from "../../../Utils/constants";
import ServerlessService from "../../../Service/ServerlessService";
import { Tooltip } from "primereact/tooltip";
import Authorize, { PERMISSIONS } from "../../../session/authorize";

import AssignContentWriter from "./assignContentWriter";
import ConsultantNotes from "../documentPreperator/consultantNotes";
import ConsultantMonitor from "../documentPreperator/consultantMonitor";
import { Card } from "primereact/card";
import BaseComponent from "../../common/BaseComponent";
import {
    getConsultants,
    resetLazyConsultants,
    deleteConsultants,
} from "../../../store/actions";
import { OverlayPanel } from "primereact/overlaypanel";
import { BreadCrumb } from "primereact/breadcrumb";
import { logOut } from "../../../Utils";

class Consultants extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowView: false,
            viewTable: [],
            visible: false,
            isShowConfirmationDialog: false,
            consultants: [],
            customers: [],
            isShowAssignTable: false,
            isShowUnAssignTable: false,
            editData: null,
            id: null,
            renewalData: null,
            tableHistorysData: null,
            isShowRenewal: false,
            globalSearch: "",
            lazyParams: {
                ...BasicLazyParams,
                sortField: "createdAt",
                sortOrder: 1,
            },
            selectedReason: "",
            operation: null,
            prevLocation: null,
            hoveredStatus: null,
            selectedStatus: "",
            overlayVisible: false,
        };
        this.serverlessService = new ServerlessService();
        this.overlayRef = React.createRef();
    }

    onHide = () => {
        this.setState({
            visible: false,
            isShowRenewal: false,
            editData: null,
        });
    };

    ;

    deleteTable = async () => {
        this.setState({
            isShowConfirmationDialog: false,
        });
        var _id = this.state.selectedId;

        await this.props.deleteConsultants({ _id });
    };

    getConsultants = () => {
        let { lazyParams, globalSearch } = this.state;

        let { tab } = this.props;

        let urlEnd = "/consultants/consultant";
        if (tab === CONSULTANT_MENU_TAB.CONTENT_WRITER) {
            urlEnd = "/consultants/consultant/contentwriter";
        } else {
        }

        this.setState({
            isLoading: true,
            consultants: [],
        });

        const url = `${urlEnd}?limit=${lazyParams.rows}&page=${lazyParams.page + 1
            }&search=${globalSearch}${lazyParams.sortField
                ? `&sort=${lazyParams.sortOrder === 1 ? "" : "-"}${lazyParams.sortField
                }`
                : ""
            }`;
        this.serverlessService
            .post(url, { status: CONSULTANT_STATUS.CREATED }, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                        consultants: res.res.data,
                        totalRecords: res.res.totalRecords,
                    });
                    this.toast.show({
                        severity: "success",
                        summary: "consultants fetched",
                        detail: res.errMessage,
                        life: 3000,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    componentDidMount() {
        if (!this.props.consultants?.length) {
            this.props.getConsultants({ status: this.props.selectedStatus });
        }
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.consultantAddedOrUpdated !=
            this.props.consultantAddedOrUpdated
        ) {
            this.props.history.push("/admin/consultants");
        }
    }

    onGlobalSearch = (e) => {
        this.props.getConsultants({
            globalSearch: e.target.value,
            status: this.props.selectedStatus,
        });
    };

    onPage = (event) => {
        this.props.getConsultants({
            lazyParams: event,
            status: this.props.selectedStatus,
        });
    };

    onSort = (event) => {
        this.props.getConsultants({
            lazyParams: event,
            status: this.props.selectedStatus,
        });
    };

    onFilter = (event) => {
        event["first"] = 0;
        this.props.getConsultants({
            lazyParams: event,
            status: this.props.selectedStatus,
        });
    };







    showConsultantNotes = (rowData) => {
        this.setState({
            isShowConsultantNotes: true,
            editData: rowData,
        });
    };



    showConsultantMonitor = (rowData) => {
        this.setState({
            isShowConsultantMonitor: true,
            editData: rowData,
        });
    };



    footerContent = () => {
        return (
            <div>
                <Button
                    label="Cancel"
                    className="p-button-text hover:bg-primary"
                    onClick={this.onHide}
                />
                <Button
                    label="Renewal"
                    autoFocus
                    onClick={this.onRenewalClick}
                />
            </div>
        );
    };

    actionsBody = (rowData) => {
        const showAssignToContentWriterButton =
            !rowData.contentWritingAssignedTo;
        const columnSize = showAssignToContentWriterButton ? "col-2" : "col-3";
        return (
            <div className="p-grid row">
                <Authorize permId={PERMISSIONS.CONSULTANTS_EDIT}>
                    <div className="col-2">
                        <span
                            onClick={() => {
                                const _rowData = { ...rowData };
                                this.props.history.push(
                                    `/admin/cosultant/edit/${rowData._id}`,
                                    { fromOperation: "edit" }
                                );
                            }}
                            data-pr-tooltip="Edit Consultant"
                            data-pr-position="top"
                            className={`p-text tableEdit${rowData._id}`}
                        >
                            <i className="pi pi-pencil" />
                        </span>
                        <Tooltip target={`.tableEdit${rowData._id}`} />
                    </div>
                </Authorize>

                <Authorize permId={PERMISSIONS.CONSULTANTS_SHOW_CONSULTANT}>
                    <div className="col-2">
                        <span
                            onClick={() => {
                                // this.props.history.push(`/admin/cosultant/edit/${rowData._id}`)
                                // this.setState({
                                //     isShowConsultant: true,
                                //     editData: rowData,
                                // });
                                this.props.history.push(
                                    `/admin/cosultant/view/${rowData._id}`
                                );
                            }}
                            data-pr-tooltip="Show Consultant"
                            data-pr-position="top"
                            className={`p-text abc${rowData._id}`}
                        >
                            <i className="pi pi-eye" />
                        </span>
                        <Tooltip target={`.abc${rowData._id}`} />
                    </div>
                </Authorize>
                <Authorize permId={PERMISSIONS.CONSULTANTS_MONITOR}>
                    <div className="col-2">
                        <span
                            onClick={() => {
                                // this.props.history.push(
                                //     `/admin/cosultant/monitor/${rowData._id}`
                                // );
                                this.setState({
                                    isShowConsultantMonitor: true,
                                    editData: rowData,
                                });
                            }}
                            data-pr-tooltip="Consultant Monitor"
                            data-pr-position="top"
                            className={`p-text tableHistory${rowData._id}`}
                        >
                            <i className="pi pi-comments" />
                        </span>
                        <Tooltip target={`.tableHistory${rowData._id}`} />
                    </div>
                </Authorize>
                <Authorize permId={PERMISSIONS.CONSULTANTS_TRACKER}>
                    <div className="col-2">
                        <span
                            onClick={() => {
                                this.props.history.push(
                                    `/admin/cosultant/tracker/${rowData._id}`
                                );
                            }}
                            data-pr-tooltip="Consultant Tracker"
                            data-pr-position="top"
                            className={`p-text tableHistory${rowData._id}`}
                        >
                            <i className="pi pi-building" />
                        </span>
                        <Tooltip target={`.tableHistory${rowData._id}`} />
                    </div>
                </Authorize>
                <Authorize permId={PERMISSIONS.CONSULTANTS_NOTES}>
                    <div className="col-2">
                        <span
                            onClick={() => {
                                // this.props.history.push(
                                //     `/admin/cosultant/notes/${rowData._id}`
                                // );
                                this.setState({
                                    isShowConsultantNotes: true,
                                    editData: rowData,
                                });
                            }}
                            data-pr-tooltip="Notes (Only visible to you.)"
                            data-pr-position="top"
                            className={`p-text tableHistory${rowData._id}`}
                        >
                            <i className="pi pi-book" />
                        </span>
                        <Tooltip target={`.tableHistory${rowData._id}`} />
                    </div>
                </Authorize>
                <Authorize permId={PERMISSIONS.CONSULTANTS_DELETE}>
                    <div className="col-2">
                        <span
                            onClick={() =>
                                this.showDeleteTableDialog(rowData._id)
                            }
                            data-pr-tooltip="Delete Consultant"
                            data-pr-position="top"
                            className={`p-text tableDelete${rowData._id}`}
                        >
                            <i className="pi pi-trash" />
                        </span>
                        <Tooltip target={`.tableDelete${rowData._id}`} />
                    </div>
                </Authorize>

                {rowData.status === CONSULTANT_STATUS.CREATED && (
                    <Authorize
                        permId={
                            PERMISSIONS.CONSULTANTS_ASSIGN_TO_CONTENT_WRITER
                        }
                    >
                        <div className="col-2">
                            {!rowData.contentWritingAssignedTo && (
                                <>
                                    <span
                                        onClick={() =>
                                            this.setState({
                                                isShowAssignToContentWriter: true,
                                                selectedCustomer: rowData,
                                            })
                                        }
                                        data-pr-tooltip="Assign To Content Writer"
                                        data-pr-position="top"
                                        className={`p-text tableDelete${rowData._id}`}
                                    >
                                        <i className="pi pi-directions" />
                                    </span>
                                    <Tooltip
                                        target={`.tableDelete${rowData._id}`}
                                        content="Assign To Content Writer" // Provide content for the tooltip
                                    />
                                </>
                            )}
                        </div>
                    </Authorize>
                )}
            </div>
        );
    };



    showDeleteTableDialog = (id) => {
        this.setState({
            isShowConfirmationDialog: true,
            selectedId: id,
        });
    };

    onCloseConfirmation = () => {
        this.setState({
            isShowConfirmationDialog: false,
        });
    };

    footerContentforDelete = () => (
        <div>
            <Button
                label="Cancel"
                className=" p-button-text hover:bg-primary mr-3"
                onClick={() => {
                    this.setState({
                        isShowConfirmationDialog: false,
                    });
                }}
            />
            <Button
                label="Ok"
                onClick={this.deleteTable}
                autoFocus
                className=" p-button-primary "
            />
        </div>
    );

    getColorCode = (createdAt) => {
        const currentTime = new Date();
        const elapsedTime = currentTime - new Date(createdAt);

        const elapsedMinutes = elapsedTime / (1000 * 60);

        const percentage = (elapsedMinutes / (60 * 24 * 60)) * 100;

        if (percentage <= 30) {
            return "#1abc9c"; // Turquoise
        } else if (percentage <= 60) {
            return "#3498db"; // Blue
        } else if (percentage <= 90) {
            return "#f39c12"; // Orange
        } else {
            return "#e74c3c"; // Red
        }
    };

    handleMouseEnter = (event, rowData) => {
        const daysCount = this.calculateDaysCount(rowData.createdAt);
        this.setState({ hoveredStatus: daysCount + " Days Completed" });
        this.overlayRef.current.toggle(event);
    };

    handleMouseLeave = () => {
        this.setState({ hoveredStatus: null });
        this.overlayRef.current.hide();
    };

    calculateDaysCount = (createdAt) => {
        const createdAtDate = new Date(createdAt);
        const today = new Date();
        const timeDifference = today.getTime() - createdAtDate.getTime();
        const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));
        return daysDifference;
    };
    exportCSV = () => {
        this.dt.exportCSV();
    };

    onFilterChange = (e) => {
        debugger
        this.setState({ selectedStatus: e.value });
        this.props.getConsultants({ status: e.value == 'all' ? '' : e.value });
    };

    getStatusDetails(status) {
        switch (status) {
            case CONSULTANT_STATUS.CREATED:
                return {
                    message: "Created",
                    details: "The process has been initiated.",
                };
            case CONSULTANT_STATUS.CONTENT_WRITING:
                return {
                    message: "Content Writing",
                    details: "Content is being written.",
                };
            case CONSULTANT_STATUS.DOCUMENT_PREPERATING:
                return {
                    message: "Document Preparing",
                    details: "Documents are being prepared.",
                };
            case CONSULTANT_STATUS.DOCUMENT_EDITING:
                return {
                    message: "Document Editing",
                    details: "Documents are being edited.",
                };
            case CONSULTANT_STATUS.DOCUMENT_VERIFYING:
                return {
                    message: "Document Verifying",
                    details: "Documents are being verified.",
                };
            case CONSULTANT_STATUS.TECH_PREPARING:
                return {
                    message: "Technical Preparing",
                    details: "Technical aspects are being prepared.",
                };
            case CONSULTANT_STATUS.MARKETING_REVIEW:
                return {
                    message: "Marketing Review",
                    details: "Undergoing marketing review.",
                };
            case CONSULTANT_STATUS.READY_TO_MARKET:
                return {
                    message: "Ready to Market",
                    details: "Ready to be marketed.",
                };
            case CONSULTANT_STATUS.MARKETING:
                return {
                    message: "Marketing",
                    details: "Currently being marketed.",
                };
            case CONSULTANT_STATUS.COMPLETED:
                return {
                    message: "Completed",
                    details: "The process is completed.",
                };
            case CONSULTANT_STATUS.HOLD:
                return {
                    message: "On Hold",
                    details: "The process is currently on hold.",
                };
            case CONSULTANT_STATUS.STOPPED:
                return {
                    message: "Stopped",
                    details: "The process has been stopped.",
                };
            default:
                return { message: "Unknown", details: "Status is unknown." };
        }
    }

    statusTemplate = (rowData) => {
        const { message, details } = this.getStatusDetails(rowData.status);
        return (
            <div>
                {message}
                {/* <p>{details}</p> */}
            </div>
        );
    };
    render() {
        const header = (
            <div className="">
                <div class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 top-100 z-1 shadow-2 lg:shadow-none border-1 lg:border-none border-gray-800">
                    <ul class="list-none flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li>
                            <p className="mx-0 my-1 ss-header">Consultants</p>
                        </li>
                    </ul>
                    <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li class="flex-order-2 lg:flex-order-0">
                            <ul className="right-0">
                                <ul className="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                                    <Authorize
                                        permId={
                                            PERMISSIONS.CONSULTANTS_GENERAL_SEARCH
                                        }
                                    >
                                        <li className="flex-order-2 lg:flex-order-0 ml-4 ">
                                            {this.state.showSearch && (
                                                <InputText
                                                    placeholder="Search"
                                                    className=""
                                                    value={
                                                        this.props.globalSearch
                                                    }
                                                    onChange={
                                                        this.onGlobalSearch
                                                    }
                                                />
                                            )}
                                        </li>
                                    </Authorize>
                                    <Authorize
                                        permId={
                                            PERMISSIONS.CONSULTANTS_GENERAL_SEARCH
                                        }
                                    >
                                        <li className="flex-order-2 lg:flex-order-0 ml-4 cursor-pointer">
                                            <i
                                                data-pr-tooltip="Search"
                                                data-pr-position="bottom"
                                                className="pi pi-search text-base lg:text-2xl mr-2 ss-tbl-srch"
                                                onClick={(e) => {
                                                    this.setState({
                                                        showSearch:
                                                            !this.state
                                                                .showSearch,
                                                    });
                                                }}
                                            ></i>
                                        </li>
                                        <Tooltip
                                            className="table-li-tooltip"
                                            autoHide={false}
                                            target=".ss-tbl-srch"
                                        />
                                    </Authorize>
                                    <Authorize
                                        permId={
                                            PERMISSIONS.CONSULTANTS_GENERAL_DOWNLOAD
                                        }
                                    >
                                        <li className="border-top-1 border-gray-800 lg:border-top-none ml-4 mr-4 cursor-pointer">
                                            <i
                                                data-pr-tooltip="Download"
                                                data-pr-position="bottom"
                                                className="pi pi-download  text-base lg:text-2xl mr-2 ss-tbl-dwnld"
                                                onClick={this.exportCSV}
                                            ></i>
                                        </li>
                                        <Tooltip
                                            className="table-li-tooltip"
                                            target=".ss-tbl-dwnld"
                                        />
                                    </Authorize>
                                </ul>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="flex justify-content-between flex-wrap">
                    <div class="flex font-bold border-round w-4"></div>
                    <div class="flex absolute right-0 align-items-right  font-bold border-round w-8 "></div>
                </div>
            </div>
        );
        const statusOptions = [
            { label: "All", value: "all" },

            { label: "Created", value: CONSULTANT_STATUS.CREATED },
            {
                label: "Content Writing",
                value: CONSULTANT_STATUS.CONTENT_WRITING,
            },
            {
                label: "Document Preparing",
                value: CONSULTANT_STATUS.DOCUMENT_PREPERATING,
            },
            {
                label: "Document Editing",
                value: CONSULTANT_STATUS.DOCUMENT_EDITING,
            },
            {
                label: "Document Verifying",
                value: CONSULTANT_STATUS.DOCUMENT_VERIFYING,
            },
            {
                label: "Tech Preparing",
                value: CONSULTANT_STATUS.TECH_PREPARING,
            },
            {
                label: "Marketing Review",
                value: CONSULTANT_STATUS.MARKETING_REVIEW,
            },
            {
                label: "Ready to Market",
                value: CONSULTANT_STATUS.READY_TO_MARKET,
            },
            { label: "Marketing", value: CONSULTANT_STATUS.MARKETING },
            { label: "Completed", value: CONSULTANT_STATUS.COMPLETED },
            { label: "Hold", value: CONSULTANT_STATUS.HOLD },
            { label: "Stopped", value: CONSULTANT_STATUS.STOPPED },
        ];

        const items = [{ label: "Admin" }, { label: "Consultants" }];

        const home = {
            icon: "pi pi-home",
            url: "http://localhost:3000/#/admin",
        };
        return (
            <>
                {/* */}
                <div className="h-screen overflow-auto ss-data-table">
                    <Card>
                        <BreadCrumb model={items} home={home} />
                        <Toolbar
                            className="sp-toolbar sp-mt20"
                            right={() => {
                                return (
                                    <div className="p-col">
                                        <Dropdown
                                            value={this.props.selectedStatus == '' ? 'all' : this.props.selectedStatus}
                                            options={statusOptions}
                                            style={{ width: "210px" }}
                                            onChange={this.onFilterChange}
                                            placeholder="Select a status"
                                        />
                                    </div>
                                );
                            }}
                            left={() => {
                                return (
                                    <Authorize
                                        permId={PERMISSIONS.CONSULTANTS_CREATE}
                                    >
                                        <Button
                                            label="New"
                                            icon="pi pi-plus"
                                            className="m-3"
                                            outlined
                                            aria-label="Create"
                                            onClick={() =>
                                                this.props.history.push(
                                                    `/admin/cosultant/add`,
                                                    { fromOperation: "add" }
                                                )
                                            }
                                        />
                                    </Authorize>
                                );
                            }}
                        ></Toolbar>

                        <Authorize permId={PERMISSIONS.CONSULTANTS_LIST}>
                            <DataTable
                                ref={(el) => (this.dt = el)}
                                size="100vw"
                                value={this.props.consultants}
                                lazy
                                header={header}
                                scrollHeight="400px"
                                onSort={this.onSort}
                                onPage={this.onPage}
                                onFilter={this.onFilter}
                                first={this.props.lazyParams.first}
                                last={this.props.totalRecords}
                                rows={this.props.lazyParams.rows}
                                totalRecords={this.props.totalRecords}
                                paginator
                                paginatorLeft
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                rowsPerPageOptions={[
                                    5, 10, 25, 50, 100, 200, 300, 500,
                                ]}
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                scrollable
                                responsiveLayout="scroll"
                                rowHover
                                dataKey="_id"
                                selection={this.state.selectedCustomer}
                                onSelectionChange={(e) =>
                                    this.setState({ selectedCustomer: e.value })
                                }
                                selectionMode="single"
                            >
                                <Column
                                    field="name"
                                    header="Full Name"
                                ></Column>
                                <Column field="mobile" header="Phone"></Column>
                                <Column field="email" header="Email"></Column>

                                <Column
                                    field="statusbar"
                                    header="Timeline"
                                    body={(rowData) => {
                                        let color = this.getColorCode(
                                            rowData.createdAt
                                        );
                                        let iconClass = "";

                                        if (color === "#1abc9c") {
                                            iconClass = "pi pi-check";
                                        } else if (color === "#3498db") {
                                            iconClass = "pi pi-info-circle";
                                        } else if (color === "#f39c12") {
                                            iconClass =
                                                "pi pi-exclamation-triangle";
                                        } else if (color === "#e74c3c") {
                                            iconClass = "pi pi-times-circle";
                                        }

                                        return (
                                            <React.Fragment>
                                                <Button
                                                    className="p-button-rounded p-button-text"
                                                    onMouseEnter={(e) =>
                                                        this.handleMouseEnter(
                                                            e,
                                                            rowData
                                                        )
                                                    }
                                                    onMouseLeave={
                                                        this.handleMouseLeave
                                                    }
                                                >
                                                    <i
                                                        className={iconClass}
                                                        style={{ color: color }}
                                                    />
                                                </Button>
                                                <OverlayPanel
                                                    ref={this.overlayRef}
                                                    appendTo={document.body}
                                                    showCloseIcon={false}
                                                >
                                                    {this.state.hoveredStatus}
                                                </OverlayPanel>
                                            </React.Fragment>
                                        );
                                    }}
                                />
                                <Column
                                    field="rolePrefered"
                                    header="Role"
                                ></Column>
                                <Column
                                    field="status"
                                    header="Status"
                                    body={this.statusTemplate}
                                ></Column>
                                <Column
                                    header="Actions"
                                    body={(rowData) =>
                                        this.actionsBody(rowData)
                                    }
                                ></Column>
                            </DataTable>
                        </Authorize>
                    </Card>
                </div>

                {this.state.isShowAssignToContentWriter && (
                    <AssignContentWriter
                        selectedCustomer={this.state.selectedCustomer}
                        onCancel={() => {
                            this.setState({
                                isShowAssignToContentWriter: false,
                                selectedCustomer: null,
                            });
                        }}
                        onHide={() => {
                            let thisObj = this;

                            this.setState(
                                {
                                    isShowAssignToContentWriter: false,
                                    selectedCustomer: null,
                                },
                                () => {
                                    thisObj.toast.show({
                                        severity: "success",
                                        summary:
                                            "User assigned to content writer.",
                                        detail: "User assigned successfully",
                                        life: 3000,
                                    });

                                    thisObj.props.getConsultants({
                                        status: this.props.selectedStatus,
                                    });
                                }
                            );
                        }}
                    />
                )}


                {this.state.isShowConsultantNotes && (
                    <ConsultantNotes
                        consultant={this.state.editData}
                        onHide={() => {
                            this.setState({
                                isShowConsultantNotes: false,
                                editData: null,
                            });
                        }}
                    />
                )}

                {this.state.isShowConsultantMonitor && (
                    <ConsultantMonitor
                        consultant={this.state.editData}
                        onHide={() => {
                            this.setState({
                                isShowConsultantMonitor: false,
                                editData: null,
                            });
                        }}
                    />
                )}

                {this.state.isShowConfirmationDialog && (
                    <Dialog
                        header="Are you Sure ?"
                        visible={this.state.isShowConfirmationDialog}
                        style={{ width: "15vw" }}
                        onHide={this.onCloseConfirmation}
                        footer={this.footerContentforDelete}
                        draggable={false}
                    >
                        <p>Deleted Consultant can't be restored.</p>
                    </Dialog>
                )}

                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
                {(this.state.isLoading || this.props.isLoading) && (
                    <LoadingComponent />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    consultants: state?.consultants?.consultants,
    totalRecords: state?.consultants?.totalRecords,
    lazyParams: state?.consultants?.lazyParams,
    isLoading: state?.consultants?.isLoading,
    selectedStatus: state?.consultants?.selectedStatus,
    consultantAddedOrUpdated: state?.consultants?.consultantAddedOrUpdated,
});

export default BaseComponent(Consultants, mapStateToProps, {
    getConsultants,
    resetLazyConsultants,
    deleteConsultants,
});
