import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { SERVERLESS_URL } from './../Service/config';

import axios from 'axios';
import { saveUserSession, sessionsChecked } from './../store/actions'

export default function (ComposedComponent) {
    class Authentication extends Component {



        initiateGetSession = () => {
            console.log('session index.js')
            let userType = localStorage.getItem('userType');


            if (userType) {


                const url = `${SERVERLESS_URL}/admin/session`;

                let config = {
                    headers: {
                        'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
                        'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`
                    }
                };
                axios.get(url, config)
                    .then(res => {
                        if (res && res.data) {
                            this.setState({
                                isLoading: false
                            }, () => {
                                this.props.saveUserSession(res.data);
                            })

                        } else {
                            this.props.sessionsChecked();
                            this.setState({
                                isLoading: false
                            })
                        }
                    }).catch((err) => {
                        this.setState({
                            isLoading: false
                        })


                    });
            } else {
                this.props.sessionsChecked();
            }
        }
        componentDidMount() {
            if (!this.props.authenticated) {

                this.initiateGetSession();

                // retrieveUserFromLocalStorage(this.props.dispatch)
                //     .then((res) => {
                //         if (res) {
                //             this.props.authUser(res);
                //             this.props.getUserData();
                //         }
                //     })
                //     .catch((e) => {

                //         this.props.history.push('/login');
                //     });
            }

            if (this.props.isSessionChecked && !this.props.authenticated) {
                this.props.history.push('/login')
            }
        }

        componentDidUpdate(prevProps) {
            if (prevProps.authenticated !== this.props.authenticated) {
                if (!this.props.authenticated) {
                    this.initiateGetSession();


                }
            }

            if (prevProps.isSessionChecked != this.props.isSessionChecked && (this.props.isSessionChecked && !this.props.authenticated)) {
                this.props.history.push('/login')
            }
        }



        render() {
            return (
                <>
                    <ComposedComponent {...this.props} />
                </>
            );
        }
    }

    function mapStateToProps(state) {
        return {
            user: state.user,
            userType: state?.user?.userType,
            authenticated: state?.user?.authenticated,
            isSessionChecked: state?.user?.isSessionChecked
        };
    }



    return connect(mapStateToProps, { saveUserSession, sessionsChecked })(withRouter(Authentication));
}



