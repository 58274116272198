import React, { Component } from 'react';
import { Chart } from 'primereact/chart';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import ServerlessService from "../../../Service/ServerlessService";
import { logOut } from '../../../Utils';


class InterSubCharts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recruiterList: [],
      selectedRecruiter: "",
      startDate: "",
      endDate: "",
      submissionsData: null,
      interviewsData: null,
      chartData: null,
    };
    this.serverlessService = new ServerlessService();
  }

  componentDidMount() {
    this.getRecruiters();
  }

  getRecruiters = () => {
    const urlEnd = "/admin/get-privilege-users";
    const requestBody = {
      privilegeType: "isBenchSales"
    }

    this.serverlessService
      .post(urlEnd, requestBody, true)
      .then((res) => {
        if (res && res.status && res.res.status) {
          this.setState({
            recruiterList: res.res.data,
          });
        }
      })
      .catch((e) => {
        if (e?.response?.status > 400 && e?.response?.status < 500) {
          logOut()
        }
        console.error("Error fetching recruiters:", e);
      });
  };

  handleRecruiterChange = (selectedRecruiter) => {
    this.setState({ selectedRecruiter });
  };

  handleStartDateChange = (startDate) => {
    this.setState({ startDate });
  };

  handleEndDateChange = (endDate) => {
    this.setState({ endDate });
  };


  //   fetchData = () => {
  //     const { selectedRecruiter, startDate, endDate } = this.state;

  //     // Fetch submissions data
  //     const submissionsApi = `/consultants/submissions?recruiterId=${selectedRecruiter}&startDate=${startDate}&endDate=${endDate}`;
  //     this.serverlessService.get(submissionsApi,true)
  //       .then((res) => {
  //         if (res && res.status === 200) {
  //           this.setState({ submissionsData: res.res.data }, () => {
  //             this.prepareChartData();
  //           });
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching submissions:", error);
  //       });

  //     // Fetch interviews data
  //     const interviewsApi = `/consultants/interviews?recruiterId=${selectedRecruiter}&startDate=${startDate}&endDate=${endDate}`;
  //     this.serverlessService.get(interviewsApi,true)
  //       .then((res) => {
  //         if (res && res.status === 200) {
  //           this.setState({ interviewsData: res.res.data }, () => {
  //             this.prepareChartData();
  //           });
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching interviews:", error);
  //       });
  //   };

  fetchData = () => {
    const { selectedRecruiter, startDate, endDate } = this.state;

    // Fetch submissions data
    const submissionsApi = `/consultants/submissions?recruiterId=${selectedRecruiter}&startDate=${startDate}&endDate=${endDate}`;
    const submissionsPromise = this.serverlessService.get(submissionsApi, true)
      .then((res) => {
        console.log('Submissions data:', res);

        return res.res.data;

      })
      .catch((error) => {
        console.error("Error fetching submissions:", error);
        return [];
      });

    // Fetch interviews data
    const interviewsApi = `/consultants/interviews?recruiterId=${selectedRecruiter}&startDate=${startDate}&endDate=${endDate}`;
    const interviewsPromise = this.serverlessService.get(interviewsApi, true)
      .then((res) => {
        console.log('Interviews data:', res);

        return res.res.data;

      })
      .catch((error) => {
        console.error("Error fetching interviews:", error);
        return [];
      });

    // Wait for both promises to resolve
    Promise.all([submissionsPromise, interviewsPromise])
      .then(([submissionsData, interviewsData]) => {
        console.log('Submissions:', submissionsData);
        console.log('Interviews:', interviewsData);
        this.setState({ submissionsData, interviewsData }, () => {
          // Call prepareChartData after both submissions and interviews data are fetched
          this.prepareChartData();
        });
      });
  };



  prepareChartData() {
    const { submissionsData, interviewsData } = this.state;

    // Count submissions and interviews
    const submissionsCount = submissionsData ? submissionsData.length : 0;
    const interviewsCount = interviewsData ? interviewsData.length : 0;
    console.log(submissionsCount, interviewsCount, "kfjdlskfjljl");

    // Prepare chart data
    const chartData = {
      labels: ['Submissions', 'Interviews'],
      datasets: [
        {
          label: 'Count',
          backgroundColor: ['rgba(255, 99, 132, 0.6)', 'rgba(54, 162, 235, 0.6)'],
          data: [submissionsCount, interviewsCount]
        }
      ]
    };

    // Set chartData state
    this.setState({ chartData });
    console.log(chartData, "ChartDataea")
  }
  render() {
    const { recruiterList, selectedRecruiter, chartData, startDate, endDate } = this.state;
    return (
      <div className="p-grid p-dir-col">
        <div className="p-grid">
          <div className="p-col">
            <div className="p-field p-d-flex">
              <label htmlFor="recruiter">Select Recruiter: </label>
              <Dropdown
                id="recruiter"
                optionLabel="name"
                optionValue="_id"
                value={selectedRecruiter}
                options={recruiterList}
                onChange={(e) => this.handleRecruiterChange(e.value)}
                placeholder="Select"
              />
            </div>
          </div>
          <div className="p-col">
            <div className="p-field p-d-flex">
              <label htmlFor="startDate">Start Date: </label>
              <Calendar
                id="startDate"
                value={startDate}
                onChange={(e) => this.handleStartDateChange(e.value)}
                dateFormat="yy-mm-dd"
                showIcon
                className="p-inputtext"
              />
            </div>
          </div>
          <div className="p-col">
            <div className="p-field p-d-flex">
              <label htmlFor="endDate">End Date: </label>
              <Calendar
                id="endDate"
                value={endDate}
                onChange={(e) => this.handleEndDateChange(e.value)}
                dateFormat="yy-mm-dd"
                showIcon
                className="p-inputtext"
              />
            </div>
          </div>
          <div className="p-col">
            <div className="p-field p-d-flex">
              <button className="p-button p-button-primary" onClick={this.fetchData}>Fetch Data</button>
            </div>
          </div>
        </div>
        <div className="p-col">
          {chartData && (
            <Chart type="bar" data={chartData} />
          )}
        </div>
      </div>
    );




  }
}

export default InterSubCharts;
