import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import LoadingComponent from "../../../LoadingComponent";
import { Dialog } from "primereact/dialog";
import { BasicLazyParams } from "../../../Utils/constants";
import ServerlessService from "../../../Service/ServerlessService";
import { Tooltip } from "primereact/tooltip";
import Authorize, { PERMISSIONS } from "../../../session/authorize";
import AssignBackToContentPre from "./assignContentWriter";
import ConsultantNotes from "../documentPreperator/consultantNotes";
import ConsultantMonitor from "../documentPreperator/consultantMonitor";
import { Card } from "primereact/card";
import BaseComponent from "../../common/BaseComponent";
import {
    getDocumentVerifier,
    resetLazyDocumentVerifier,
} from "../../../store/actions";
import { BreadCrumb } from "primereact/breadcrumb";
import { logOut } from "../../../Utils";

class DocumentPreparator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowView: false,
            viewTable: [],
            visible: false,
            isShowConfirmationDialog: false,
            consultants: [],
            customers: [],
            isShowAssignTable: false,
            isShowUnAssignTable: false,
            editData: null,
            id: null,
            renewalData: null,
            tableHistorysData: null,
            rowDataOfTable: null,
            isShowRenewal: false,
            globalSearch: "",
            lazyParams: {
                ...BasicLazyParams,
                sortField: "createdAt",
                sortOrder: 1,
            },
            selectedReason: "",
        };
        this.serverlessService = new ServerlessService();
    }

    onHide = () => {
        this.setState({
            visible: false,
            isShowRenewal: false,
            editData: null,
        });
    };


    getDocumentVerifier = () => {
        let { lazyParams, globalSearch } = this.state;

        this.setState({
            isLoading: true,
            consultants: [],
        });

        const url = `/consultants/consultant/get-to-verify?limit=${lazyParams.rows
            }&page=${lazyParams.page + 1}&search=${globalSearch}${lazyParams.sortField
                ? `&sort=${lazyParams.sortOrder === 1 ? "" : "-"}${lazyParams.sortField
                }`
                : ""
            }`;
        this.serverlessService
            .post(url, {}, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                        consultants: res.res.data,
                        totalRecords: res.res.totalRecords,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    componentDidMount() {
        if (!this.props.documentVerifierConsultants?.length) {
            this.props.getDocumentVerifier();
        }
    }

    onGlobalSearch = (e) => {
        this.props.getDocumentVerifier({ globalSearch: e.target.value });
    };

    onPage = (event) => {
        this.props.getDocumentVerifier({ lazyParams: event });
    };

    onSort = (event) => {
        this.props.getDocumentVerifier({ lazyParams: event });
    };

    onFilter = (event) => {
        event["first"] = 0;
        this.props.getDocumentVerifier({ lazyParams: event });
    };


    actionsBody = (rowData) => {
        return (
            <div className="p-grid row">
                <Authorize
                    permId={
                        PERMISSIONS.DOCUMENT_VERIFICATION_ASSIGN_BACK_TO_PREPERATION_TEAM
                    }
                >
                    <div className="col-2">
                        <span
                            onClick={() =>
                                this.setState({
                                    isShowAssignToContentWriter: true,
                                    selectedCustomer: rowData,
                                })
                            }
                            data-pr-tooltip="Assign Back To Preparation Team"
                            data-pr-position="top"
                            className={`p-text tableDelete${rowData._id}`}
                        >
                            <i className="pi pi-directions-alt" />
                        </span>
                        <Tooltip target={`.tableDelete${rowData._id}`} />
                    </div>{" "}
                </Authorize>
                <Authorize
                    permId={
                        PERMISSIONS.DOCUMENT_VERIFICATION_ASSIGN_TO_TECH_PREP_TEAM
                    }
                >
                    <div className="col-2">
                        <span
                            onClick={() =>
                                this.setState({
                                    isShowAssignToContentWriter: true,
                                    selectedCustomer: rowData,
                                    isAssingToPreperationTeam: true,
                                })
                            }
                            data-pr-tooltip="Assign To Tech Preperation Team"
                            data-pr-position="top"
                            className={`p-text ass${rowData._id}`}
                        >
                            <i className="pi pi-directions" />
                        </span>
                        <Tooltip target={`.ass${rowData._id}`} />
                    </div>
                </Authorize>
                <Authorize
                    permId={PERMISSIONS.DOCUMENT_VERIFICATION_SHOW_CONSULTANT}
                >
                    <div className="col-2">
                        <span
                            onClick={() => {
                                this.props.history.push(
                                    `/admin/cosultant/view/${rowData._id}`
                                );
                            }}
                            data-pr-tooltip="Show Consultant"
                            data-pr-position="top"
                            className={`p-text abc${rowData._id}`}
                        >
                            <i className="pi pi-eye" />
                        </span>
                        <Tooltip target={`.abc${rowData._id}`} />
                    </div>
                </Authorize>
                <Authorize permId={PERMISSIONS.DOCUMENT_VERIFICATION_MONITOR}>
                    <div className="col-2">
                        <span
                            onClick={() => {
                                this.setState({
                                    isShowConsultantMonitor: true,
                                    editData: rowData,
                                });
                            }}
                            data-pr-tooltip="Consultant Monitor"
                            data-pr-position="top"
                            className={`p-text tableHistory${rowData._id}`}
                        >
                            <i className="pi pi-comments" />
                        </span>
                        <Tooltip target={`.tableHistory${rowData._id}`} />
                    </div>
                </Authorize>
                <Authorize permId={PERMISSIONS.DOCUMENT_VERIFICATION_TRACKER}>
                    <div className="col-2">
                        <span
                            onClick={() => {
                                this.props.history.push(
                                    `/admin/cosultant/tracker/${rowData._id}`
                                );
                            }}
                            data-pr-tooltip="Consultant Tracker"
                            data-pr-position="top"
                            className={`p-text tableHistory${rowData._id}`}
                        >
                            <i className="pi pi-building" />
                        </span>
                        <Tooltip target={`.tableHistory${rowData._id}`} />
                    </div>
                </Authorize>
                <Authorize permId={PERMISSIONS.DOCUMENT_VERIFICATION_NOTES}>
                    <div className="col-2">
                        <span
                            onClick={() => {
                                this.setState({
                                    isShowConsultantNotes: true,
                                    editData: rowData,
                                });
                            }}
                            data-pr-tooltip="Notes (Only visible to you.)"
                            data-pr-position="top"
                            className={`p-text tableHistory${rowData._id}`}
                        >
                            <i className="pi pi-book" />
                        </span>
                        <Tooltip target={`.tableHistory${rowData._id}`} />
                    </div>
                </Authorize>
            </div>
        );
    };



    exportCSV = () => {
        this.dt.exportCSV();
    };
    render() {
        const header = (
            <div className="">
                <div class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 top-100 z-1 shadow-2 lg:shadow-none border-1 lg:border-none border-gray-800">
                    <ul class="list-none flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li>
                            <p className="mx-0 my-1 ss-header">Consultants</p>
                        </li>
                    </ul>
                    <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li class="flex-order-2 lg:flex-order-0">
                            <ul className="right-0">
                                <ul className="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                                    <Authorize
                                        permId={
                                            PERMISSIONS.DOCUMENT_VERIFICATION_GENERAL_SEARCH
                                        }
                                    >
                                        <li className="flex-order-2 lg:flex-order-0 ml-4 ">
                                            {this.state.showSearch && (
                                                <InputText
                                                    placeholder="Search"
                                                    className=""
                                                    value={
                                                        this.props.globalSearch
                                                    }
                                                    onChange={
                                                        this.onGlobalSearch
                                                    }
                                                />
                                            )}
                                        </li>
                                    </Authorize>
                                    <Authorize
                                        permId={
                                            PERMISSIONS.DOCUMENT_VERIFICATION_GENERAL_SEARCH
                                        }
                                    >
                                        <li className="flex-order-2 lg:flex-order-0 ml-4 cursor-pointer">
                                            <i
                                                data-pr-tooltip="Search"
                                                data-pr-position="bottom"
                                                className="pi pi-search text-base lg:text-2xl mr-2 ss-tbl-srch"
                                                onClick={(e) => {
                                                    this.setState({
                                                        showSearch:
                                                            !this.state
                                                                .showSearch,
                                                    });
                                                }}
                                            ></i>
                                        </li>
                                        <Tooltip
                                            className="table-li-tooltip"
                                            autoHide={false}
                                            target=".ss-tbl-srch"
                                        />
                                    </Authorize>
                                    <Authorize
                                        permId={
                                            PERMISSIONS.DOCUMENT_VERIFICATION_GENERAL_DOWNLOAD
                                        }
                                    >
                                        <li className="border-top-1 border-gray-800 lg:border-top-none ml-4 mr-4 cursor-pointer">
                                            <i
                                                data-pr-tooltip="Download"
                                                data-pr-position="bottom"
                                                className="pi pi-download  text-base lg:text-2xl mr-2 ss-tbl-dwnld"
                                                onClick={this.exportCSV}
                                            ></i>
                                        </li>
                                        <Tooltip
                                            className="table-li-tooltip"
                                            target=".ss-tbl-dwnld"
                                        />
                                    </Authorize>
                                </ul>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        );
        const items = [{ label: "Admin" }, { label: "Document Verifications" }];

        const home = {
            icon: "pi pi-home",
            url: "http://localhost:3000/#/admin",
        };
        return (
            <>
                <div className="h-screen overflow-auto ss-data-table">
                    <Card>
                        <BreadCrumb model={items} home={home} />
                        <Authorize
                            permId={PERMISSIONS.DOCUMENT_VERIFICATION_LIST}
                        >
                            <DataTable
                                ref={(el) => (this.dt = el)}
                                value={this.props.documentVerifierConsultants}
                                lazy
                                header={header}
                                scrollHeight="400px"
                                onSort={this.onSort}
                                onPage={this.onPage}
                                onFilter={this.onFilter}
                                first={this.props.lazyParams?.first}
                                last={this.props.totalRecords}
                                rows={this.props.lazyParams?.rows}
                                totalRecords={this.props.totalRecords}
                                paginator
                                paginatorLeft
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                rowsPerPageOptions={[
                                    5, 10, 25, 50, 100, 200, 300, 500,
                                ]}
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                scrollable
                                responsiveLayout="scroll"
                                rowHover
                                dataKey="_id"
                                selection={this.state.selectedCustomer}
                                onSelectionChange={(e) =>
                                    this.setState({ selectedCustomer: e.value })
                                }
                                selectionMode="single"
                            >
                                <Column
                                    field="name"
                                    header="Full Name"
                                ></Column>
                                <Column field="mobile" header="Phone"></Column>
                                <Column field="email" header="Email"></Column>
                                <Column
                                    field="rolePrefered"
                                    header="Role"
                                ></Column>

                                <Column
                                    header="Actions"
                                    body={(rowData) =>
                                        this.actionsBody(rowData)
                                    }
                                ></Column>
                            </DataTable>
                        </Authorize>
                    </Card>
                </div>

                {this.state.isShowAssignToContentWriter && (
                    <AssignBackToContentPre
                        isAssingToPreperationTeam={
                            this.state.isAssingToPreperationTeam
                        }
                        selectedCustomer={this.state.selectedCustomer}
                        onCancel={() => {
                            this.setState({
                                isShowAssignToContentWriter: false,
                                selectedCustomer: null,
                                isAssingToPreperationTeam: false,
                            });
                        }}
                        onHide={() => {
                            let thisObj = this;

                            this.setState(
                                {
                                    isShowAssignToContentWriter: false,
                                    selectedCustomer: null,
                                    isAssingToPreperationTeam: false,
                                },
                                () => {
                                    thisObj.toast.show({
                                        severity: "success",
                                        summary: this.state
                                            .isAssingToPreperationTeam
                                            ? "User Assigned back to Preparation Team."
                                            : "User assigned to Tech preparation Team.",
                                        detail: "User assigned successfully",
                                        life: 3000,
                                    });
                                    thisObj.props.getDocumentVerifier();
                                }
                            );
                        }}
                    />
                )}

                {this.state.isShowNotes && (
                    <Dialog
                        header={"Notes"}
                        visible={true}
                        style={{ width: "49vw" }}
                        draggable={false}
                        onHide={() => {
                            this.setState({
                                isShowNotes: false,
                                editData: null,
                            });
                        }}
                    >
                        <p>{this.state.editData.notesToDocumentPreparator}</p>
                    </Dialog>
                )}


                {this.state.isShowConsultantNotes && (
                    <ConsultantNotes
                        consultant={this.state.editData}
                        onHide={() => {
                            this.setState({
                                isShowConsultantNotes: false,
                                editData: null,
                            });
                        }}
                    />
                )}
                {this.state.isShowConsultantMonitor && (
                    <ConsultantMonitor
                        consultant={this.state.editData}
                        onHide={() => {
                            this.setState({
                                isShowConsultantMonitor: false,
                                editData: null,
                            });
                        }}
                    />
                )}


                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
                {(this.state.isLoading || this.props.isLoading) && (
                    <LoadingComponent />
                )}
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    documentVerifierConsultants: state?.documentVerifiers?.documentVerifierConsultants,
    totalRecords: state?.documentVerifiers?.totalRecords,
    lazyParams: state?.documentVerifiers?.lazyParams,
    isLoading: state?.documentVerifiers?.isLoading,
});

export default BaseComponent(DocumentPreparator, mapStateToProps, {
    getDocumentVerifier,
    resetLazyDocumentVerifier,
});
