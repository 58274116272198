import { ACTIONS } from '../actionTypes';
import axios from 'axios';
import { SERVERLESS_URL } from './../../Service/config';
import { logOut } from '../../Utils';

export const getPermissionRequested = () => ({ type: ACTIONS.GET_PERMISSIONS_REQUESTED });
const getPermissionReqSucceeded = (data) => ({ type: ACTIONS.GET_PERMISSIONS_SUCCEEDED, data });
const getPermissionRequestFailed = () => ({ type: ACTIONS.GET_PERMISSIONS_FAILED });

const url = `${SERVERLESS_URL}/authorize/getPermissions`;

export const getPermissionDetails = () => {
    return axios({
        method: 'get',
        url,
        headers: {
            'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
            'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`,
            // 'app_type': process.env.REACT_APP_TARGET
        }
    });
};

export const getPermissionList = () => (dispatch) => {
    dispatch(getPermissionRequested());
    getPermissionDetails()
        .then((res) => {
            if (res.data) {
                dispatch(getPermissionReqSucceeded(res.data.data));
            } else {
                dispatch(getPermissionRequestFailed());
            }
        })
        .catch((e) => {
            if (e?.response?.status > 400 && e?.response?.status < 500) {
                logOut()
            }
            if (e.response && e.response.status == 401) {
                localStorage.clear();
                window.location.reload();
            } else {
                dispatch(getPermissionRequestFailed());
            }
        });
};
