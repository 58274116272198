import { BasicLazyParams } from "../../Utils/constants";
import { ACTIONS } from "../actionTypes";
export default (
    state = {
        contacts: [],
        isLoading: false,
        error: false,
        errorMessage: "",
        lazyParams: {
            ...BasicLazyParams,
            sortField: "-createdAt",
            sortOrder: 1,
        },
        globalSearch: "",
        totalRecords: 0,
    },
    action
) => {
    switch (action.type) {
        case ACTIONS.REQUEST_CONTACT:
            return {
                ...state,
                lazyParams: {
                    ...state.lazyParams,
                    ...action?.data?.lazyParams,
                },
                globalSearch: action?.data?.globalSearch || state?.globalSearch,
                isLoading: true,
                error: false,
            };
        case ACTIONS.RECEIVED_CONTACT:

            return {
                ...state,
                isLoading: false,
                contacts: action?.data?.data,
                totalRecords: action?.data?.totalRecords,
                lazyParams: {
                    ...state.lazyParams,
                    ...action?.data?.lazyParams,
                },
            };
        case ACTIONS.ERROR_CONTACT:
            return {
                ...state,
                isLoading: false,
                error: true,
                errorMessage: action?.err?.message,
            };
        case ACTIONS.CLEAR_CONTACT:
            return {
                isLoading: false,
                error: false,
                errorMessage: "",
                contacts: []
            };
        default:
            return state;
    }
};
