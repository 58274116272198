import * as React from "react";
import './styles.scss';


class FileUpload extends React.Component {
    //private services = new FileUploadServices();
    constructor(props) {
        super(props);
        this.state = {
            uploadedFileInfo: [],
            uploadedFilesInfo: [],
            path: this.props.path ? this.props.path : `${'baseURL'}/use/api/uploadfiles`

        }
        this.onChangeHandler = this.onChangeHandler.bind(this);
        this.FileUploadhandler = this.FileUploadhandler.bind(this);
        this.OnSucess = this.OnSucess.bind(this);
        this.onProgress = this.onProgress.bind(this);
    }

    // private postFiles(data: any) {
    //   var xhr = new XMLHttpRequest();
    //   xhr.open("POST", "http://localhost:7777/api/uploadfiles");
    //   xhr.upload.addEventListener("progress", function (evt) {
    //     if (evt.lengthComputable) {
    //       let percentComplete: any = evt.loaded / evt.total;
    //       let df: any = percentComplete.toFixed(2) * 100;
    ////       console.log(df.toFixed(0));
    ////       console.log(evt);
    //     }
    //   }, false);
    //   xhr.onloadstart = function (e) {
    //     console.log("start")
    //   }
    //   xhr.onloadend = function (e) {
    //     console.log("end")
    //     console.log(e);
    //   }
    //   xhr.send(data);
    //   xhr.onreadystatechange = function () {
    //     if (this.readyState == 4 && this.status == 200) {
    ////       console.log(xhr.response.toJSON());
    //     }
    //   };
    // }

    FileUploadhandler() {

    }

    OnSucess(some) {
        // console.log(some);
        // this.props.afterFilesAdded(some);
        // this.setState({
        //   uploadedFilesInfo : 
        // })
        //  console.log(this.state.uploadedFilesInfo);
    }


    onProgress(some) {
        //    console.log(some);
        //  this.props.onProgress(some);
        console.log(this.state.uploadedFilesInfo);

    }

    // onChangeHandler(event, onSucess, onProgress) {
    //     this.props.afterFilesUploaded(event.target.files);

    //     if (event.target.files !== null && event.target.files !== undefined && event.target.files.length > 0) {

    //         // this.props.onProgress(event.target.files);

    //         //  let uploadedFilesInfo: any= [];
    //         for (let i = 0; i < event.target.files.length; i++) {
    //             let needToUpload = true;
    //             if (this.state.uploadedFileInfo.length > 0) {
    //                 for (let j = 0; j < this.state.uploadedFileInfo.length; j++) {
    //                     if (event.target.files[i].name === this.state.uploadedFileInfo[j].data.originalName) {
    //                         needToUpload = false;
    //                     }
    //                 }
    //             }
    //             if (needToUpload) {
    //                 let datae = event.target.files[i];
    //                 const data = new FormData();
    //                 data.append('file', event.target.files[i]);
    //                 // this.services.uploadFiles(data).then((res:any) => {
    //                 //     // this.setState((prevState, prevProps) => ({
    //                 //     //     uploadedFileInfo : [...prevState.uploadedFileInfo, res]
    //                 //     //   }));
    //                 //     this.props.afterFilesUploaded(res);
    //                 // });
    //                 // this.postFiles(data);

    //                 var xhr = new XMLHttpRequest();
    //                 xhr.open("POST", this.state.path);
    //                 xhr.upload.addEventListener("progress", function (this, evt) {
    //                     if (evt.lengthComputable) {
    //                         let percentComplete = evt.loaded / evt.total;
    //                         let df = percentComplete.toFixed(2) * 100;
    //                         // console.log(df.toFixed(0));
    //                         // console.log(evt);
    //                         // console.log(xhr);
    //                         // console.log(xhr);
    //                         //datae.map((item : any) => { return item['d']= df.toFixed(0) });
    //                         // console.log(datae);
    //                         datae.progress = df.toFixed(0);
    //                         let db = datae;
    //                         db.progress = df.toFixed(0);
    //                         // uInfo = [...uInfo, datae];
    //                         //       console.log(db);
    //                         onProgress(db);
    //                         // console.log(datae);
    //                     }
    //                 }, false);
    //                 xhr.onloadstart = function (e) {

    //                 }
    //                 xhr.onloadend = function (e) {

    //                 }
    //                 xhr.send(data);
    //                 xhr.onreadystatechange =
    //                     function () {
    //                         if (this.readyState === 4 && this.status === 200) {
    //                             // console.log(this);
    //                             // console.log(this.response);
    //                             if (this.response) {
    //                                 var res = JSON.parse(this.response);
    //                                 //console.log(res);

    //                                 datae.response = res;
    //                                 //var de = datae
    //                                 //console.log(de);
    //                                 //onSucess(de);
    //                                 // 
    //                                 // onProgress(de);
    //                             }
    //                         }

    //                         if (this.readyState === 4 && this.status !== 200) {
    //                             alert('some error occurred.');
    //                         }
    //                     };



    //             }
    //         }
    //     }
    // }

    onChangeHandler(event) {
        this.props.afterFilesUploaded(event.target.files);


    }



    componentWillReceiveProps(newProps) {
        if (newProps.Reset === true) {
            this.setState({
                uploadedFileInfo: [],
                uploadedFilesInfo: []
            });
        }
    }
    getAcceptString(accept) {
        // if (accept) {
        //     if (accept == "images") {
        //        // return 'image/*'
        //        return ['image/jpeg', 'image/png', 'image/jpg'];
        //     } else if (accept == "audios") {
        //         return ['audio/mpeg'];
        //     } else if (accept == "videos") {
        //         return ['video/mp4', 'video/quicktime'];
        //     } else if (accept == "pdfs") {
        //         return ['application/pdf']
        //     } else {

        //         return 'audio/*,video/*,image/*,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,application/vnd.ms-powerpoint';
        //     }
        // } else {
        //     return 'audio/*,video/*,image/*,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,application/vnd.ms-powerpoint';
        // }
        if (accept) {
            if (accept == "images") {
                //return 'image';
                return ['image/jpeg', 'image/png', 'image/jpg'];
            } if (accept == "png") {
                //return 'image';
                return ['image/png'];
            } else if (accept == "audios") {
                return ['audio/mpeg'];
            } else if (accept == "videos") {
                return ['video/mp4', 'video/quicktime'];
            } else if (accept == "pdfs") {
                return ['application/pdf'];
            } else if (accept == 'documents') {
                return [
                    // Images
                    'image/jpeg',
                    'image/png',
                    'image/gif',
                    'image/bmp',
                    'image/webp',

                    // Documents
                    'application/msword',
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                    'application/vnd.ms-excel',
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'application/vnd.ms-powerpoint',
                    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                    'application/vnd.oasis.opendocument.text',

                    // PDF
                    'application/pdf',

                    // Other Document-related types
                    'text/plain',
                    'application/rtf',
                    'text/csv',
                ]
            } else if (accept == "imagevideo") {
                return ['video/mp4', 'video/quicktime', 'image/jpg', 'image/jpeg', 'image/png']
            } else {
                return [];
            }
        } else {
            return []
        }
    }

    render() {
        return (
            <>
                <label htmlFor={this.props.id} className="sp-icon attachment-icon" title="Add files" >
                    <i className="pi pi-file" ></i>
                </label>
                {/* accept="audio/*,video/*,image/*, application/pdf,application/vnd.ms-excel" */}
                <input
                    //accept="image/*"
                    accept={this.getAcceptString(this.props.accept)}
                    ref={this.props.inputRef} type="file" name="files" hidden={true} id={this.props.id} multiple={this.props.multiple} className="sp-file" onChange={(event) => this.onChangeHandler(event, this.OnSucess, this.onProgress)} />
            </>
        );
    }
}

export default FileUpload;


