import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import React, { Component } from 'react'
import NavFooter from '../../navFooter'
import { classNames } from "primereact/utils";
import Service from '../../../Service';
import ServerlessService from '../../../Service/ServerlessService';
import { BasicLazyParams } from '../../../Utils/constants';
import { Checkbox } from 'primereact/checkbox';
import { Divider } from 'primereact/divider';
import { logOut } from '../../../Utils';

export default class Checkout extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isAddProfile: false,
            profiles: [],
            lazyParams: { ...BasicLazyParams, sortField: 'updatedAt', sortOrder: 1 },
            globalSearch: '',
        }
        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }


    getProfiles = () => {
        let { lazyParams, globalSearch } = this.state;

        this.setState({
            isLoading: true,
            profiles: []
        });
        const url = `/mvisited/all?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}${lazyParams.sortField ? `&sort=${lazyParams.sortOrder == 1 ? '' : '-'}${lazyParams.sortField}` : ''}`;
        this.serverlessService.post(url, {}, true).then((res) => {


            if (res && res.status && res.res.status) {
                this.setState({
                    isLoading: false,
                    profiles: res.res.data,
                    totalRecords: res.res.totalRecords
                })

            } else {

                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occurred', detail: res.message, life: 3000 });
            }

        }).catch(e => {
            if (e?.response?.status > 400 && e?.response?.status < 500) {
                logOut()
            }
            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occurred', detail: e.message, life: 3000 });
        })
    }


    onPaymentSuccuess(obj) {
        console.log(obj);

    }

    openPaymentWindow = (data) => {
        var thisObj = this;
        let options = {
            "key": data.key,
            "amount": data.paymentInit.amount,
            "name": "Q GROUP MEDIA",
            "currency": "INR",
            "order_id": data.paymentInit.id,
            "description": "Please complete the payment.",
            "handler": function (response) {
                thisObj.onPaymentSuccuess(response);
            },

            "theme": {
                "color": "#ca1c1c"
            }
        };
        var thisWindow = window;
        let rzp = new thisWindow.Razorpay(options);
        rzp.open();
    }


    _submitForm() {
        if (this.isFormValid()) {
            let requestObj = this.state.donationForm;
            requestObj._id = Date().toString();
            if (requestObj._id == '') {
                requestObj._id = null;
            }
            this.setState({
                isLoading: true
            });
            this.service.postDataOrError(URLs.raiseDonationRequest, requestObj, false, false).then((res) => {
                if (res.status && res.statuscode === 23) {
                    this.setState({
                        response: res.data,
                        isLoading: false,
                        showModel: false,
                        donationForm: {
                            _id: this.props.User && this.props.User.User && this.props.User.User._id ? this.props.User.User._id : '',
                            firstName: this.props.User && this.props.User.User && this.props.User.User.firstName ? this.props.User.User.firstName : '',
                            phoneNumber: this.props.User && this.props.User.User && this.props.User.User.phoneNumber ? this.props.User.User.phoneNumber : '',
                            email: this.props.User && this.props.User.User && this.props.User.User.email ? this.props.User.User.email : '',
                            amount: ''
                        }
                    });
                    let data = res.data;

                }
                else {
                    this.setState({
                        isLoading: false,
                        errormsg: res.statusmsg
                    });

                    setTimeout(() => {
                        this.setState({
                            errormsg: ''
                        });
                    }, 5000);
                }
            });
        }
    }


    componentDidMount() {

        let data = {
            "paymentInit": {
                "id": "order_KqpTKdUVB6wTBN",
                "entity": "order",
                "amount": 10000,
                "amount_paid": 0,
                "amount_due": 10000,
                "currency": "INR",
                "receipt": "Matrimony Package",
                "offer_id": null,
                "status": "created",
                "attempts": 0,
                "notes": [],
                "created_at": 1670811084
            },
            "key": "rzp_test_0culdOcTScw84I"
        };


    }



    render() {
        return (
            <div className="surface-50 px-4 py-8 md:px-6 lg:px-8">
                <div className="text-900 font-medium text-3xl mb-5">Your order is almost done</div>
                <div className="grid -mr-3 -ml-3">
                    <div className="col-12 lg:col-9 p-3">
                        <div className="surface-card grid formgrid p-fluid border-round shadow-2 p-4">
                            <div className="col-12 mt-4 lg:mt-0 mb-4">
                                <div className="text-900 font-medium text-xl">Billing Address</div>
                            </div>
                            <div className="col-12 field mb-0">
                                <label htmlFor="country2" className="text-900 font-medium mb-3">Country</label>
                                <input inputid="country2" type="text" className="p-inputtext w-full mb-3" />
                            </div>
                            <div className="col-12 lg:col-6 field mb-0">
                                <label htmlFor="name2" className="text-900 font-medium mb-3">Name</label>
                                <input inputid="name2" type="text" className="p-inputtext w-full mb-3" />
                            </div>
                            <div className="col-12 lg:col-6 field mb-0">
                                <label htmlFor="surname2" className="text-900 font-medium mb-3">Surname</label>
                                <input inputid="surname2" type="text" className="p-inputtext w-full mb-3" />
                            </div>
                            <div className="col-12 field mb-0">
                                <label htmlFor="address3" className="text-900 font-medium mb-3">Apartment, Suite, etc.</label>
                                <input inputid="address3" type="text" className="p-inputtext w-full mb-3" />
                            </div>
                            <div className="col-12 lg:col-6 field mb-0">
                                <label htmlFor="pc2" className="text-900 font-medium mb-3">Postal Code</label>
                                <input inputid="pc2" type="text" className="p-inputtext w-full mb-3" />
                            </div>
                            <div className="col-12 lg:col-6 field mb-0">
                                <label htmlFor="city2" className="text-900 font-medium mb-3">City</label>
                                <input inputid="city2" type="text" className="p-inputtext w-full mb-3" />
                            </div>
                            <div className="col-12 lg:col-6  field mb-0">
                                <label htmlFor="phone" className="text-900 font-medium mb-3">Phone</label>
                                <input inputid="phone" type="text" className="p-inputtext w-full mb-3" />
                            </div>
                            <div className="col-12 lg:col-6  field mb-0">
                                <label htmlFor="email" className="text-900 font-medium mb-3">Email</label>
                                <input inputid="email" type="text" className="p-inputtext w-full mb-3" />
                            </div>

                        </div>

                    </div>
                    <div className="col-12 lg:col-3 p-3">
                        <div className="surface-card p-4 border-round shadow-2">
                            <div className="border-bottom-1 surface-border pb-4">
                                <span className="text-900 text-xl block">Order Summary</span>
                            </div>
                            <div className="border-bottom-1 surface-border my-3 py-2">
                                <div className="flex justify-content-between mb-3">
                                    <span className="text-900">Price</span>
                                    <span className="text-900">$123.00</span>
                                </div>
                                <div className="flex justify-content-between mb-3">
                                    <span className="text-900">Delivery</span>
                                    <span className="text-green-500">Free</span>
                                </div>
                                <div className="flex justify-content-between mb-3">
                                    <span className="text-900">Discount</span>
                                    <span className="text-900">$12.00</span>
                                </div>
                            </div>
                            <div className="border-bottom-1 surface-border my-3 py-2">
                                <div className="flex justify-content-between mb-3">
                                    <span className="text-900 font-medium">Total</span>
                                    <span className="text-900 font-bold">$111.00</span>
                                </div>
                            </div>
                            <Button className="surface-400 border-none hover:bg-primary w-full mt-3" label="Place Order" />
                        </div>
                    </div>
                </div>
            </div>


        )
    }
}
